/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react/no-string-refs */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable  sonarjs/no-nested-template-literals */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  makeStyles,
  Grid,
  RACButton,
  List,
  ListItem,
  Typography,
  Card,
  CardContent,
  RACSelect,
  RACDatePicker,
  CircularProgress,
  RACModalCard,
  RACToggle,
  RACTextbox,
} from '@rentacenter/racstrap';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { GetDropDownVals, GetFscContestDetail, GetReferenceValues, UpdateFscContestDetail } from '../../api/user';
import { MultiSelect } from 'react-multi-select-component';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { ReactComponent as Alerticon } from '../../assets/images/No-records.svg';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/x.svg';
import { ReactComponent as AddIcon } from '../../assets/images/add.svg';
import Modal from 'react-modal';
import { ReactComponent as FSCUpdateClose } from '../../assets/images/fsc-update-close.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import clsx from 'clsx';
const useClasses = makeStyles((theme: any) => ({
  customerToolbar: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1030,
    backgroundColor: `white`,
    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
  },
  fixedBottom: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1030,
    backgroundColor: `white`,
    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
    padding: '1rem',
  },
  multiSelect: {
    // "& div": {
    width: "100%",
    marginTop: "10px",
    marginRight: "18px",
    "& div": {
      "& div": {
        marginTop: '-4px',
        "& div": {
          marginTop: '7px'
        }
      }
    }
    // },
  },
  racFixedFooterMargin: {
    marginBottom: '120px',
  },
  spacerMT4: {
    marginTop: theme.typography.pxToRem(24),
  },
  modalFscMessage: {
    //eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: '16px',
    paddingBottom: '10px',
    paddingLeft: '13px',
  },
  fscModalTitle: {
    paddingLeft: '10px',
    fontSize: '20px',
    marginTop: '25px',
  },
  col1: {
    width: '30%',
    float: 'left',
  },
  col2: {
    width: '30%',
    float: 'left',
    marginLeft: '35px',
  },
  SDTitle: {
    fontFamily: 'OpenSans-semibold',
    fontSize: '16px',
    marginLeft: '13px',
  },
  EDTitle: {
    fontFamily: 'OpenSans-semibold',
    fontSize: '16px',
    marginLeft: '10px',
  },
  STDate: {
    fontFamily: 'OpenSans-semibold',
    fontSize: '16px',
    width: '124px',
    padding: '6px',
    marginLeft: '13px',
    background: '#d8d8fd',
    borderRadius: '5px',
    color: '#0303dd',
    marginBottom: '20px',
  },
  ENDate: {
    fontFamily: 'OpenSans-semibold',
    fontSize: '16px',
    width: '131px',
    padding: '6px',
    marginLeft: '11px',
    background: '#d8d8fd',
    borderRadius: '5px',
    color: '#0303dd',
    marginBottom: '20px',
  },
  calenderIcon: {
    float: 'left',
    marginTop: '4px',
    marginRight: '5px',
  },
  spacerMargin: {
    margin: theme.typography.pxToRem(8),
  },
  textEditorStyle: {
    "& div:last-child": {
      height: "300px",
    },
  },
  InlineFlex: {
    display: 'inline-flex',
  },
  ml2: {
    marginLeft: theme.typography.pxToRem(8),
  },
  loaderStyle: {
    border: 'none',
    backgroundColor: 'transparent',
    // userFocus: 'none',
    // MozUserFocus: 'none',
    outline: 'none',
  },
  RACLoaderPage: {
    textAlign: 'center',
    marginTop: '250px',
    fontSize: '16px',
    marginLeft: '30px',
    marginRight: '30px',
    outline: 'none',
  },
  my3: {
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },
  textCenter: {
    textAlign: 'center',
  },
  mt4: {
    marginTop: theme.typography.pxToRem(24),
  },
  RACPOPMsg: {
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0,
  },
  zindexforMultiSelectDDHigherVal: {
    zIndex: -1,
    position: 'relative'
  },
  zindexforMultiSelectDDLowerVal: {
    zIndex: 0,
    position: 'relative'
  },
  //multi select CSS
  demo: {
    '& .dropdown-content': {
      '& .search': {
        border: '1px solid #e3e3e3',
      },
    },
    '& .options': {
      overflowX: 'hidden !important',
    },
    display: 'inline-block'
  },
  customMultiselect: {
    '& .dropdown-container': {
      width: '210px !important',
      '& div': {
        width: '210px !important',
        overflow: 'hidden !important',
      },
    },
    '& .dropdown-heading': {
      height: '35px !important',
      width: '210px !important',
      paddingRight: '0px',
      '& .gray': {
        color: '#808080',
      },
      '& .dropdown-heading-dropdown-arrow': {
        color: '#212529',
      },
    },
  },
  mt5: {
    marginTop: '10px',
  },

  breadcrumb: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    marginBottom: theme.typography.pxToRem(10),
    listStyle: 'none',
  },
  breadcrumbItem: {
    width: 'auto',
    fontFamily: 'OpenSans-bold',
    fontSize: '12px',
  },
  breadcrumbItemActive: {
    width: 'auto',
    fontFamily: 'OpenSans-bold',
    fontSize: '14px',
    color: '#2179FE',
    paddingLeft: 0,
  },
  breadcrumbArrow: {
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: '5px solid transparent',
      borderLeft: '7px solid #707070',
      borderBottom: '5px solid transparent',
      marginTop: 0,
      float: 'left',
      paddingRight: '13px',
      color: '#6c757d',
    },
  },
  breadcrumblinkfont: {
    textDecoration: 'none',
    color: 'gray',
    fontFamily: 'OpenSans-bold',
    fontSize: '14px',
  },
  breadCrumPadding: {
    paddingLeft: '0px',
  },
  titleFSC: {
    padding: '10px 16px',
    fontFamily: 'OpenSans-bold',
    float: 'left',
    fontSize: '22px',
  },
  card: {
    borderRadius: '16px!important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
    width: '100%',
  },
  w100: {
    width: '100%',
  },
  statusFont: {
    padding: '13px 16px',
    fontFamily: 'OpenSans-bold',
    float: 'left',
    fontSize: '16px',
  },
  statusDropdownWidth: {
    // float: 'left',
    width: '20% !important',
    // padding: '3px 4px',
    // marginBottom: '20px',
    marginRight: '20px',
  },
  statusDropdownWidthForContest: {
    // float: 'left',
    width: '17% !important',
    // padding: '3px 4px',
    // marginBottom: '20px',
    marginRight: '11px',
  },
  statusDropdownWidthForMultiSelect: {
    // float: 'left',
    width: '17% !important',
    // padding: '3px 4px',
    // marginBottom: '20px',
    marginRight: '11px',
  },
  statusDropdownContent: {
    '& svg': {
      color: 'grey',
    },
    '& div': {
      fontSize: '15px',
      padding: '8px 15px',
      fontFamily: 'OpenSans-semibold',
      color: 'grey',
      borderColor: '#e5e5e5',
      '&:focus': {
        boxShadow: 'none',
      },
    },
  },
  datePicker: {
    float: 'left',
    width: '20%',
    marginRight: '20px',
    marginTop: '0.2rem',
  },
  startDate: {
    width: '100%',
    float: 'left',
    margin: '0px !important',
    padding: '3px 0px',
    '& input': {
      padding: '11px 12px',
      fontFamily: 'OpenSans-semibold',
      color: 'grey',
    },
  },
  endDate: {
    width: '100%',
    float: 'left',
    padding: '3px 0px',
    margin: '0px !important',
    '& input': {
      padding: '11px 12px',
      fontFamily: 'OpenSans-semibold',
      color: 'grey',
    },
  },
  searchTextBox: {
    width: '100%',
    '& input': {
      padding: '10px 12px',
      borderRadius: '5px',
      '&:focus': {
        boxShadow: 'none',
        borderColor: '#ced4da',
        borderRadius: '5px',
      },
    },
  },
  searchBox: {
    float: 'right',
    width: '20%',
    padding: '3px 30px',
  },
  colEvents: {
    width: '97%',
    margin: '0 1.5%',
    zIndex: 1,
    paddingRight: '0.75rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    marginLeft: '0px',
    borderBottom: '1px solid #e3e3e3',
  },
  updatePanel: {
    borderLeft: '3px solid #2E31BE !important',
    background: 'white',
    width: '100%',
    textAlign: 'inherit',
    position: 'relative',
    display: 'block',
    paddingBottom: '0rem',
    padding: '0.5rem 1rem',
    color: '#212529',
    textDecoration: 'none',
    paddingTop: '5px',
  },
  startDateTitle: {
    float: 'left',
    marginTop: '15px',
    fontFamily: 'OpenSans-semibold',
    color: 'grey',
  },
  startDateDay: {
    float: 'left',
    marginTop: '15px',
    marginLeft: '5px',
    fontFamily: 'OpenSans-semibold',
    width: '8%',
  },
  updateDetails: {
    fontFamily: 'OpenSans-semibold',
  },
  endDateTitle: {
    float: 'left',
    marginTop: '15px',
    fontFamily: 'OpenSans-semibold',
    color: '#c30707',
  },
  endDateDay: {
    float: 'left',
    marginTop: '15px',
    marginLeft: '5px',
    fontFamily: 'OpenSans-semibold',
    width: '8%',
    color: '#c30707',
  },
  togglePosition: {
    float: 'left',
    margin: '0px 100px',
    marginTop: '35px',
    marginRight: '15px',
  },
  toggleClass: {},
  createdByName: {
    float: 'left',
    marginTop: '15px',
    marginLeft: '5px',
    fontFamily: 'OpenSans-semibold',
  },
  statusToggleText: {
    float: 'left',
    marginTop: '15px',
    fontFamily: 'OpenSans-semibold',
  },
  footerGrid: {
    paddingTop: '120px !important',
    marginRight: '-0.75rem',
    marginLeft: '-0.75rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  footerFixed: {
    backgroundColor: '#fff !important',
    padding: '1rem !important',
    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%) !important',
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1030,
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  cancelButton: {
    textTransform: 'none',
    fontFamily: 'OpenSans-bold',
    border: 'none',
    fontSize: '18px',
    color: 'black',
    background: 'none',
    padding: '1rem 0px',
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
      border: 'none',
    },
  },
  createNewButton: {
    textTransform: 'none',
    backgroundColor: '#2179FE',
    fontFamily: 'OpenSans-bold',
    padding: '15px 30px',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: '#2179FE',
    },
  },
  dropDownLabel: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '0.5rem',
    fontSize: '17px',
  },
  dateLabel: {
    fontFamily: 'OpenSans-semibold',
    fontSize: '16px',
  },
  toolBarClass: {
    // float: 'left',
    border: '1px solid #d1d0d0 !important',
    background: '#f5f4f4',
    // width: '99.9%',
    margin: '0',
  },
  editorClass: {
    // float: 'left',
    border: '1px solid #d1d0d0',
    // width: '101%',
    height: '250px',
    overflow: 'hidden',
    width: '100%',
    padding: '0px 20px',
    float: 'left',
    fontSize: '17px',
  },
  editorToolbar: {
    // float: 'left',
    width: '55%',
    padding: '5px',
  },
  wrapperClass: {
    width: '100%',
  },
  messageLabel: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '0.6rem',
    fontSize: '17px',
    // float: 'left',
    width: '100%',
  },
  previewLabel: {
    width: '100%',
    float: 'left',
    fontFamily: 'OpenSans-semibold',
    marginBottom: '0.1rem',
    fontSize: '17px',
    padding: '5px',
    marginTop: '50px',
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },
  bgCard: {
    color: '#fff',
    borderRadius: '1rem',
    float: 'left',
    padding: '10px !important',
    height: '100%',
    width: 'inherit',
  },
  borderBlue: {
    backgroundColor: "#00BD26",
  },
  //MEDIUM
  borderViolet: {
    backgroundColor: "#FFA61E",
  },
  //HIGH
  borderYellow: {
    backgroundColor: "#E85C5C",
  },
  fscUpdateMessage: {
    float: 'left',
    fontFamily: 'OpenSans-regular',
    fontSize: '16px',
    marginLeft: '5px',
    marginTop: '4px',
  },
  readMore: {
    color: '#fff',
    marginLeft: '20px',
    fontSize: '16px',
    marginTop: '3px',
    fontFamily: 'OpenSans-regular',
    cursor: 'pointer',
    textDecoration: 'underline',
    float: 'left',
  },
  paddingPreviewCard: {
    padding: '0px',
  },
  cardMargin: {
    marginBottom: '30px',
  },
  cardGrid: {
    padding: '0px 15px',
  },
  boldIconClass: {
    background: '#f5f4f4',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  italicIconClass: {
    background: '#f5f4f4',
    '&:active': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  undoClass: {
    background: '#f5f4f4',
    '&:active': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  redoClass: {
    background: '#f5f4f4',
    '&:active': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));
export const CreateAndUpdateFscContest = (props: any | undefined) => {
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color"
  ];

  const types = {
    lob: [{ label: 'Select', value: '0' }],
    associationType: [{ label: 'Select', value: '0' }],
    country: [{ label: 'Select', value: '0' }],
    state: [{ label: 'Select', value: '0' }],
    store: [{ label: 'Select', value: '0' }],
    region: [{ label: 'Select', value: '0' }],
    district: [{ label: 'Select', value: '0' }],
    company: [{ label: 'Select', value: '0' }, { label: 'All', value: 'ALL' }],
    priority: [{ label: 'Select', value: '0' }, { label: 'high', value: '1' }, { label: 'medium', value: '2' }, { label: 'low', value: '3' }]

  }
  const history = useHistory();
  const classes = useClasses();
  const [dropDownTypes, setDropDownTypes] = useState<any>(types)
  const [dropDownValues, setDropDownVals] = useState<any>()

  const [currentPageInfo, setCurrentpageinfo] = useState<{ category: string, type: string }>()
  const [isSuccess, setisSuccess] = useState<any>(false);
  const [updateMsgPopup, setupdateMsgPopup] = useState<any>(false);
  const [loader, setloader] = useState(false);
  const [readMorePopup, setReadMorePopup] = useState(false);
  const [disableDate, setDisableDate] = useState(false);
  const [disableValidate, setDisableValidate] = useState(true);
  const [contestDropDown, setcontestDropDown] = useState<any>([]);
  const [contestNamePopup, setContestNamePopup] = useState<any>();
  const [newContestName, setNewContestName] = useState<any>([]);
  const [errorFIleHandler, seterrorFIleHandler] = useState<any>(false);
  const [isStartDateLessThanCurrentDate, setisStartDateLessThanCurrentDate] = useState<any>(false);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
    ],
  };
  const { quill, quillRef } = useQuill({ modules });
  const messageRef = useRef(null)
  console.log('messageRef..', messageRef)
  const [message, setmessage] = useState<any>('');
  const [createFscContestDetails, setcreateFscContestDetails] = useState<any>({
    contestId: '',
    contestName: '',
    organization: '',
    type: '',
    associationTypeVal: [],
    priority: '',
    startDate: '',
    endDate: '',
    message: '',
    isActive: '',
    operationCommunicationId: '',
    base64: '',
    fileName: ''
  })
  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const dropDownVals: any = {
    "orgDetails": [
      {
        "accountingunitnumber": "M0845",
        "accountingunitname": "M0845",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000231
      },
      {
        "accountingunitnumber": "M0846",
        "accountingunitname": "M0846",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000232
      },
      {
        "accountingunitnumber": "M0847",
        "accountingunitname": "M0847",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000233
      },
      {
        "accountingunitnumber": "M0848",
        "accountingunitname": "M0848",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000234
      },
      {
        "accountingunitnumber": "M0849",
        "accountingunitname": "M0849",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000235
      },
      {
        "accountingunitnumber": "M0850",
        "accountingunitname": "M0850",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000236
      },
      {
        "accountingunitnumber": "M0851",
        "accountingunitname": "M0851",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000237
      },
      {
        "accountingunitnumber": "M0852",
        "accountingunitname": "M0852",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000238
      },
      {
        "accountingunitnumber": "M0853",
        "accountingunitname": "M0853",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000239
      },
      {
        "accountingunitnumber": "M0854",
        "accountingunitname": "M0854",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000240
      },
      {
        "accountingunitnumber": "M0855",
        "accountingunitname": "M0855",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000241
      },
      {
        "accountingunitnumber": "M0673",
        "accountingunitname": "M0673",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190004462
      },
      {
        "accountingunitnumber": "M0990",
        "accountingunitname": "M0990",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190005502
      },
      {
        "accountingunitnumber": "M0726",
        "accountingunitname": "M0726",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190006403
      },
      {
        "accountingunitnumber": "M0725",
        "accountingunitname": "M0725",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190005282
      },
      {
        "accountingunitnumber": "M0671",
        "accountingunitname": "M0671",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190003782
      },
      {
        "accountingunitnumber": "M0669",
        "accountingunitname": "M0669",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190003582
      },
      {
        "accountingunitnumber": "DR003",
        "accountingunitname": "DR003",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190004602
      },
      {
        "accountingunitnumber": "R0705",
        "accountingunitname": "R0705",
        "accountingunittype": "REGION",
        "accountingunitlevel": "3",
        "orghierarchyid": 9999193615232
      },
      {
        "accountingunitnumber": "R0710",
        "accountingunitname": "R0710",
        "accountingunittype": "REGION",
        "accountingunitlevel": "3",
        "orghierarchyid": 9999193615233
      },
      {
        "accountingunitnumber": "M0292",
        "accountingunitname": "M0292",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190000190
      },
      {
        "accountingunitnumber": "M7024",
        "accountingunitname": "M7024",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999193620190
      },
      {
        "accountingunitnumber": "R0998",
        "accountingunitname": "R0998",
        "accountingunittype": "REGION",
        "accountingunitlevel": "3",
        "orghierarchyid": 9999192885188
      },
      {
        "accountingunitnumber": "M7030",
        "accountingunitname": "M7030",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999193620211
      },
      {
        "accountingunitnumber": "M7031",
        "accountingunitname": "M7031",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999193620220
      },
      {
        "accountingunitnumber": "R0713",
        "accountingunitname": "R0713",
        "accountingunittype": "REGION",
        "accountingunitlevel": "3",
        "orghierarchyid": 9999193615229
      },
      {
        "accountingunitnumber": "M1724",
        "accountingunitname": "M1724",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999191605189
      },
      {
        "accountingunitnumber": "M0698",
        "accountingunitname": "M0698",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190210187
      },
      {
        "accountingunitnumber": "M1041",
        "accountingunitname": "M1041",
        "accountingunittype": "DISTRICT",
        "accountingunitlevel": "4",
        "orghierarchyid": 9999190215187
      }
    ],
    "associationType": [
      {
        "association_type_id": 2,
        "association_type_name": "LOB",
        "association_type_desc": "Top level association type. Represents a line of business. Examples would be: ANOW, RTO",
        "created_by": "initload",
        "created_date": "2021-11-30T17:20:55.976785-06:00",
        "last_modified_by": "initload",
        "last_modified_date": "2021-11-30T17:20:55.976785-06:00",
        "display_value": "LOB",
        "display_seq": 10
      },
      {
        "association_type_id": 3,
        "association_type_name": "COUNTRY",
        "association_type_desc": "Country Code (Abbreviation). Data set sourced from COUNTRY table. The ASSOCIATION_REF_CODE refers to Country.abbreviation column in source table.",
        "created_by": "initload",
        "created_date": "2021-11-30T17:20:55.976785-06:00",
        "last_modified_by": "initload",
        "last_modified_date": "2021-11-30T17:20:55.976785-06:00",
        "display_value": "Country",
        "display_seq": 20
      },
      {
        "association_type_id": 4,
        "association_type_name": "STATE",
        "association_type_desc": "State Code (Abbreviation). Data set sourced from STATE_PROVINCE table. The ASSOCIATION_REF_CODE refers to State.abbreviation column in source table.",
        "created_by": "initload",
        "created_date": "2021-11-30T17:20:55.976785-06:00",
        "last_modified_by": "initload",
        "last_modified_date": "2021-11-30T17:20:55.976785-06:00",
        "display_value": "State",
        "display_seq": 30
      },
      {
        "association_type_id": 5,
        "association_type_name": "STORE",
        "association_type_desc": "Store Number. Data set sourced from STORE table. The ASSOCIATION_REF_CODE refers to Store.store_number column in source table.",
        "created_by": "initload",
        "created_date": "2021-11-30T17:20:55.976785-06:00",
        "last_modified_by": "initload",
        "last_modified_date": "2021-11-30T17:20:55.976785-06:00",
        "display_value": "Store",
        "display_seq": 40
      },
      {
        "association_type_id": 6,
        "association_type_name": "REGION",
        "association_type_desc": "Region Code (Abbreviation). Data set sourced from org_hierarchy table.\r\n    The Region code refers to org_hierarchy.accounting_unit_number column in source table.",
        "created_by": "initload",
        "created_date": "2021-10-27T10:14:32.026715-05:00",
        "last_modified_by": "initload",
        "last_modified_date": "2021-10-27T10:14:32.026715-05:00",
        "display_value": "Region",
        "display_seq": 50
      },
      {
        "association_type_id": 7,
        "association_type_name": "DISTRICT",
        "association_type_desc": "District Code (Abbreviation). Data set sourced from org_hierarchy table.\r\n    The District code refers to org_hierarchy.accounting_unit_number column in source table.",
        "created_by": "initload",
        "created_date": "2021-10-27T10:15:22.285149-05:00",
        "last_modified_by": "initload",
        "last_modified_date": "2021-10-27T10:15:22.285149-05:00",
        "display_value": "District",
        "display_seq": 60
      },
      {
        "association_type_id": 9,
        "association_type_name": "COMPANY",
        "association_type_desc": "Company Code (Abbreviation). Data set sourced from org_hierarchy table.\r\n    The Company code refers to org_hierarchy.accounting_unit_number column in source table.",
        "created_by": "initload",
        "created_date": "2021-12-01T10:00:20.096268-06:00",
        "last_modified_by": "initload",
        "last_modified_date": "2021-12-01T10:00:20.096268-06:00",
        "display_value": "Company",
        "display_seq": 70
      }
    ],
    "storeDetails": [
      {
        "store_id": 2202,
        "store_number": "02202",
        "open_date": "2006-11-15",
        "close_date": "2099-12-31",
        "state_province_id": 43,
        "language_id": 1,
        "store_name": "RANSHAW-ANTHRA PLAZA",
        "timezone_id": 17,
        "store_type_id": 22,
        "store_sub_type_id": 10,
        "pos_type": "SIMS",
        "payment_web_id": null,
        "cc_payment_merchant_id": null,
        "ach_payment_merchant_id": null,
        "braintree_merchant_account_id": "RentACenter_Venmo",
        "venmo_enabled": 1,
        "paypal_enabled": 1,
        "address_line_1": "9331 STATE ROUTE 61",
        "address_line_2": null,
        "city": "COAL TOWNSHIP",
        "zip": "17866-4199",
        "longitude": -76.507,
        "latitude": 40.790,
        "work_phone_number": "5706442224",
        "ent_store_id": null,
        "created_by": "LOAD",
        "created_date": "2011-05-05T09:40:14-05:00",
        "last_modified_by": "P_UPDATE_INACTIVE_NODES",
        "last_modified_date": "2021-10-13T01:28:28-05:00",
        "extract_date": "2016-05-12",
        "conversion_date": null,
        "epay_terminal_id": null
      },
      {
        "store_id": 4611,
        "store_number": "04611",
        "open_date": "2006-11-15",
        "close_date": "2099-12-31",
        "state_province_id": 43,
        "language_id": 1,
        "store_name": "TAMAQUA-BROAD ST",
        "timezone_id": 17,
        "store_type_id": 22,
        "store_sub_type_id": 10,
        "pos_type": "SIMS",
        "payment_web_id": null,
        "cc_payment_merchant_id": null,
        "ach_payment_merchant_id": null,
        "braintree_merchant_account_id": "RentACenter_Venmo",
        "venmo_enabled": 1,
        "paypal_enabled": 1,
        "address_line_1": "45 PLAZA DR STE 300",
        "address_line_2": null,
        "city": "TAMAQUA",
        "zip": "18252-4456",
        "longitude": -75.987,
        "latitude": 40.835,
        "work_phone_number": "5706685622",
        "ent_store_id": null,
        "created_by": "LOAD",
        "created_date": "2011-05-05T09:40:14-05:00",
        "last_modified_by": "P_UPDATE_INACTIVE_NODES",
        "last_modified_date": "2021-10-13T02:21:13-05:00",
        "extract_date": "2016-06-29",
        "conversion_date": null,
        "epay_terminal_id": null
      }
    ],
    "companyDetails": [
      {
        "company_id": 1,
        "company_code": "831",
        "company_name": "Martin Rentals, Inc. - dba Rent-A-Center",
        "start_date": "2000-01-01",
        "end_date": "7777-07-07",
        "line_of_business_id": 8,
        "franchisee_indicator": 1,
        "corporate_accounting_unit": "FZ831",
        "internal_company_number": "831",
        "nonops_accounting_unit": null,
        "email_address": null,
        "signature_image_path": "signatureimage/franchisee/Martin_Rents.PNG",
        "signature_name": "Mark Martin",
        "created_by": "CHERUS",
        "created_date": "2021-02-01T13:43:04-06:00",
        "last_modified_by": "CHERUS",
        "last_modified_date": "2021-02-01T13:43:04-06:00"
      },
      {
        "company_id": 64,
        "company_code": "834",
        "company_name": "The Rent To Own Concept, Inc. - dba Rent-A-Center",
        "start_date": "2000-01-01",
        "end_date": "7777-07-07",
        "line_of_business_id": 8,
        "franchisee_indicator": 1,
        "corporate_accounting_unit": "FZ834",
        "internal_company_number": "834",
        "nonops_accounting_unit": null,
        "email_address": null,
        "signature_image_path": "signatureimage/franchisee/BRUCE_EMORY.PNG",
        "signature_name": "Bruce Emory",
        "created_by": "CHERUS",
        "created_date": "2021-02-01T13:43:05-06:00",
        "last_modified_by": "CHERUS",
        "last_modified_date": "2021-02-01T13:43:05-06:00"
      },
      {
        "company_id": 65,
        "company_code": "835",
        "company_name": "TLH Enterprises, Inc. - dba Rent-A-Center",
        "start_date": "2000-01-01",
        "end_date": "7777-07-07",
        "line_of_business_id": 8,
        "franchisee_indicator": 1,
        "corporate_accounting_unit": "FZ835",
        "internal_company_number": "835",
        "nonops_accounting_unit": null,
        "email_address": null,
        "signature_image_path": "signatureimage/franchisee/TLH_Signature.PNG",
        "signature_name": "Chris Higgs",
        "created_by": "CHERUS",
        "created_date": "2021-02-01T13:43:05-06:00",
        "last_modified_by": "CHERUS",
        "last_modified_date": "2021-02-01T13:43:05-06:00"
      },
      {
        "company_id": 49,
        "company_code": "808",
        "company_name": "Eclipsed RTO LLC - dba Rent-A-Center",
        "start_date": "2020-01-01",
        "end_date": "7777-07-07",
        "line_of_business_id": 8,
        "franchisee_indicator": 1,
        "corporate_accounting_unit": "FZ808",
        "internal_company_number": "808",
        "nonops_accounting_unit": null,
        "email_address": null,
        "signature_image_path": "signatureimage/franchisee/Al_Bhakta.png",
        "signature_name": "Al Bhakta",
        "created_by": "CHERUS",
        "created_date": "2020-09-29T15:24:26-05:00",
        "last_modified_by": "CHERUS",
        "last_modified_date": "2020-09-29T15:24:26-05:00"
      },
      {
        "company_id": 66,
        "company_code": "809",
        "company_name": "Franchise1",
        "start_date": "2020-01-01",
        "end_date": "2099-12-31",
        "line_of_business_id": 8,
        "franchisee_indicator": 1,
        "corporate_accounting_unit": null,
        "internal_company_number": "809",
        "nonops_accounting_unit": null,
        "email_address": null,
        "signature_image_path": null,
        "signature_name": null,
        "created_by": "CHERUS",
        "created_date": "2020-09-29T15:24:26-05:00",
        "last_modified_by": "CHERUS",
        "last_modified_date": "2020-09-29T15:24:26-05:00"
      }
    ],
    "countryDetails": [
      {
        "country_id": 1,
        "country_name": "United States",
        "abbreviation": "US",
        "created_by": "INITLOAD",
        "created_date": "2012-10-29T16:38:16-05:00",
        "last_modified_by": "INITLOAD",
        "last_modified_date": "2012-10-29T16:38:16-05:00"
      },
      {
        "country_id": 2,
        "country_name": "Canada",
        "abbreviation": "CA",
        "created_by": "INITLOAD",
        "created_date": "2012-10-29T16:38:16-05:00",
        "last_modified_by": "INITLOAD",
        "last_modified_date": "2012-10-29T16:38:16-05:00"
      },
      {
        "country_id": 3,
        "country_name": "Mexico",
        "abbreviation": "MX",
        "created_by": "INITLOAD",
        "created_date": "2012-10-29T16:38:16-05:00",
        "last_modified_by": "INITLOAD",
        "last_modified_date": "2012-10-29T16:38:16-05:00"
      }
    ]
  }
  const categoryName: any = history.location.pathname.split("/")

  console.log('quillRef', quillRef)
  const FSCMessage =
    'We have identified an issue with converted Sales Lead following to the Online Transaction Dashboard and Online Transaction not showing in Salesforce we are working on a fix but do not have a known time at which the issue will be solved';


  useEffect(() => {

    const pageLoad = async () => {
      try {
        const { data }: any = history.location.state
        setloader(true)
        debugger
        const apiCalls = [GetDropDownVals('COMPANY')]
        if (data.associationTypeName == 'LOB' && data.associationTypeName) {
          apiCalls.push(GetReferenceValues('line_of_business'))
        } else if (data.associationTypeName == 'STATE' && data.associationTypeName) {
          apiCalls.push(GetReferenceValues('/entity/state_province'))
        } else if (data.associationTypeName) {
          apiCalls.push(GetDropDownVals(data.associationTypeName))
        }
        if (categoryName.at(-2).toUpperCase() == 'CONTEST') {
          apiCalls.push(GetFscContestDetail({ isAllDataRequired: true, type: categoryName.at(-2).toUpperCase(), operationCommunicationId: data.operationCommunicationId }))
        }
        const response: any = await Promise.all(apiCalls)
        bindDDHandler('company', response, data.associationTypeName)
        setDropDownVals(response)
        if (Object.keys(data).length > 0) {
          setmessage(data.message)
          const prorityLevel = data.prorityLevel?.toLowerCase()
          // const associationValues: any =[]
          const associationValues: any = getAssociationVals(data, response)
          const companyObj = response[0].companyDetails.find((el: any) => el.companyid == String(data.companyId))
          if (categoryName.at(-2) == 'contest') {
            data.base64 = response && response[2].communicationRecords ? response[2].communicationRecords[0].base64data : ''
            data.fileName = response && response[2].communicationRecords ? response[2].communicationRecords[0].imagePath : ''
          }
          if (props.location.state.type == 'CREATE') {
            data.base64 = data && data.base64data ? data.base64data : ''
            data.fileName = data && data.imagePath ? data.imagePath : ''
          }
          setcreateFscContestDetails({
            organization: data && data.companyId ? String(data.companyId) : 'ALL', startDate: data.startDate, endDate: data.endDate, priority: prorityLevel.charAt(0).toUpperCase() + prorityLevel.slice(1), type: data.associationTypeName
            , associationTypeVal: associationValues, isActive: data.endDate >= moment().format('YYYY-MM-DD'), operationCommunicationId: String(data.operationCommunicationId), contestId: String(data.contestId), base64: data.base64, fileName: data.fileName
          })
        }

        if (categoryName.at(-2) == 'contest') {
          const propsData: any = history.location.state
          const contest = propsData && propsData.contestDropDown ? propsData.contestDropDown : []
          setcontestDropDown(contest)
        }

        setloader(false)
      } catch (e: any) {
        setloader(false)
        console.log('Error...', e)
      }
    }
    pageLoad()
    setCurrentpageinfo({ type: props.location.state.type, category: categoryName.at(-2) || "" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let btnActive = false;
    if ((!createFscContestDetails.organization || createFscContestDetails.organization == '0') ||
      (!createFscContestDetails.type || createFscContestDetails.type == '0') ||
      (!createFscContestDetails.priority || createFscContestDetails.priority == '0') ||
      (!createFscContestDetails.startDate || createFscContestDetails.startDate == 'Invalid date') ||
      (!createFscContestDetails.endDate || createFscContestDetails.endDate == 'Invalid date') ||
      (categoryName[2] == 'contest' && !createFscContestDetails.base64)) {
      btnActive = true
    }
    if (messageTextHTMLFormatElement && messageTextHTMLFormatElement.textContent.trim().length == 0) {
      btnActive = true
    }
    if ((categoryName[2] == 'contest') && (!createFscContestDetails.contestId || createFscContestDetails.contestId == '0')) {
      btnActive = true
    }
    if ((createFscContestDetails.endDate < createFscContestDetails.startDate) && createFscContestDetails.isActive) {
      btnActive = true
    }
    console.log()
    if (createFscContestDetails.associationTypeVal.length == 0) {
      btnActive = true
    }
    setDisableValidate(btnActive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFscContestDetails.organization, createFscContestDetails.type, createFscContestDetails.priority, createFscContestDetails.startDate, createFscContestDetails.contestId, createFscContestDetails.endDate, message, createFscContestDetails.associationTypeVal, createFscContestDetails.base64])
  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return str !== undefined &&
      str !== null &&
      typeof str === 'string' &&
      str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  const readMoreElement: any = document.getElementById('disableId')
  const disEl: any = document.getElementById('disableId')
  function readMoreHandler(e: any) {
    readMoreElement.innerHTML = disEl.innerHTML
    setReadMorePopup(true)
  }
  const messageTextHTMLFormatElement: any = document.getElementById('messageTextHTMLFormat')
  const quillid: any = document.getElementsByClassName('ql-editor')
  if (quillid && quillid.length > 0) {
    console.log('quillid', quillid[0].innerHTML)
  }

  if (messageTextHTMLFormatElement) {
    messageTextHTMLFormatElement.innerHTML = message
  }
  const getAssociationVals = (data: any, response: any) => {
    const associationValues: any = []
    if (data.associationTypeName == 'STATE') {
      response && response.length > 0 && response[1].referenceDetails.forEach((value: any) => {
        if (data.associationVals.includes(value.abbreviation)) {
          associationValues.push({
            label: value.stateProvinceName,
            value: value.abbreviation,
          });
        }
      });
    }
    if (data.associationTypeName == 'STORE') {
      response && response.length > 0 && response[1].storeDetails.forEach((value: any, index: any) => {
        if (data.associationVals.includes(value.storenumber)) {
          associationValues.push({
            label: value.storenumber,
            value: value.storenumber,
          });
        }
      });
    }
    if (data.associationTypeName == 'REGION' || data.associationTypeName == 'DISTRICT') {
      response && response.length > 0 && response[1].distregDetails.forEach((value: any, index: any) => {
        if (data.associationVals.includes(value.accountingunitnumber)) {
          associationValues.push({
            label: value.accountingunitnumber,
            value: value.accountingunitnumber,
          });
        }
      });
    }
    if (data.associationTypeName == 'LOB') {
      response && response.length > 0 && response[1].referenceDetails.forEach((value: any) => {
        if (data.associationVals.includes(value.referenceCode)) {
          associationValues.push({
            label: value.description,
            value: value.referenceCode,
          });
        }
      });
    }
    if (data.associationTypeName == 'COUNTRY') {
      response && response.length > 0 && response[1].countryDetails.forEach((value: any) => {
        if (data.associationVals.includes(value.abbreviation)) {
          associationValues.push({
            label: value.countryname,
            value: value.abbreviation,
          });
        }
      });
    }
    return associationValues
  }
  console.log('elemnt', message)

  console.log('createFscContestDetails', quillRef)
  const bindDDHandler = (type: any, response: any, dynamicAssociationType: any) => {
    const { data }: any = history.location.state
    debugger

    const types: any = {
      lob: [],
      associationType: [{ label: 'Select', value: '0' }],
      country: [],
      state: [],
      store: [],
      region: [],
      district: [],
      company: [{ label: 'Select', value: '0' }, { label: 'ALL', value: 'ALL' }],
      priority: [{ label: 'Select', value: '0' }, { label: 'High', value: 'High' }, { label: 'Medium', value: 'Medium' }, { label: 'Low', value: 'Low' }]
    }
    dropDownVals.associationType.forEach((value: any, index: any) => {
      if (value.association_type_name !== 'COMPANY') {
        types.associationType.push({
          label: value.association_type_name,
          value: value.association_type_name,
        });
      }
    });
    if (type == 'company') {
      response && response.length > 0 && response[0].companyDetails.forEach((value: any, index: any) => {
        types.company.push({
          label: value.companyname,
          value: String(value.companyid),
        });
      });
    }
    if (dynamicAssociationType == 'COUNTRY') {
      response && response.length > 0 && response[1].countryDetails.forEach((value: any, index: any) => {
        types.country.push({
          label: value.countryname,
          value: value.abbreviation,
        });
      });
    }
    if (dynamicAssociationType == 'REGION' || dynamicAssociationType == 'DISTRICT') {
      response && response.length > 0 && response[1].distregDetails.forEach((value: any, index: any) => {
        if (dynamicAssociationType == 'REGION') {
          types.region.push({
            label: value.accountingunitnumber,
            value: value.accountingunitnumber,
          });
        } else {
          types.district.push({
            label: value.accountingunitnumber,
            value: value.accountingunitnumber,
          });
        }

      });
    }
    if (dynamicAssociationType == 'STORE') {
      response && response.length > 0 && response[1].storeDetails.forEach((value: any, index: any) => {
        if (((createFscContestDetails.organization == value.companyid) || (data.companyId == value.companyid)) && createFscContestDetails.organization !== '1') {
          types.store.push({
            label: value.storenumber,
            value: value.storenumber,
          });
        }
        if (createFscContestDetails.organization == '1') {
          types.store.push({
            label: value.storenumber,
            value: value.storenumber,
          });
        }
        if (createFscContestDetails.organization == 'ALL') {
          types.store.push({
            label: value.storenumber,
            value: value.storenumber,
          });
        }
        if (createFscContestDetails.organization == 'ALL') {
          types.store.push({
            label: value.storenumber,
            value: value.storenumber,
          });
        }
        if (createFscContestDetails.organization == 'ALL') {
          types.store.push({
            label: value.storenumber,
            value: value.storenumber,
          });
        }
        if (createFscContestDetails.organization == 'ALL' || !createFscContestDetails.organization) {
          types.store.push({
            label: value.storenumber,
            value: value.storenumber,
          });
        }
      });
    }
    if (dynamicAssociationType == 'LOB') {
      response && response.length > 0 && response[1].referenceDetails.forEach((value: any, index: any) => {
        types.lob.push({
          label: value.description,
          value: value.referenceCode
        });
      });
    }
    if (dynamicAssociationType == 'STATE') {
      response && response.length > 0 && response[1].referenceDetails.forEach((value: any, index: any) => {
        types.state.push({
          label: value.stateProvinceName,
          value: value.abbreviation
        });
      });
    }
    setDropDownTypes(types)
    console.log('types', types);
  }
  const handleAssociateType = async (e: any) => {
    if (e.target.value != createFscContestDetails.type && e.target.value !== '0') {
      try {
        // const { data }: any = history.location.state
        let res = ''
        if (e.target.value == 'LOB') {
          setloader(true)
          res = await GetReferenceValues('line_of_business')
        } else if (e.target.value == 'STATE') {
          setloader(true)
          res = await GetReferenceValues('/entity/state_province')
        } else {
          setloader(true)
          res = await GetDropDownVals(e.target.value)
        }
        setloader(false)
        bindDDHandler('company', [dropDownValues[0], res], e.target.value)
        console.log(res, dropDownValues)
        setcreateFscContestDetails({ ...createFscContestDetails, type: e.target.value, associationTypeVal: [] })
      } catch (e: any) {
        console.log(e)
      }
    } else {
      setcreateFscContestDetails({ ...createFscContestDetails, type: '', associationTypeVal: [] })
    }
  }
  const apiResponseOkButtonHandler = () => {
    setupdateMsgPopup(false);
    history.push(`/dashboard/homepage`)
  }
  const duplicateCommunicationRecord = () => {
    // const { data }: any = history.location.state
    const propsData: any = history.location.state
    // const contest = propsData && propsData.contestDropDown ? propsData.contestDropDown : []
    // setcontestDropDown(contest)
    propsData.data.operationCommunicationId = ''
    propsData.data.base64data = createFscContestDetails.base64
    propsData.data.imagePath = createFscContestDetails.fileName

    history.push({ pathname: `/storemgmt/${categoryName[2].toLowerCase()}/create`, state: { type: 'CREATE', data: propsData.data, contestDropDown: propsData.contestDropDown } })
  }
  const UpdateInfoMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          {isSuccess ? <SuccessIcon></SuccessIcon> : <Alerticon></Alerticon>}
          <Typography
            variant={'body2'}
            className={`${classes.my3} ${classes.RACPOPMsg}`}
          >
            {isSuccess ? 'New Update sent successfully' : 'Something went wrong'}
          </Typography>
        </Grid>
        <Grid
          item
          className={`${classes.textCenter} ${classes.mt4} ${classes.w100}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={apiResponseOkButtonHandler}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const activeToggleOnChange = (e: any) => {
    if (!e.target.checked) {
      setDisableDate(true)
      setcreateFscContestDetails({ ...createFscContestDetails, isActive: e.target.checked, endDate: moment().subtract(1, 'days').format('YYYY-MM-DD') })
    } else {
      setDisableDate(false)
      setcreateFscContestDetails({ ...createFscContestDetails, isActive: e.target.checked, endDate: moment().format('YYYY-MM-DD') })
    }
  }
  const insertOrUpdateFscContest = async () => {
    debugger
    dropDownValues
    if (createFscContestDetails.startDate < moment().format('YYYY-MM-DD') && createFscContestDetails.endDate < moment().format('YYYY-MM-DD')) {
      setisStartDateLessThanCurrentDate(true)
      return
    }
    let companyCode = ''
    dropDownValues && dropDownValues.length > 0 && dropDownValues[0].companyDetails?.forEach((el: any) => {
      if (el.companyid == createFscContestDetails.organization) {
        companyCode = el.companycode
      }
    })
    const payload: any = {
      fscContestUpdateMsg: message,
      startDate: moment(createFscContestDetails.startDate).format('YYYY-MM-DD'),
      endDate: moment(createFscContestDetails.endDate).format('YYYY-MM-DD'),
      companyCode: createFscContestDetails.organization == 'ALL' ? null : companyCode,
      type: categoryName[2].toUpperCase(),
      priority: createFscContestDetails.priority.toUpperCase()
    }
    if (!createFscContestDetails.isActive && (createFscContestDetails.startDate > createFscContestDetails.endDate)){
      payload.startDate = moment(createFscContestDetails.startDate).subtract(1, 'days').format('YYYY-MM-DD');
    }
    if (currentPageInfo && currentPageInfo.type == 'UPDATE') {
      payload.fscContestOperationId = createFscContestDetails.operationCommunicationId
    }
    if (categoryName[2] == 'contest') {
      //createFscContestDetails.base64
      payload.imageBase64 = createFscContestDetails.base64
      if (newContestName.length > 0) {
        payload.contestName = newContestName[0]
      }
    }


    // if (createFscContestDetails.contestName && categoryName[2] == 'contest') {
    //   payload.contestName = createFscContestDetails.contestName 
    // }

    if (createFscContestDetails.contestId && categoryName[2] == 'contest' && newContestName.length == 0) {
      payload.contestId = createFscContestDetails.contestId
    }
    // if (!createFscContestDetails.isActive) {
    //   payload.endDate = moment(createFscContestDetails.endDate).subtract('1', 'day').format('MM/DD/YYYY')
    // }
    setloader(true)
    payload.associationDetails = { [createFscContestDetails.type.toLowerCase()]: createFscContestDetails.associationTypeVal.map((el: any) => el.value) }
    const response: any = await UpdateFscContestDetail(payload)

    if (response) {
      setisSuccess(true)
      setloader(false)
    } else {
      setisSuccess(false)
      setloader(false)
    }
    setupdateMsgPopup(true);

    console.log(response)
  }
  const disableInsertOrUpdateBtn = (): boolean => {
    let btnActive = false;
    if ((!createFscContestDetails.organization || createFscContestDetails.organization == '0') ||
      (!createFscContestDetails.type || createFscContestDetails.type == '0') ||
      (!createFscContestDetails.priority || createFscContestDetails.priority == '0') ||
      (!createFscContestDetails.startDate) ||
      (!createFscContestDetails.endDate)) {
      btnActive = true
    }
    if (messageTextHTMLFormatElement && messageTextHTMLFormatElement.textContent && messageTextHTMLFormatElement.textContent.length == 0) {
      btnActive = true
    }
    console.log('fscmessage...', message, messageTextHTMLFormatElement.textContent)
    return btnActive
  }
  const openReadMoreContent = () => {
    return (
      <div>
        <Grid className={classes.spacerMargin}>
          <Typography className={classes.modalFscMessage} id='readMorePopup'>
          </Typography>
        </Grid>
        <Grid className={classes.spacerMargin}>
          <Grid className={classes.col1}>
            <Typography className={classes.SDTitle}>Start Date</Typography>
            <Typography className={classes.STDate}>
              <CalendarIcon className={classes.calenderIcon} />
              {moment(createFscContestDetails.starDate).format('MM/DD/YYYY')}
            </Typography>
          </Grid>
          <Grid className={classes.col2}>
            <Typography className={classes.EDTitle}>End Date</Typography>
            <Typography className={classes.ENDate}>
              <CalendarIcon className={classes.calenderIcon} />
              {moment(createFscContestDetails.endDate).format('MM/DD/YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };
  const saveHandlerForContestName = () => {
    setcreateFscContestDetails({ ...createFscContestDetails, contestName: createFscContestDetails.contestName })
    setNewContestName([...newContestName, createFscContestDetails.contestName])
    const contestName = contestDropDown
    contestName.push({ label: createFscContestDetails.contestName, value: String(contestDropDown.length + 1) })
    setcontestDropDown(contestName);
    setContestNamePopup(false)
  }
  const cancelHandlerForContestName = () => {
    setContestNamePopup(false)
    setcreateFscContestDetails({ ...createFscContestDetails, contestName: '' })
  }
  const contestMessagePopup = () => {
    return (
      <div>
        <Grid className={classes.spacerMargin} style={{ display: 'flex', flexDirection: 'column', marginBottom: '-9px' }}>
          <Grid>
            <RACTextbox
              type="text"
              name="contestMessage"
              value={createFscContestDetails.contestMessage}
              OnChange={(e: any) => setcreateFscContestDetails({ ...createFscContestDetails, contestName: e.target.value })}
              inputlabel=""
              isCurrency={false}
            />
          </Grid>
          <Grid
            className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}
          >
            <Grid className={`${classes.floatRight}`} style={{ marginLeft: '10px' }}>
              <RACButton
                variant="contained"
                color="primary"
                onClick={saveHandlerForContestName}
                disabled={createFscContestDetails.contestName && createFscContestDetails.contestName.length == 0 ? true : false}
              >
                Save
              </RACButton>
            </Grid>
            <Grid className={classes.floatRight}>
              <RACButton
                variant="outlined"
                color="primary"
                onClick={cancelHandlerForContestName}
              >
                Cancel
              </RACButton>
            </Grid>

          </Grid>
        </Grid>
      </div>
    );
  };
  const getBase64 = (file: any) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL: any = "";
      // Make new FileReader
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  const uploadHandler = (event: any) => {
    const file = event.target.files[0];
    if (file.name.split('.')[1] == 'png' || file.name.split('.')[1] == 'jpg' || file.name.split('.')[1] == 'jpeg') {
      seterrorFIleHandler(false)
      // setFileName(file.name)
      getBase64(file)
        .then((result: any) => {
          console.log('result', result.split(',')[1])
          file["base64"] = result;
          console.log("File Is", file.base64, file.base64.split(',')[1]);
          setcreateFscContestDetails({ ...createFscContestDetails, base64: result.split(',')[1], fileName: file.name })
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      seterrorFIleHandler(true)
    }

  }
  return (
    <Grid item md={12}>
      {/* <Typography id='disableId' style={{display:''}}></Typography> */}
      <RACModalCard
        isOpen={readMorePopup}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setReadMorePopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={openReadMoreContent()}
      />
      <RACModalCard
        isOpen={contestNamePopup}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={cancelHandlerForContestName}
        title='Contest Name'
        // eslint-disable-next-line react/no-children-prop
        children={contestMessagePopup()}
      />
      <Modal
        isOpen={loader}
        className={classes.loaderStyle}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid>
          <Grid>
            <Grid className={classes.RACLoaderPage}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <RACModalCard
        borderRadius="20px"
        isOpen={updateMsgPopup}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setupdateMsgPopup(false);
        }}
        // eslint-disable-next-line react/no-children-prop
        children={UpdateInfoMsgPopup()}
        title=""
      />
      <div className={classes.racFixedFooterMargin}>
        <Grid>
          <List className={classes.breadcrumb}>
            <ListItem className={classes.breadcrumbItem}>
              <Typography className={classes.breadcrumblinkfont}>
                Admin
              </Typography>
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.breadCrumPadding}`}
            >
              {currentPageInfo && currentPageInfo.category == 'fsc' ?
                <Link to={'/storemgmt/fsc/details'} style={{ textDecoration: 'none' }}><Typography
                  className={classes.breadcrumblinkfont}
                >
                  FSC Updates
                </Typography></Link> : <Link to={'/storemgmt/contest/details'} style={{ textDecoration: 'none' }}><Typography
                  className={classes.breadcrumblinkfont}
                >
                  Contest Updates
                </Typography></Link>}
            </ListItem>
            {currentPageInfo && currentPageInfo?.category == 'fsc' ?
              <ListItem
                className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.breadcrumbItemActive}`}
              >
                Create New Update
              </ListItem> : <ListItem
                className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.breadcrumbItemActive}`}
              >
                Contest Details
              </ListItem>}
          </List>
          {currentPageInfo && currentPageInfo.category == 'fsc' ? <Typography className={classes.titleFSC}>
            {currentPageInfo.type == 'CREATE' ? 'Create New Update' : 'Details of the update'}
          </Typography> : <Typography className={classes.titleFSC}>
            Contest Details
          </Typography>}
          <Grid className={classes.cardGrid}>
            <Card className={`${classes.w100} ${classes.card}`}>
              <CardContent>
                <Grid style={{ width: '100%', height: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                  {currentPageInfo && currentPageInfo?.category == 'contest' ?
                    <>
                      <RACSelect
                        inputLabelClassName={classes.dropDownLabel}
                        inputLabel="Contest"
                        options={contestDropDown}
                        required={true}
                        defaultValue={createFscContestDetails.contestId ? createFscContestDetails.contestId : '0'}
                        onChange={(e) => setcreateFscContestDetails({ ...createFscContestDetails, contestId: e.target.value })}
                        classes={{
                          formControl: classes.statusDropdownWidthForContest,
                          nativeSelect: classes.statusDropdownContent,
                        }}
                      ></RACSelect> <Grid style={{ marginTop: '32px', marginRight: '10px', cursor: 'pointer' }} onClick={() => setContestNamePopup(true)}><AddIcon /></Grid></> : ''}
                  <RACSelect
                    inputLabelClassName={classes.dropDownLabel}
                    inputLabel="Organization"
                    options={dropDownTypes?.company}
                    required={true}
                    defaultValue={createFscContestDetails.organization ? createFscContestDetails.organization : '0'}
                    onChange={(e) => setcreateFscContestDetails({ ...createFscContestDetails, organization: e.target.value })}
                    classes={{
                      formControl: classes.statusDropdownWidth,
                      nativeSelect: classes.statusDropdownContent,
                    }}
                  ></RACSelect>
                  <RACSelect
                    inputLabel="Type"
                    inputLabelClassName={classes.dropDownLabel}
                    options={dropDownTypes?.associationType}
                    required={true}
                    defaultValue={createFscContestDetails.type ? createFscContestDetails.type : '0'}
                    // onChange={(e) => setcreateFscContestDetails({ ...createFscContestDetails, type: e.target.value })}
                    onChange={(e) => handleAssociateType(e)}
                    // onChange={(e) => storeDisplay(e)}
                    classes={{
                      formControl: classes.statusDropdownWidth,
                      nativeSelect: classes.statusDropdownContent,
                    }}
                  ></RACSelect>
                  {/* {createFscContestDetails.type ? <RACSelect
                inputLabelClassName={classes.dropDownLabel}
                inputLabel={createFscContestDetails.type.charAt(0).toUpperCase() + createFscContestDetails.type.slice(1)}
                options={dropDownTypes[createFscContestDetails.type.toLowerCase()]}
                defaultValue={createFscContestDetails.associationTypeVal ? createFscContestDetails.associationTypeVal : '0'}
                onChange={(e) => setcreateFscContestDetails({ ...createFscContestDetails, associationTypeVal: e.target.value })}
                // onChange={(e) => storeDisplay(e)}
                classes={{
                  formControl: classes.statusDropdownWidth,
                  nativeSelect: classes.statusDropdownContent,
                }}
              ></RACSelect>:null } */}
                  {createFscContestDetails.type && createFscContestDetails.type !== '0' && createFscContestDetails.type !== '' ?
                    <Grid item className={classes.statusDropdownWidthForMultiSelect}>
                      <Typography >{createFscContestDetails.type.charAt(0).toUpperCase() + createFscContestDetails.type.slice(1)}<span style={{ color: '#ef0000', marginLeft: '4px' }}>*</span></Typography>
                      <MultiSelect
                        options={dropDownTypes[createFscContestDetails.type.toLowerCase()] || []}
                        className={classes.multiSelect}
                        // options={[{label: 'value',value: 'value'}]}
                        value={createFscContestDetails.associationTypeVal}
                        onChange={(e: any) => setcreateFscContestDetails({ ...createFscContestDetails, associationTypeVal: e })}
                        labelledBy={'Select........'}
                      />
                    </Grid>
                    : null}
                  <RACSelect
                    inputLabelClassName={classes.dropDownLabel}
                    inputLabel="Priority"
                    options={dropDownTypes?.priority}
                    required={true}
                    defaultValue={createFscContestDetails.priority ? createFscContestDetails.priority : '0'}
                    onChange={(e) => setcreateFscContestDetails({ ...createFscContestDetails, priority: e.target.value })}
                    // onChange={(e) => storeDisplay(e)}
                    classes={{
                      formControl: classes.statusDropdownWidth,
                      nativeSelect: classes.statusDropdownContent,
                    }}
                  ></RACSelect>
                  <Grid item className={classes.datePicker}>
                    <RACDatePicker
                      classes={{
                        input: classes.startDate,
                        label: classes.dateLabel,
                      }}
                      label="Start Date"
                      value={moment(createFscContestDetails.startDate).format('YYYY-MM-DD')
                      }
                      required={true}
                      disabled={disableDate}
                      onChange={(e: any) => {
                        setcreateFscContestDetails({
                          ...createFscContestDetails, startDate: moment(e)
                            .format('YYYY-MM-DD')
                        }), setisStartDateLessThanCurrentDate(moment(e).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') ? false : true)
                      }}
                      errorMessage={isStartDateLessThanCurrentDate ? 'Please enter valid start date' : ''}
                      // onChange={handleStartDate}
                      inputProps={{
                        min: moment()
                          .format('YYYY-MM-DD'),
                      }}
                      onKeyDown={(e: any) => {
                        e.preventDefault();
                      }}
                    ></RACDatePicker>
                  </Grid>
                  <Grid item className={classes.datePicker}>
                    <RACDatePicker
                      classes={{
                        input: classes.endDate,
                        label: classes.dateLabel,
                      }}
                      onChange={(e: any) => setcreateFscContestDetails({
                        ...createFscContestDetails, endDate: moment(e).format('YYYY-MM-DD'), isActive: true
                      })}
                      label="End Date"
                      value={createFscContestDetails.endDate}
                      disabled={disableDate}
                      required={true}
                      // onChange={handleEndDate}
                      inputProps={{
                        min: moment()
                          .format('YYYY-MM-DD'),
                      }}
                      onKeyDown={(e: any) => {
                        e.preventDefault();
                      }}
                      errorMessage={((createFscContestDetails.endDate < createFscContestDetails.startDate) && createFscContestDetails.isActive) ? 'Please enter valid end date' : ''}
                    ></RACDatePicker>
                  </Grid>
                  {currentPageInfo?.category == 'contest' ? <Grid item className={classes.datePicker}>
                    <Typography variant='h6'>Upload Contest Icon<span style={{ color: '#ef0000', marginLeft: '4px' }}>*</span></Typography>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                      {/* <button
                        type="button"
                        className={classes.buttonUploading}
                        title=""
                      >
                        Upload
                        <input id="files" style={{ visibility: 'hidden' }} type="file" onChange={uploadHandler} />
                      </button> */}

                      <label htmlFor="btn-upload">
                        <input
                          id="btn-upload"
                          name="btn-upload"
                          style={{ display: 'none' }}
                          type="file"
                          onChange={uploadHandler} />
                        <span style={{
                          width: '120%',
                          height: '100%',
                          color: '#22acf6',
                          background: 'aliceblue',
                          display: 'block',
                          marginTop: '6px',
                          paddingTop: '8px',
                          paddingLeft: '18px',
                          cursor: 'pointer',
                          borderRadius: '10px',
                          fontWeight: '600'
                        }}>
                          Upload
                        </span>
                      </label>
                      {createFscContestDetails.fileName ? <><Typography variant='h6' style={{
                        display: 'inline-block', alignSelf: 'center', color: 'gray',
                        marginLeft: '17px'
                      }}>{createFscContestDetails.fileName}</Typography><span style={{
                        marginTop: '13px',
                        marginLeft: '10px',
                        color: 'gray'
                      }} onClick={() => { setcreateFscContestDetails({ ...createFscContestDetails, base64: '', fileName: '' }) }}><CrossIcon /></span></> : null}
                    </Grid>
                    {errorFIleHandler ? <Typography style={{ color: '#ef0000' }}>Please enter valid file type</Typography> : null}
                  </Grid> : null}
                  <Grid item className={classes.datePicker} style={{ paddingLeft: '10px', marginTop: '38px' }}>
                    <RACToggle
                      toggleColor={'green'}
                      rightLabel={
                        createFscContestDetails.isActive ? 'Active' : 'InActive'
                      }
                      OnChange={activeToggleOnChange}
                      rightText={true}
                      GridClassName={classes.InlineFlex}
                      labelClassName={classes.ml2}
                      checked={createFscContestDetails.isActive}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.editorToolbar}>
                  <Typography className={classes.messageLabel}>
                    {currentPageInfo?.category == 'contest' ? 'Contest Message' : 'Message'}<span style={{ color: '#ef0000', marginLeft: '4px' }}>*</span>
                  </Typography>
                  {/* {/* <div style={{height: 300, width:'80%' }}> */}
                  <div style={{ height: '300px', width: '80%' }} className={classes.textEditorStyle}>
                    <ReactQuill theme="snow" modules={modules} formats={formats} value={message} onChange={setmessage} />
                  </div>

                  {/* <RichTextEditor
                  // eslint-disable-next-line sonarjs/no-all-duplicated-branches
                  value={createFscContestDetails.message != undefined ? createFscContestDetails.message : createFscContestDetails.message}
                  onChange={onEditorStateChange}
                />
                  <Editor 
                  // editorState={ed}
                  // onEditorStateChange={(e) => { setEditorState(e)}}
                  onChange={onEditorStateChange}
                  toolbar={{
                                options: ['history', 'inline'], inline: {
                                    inDropdown: false,
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['bold', 'italic'],
                                    bold: { className: classes.boldIconClass },
                                    italic: { className: classes.italicIconClass },
                                },
                                history: {
                                    inDropdown: false,
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['undo', 'redo'],
                                    undo: { className: classes.undoClass },
                                    redo: { className: classes.redoClass },
                                },
                }} toolbarClassName={classes.toolBarClass} wrapperClassName={classes.wrapperClass} editorClassName={`${classes.editorClass} ${applyZindex ? `${classes.zindexforMultiSelectDDHigherVal}` : `${classes.zindexforMultiSelectDDLowerVal}`}`}></Editor> */}
                </Grid>

                {/* <Grid item className={`${classes.px2} ${classes.w100} ${classes.paddingPreviewCard}`}> */}
                <Typography className={classes.previewLabel}>
                  Preview
                </Typography>

                <Card
                  className={`${classes.w100} ${classes.card} ${classes.cardMargin}`}
                >
                  <CardContent className={`${classes.bgCard} ${createFscContestDetails.priority.toUpperCase() == 'HIGH' ? classes.borderYellow : createFscContestDetails.priority.toUpperCase() == 'MEDIUM' ? classes.borderViolet : classes.borderBlue}`} style={{ display: 'flex' }}>
                    {createFscContestDetails.base64 ? <div style={{ width: '13%', height: '30%' }}><img src={`data:image/png;base64,${createFscContestDetails.base64}`} style={{
                      width: '100%',
                      height: '50px',
                      marginTop: '7px',
                      objectFit: 'fill'
                    }} />   </div> : null}

                    <Typography className={classes.fscUpdateMessage} id='messageTextHTMLFormat'>
                    </Typography>
                    {/* {/* <Typography>{messageTextHTMLFormatElement && messageTextHTMLFormatElement.textContent.length > 160 ? <a className={classes.readMore} onClick={readMoreHandler} >Read More</a> : null}</Typography> */}
                  </CardContent>
                </Card>
                {/* </Grid> */}
              </CardContent>
            </Card>
          </Grid>
          <Grid container classes={{
            root: clsx(classes.customerToolbar, classes.fixedBottom),
          }}
            style={{ zIndex: 0 }}>
            <Grid className={classes.footerFixed}>
              <Grid className={classes.floatLeft}>
                <Link to={`/storemgmt/${categoryName[2].toLowerCase()}/details`} style={{ textDecoration: 'none' }}>
                  <RACButton
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </RACButton>
                </Link>
              </Grid>
              <Grid className={`${classes.floatRight}`}>
                {currentPageInfo?.type == 'UPDATE' && (
                  <RACButton
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '10px' }}
                    onClick={duplicateCommunicationRecord}
                  >
                    Duplicate
                  </RACButton>)}
                {currentPageInfo?.type == 'CREATE' ? (
                  <RACButton
                    variant="contained"
                    color="primary"
                    disabled={disableValidate}
                    onClick={insertOrUpdateFscContest}
                  >
                    Send Update
                  </RACButton>
                ) : currentPageInfo?.type == 'UPDATE' ? (
                  <RACButton
                    variant="contained"
                    color="primary"
                    disabled={disableValidate}
                    onClick={insertOrUpdateFscContest}
                  >
                    Save Details
                  </RACButton>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
