/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DapCalendarStyles } from './DapCalendarStyles';
import { StoreMgmtContext } from '../../context/storeMgmtContext';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACDatePicker,
  RACButton,
  RACSelect,
  RACTextbox,
  RACBadge,
  RACTableCell,
  List,
  ListItem,
  RACModalCard,
  RACTable,
  RACTableRow,
  Box,
} from '@rentacenter/racstrap';

function Footer(props: any) {
  const { setcreateTaskModal, TaskandGoals } = useContext(StoreMgmtContext)
  const history = useHistory();
  const classes = DapCalendarStyles();
  const [createEventClicked, setCreateEventClicked] = useState<any>(false);
  const [PopupClass, setPopupClass] = useState<string>(
    `${classes.customMenuContainer} ${classes.popUpHide}`
  );
  function handleCancelBtn() {
    history.push({
      pathname: `/dashboard/homepage`,
    });
  }
  function handleCustomerEvent() {
    history.push({
      pathname: `/customer/searchcustomer`,
    });
  }
  const handleCreateEvent = () => {
    if (createEventClicked) {
      setCreateEventClicked(false)
    }
    else {
      setCreateEventClicked(true)
    }
    if (PopupClass === `${classes.customMenuContainer} ${classes.popUpHide}`) {
      setPopupClass(`${classes.customMenuContainer} ${classes.popUp}`);
    }
    if (PopupClass === `${classes.customMenuContainer} ${classes.popUp}`) {
      setPopupClass(`${classes.customMenuContainer} ${classes.popUpHide}`);
    }
  }
  const handleCreateStoreEvent = () => {
    history.push({
      pathname: `/storemgmt/createevent`,
    });
  }
  return (
    <Grid
      container
      classes={{
        root: clsx(classes.customerToolbar, classes.fixedBottom),
      }}
      style={{ zIndex: 0 }}
    >
      <Grid className={classes.footerFixed}>
        <Grid style={{ float: 'left' }}>
          <RACButton
            variant="outlined"
            color="secondary"
            onClick={() => handleCancelBtn()}
          >
            Cancel
          </RACButton>
        </Grid>
        <Grid style={{ float: 'right', display: "flex" }}>
          {props.task == 'Task' ?
            <RACButton
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
              onClick={() => {
                setcreateTaskModal(true)
              }}
            >
              Create Task
            </RACButton>
            : null}

          {props.task == 'calender' ? <RACButton
            variant="contained"
            color="primary"
            onClick={() => handleCreateEvent()}
          >
            Create Event
            <span className={classes.dropdowntoggle}></span>
            {createEventClicked ? (
              <Grid
                className={
                  // checkAction == '' ?
                  `${classes.hideRental}`
                  // :
                  //  `${classes.popupRental}`
                }
              >
                <List
                  data-testid="UlButtonsId"
                  aria-labelledby="btnGroupDrop1"
                  style={{
                    left: '947 !important',
                    top: '-35px !important',
                  }}
                >
                  <ListItem>
                    <a
                      className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                      data-bs-toggle="modal"
                      data-testid="TerminateLDWId"
                      onClick={() => handleCustomerEvent()}
                      data-bs-target="#terminateldw"
                    >
                      Customer
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                      data-bs-toggle="modal"
                      onClick={() => handleCreateStoreEvent()}
                    >
                      Store
                    </a>
                  </ListItem>
                </List>
              </Grid>
            ) : null}
          </RACButton> : null}



        </Grid>

      </Grid>
    </Grid>
  );
}

export default Footer;
