/* eslint-disable no-console */
//import { createBrowserHistory, History } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { createBrowserHistory, History } = require('history');
// eslint-disable-next-line @typescript-eslint/no-var-requires
import { App } from './app/App';
import './index.css';

if (!HTMLElement.prototype.scrollTo) {
  HTMLElement.prototype.scrollTo = function (options: any) {
    this.scrollTop = options?.top || 0;
    this.scrollLeft = options?.left || 0;
  };
}
export interface CustomPropInterface {
  GetRole: () => string;
  GetEmployeeId: () => string;
  GetName: () => string;
}

window.renderstoremgmt = (
  containerId: string,
  history: typeof History,
  customProps?: CustomPropInterface
) => {
  const elem = document.getElementById(containerId);
  console.log('inside render storeMgmt ', containerId);
  console.log('inside history ', history);
  if (elem) {
    ReactDOM.render(<App history={history} customProps={customProps} />, elem);
  }
};

window.unmountstoremgmt = (containerId: string) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.unmountComponentAtNode(elem);
  }
};

if (!window.isRenderedByContainer) {
  import('./bootstrap').then(() => {
    const defaultHistory = createBrowserHistory({
      basename: process.env.PUBLIC_URL,
    });

    window.renderstoremgmt('root', defaultHistory);
  });
}
