/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACButton,
  RACSelect,
  RACTextbox,
  RACDatePicker,
  List,
  ListItem,
  CircularProgress,
  RACModalCard,
} from '@rentacenter/racstrap';
import { addDays } from 'date-fns';
import moment from 'moment';
import clsx from 'clsx';
import { getTimeSlot, CoworkerRequired, postAppointment, manageAppointment, getRadiusStores, getAgreementDelivery } from '../../api/user';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { agreementGlobalStyles } from './DAPStyles';
import Modal from 'react-modal';


const MAX_LOAD_DAYS : number= 8;
// let prevEl: any, currEl: any = ''
export default function StorePickupEvent() {
  const history = useHistory();
  const historyData: any = history.location.state

  let BindtimeslotDate = '';

  const classes = agreementGlobalStyles();
  const [data, setData] = useState<any>({
    EventTitle: '',
    Time: '',
    Description: '',
  });
  const [dropDown, setDropDown] = useState<any>({
    scheduleArray: '1',
    coWorkerArray: '1',
  });
  const [Color, setColor] = useState(`${classes.blue}`);
  const [timeSlotData, settimeSlotData]: any = useState([]);
  const [BindtimeslotId, setBindtimeslotId] = useState('');
  const [ContextStartDate, SetContextStartDate] = useState('');
  const [ContextEndDate, SetContextEndDate] = useState('');
  const [choosenTime, SetTime] = useState('');
  const [masterLoader, setmasterLoader] = React.useState(false);
  const [timeslotid, Settimeslotid] = useState('');
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  // const [SaveTrigger, SetSaveTrigger] = useState(false);
  const [AppoinmentSource, setAppoinmentSource]: any = React.useState('');
  const [apierror, setApierror] = React.useState(false);
  const [ApierrorContent, setApierrorContent] = React.useState('');
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [coWorkerRequiredArray, setcoWorkerRequiredArray]: any = useState([]);
  const [conditionLoader, SetconditionLoader] = useState(true);
  const [coworkers, SetCoworkers]: any = useState('');
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const [loader, setloader] = useState(false);
  const [tsloader, setTsloader] = useState(false);
  const [paramValue, setparamValue] = useState<any>('');
  const [currentBtnClass, setCurrentBtnClass] = useState<any>('');

  const [maxDate, setMaxDate] = useState<any>('2099-12-31');
  const [limitTime, setLimitTime] = useState<any>('15:00:00');
  const [storyArray, setStoreArray]: any = useState([]);
  const [chooseStore, setChooseStore]: any = useState('');



  const handleInputChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    // getEventFnc();
    const queryParams = new URLSearchParams(window.location.search);

    // Get the value of a specific parameter
    const queryparamValue = queryParams.get('editevent');
    setparamValue(queryparamValue)
    if (queryparamValue) {
      setData({
        EventTitle: historyData?.appointmentInfo?.title || '',
        Time: '',
        Description: historyData?.appointmentInfo?.instructions || '',
        address1: historyData?.customerInfo?.address?.addressLine1 || '',
        address2: historyData?.customerInfo?.address?.addressLine2 || '',
        city: historyData?.customerInfo?.address?.city || '',
        state: historyData?.customerInfo?.address?.state || '',
        postalCode: historyData?.customerInfo?.address?.postalCode || '',
      })
      const startTime = moment(historyData.timeSlotInfo.startTime, 'HH:mm:ss').format('hh:mm A');
      const endTime = moment(historyData.timeSlotInfo.endTime, 'HH:mm:ss').format('hh:mm A');

      SetTime(startTime + '-' + endTime)
      SetCoworkers(historyData.appointmentInfo.requiredCoworkers || '1')
      setChooseStore(historyData.appointmentInfo.locationId || ''); 

      setStartDate(moment(historyData.timeSlotInfo.startTimeStamp).format('YYYY-MM-DD'),)
    }
    setloader(true);
    doload();
  }, []);

  const doload = async () => {
    setloader(true);
    try {
      await getCoworkerRequiredData();
      await getStores();

      const deliveryinfo: any = await getEventDate(historyData?.agreement.agreementId, historyData?.appointmentInfo?.parentAppointmentId);

      setMaxDate(deliveryinfo.deliverydate);
      setLimitTime(deliveryinfo.deliverytime);
      
      await getTimeSlots(moment().format('YYYY-MM-DD'), deliveryinfo);
      setloader(false);
    }
    catch (error: any) {
      console.log(error);
      setloader(false);
      setApierrorContent(error);
      setApierror(true);
    }
  }

  const handleSave = async () => {
    try {
      setloader(true);

      const payloadToUpdateSE: any = {
        appointmentId: historyData.appointmentInfo.appointmentId,
        storeNumber: storeNumber,
        timeSlot: timeslotid,
        appointmentInfo: {
          appointmentDate: startDate,
          instructions: data.Description,
          requiredCoworkers: coworkers,
          locationId: chooseStore,
        },
      };
      let updateEvent = await manageAppointment(payloadToUpdateSE);

      setloader(false);
      if (updateEvent.status == 200) {
        history.push({ pathname: `/storemgmt/dap` });
      } else if (updateEvent.status == 400) {
        setApierror(true);
        setApierrorContent('Something went wrong');
      } else {
        setErrorMsg(true);
      }
    } catch (e: any) {
      setApierrorContent('Something went wrong');
      setloader(false);
      setApierror(true);
    }
  };

  const apiErrorPopup = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon></AlertIcon>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            {ApierrorContent}
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {setApierror(false); history.push(`/storemgmt/dap`)}}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const noRecordPopup = () => {
    return (
      <Grid>
        <Grid>
          <Grid className={classes.textCenter}>
            <AlertIcon />
            <Typography> Something Went Wrong.</Typography>
          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => setErrorMsg(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getCoworkerRequiredData = async () => {
    const bodyobject = {
      storeNumbers: [storeNumber],

      paramKeyNames: ['CoworkerRequired'],
    };
    const CoworkerNum = await CoworkerRequired(bodyobject);
    if (CoworkerNum.status === 200) {
      const value =
        CoworkerNum.data !== null &&
          CoworkerNum.data.storeProfileResponse !== undefined &&
          CoworkerNum.data.storeProfileResponse &&
          CoworkerNum.data.storeProfileResponse.configDetails !== undefined &&
          CoworkerNum.data.storeProfileResponse.configDetails.length > 0 &&
          CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails !==
          undefined &&
          CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails
            .length > 0
          ? CoworkerNum.data.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue
          : 0;

      // eslint-disable-next-line no-console
      console.log(value);
      const integervalue = parseInt(value);
      const arrayNumber: any = [{ label: 'Select', value: 'SELECT' }];

      for (let i = 0; i <= integervalue; i++) {
        arrayNumber.push({ label: String(i), value: String(i) });
      }
      // eslint-disable-next-line no-console
      console.log('ArrayNumber', arrayNumber);
      setcoWorkerRequiredArray(arrayNumber);
      SetconditionLoader(false);
    } else {
      const arrayNumber: any = [{ label: 'Select', value: 'SELECT' }];
      setcoWorkerRequiredArray(arrayNumber);
      SetconditionLoader(false);
    }
  };


  const getStores = async () => {
    const radiusStores = await getRadiusStores(storeNumber);
    if (radiusStores.status === 200) {
      const values = radiusStores.data.response;

      const storeArray: any = values.map((store) => ({
        label: store.neighborStoreId,
        value: store.neighborStoreId
      }));
      setStoreArray(storeArray);
    }
  };

  const getEventDate = async (agrId: any, appointmentId: any) => {
    if (!agrId || !appointmentId) {
      throw "getEventDate. Null Id";
    }
    const response = await getAgreementDelivery(agrId, appointmentId);
    if (response.status !== 200) throw "api error in getEvent";

    if (!response.data.timeSlotInfo) throw "api error in getEvent. No timeslot";
 
    const result: any = {
      deliverydate: response.data.timeSlotInfo.startTimeStamp.substring(0, 10),
      deliverytime: response.data.timeSlotInfo.startTime
    }
    return result;

  };

  const Timebind = (event: any) => {
    SetTime(moment(event.target.value).format('hh:mm A'));
    // SetSaveTrigger(false);
  };

  const chooseDates = async (selectedFormatdate: any) => {
    try {
    settimeSlotData([]);
    setTsloader(true);
    
    setStartDate(moment(selectedFormatdate).format('YYYY-MM-DD'));
    const startDate = selectedFormatdate;
    
    await getTimeSlots(startDate);
    SetTime('');
    Settimeslotid('');
    setTsloader(false);
    console.log('beoefevcdvbh', BindtimeslotId);
    setBindtimeslotId('');
    }
    catch(error){
      setTsloader(false);
    }
  };
  function* numCount() {
    let count = 99;
    while (true) yield count++; /*  ww w  .  j av a2s.co  m*/
  }
  const irt = numCount();

  const getTimeSlots = async (startingDate: any, deliveryinfo: any = {}) => {
    console.log(deliveryinfo);

    let targetDate = deliveryinfo.deliverydate ? deliveryinfo.deliverydate : maxDate;
    let maxLimitDate = deliveryinfo.deliverydate ? deliveryinfo.deliverydate : maxDate;
    let targetLimitTime = deliveryinfo.deliverytime ? deliveryinfo.deliverytime: limitTime;

    if(moment(startingDate) > moment(targetDate) ){
      console.log("delivery time is before selected store pickup date");
      settimeSlotData([]);
      return;
    }
    const startDate = moment(startingDate);
    const endddate = moment(targetDate);
   
    const differenceInDays = endddate.diff(startDate, 'days');
    if (differenceInDays > MAX_LOAD_DAYS) {
      targetDate = startDate.add(MAX_LOAD_DAYS, 'days').format('YYYY-MM-DD');
    }

    const payload = {
      isLoaner: 'Y',
      customerId: '108348922',
      endDate: targetDate,
      eventSource: 'STO',
      startDate: startingDate,
      storeId: sessionStorage.getItem('storeNumber'),
    };
    const timeslotresponse = await getTimeSlot(payload);
    if(timeslotresponse.status != 200) {
      throw "API Error";
    }
    if (timeslotresponse.data !== undefined) {
      console.log('timeslotresponse', timeslotresponse);

      setAppoinmentSource(timeslotresponse.data.appointmentSource);
      // SetTimeslotReason(timeslotresponse);
      const TimeSlotsDetailsArrayValue: any = [];
      interface TimeSlotDetails {
        timeSlot: string;
        startTime: string;
        endTime: string;
        status: string;
      }

      interface TimeSlotDetailsArray {
        Date: string;
        TimeSlots: TimeSlotDetails;
      }

      const TimeSlotVariable = timeslotresponse.data.timeSlots;
      const sorted: any = Object.keys(TimeSlotVariable)
        .sort(function (a: any, b: any) {
          const date1: any = new Date(a);
          const date2: any = new Date(b);
          return date1 - date2;
        })
        .reduce((accumulator: any, key: any) => {
          accumulator[key] = TimeSlotVariable[key];

          return accumulator;
        }, {});
      Object.keys(sorted).forEach(function (key) {
        const value = sorted[key];
        //  console.log(key + ':' + value);
        const Timeslots: any = [];

        value.map((value: any) => {
          const timeslot_object = {} as TimeSlotDetails | any;
          timeslot_object.timeSlot = value.timeSlot;
          timeslot_object.startTime = value.startTime;
          timeslot_object.endTime = value.endTime;
          timeslot_object.status = value.status;
          timeslot_object.id = irt?.next()?.value
          // 
          Timeslots.push(timeslot_object);
        });

        const TotalTimeSlots = {} as TimeSlotDetailsArray;
        TotalTimeSlots.Date = key;
        TotalTimeSlots.TimeSlots = Timeslots;
        TimeSlotsDetailsArrayValue.push(TotalTimeSlots);
      });
     
      handleTimeSlot(TimeSlotsDetailsArrayValue, maxLimitDate, targetLimitTime)  
      settimeSlotData(TimeSlotsDetailsArrayValue);
      console.log(TimeSlotsDetailsArrayValue, 'timeslot converted array');
    }

    console.log(timeslotresponse.data, 'testingresponsetimeslot');
  };

  const handleTimeSlot = (timeslotDetails: any, thedate: string, thetime: string) => {
  
    const timeslots: any = timeslotDetails.find((item => item.Date === thedate));
    if (!timeslots || !timeslots.TimeSlots) return;
   
    timeslots.TimeSlots.forEach(function (item: any, index) {
      if (compareTimes(item.startTime, thetime) > 0) {
        item.status = "Busy";
      }
    });

  }

  function compareTimes(time1, time2) {
    const format = "HH:mm:ss";
    const moment1 = moment(time1, format);
    const moment2 = moment(time2, format);

    if (moment1.isBefore(moment2)) {
      return -1;
    } else if (moment1.isAfter(moment2)) {
      return 1;
    } else {
      return 0;
    }
  }
  const getslotdetails = (
    avail: any,
    modifiedStarttime: any,
    modifiedEndtime: any,
    uniqueItem?: any
  ) => {

    setColor(`${classes.blue}`);
    SetContextStartDate(modifiedStarttime);
    SetContextEndDate(modifiedEndtime);
    SetTime(avail.target.value);
    Settimeslotid(avail.target.id);
    setBindtimeslotId(avail.target.id);
    setCurrentBtnClass(uniqueItem)
    // BindtimeslotId = avail.target.id;
    BindtimeslotDate = avail.target.name;
    setStartDate(moment(BindtimeslotDate).format('YYYY-MM-DD'));
    buildTimeSlot();
  };


  const timeslotbind = (objdate: any) => {
    // var itr = 0;
    if (objdate.TimeSlots != undefined) {
      return objdate.TimeSlots.map((objtime: any) => {

        // itr += 1;
        const modifiedStarttime = moment(objtime.startTime, 'HH:mm:ss').format('hh:mm A');
        const modifiedEndtime = moment(objtime.endTime, 'HH:mm:ss').format('hh:mm A');
        const slicedTimeslot =
          modifiedStarttime.slice(0, 5) +
          modifiedStarttime.slice(8, modifiedStarttime.length);

        const Currently = new Date();
        const todayDate = String(Currently.getDate()).padStart(2, '0');
        const todayMonth = String(Currently.getMonth() + 1).padStart(2, '0'); //January is 0!
        const todayYear = Currently.getFullYear();

        const todaysDate = todayMonth + '/' + todayDate + '/' + todayYear;

        if (todaysDate == objdate.Date || todaysDate > objdate.Date) {
          const startTime: any = objtime.startTime;
          const hoursdisplayed = startTime.split(':')[0];
          const minutesdisplayed = startTime.split(':')[1];
          const displayedTime = hoursdisplayed + ':' + minutesdisplayed;
          console.log(displayedTime);

          const Currently = new Date();
          const TimeatPresent = new Date(Currently.getTime());
          const currentHours = TimeatPresent.getHours();
          console.log(currentHours);
          const currentMinutes = TimeatPresent.getMinutes();
          console.log(currentMinutes);
          const Currenttime = currentHours + ':' + currentMinutes;
          console.log(Currenttime);

          if (objtime.status !== 'Available') {
            let slotColor = '';

            if (
              BindtimeslotId !== '' &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== '' &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes.slotBooked;
            } else {
              slotColor = classes.slotBusy;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={`${slotColor} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                }
                disabled={true}
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          } else if (
            todaysDate > objdate.Date ||
            (todaysDate == objdate.Date && displayedTime <= Currenttime)
            // eslint-disable-next-line sonarjs/no-duplicated-branches
          ) {
            let slotColor = '';

            if (
              BindtimeslotId !== '' &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== '' &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes.slotBooked;
            } else {
              slotColor = classes.slotBusy;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={`${slotColor} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}

                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                }
                disabled={true}
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          } else {
            let slotColor = '';

            if (
              BindtimeslotId !== '' &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== '' &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes.slotBooked;
            } else {
              slotColor = classes.slotAvailable;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={`${slotColor} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}

                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                }
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          }
        } else {
          if (objtime.status !== 'Available') {
            return (
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={`${classes.slotBusy} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}

                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                }
                disabled={true}
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          } else {
            let slotColor = '';
            if (
              BindtimeslotId !== '' &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== '' &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes.slotBooked;
            } else {
              slotColor = classes.slotAvailable;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={`${slotColor} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}

                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                }
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          }
        }
      });
    }
  };

  const formatDate = (dates: any) => {
    // 
    // const dateFormat = new Date(dates);
    // return (
    //     dateFormat.toString().slice(0, 3) +
    //     ', ' +
    //     dateFormat.toString().slice(4, 10)
    // );
    const dateFormat = moment(dates);
    return dateFormat.format('ddd, MMM DD');
  };

  const buildTimeSlot = () => {
    if (timeSlotData != undefined && timeSlotData.length > 0) {
      let slotNumbers = 0;
      return timeSlotData.map((objdate: any) => {
        if (
          objdate.TimeSlots != null &&
          objdate.TimeSlots != '' &&
          slotNumbers < 5   //limit to max 5
        ) {
          slotNumbers++;
          const formattedDate = formatDate(objdate.Date);
          return (
            // eslint-disable-next-line react/jsx-key
            <Grid item className={classes.agrTimeSlot}>
              <Typography
                className={`${classes.semiBold} ${classes.font14} ${classes.MarginDate}`}
              >
                {formattedDate}
              </Typography>
              <Grid className={classes.agrListGroup}>
                {timeslotbind(objdate)}
              </Grid>
            </Grid>
            // </div>
            // </div>
            // </div>
          );
        }
      });
    } else {
      return (
        <div className={classes.Norecords}>
          {tsloader?<CircularProgress />: 'No available timeslots'}
        
        </div>
      );
    }
  };
  return (   
    <>
      <div>
        <Modal
          isOpen={loader}
          className={classes.loaderStyle}
          id="openpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <Grid>
            <Grid>
              <Grid className={classes.RACLoaderPage}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Grid>
          <List className={`${classes.breadcrumb}`}>
            <ListItem
              className={`${classes.breadcrumbItemActive}`}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                history.push({
                  pathname: `/dashboard`,
                });
              }}
            >
              Dashboard
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.breadcrumbItemActive} `}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                history.push({
                  pathname: `/storemgmt/dap`,
                });
              }}
            >
              DAP Events
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}  `}
              style={{ cursor: 'pointer' }}
            >
              {paramValue ? 'Edit' : 'Create'} Store Pick-up Event
            </ListItem>
          </List>
        </Grid>
        <Grid>
          <Typography
            variant="h4"
            style={{ marginTop: '1%', marginLeft: '1%' }}
          >
            {paramValue ? 'Edit' : 'Create'} Store Pick-up Event
          </Typography>
        </Grid>
        <Grid style={{ marginBottom: '100px' }}>
          <Card className={classes.cardidle} style={{ marginTop: '0%' }}>
            <CardContent>

              <Grid container style={{ marginLeft: '20px', marginBottom: '40px' }}>
                <Grid item spacing={2}>
                  <Grid container >
                    <Grid item style={{ width: '100%' }}>
                      <Typography variant="h5" className={classes.subTitle2}>
                        Store for Pick-up
                      </Typography> </Grid>
                    <Grid item style={{ width: '100%' }}>
                      <RACSelect
                        inputLabel=""
                        loading={conditionLoader}
                        required={false}
                        options={storyArray}
                        defaultValue={chooseStore !== '' ? chooseStore : 'SELECT'}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setChooseStore(e.target.value);
                        }}
                        {...(storyArray.length == 1 &&
                          !conditionLoader && {
                          errorMessage: 'Unable to fetch Data',
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ marginLeft: '180px' }} spacing={2}>
                  <Grid container >
                    <Grid item style={{ width: '100%' }}>
                      <Typography variant="h5" className={classes.subTitle2}>
                        Store Address
                      </Typography> </Grid>
                    <Grid item style={{ width: '100%' }}>
                      <Grid container spacing={2}>
                        <Grid item style={{ width: '40%' }}>
                          <Typography  >
                            Address Line 1
                          </Typography> </Grid>
                        <Grid item style={{ width: '25%' }}>
                          <Typography >
                            Address Line 2
                          </Typography> </Grid>
                        <Grid item style={{ width: '10%' }}>
                          <Typography >
                            Zip
                          </Typography> </Grid>
                        <Grid item style={{ width: '15%' }}>
                          <Typography >
                            City
                          </Typography> </Grid>
                        <Grid item style={{ width: '10%' }}>
                          <Typography >
                            State
                          </Typography> </Grid>
                      </Grid>
                    </Grid>
                    <Grid item style={{ width: '100%' }}>
                      <Grid container spacing={2}>
                        <Grid item style={{ width: '40%' }}>
                          <Typography >
                            {data.address1}
                          </Typography> </Grid>
                        <Grid item style={{ width: '25%' }}>
                          <Typography >
                            {data.address2}
                          </Typography> </Grid>
                        <Grid item style={{ width: '10%' }}>
                          <Typography >
                            {data.postalCode}
                          </Typography> </Grid>
                        <Grid item style={{ width: '15%' }}>
                          <Typography >
                            {data.city}
                          </Typography> </Grid>
                        <Grid item style={{ width: '10%' }}>
                          <Typography >
                            {data.state}
                          </Typography> </Grid>
                      </Grid>
                    </Grid>

                  </Grid>


                </Grid>
              </Grid>

              <Grid container style={{ marginLeft: '20px' }}>
                <Grid item style={{ width: '100%' }}>
                  <Typography variant="h5" className={classes.subTitle2}>
                    Pick-up Details
                  </Typography>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={2} >
                    <Grid item
                      style={{
                        width: '11%',
                        marginTop: '1%'
                      }}
                    >
                      <RACDatePicker
                        label="Date"
                        required={true}
                        // className={classes.margintextsize}
                        // style={{ paddingLeft: '40%' }}
                        // classes={classes.margintextsize}
                        value={startDate}
                        onChange={(e: any) => chooseDates(e)}
                        inputProps={{
                          min: moment().format('YYYY-MM-DD'),  
                          max: maxDate
                        }}
                      />
                    </Grid>
                    <Grid item
                      style={{
                        width: '11%',
                        marginTop: '1%',
                        marginLeft: '2%',
                      }}
                    >
                      <RACTextbox
                        inputlabel="Time"
                        name="Time"
                        // className={classes.margintextsize}
                        value={choosenTime}
                        disabled={true}
                        OnChange={Timebind}
                      />
                    </Grid>
                    <Grid item
                      style={{
                        width: '15%',
                        marginTop: '1%',
                        marginLeft: '2%',
                      }}
                    >
                      <RACSelect
                        inputLabel="# Co-workers Required"
                        loading={conditionLoader}
                        required={true}
                        options={coWorkerRequiredArray}
                        defaultValue={coworkers !== '' ? coworkers : 'SELECT'}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          SetCoworkers(e.target.value);
                        }}
                        {...(coWorkerRequiredArray.length == 1 &&
                          !conditionLoader && {
                          errorMessage: 'Unable to fetch Data',
                        })}
                      />
                    </Grid>
                    <Grid item
                      style={{
                        width: '20%',
                        marginTop: '1%',
                        marginLeft: '2%',
                      }}
                    >
                      <RACTextbox
                        inputlabel="Instruction"
                        required={false}
                        name="Description"
                        // className={classes.margintextsize}
                        value={data.Description}
                        OnChange={(e: any) => {
                          handleInputChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ marginLeft: '20px' }}>
                <Grid item md={8} className={classes.spacerMT8}>
                  <Grid className={classes.floatLeft}>
                    <Typography variant="h5" className={classes.subTitle}>
                      Select Available Timeslot
                    </Typography>
                    <Typography className={classes.deliveryEventDesc}>
                      Only 4 events can be added in one time slot.
                    </Typography>
                  </Grid>
                  <Grid className={classes.floatRight}>
                    <Grid
                      className={`${classes.floatLeft} ${classes.me48}`}
                    >
                      <span className={classes.circleAvailable} />
                      <span>Available</span>
                    </Grid>
                    <Grid
                      classes={{
                        root: clsx(classes.floatLeft, classes.spacerMR4),
                      }}
                    >
                      <span className={classes.circleBusy} />
                      <span>Busy</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container >
                <Grid
                  item
                  md={12}
                  className={classes.spacerMB5}
                  style={{ marginLeft: '1.5%' }}
                >
                  <Grid
                    style={{ width: '60%' }}
                    className={`${classes.mt2} ${classes.me2}`}
                  >
                    {buildTimeSlot()}
                  </Grid>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
          <Grid container classes={{
            root: clsx(classes.customerToolbar, classes.fixedBottom),
          }}
            style={{ zIndex: 0 }}>
            <Grid className={classes.footerFixed}>
              <Grid className={classes.floatLeft}>
                <RACButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => history.push(`/storemgmt/dap`)}
                >
                  Cancel
                </RACButton>
              </Grid>
              <Grid style={{ float: 'right' }}>
                <RACButton
                  variant="contained"
                  color="primary"
                  style={{ float: 'right' }}
                  disabled={(choosenTime && (coworkers && coworkers != 'SELECT') &&timeSlotData.length>0) ? false : true}
                  onClick={(e) => {
                    handleSave();
                  }}
                >
                  {'Update'}
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          {masterLoader ? (
            <Grid
              style={{
                position: 'fixed',
                background: '#f7f5f5',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                opacity: 0.6,
                zIndex: 1050,
                textAlign: 'center',
                margin: '0px 0px',
              }}
            >
              <Grid
                style={{
                  display: 'block',
                  position: 'fixed',
                  zIndex: 9999999,
                  top: '40%',
                  right: '50%',
                }}
              >
                <CircularProgress />
              </Grid>
            </Grid>
          ) : null}
          <RACModalCard
            isOpen={apierror}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={'9px !important'}
          >
            {apiErrorPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={errorMsg}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={'9px !important'}
          >
            {noRecordPopup()}
          </RACModalCard>
        </Grid>
      </div >
    </>
  );
}

