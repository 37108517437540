/* eslint-disable */
import { add, eachDayOfInterval, format, sub } from 'date-fns';
import { CancelToken } from 'axios';

import { client } from './client';
import { createTimeslot, TimeSlot } from '../domain/Calendar/Timeslot';
import {
  CalendarEvent,
  RawTimeSlot,
  EventStatusActionType,
  StoreEventRequest,
  BlockTimeEventRequest,
  EventSourceType,
  TimeSlotStatus
} from '../domain/Calendar/CalendarEvent';
import { SearchParams, SearchResult } from '../domain/Calendar/search';

export const dateFormat = 'MM/dd/yyyy';

export const getMockTimeSlots = (selectedDate: Date) => {
  return [
    {
      timeSlotId: '2',
      startTime: '10:00:00',
      endTime: '12:00:00',
      status: TimeSlotStatus.Available
    },
    {
      timeSlotId: '3',
      startTime: '13:00:00',
      endTime: '15:00:00',
      status: TimeSlotStatus.Blocked
    },
    {
      timeSlotId: '4',
      startTime: '15:00:00',
      endTime: '17:00:00',
      status: TimeSlotStatus.Available
    },
    {
      timeSlotId: '5',
      startTime: '17:00:00',
      endTime: '19:00:00',
      status: TimeSlotStatus.Busy
    }
  ].map((timeSlot: RawTimeSlot) => {
    return createTimeslot(timeSlot, selectedDate);
  });
};

export const getTimeSlots = async (
  storeId: string,
  selectedDate: Date,
  cancelToken?: CancelToken
) => {
  const formattedDate = format(selectedDate, dateFormat);
  const timeSlotsResponse: Record<string, RawTimeSlot[]> = await client(
    `stores/${storeId}/timeslots/v2`,
    {
      method: 'GET',
      params: {
        eventSource: 'Store',
        startDate: formattedDate,
        endDate: formattedDate
      },
      cancelToken
    }
  );

  if (!timeSlotsResponse || !timeSlotsResponse[formattedDate]) return [];
  return timeSlotsResponse[formattedDate].map((timeSlot: RawTimeSlot) => {
    return createTimeslot(timeSlot, selectedDate);
  });
};

export const getIntervalTimeSlots = async (
  storeId: string,
  startDate: Date,
  endDate: Date,
  eventSource: EventSourceType,
  cancelToken?: CancelToken
) => {
  const timeSlotsResponse: Record<string, RawTimeSlot[]> = await client(
    `stores/${storeId}/timeslots/v2`,
    {
      method: 'GET',
      params: {
        eventSource: eventSource,
        startDate: format(startDate, dateFormat),
        endDate: format(endDate, dateFormat)
      },
      cancelToken
    }
  );

  const days = eachDayOfInterval({ start: startDate, end: endDate });
  const response: TimeSlot[][] = [];

  days.forEach(day => {
    const date: string = format(day, dateFormat);
    response.push(
      timeSlotsResponse[date].map(timeSlot => {
        return createTimeslot(timeSlot, day);
      })
    );
  });

  return response;
};

export const getEvents = (
  storeId: string,
  selectedDate: string,
  cancelToken?: CancelToken
) =>
  client(`stores/${storeId}/events`, {
    method: 'GET',
    params: { startDate: selectedDate, endDate: selectedDate },
    cancelToken
  });

export const searchEvents = (
  storeId: string,
  params: SearchParams,
  cancelToken?: CancelToken
): Promise<SearchResult[]> => {
  const startDate = format(sub(new Date(), { days: 1 }), dateFormat);
  const endDate = format(add(new Date(), { months: 6 }), dateFormat);

  return client(`stores/${storeId}/events`, {
    method: 'GET',
    params: { startDate, endDate, ...params },
    cancelToken
  });
};

export const getEvent = (
  storeId: string,
  eventId: string,
  getType?: string
) => {
  const padStore = storeId.padStart(5, '0');
  let path = `stores/${padStore}/events/${eventId}`;
  if (getType) {
    path = path + '?type=' + getType;
  }

  return client(path, {
    method: 'GET'
  });
};

export const updateEventStatus = (
  eventId: string,
  storeId: string,
  eventStatusAction: EventStatusActionType,
  eventSource: EventSourceType,
  reason?: string
) =>
  client(`stores/${storeId}/events/${eventId}/${eventStatusAction}`, {
    method: 'PUT',
    params: { eventSource, reason }
  });

export const updateEventStatusWithPayload = (
  eventId: string,
  storeId: string,
  eventStatusAction:
    | EventStatusActionType.Delete
    | EventStatusActionType.Cancel,
  eventSource: EventSourceType,
  reasonText?: string
) =>
  client(`stores/${storeId}/events/${eventId}/${eventStatusAction}`, {
    method: 'PUT',
    body: { eventSource, reasonText }
  });

export const createEvent = (
  storeId: string,
  payload: Partial<CalendarEvent> | StoreEventRequest | BlockTimeEventRequest
) =>
  client(`stores/${storeId}/events`, {
    method: 'POST',
    body: payload
  });

export const updateEvent = (
  storeId: string,
  payload: Partial<CalendarEvent> | StoreEventRequest | BlockTimeEventRequest,
  eventId: string
) =>
  client(`stores/${storeId}/events/${eventId}`, {
    method: 'PUT',
    body: payload
  });
