/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/core';
export const pinManagementStyles = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const UseClasses = makeStyles((theme) => ({
    container: {
        width: "100%",
        paddingRight: "0.75rem",
        paddingLeft: "0.75rem",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "60px",
        paddingBottom: "80px !important",
      },
      loaderStyle: {
        border: 'none',
        backgroundColor: 'transparent',
        // userFocus: 'none',
        // MozUserFocus: 'none',
        outline: 'none',
      },
      colMd12mt3: {
        flex: "0 0 auto",
        width: "100%",
        marginTop: "1rem !important",
      },
      RACLoaderPage: {
        textAlign: 'center',
        marginTop: '250px',
        fontSize: '16px',
        marginLeft: '30px',
        marginRight: '30px',
        outline: 'none',
      },
      row: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "0",
        marginRight: "-0.75rem",
      },
      header: {
        width: "100%",
        fontSize: "18px",
        fontFamily: "OpenSans-bold",
        marginBottom: "15px",
      },
      card: {
        border: "none",
        borderRadius: "1rem",
        boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
      },
      cardBody: {
        flex: "1 1 auto",
        padding: "1rem 1rem",
        paddingBottom: "10px !important",
      },
      floatLeft: {
        float: 'left',
      },
      float: {
        float: 'right',
      },
      racPinContainer: {
        margin: '0 auto',
        width: '80%',
      },
      racPinLabel: {
        fontFamily: 'OpenSans-semibold',
        color: '#000000',
        marginLeft: '0.5rem',
        marginBottom: '3px',
      },
      crossIcon:{
        '&div':{
        '&svg': {
           height:"20",
           width:"20"
        }
      }
      },
      footerGrid: {
        paddingTop: '120px !important',
        marginRight: '-0.75rem',
        marginLeft: '-0.75rem',
        display: 'flex',
        flexWrap: 'wrap',
      },
      footerFixed: {
        backgroundColor: '#fff !important',
        padding: '1rem !important',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%) !important',
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
      },
      textCenter: {
        textAlign: 'center',
      },
  
      RACPOPMsg: {
        fontFamily: 'OpenSans-bold',
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0,
      },
      marginTop: {
        marginTop: '-1rem',
      },
      justifyContentCenter: {
        justifyContent: 'center',
      },
  
      explanationContent: {
        justifyContent: 'right',
      },
      spacerMT4: {
        marginTop: theme.typography.pxToRem(24),
      },
      spacerMR2: {
        marginRight: theme.typography.pxToRem(8),
      },
      foc: {
        '&:focus': {
          borderColor: '#80bdff',
          boxShadow: '1px 1px 6px 3px #80bdff',
        },
      },
}));
return UseClasses();
};