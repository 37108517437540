/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity*/
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-redundant-boolean */
import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RACButton,
  RACModalCard,
  CircularProgress
} from '@rentacenter/racstrap';
import PinField from 'react-pin-input';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { pinManagementStyles } from './pinManagementStyles'
// import  crossIcon from '../../assets/images/crossIcon.png';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon-print.svg';
import { ReactComponent as SuccessPopUpIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/CloseIcon.svg';
import { updatePinDetails, getmenuandstore } from '../../api/user';
import { CustomPropInterface } from '../../index';
import { ContainerContext } from "../../app/App";



export function PinManagement() {
  //mentioning all the use ref for clearing all th pin for react pin input packages
  const [isPinConfigured, setisPinConfigured] = useState(true)
  let elementConfirm: any = useRef();
  let elementNew: any = useRef();
  let elementCurrent: any = useRef();
  const history = useHistory();

  //In use effect In page load need to get the username and support Numnber set the vale to the respective states
  useEffect(() => {
    pageLoad();
    elementCurrent.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = pinManagementStyles();
  const [pinDetails, setPinDetails] = useState({
    currentPin: '',
    newPin: '',
    confirmPin: ''
  });
  const [validatePinDetails, setValidatePinDetails] = useState({
    mustBeFour: false,
    mustNotStartZero: false
  });

  const [erroModel, setErrorModel] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isAccountLocked, setIsAccountLocked] = useState(false)
  const [defaultDetails, setDefaultDetails] = useState({
    userName: '',
    userId: '',
    supportPhoneNumber: '',
  })
  const [loader, setLoader] = useState(false);
  const containerData = useContext(ContainerContext) as CustomPropInterface;

  //This function used to formate the mobile number in US formate
  const formatePhoneNumber = (phoneNumber: string) => {
    //normalize string and remove all unnecessary characters
    phoneNumber = phoneNumber.replace(/[^\d]/g, "");

    //check if number length equals to 10
    if (phoneNumber.length == 10) {
      //reformat and return phone number
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }

    return null;
  }


  //This method will trigger in each one of the pin fields and set the value in respective states
  const pinOnChange = (event: string, type: string) => {
    if (type === 'CURRENT')
      setPinDetails({ ...pinDetails, currentPin: event })
    if (type === 'NEWPIN') {
      const validatePinRegex = /^(?!(0))[0-9]+$/;
      setPinDetails({ ...pinDetails, newPin: event })
      setValidatePinDetails({
        ...validatePinDetails,
        mustBeFour: event.length === 4 ? true : false,
        mustNotStartZero: validatePinRegex.test(event)
      })
    }
    if (type === 'CONFIRMPIN')
      setPinDetails({ ...pinDetails, confirmPin: event });
  }

  const pageLoad = async () => {
    setLoader(true);
    console.log('Inside storemgmt', containerData, containerData !== undefined ? containerData.GetName() : '');
    let userNameValue = '';
    let userIdValue = '';
    if (containerData !== undefined) {
      userNameValue = containerData.GetName()
      userIdValue = containerData.GetEmployeeId()
    } else {
      const coWorkerDetailsObj: any = await getmenuandstore();
      console.log('Menu and Store', coWorkerDetailsObj)
      if (coWorkerDetailsObj?.coworkerProfile !== undefined &&
        coWorkerDetailsObj?.coworkerProfile !== null && coWorkerDetailsObj?.coworkerProfile.firstName !== undefined && coWorkerDetailsObj?.coworkerProfile.lastName !== undefined && coWorkerDetailsObj?.coworkerProfile.employeeId !== undefined) {
        userNameValue = coWorkerDetailsObj?.coworkerProfile.firstName + ' ' + coWorkerDetailsObj?.coworkerProfile.lastName
        userIdValue = coWorkerDetailsObj?.coworkerProfile.employeeId
        setisPinConfigured(coWorkerDetailsObj?.coworkerProfile?.isPinConfigured == false ? false : true)
      }
    }
    const formatedPhoneNumber = formatePhoneNumber('8002752696');
    setDefaultDetails({ ...defaultDetails, userName: userNameValue, userId: userIdValue, supportPhoneNumber: formatedPhoneNumber ? formatedPhoneNumber : '' });
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  //Reset the stats will be reset only the state value and using use ref to clear all the values inside the filed
  const resetStateValue = () => {
    setPinDetails({
      ...pinDetails,
      currentPin: '',
      newPin: '',
      confirmPin: ''
    });
    setValidatePinDetails({
      ...validatePinDetails,
      mustBeFour: false,
      mustNotStartZero: false
    });
    elementConfirm.clear();
    elementNew.clear();
    elementCurrent.clear();
  }

  const handleSaveBtn = async () => {
    setLoader(true);
    console.log('Saving', pinDetails.confirmPin, pinDetails.confirmPin);
    if (pinDetails.confirmPin === pinDetails.newPin) {
      const payload: any = {
        userId: defaultDetails.userId,
        currentEncryptedPin: pinDetails.currentPin ? pinDetails.currentPin : null,
        newEncryptedPin: pinDetails.newPin
      }
      const response = await updatePinDetails(payload)
      console.log("ManagePin details response", response);

      if (response && response.statusCode == 200) {
        setIsValidPassword(true);
      } 
      else if(response && response.data && response?.data?.errors[0]?.code == 400 && JSON.parse(response?.data?.errors[0]?.error)[0]?.message == 'ACCOUNT_BLOCKED'){
          setIsValidPassword(false); 
          setIsAccountLocked(true);
      }
      else {
        setIsValidPassword(false);
      }
    }
    setErrorModel(true);
    setLoader(false);
  }
  //Error Model popup will have error and success model for overall pin management module
  const ErrorModelPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          {isValidPassword === true ? <Grid style={{ marginBottom: '15px' }}><SuccessPopUpIcon /></Grid> : null}
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            {
              pinDetails.currentPin == pinDetails.newPin ? `Please try new pin that you haven't used already.` :
                pinDetails.confirmPin !== pinDetails.newPin ? `New pin and confim pin is not matched, Please try again.` :
                  isAccountLocked == true ? `Your account has been locked due to consecutive incorrect PIN entries.` :
                    isValidPassword === false ? `Please enter the correct current PIN to change your PIN.` :
                      'Your PIN has been successfully changed.'
            }
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => {
              isValidPassword === true ?
                history.push({
                  pathname: `/dashboard/homepage`,
                }) : setErrorModel(false)
              if (pinDetails.currentPin == pinDetails.newPin) { elementConfirm.clear(); elementNew.clear(); elementNew.focus(); }
              else if (pinDetails.confirmPin !== pinDetails.newPin) { elementConfirm.clear(); elementConfirm.focus() }
              else if (isValidPassword === false) { elementCurrent.clear(); elementCurrent.focus(); }

            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  function ErrorModelCard() {
    return (
      <RACModalCard
        isOpen={erroModel}
        maxWidth="xs"
        closeIcon={false}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={ErrorModelPopup()}
      // title="Validation Message"
      />
    );
  }


  return (
    <>
      <Grid style={{ marginBottom: '1%' }} className={classes.container}>
        <ErrorModelCard />
        {/*Heading SubContainer*/}

        <Grid style={{ width: '100%', float: 'left' }}>
          <Grid className={classes.colMd12mt3}>
            <Grid className={classes.row} style={{ marginBottom: '3%' }}>
              {/* Drawer total starts here */}
              <Typography variant="h4" className={classes.header}>
                Pin Management
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            width: '30%',
            marginRight: '35%',
            marginLeft: '35%',
            float: 'left',
          }}
        >
          <Card className={classes.card} style={{ padding: "2%" }}>
            <CardContent className={classes.cardBody}>
              <Grid style={{ background: '#EFF4FF', padding: '4%', marginBottom: "5%", marginTop: "2%" }}>
                <Grid style={{ color: '#2179FE' }}>
                  <Typography variant="h5">
                    {'User : ' + defaultDetails.userName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid style={{ marginBottom: "5%" }}>
                <Typography variant='h4'>
                  To set or change your PIN, complete and save
                </Typography>
              </Grid>
              <Grid style={{ width: '100%', marginBottom: "5%" }}>
                <Grid className={classes.row}>
                  <Grid style={{ width: "20%" }}>
                    <Typography style={{ marginTop: "14%", fontFamily: "OpenSans-bold" }}>
                      Current PIN
                    </Typography>
                  </Grid>
                  <Grid container className={classes.racPinContainer}>
                    {/* <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
            </Grid> */}
                    <Grid item md={12}
                    // onKeyPress={pinEnter}
                    >
                      <PinField
                        length={4}
                        type="numeric"
                        focus={true}
                        secret={true}
                        autoSelect={false}
                        disabled={isPinConfigured == false ? true : false}
                        style={{ marginLeft: '0.3rem' }}
                        inputStyle={{
                          border: '1px solid #adb5bd',
                          borderRadius: '0.6rem',
                          marginRight: '20px',
                          backgroundColor: isPinConfigured == false ? '#f2f2f2' : 'white'
                        }}
                        ref={(n: any) => (elementCurrent = n)}
                        onChange={(event: any) => pinOnChange(event, 'CURRENT')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ width: '100%', marginBottom: "7%" }}>
                <Grid className={classes.row}>
                  <Grid style={{ width: "20%" }}>
                    <Typography style={{ marginTop: "14%", fontFamily: "OpenSans-bold" }}>
                      New PIN
                    </Typography>
                  </Grid>
                  <Grid container className={classes.racPinContainer}>
                    {/* <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
            </Grid> */}
                    <Grid item md={12}
                    // onKeyPress={pinEnter}
                    >
                      <PinField
                        length={4}
                        type="numeric"
                        focus={true}
                        secret={true}
                        autoSelect={false}
                        style={{ marginLeft: '0.3rem' }}
                        inputStyle={{
                          border: '1px solid #adb5bd',
                          borderRadius: '0.6rem',
                          marginRight: '20px',
                        }}
                        ref={(n: any) => (elementNew = n)}
                        // onComplete={(event: any) => pinValue(event)}
                        onChange={(event: any) => pinOnChange(event, 'NEWPIN')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ width: "100%", marginBottom: "5%" }}>
                <Grid className={classes.row}>
                  <Grid className={classes.crossIcon}  >
                    {validatePinDetails.mustBeFour ?
                      <Grid style={{ float: "left", marginTop: '3px', marginRight: '5px' }}>
                        <SuccessIcon />
                      </Grid> :
                      <Grid style={{ float: "left", marginTop: '3px', marginRight: '5px' }}>
                        <CloseIcon />
                      </Grid>}
                    <Grid style={{ float: "left", marginTop: "1px", marginLeft: "2px" }}>
                      <Typography style={{ fontSize: "0.950rem" }}>
                        PIN must be 4 digits.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ width: "100%", marginBottom: "4%" }}>
                <Grid className={classes.row}>
                  <Grid className={classes.crossIcon}  >
                    {validatePinDetails.mustNotStartZero ?
                      <Grid style={{ float: "left", marginTop: '3px', marginRight: '5px' }}>
                        <SuccessIcon />
                      </Grid> :
                      <Grid style={{ float: "left", marginTop: '3px', marginRight: '5px' }}>
                        <CloseIcon />
                      </Grid>}
                    <Grid style={{ float: "left", marginTop: "1px", marginLeft: "2px" }} >
                      <Typography style={{ fontSize: "0.950rem" }} >
                        Any combo of 0-9, must not start with 0.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ width: '100%', marginBottom: "7%" }}>
                <Grid className={classes.row}>
                  <Grid style={{ width: "20%" }}>
                    <Typography style={{ marginTop: "14%", fontFamily: "OpenSans-bold" }}>
                      Confirm PIN
                    </Typography>
                  </Grid>
                  <Grid container className={classes.racPinContainer}>
                    {/* <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
            </Grid> */}
                    <Grid item md={12}
                    // onKeyPress={pinEnter}
                    >
                      <PinField
                        length={4}
                        type="numeric"
                        focus={true}
                        secret={true}
                        autoSelect={false}
                        style={{ marginLeft: '0.3rem' }}
                        inputStyle={{
                          border: '1px solid #adb5bd',
                          borderRadius: '0.6rem',
                          marginRight: '20px',
                        }}
                        ref={(n: any) => (elementConfirm = n)}
                        // onComplete={(event: any) => pinValue(event)}
                        onChange={(event: any) => pinOnChange(event, 'CONFIRMPIN')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.row} style={{ justifyContent: "center", marginBottom: "5%" }}>

                { isAccountLocked == true ? 
                <Grid  style={{ marginRight: '8px' }}>
                  <Typography style={{ textAlign: "left", marginBottom: "2%", color: 'red' }}>
                  Invalid PIN. Your account has been locked after 3 consecutive incorrect entries. Please call <span style={{ color: '#2179FE', fontFamily: 'OpenSans-bold' }}>{defaultDetails.supportPhoneNumber}</span> for assistance with resetting your PIN.
                  </Typography>
                </Grid>: 
                <>
                <Grid style={{ marginRight: '8px' }}>
                  <Typography style={{ textAlign: "center", marginBottom: "2%" }}>
                    Forgot PIN-Call Solution Center</Typography> </Grid>
                <Grid>
                  <Typography variant='h5'> {defaultDetails.supportPhoneNumber}</Typography>
                </Grid>
                </>
            }

              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* Footer starts here */}
        <Grid container className={classes.footerGrid}>
          <Grid className={classes.footerFixed}>
            <Grid style={{ float: 'left' }}>
              <RACButton
                disabled={loader}
                variant="outlined"
                color="primary"
                onClick={() => history.push({
                  pathname: `/dashboard/homepage`,
                })}
              >
                Cancel
              </RACButton>
            </Grid>
            <Grid style={{ float: 'right', width: '13%' }}>
              <RACButton
                //  disabled={loader}
                variant="outlined"
                color="secondary"
                disabled={pinDetails.confirmPin !== '' || pinDetails.newPin !== '' || pinDetails.currentPin !== '' ? false : true || loader}
                style={{ marginRight: '22px' }}
                onClick={() => { resetStateValue() }}
              >
                Clear
              </RACButton>

              <RACButton
                variant="contained"
                color="primary"
                disabled={pinDetails.confirmPin.length == 4 && pinDetails.newPin.length == 4 && validatePinDetails.mustBeFour && validatePinDetails.mustNotStartZero ? false : true || loader}
                onClick={() => handleSaveBtn()}
              >
                Save
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
        {/* Footer ends here */}
      </Grid>
      <Modal
        isOpen={loader}
        className={classes.loaderStyle}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid>
          <Grid>
            <Grid className={classes.RACLoaderPage}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}