/* eslint-disable */
import { Button, Label, NumericInput } from '@rentacenter/legacyracstrap';
import React, { HTMLProps, useContext, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './StoreGoalForm.module.scss';

import { Controller, useForm } from 'react-hook-form';
import { StoreGoalResponse, StoreGoal } from '../../../../../../domain/Calendar/Goal';
import { setStoreGoal ,updateStoreGoal } from '../../../../../../api/goals';
import { ToastType, useToastActionsContext } from '@rentacenter/legacyracstrap';
import { StoreContext } from '../../../../../../context/Storeprovider';
import { noOp } from '../../../../../../utils/helper-functions';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const storeGoalFormTestId = 'storeGoalFormTestId';
export const storeGoalTooltipTestId = 'storeGoalTooltipTestId';
export interface StoreGoalFormProps extends HTMLProps<HTMLDivElement> {
  readonly goal: StoreGoal;
  readonly startDate: Date;
  readonly onCancel?: () => void;
  readonly onSave: (value: StoreGoalResponse) => void;
}

export interface GoalFormData {
  readonly goal: string;
}

type Action = 'set' | 'updated';

export const validationHandler = (value: any) =>
  value > 0 ? true : 'Goals need to be grater than 0.';

export const StoreGoalForm = ({
  onCancel = noOp,
  onSave,
  goal,
  startDate
}: StoreGoalFormProps) => {
  const { selectedStore } = useContext(StoreContext);
  const { showToast } = useToastActionsContext();
  const [pending, setPending] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm<GoalFormData>({
    mode: 'onSubmit',
    defaultValues: {
      goal: goal.metricGoal === 0 ? '' : String(goal.metricGoal)
    }
  });

  const showToastError = () => {
    showToast(ToastType.Error, 'An error has occurred. Please try again.');
  };

  const showSuccessToast = (action: Action) => {
    showToast(ToastType.Success, `Your Goal has been successfully ${action}!`);
  };

  const handleRequest = (
    request: Promise<StoreGoalResponse>,
    action: Action
  ) => {
    request
      .then(res => {
        onSave(res);
        showSuccessToast(action);
      })
      .catch(() => {
        showToastError();
      })
      .finally(() => {
        onCancel();
        setPending(false);
      });
  };

  const onSubmit = (formData: GoalFormData) => {
    setPending(true);

    if (!selectedStore || goal === undefined) return;

    const { storeMetricGoalId, storeNumber, ...oldGoal } = goal;

    if (storeMetricGoalId) {
      const request = updateStoreGoal(selectedStore, storeMetricGoalId, {
        metricType: oldGoal.metricType,
        metricGoal: formData.goal,
        metricGoalDates: {
          startDate: oldGoal.metricGoalDates.startDate,
          endDate: oldGoal.metricGoalDates.endDate
        }
      });

      handleRequest(request, 'updated');
    } else {
      const request = setStoreGoal(
        selectedStore,
        startDate,
        goal.metricType,
        formData.goal
      );

      handleRequest(request, 'set');
    }
  };

  return (
    <form
      id={storeGoalFormTestId}
      className={styles.storeGoalForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.goalInputWrapper}>
        <Label>
          Goals{' '}
          <Tooltip
            data-testid={storeGoalTooltipTestId}
            arrow
            placement="bottom-start"
            title="Value should be between 1 and 999"
          >
            <span>
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </Tooltip>
        </Label>
        <Controller
          control={control}
          name="goal"
          rules={{
            required: 'This field is required.',
            validate: validationHandler
          }}
          render={({ ref, onChange, value, ...rest }, { invalid }) => (
            <NumericInput
              classes={{ label: styles.label, input: styles.goalInput }}
              size="small"
              invalid={invalid}
              disabled={pending}
              onChange={(e: any) => {
                clearErrors('goal');
                onChange(e);
              }}
              required
              value={value}
              errorMessage={errors?.goal?.message}
              options={{
                decimalPlaces: 0,
                minimumValue: 0,
                maximumValue: 999
              }}
              {...rest}
            />
          )}
        />
      </div>
      <div className={styles.editButtonsGroup}>
        <Button
          data-testid="cancel"
          size="small"
          className={styles.cancel}
          onClick={onCancel}
          variant="outlined"
          disabled={pending}
        >
          Cancel
        </Button>
        <Button
          data-testid="save"
          size="small"
          className={styles.save}
          type="submit"
          disabled={pending}
          {...(pending && {
            icon: <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
          })}
        >
          Save
        </Button>
      </div>
    </form>
  );
};
