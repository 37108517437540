/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles } from '@rentacenter/racstrap';
import GlobalSearchBtn from '../../assets/images/search-btn.svg';
export const globalStyles = () => {
  const useClasses = makeStyles((theme) => ({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(189, 189, 196)',
        borderRadius: '20px',
        border: '3px solid rgb(206, 204, 201)',
      },
      '*::-webkit-scrollbar-track': {
        background: 'rgb(245, 243, 239)',
      },
    },

    /***************************************************************/
    /**** Setting up the default MARGIN values - starts here *****/
    /***************************************************************/
    loaderStyle: {
      border: 'none',
      backgroundColor: 'transparent',
      // userFocus: 'none',
      // MozUserFocus: 'none',
      outline: 'none',
    },
    RACLoaderPage: {
      textAlign: 'center',
      marginTop: '250px',
      fontSize: '16px',
      marginLeft: '30px',
      marginRight: '30px',
      outline: 'none',
    },
    m0: {
      margin: theme.typography.pxToRem(0),
    },
    m1: {
      margin: theme.typography.pxToRem(4),
    },
    m2: {
      margin: theme.typography.pxToRem(8),
    },
    m3: {
      margin: theme.typography.pxToRem(16),
    },
    m4: {
      margin: theme.typography.pxToRem(24),
    },
    m5: {
      margin: theme.typography.pxToRem(48),
    },
    mAuto: {
      margin: 'auto',
    },

    mr0: {
      marginRight: theme.typography.pxToRem(0),
    },
    mr1: {
      marginRight: theme.typography.pxToRem(4),
    },
    mr2: {
      marginRight: theme.typography.pxToRem(8),
    },
    mr3: {
      marginRight: theme.typography.pxToRem(16),
    },
    mr4: {
      marginRight: theme.typography.pxToRem(24),
    },
    mr5: {
      marginRight: theme.typography.pxToRem(48),
    },
    mrAuto: {
      marginRight: 'auto',
    },

    ml0: {
      marginLeft: theme.typography.pxToRem(0),
    },
    ml1: {
      marginLeft: theme.typography.pxToRem(4),
    },
    ml2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    ml3: {
      marginLeft: theme.typography.pxToRem(16),
    },
    ml4: {
      marginLeft: theme.typography.pxToRem(24),
    },
    ml5: {
      marginLeft: theme.typography.pxToRem(48),
    },
    mlAuto: {
      marginLeft: 'auto',
    },

    mt0: {
      marginTop: theme.typography.pxToRem(0),
    },
    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },
    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },
    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },
    mtAuto: {
      marginTop: 'auto',
    },

    mb0: {
      marginBottom: theme.typography.pxToRem(0),
    },
    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },
    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },
    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },
    mbAuto: {
      marginBottom: 'auto',
    },
    mbFooterSpacing: {
      marginBottom: theme.typography.pxToRem(130),
    },

    my0: {
      marginTop: theme.typography.pxToRem(0),
      marginBottom: theme.typography.pxToRem(0),
    },
    my1: {
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },
    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },
    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },
    my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
    },
    myAuto: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },

    mx0: {
      marginLeft: theme.typography.pxToRem(0),
      marginRight: theme.typography.pxToRem(0),
    },
    mx1: {
      marginLeft: theme.typography.pxToRem(4),
      marginRight: theme.typography.pxToRem(4),
    },
    mx2: {
      marginLeft: theme.typography.pxToRem(8),
      marginRight: theme.typography.pxToRem(8),
    },
    mx3: {
      marginLeft: theme.typography.pxToRem(16),
      marginRight: theme.typography.pxToRem(16),
    },
    mx4: {
      marginLeft: theme.typography.pxToRem(24),
      marginRight: theme.typography.pxToRem(24),
    },
    mx5: {
      marginLeft: theme.typography.pxToRem(48),
      marginRight: theme.typography.pxToRem(48),
    },
    mxAuto: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    /***************************************************************/
    /**** Setting up the default MARGIN values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the default PADDING values - starts here *****/
    /***************************************************************/
    p0: {
      padding: '0 !important',
    },
    p1: {
      padding: theme.typography.pxToRem(4),
    },
    p2: {
      padding: theme.typography.pxToRem(8),
    },
    p3: {
      padding: theme.typography.pxToRem(16),
    },
    p4: {
      padding: theme.typography.pxToRem(24),
    },
    p5: {
      padding: theme.typography.pxToRem(48),
    },
    pAuto: {
      padding: 'auto',
    },

    pr0: {
      paddingRight: theme.typography.pxToRem(0),
    },
    pr1: {
      paddingRight: theme.typography.pxToRem(4),
    },
    pr2: {
      paddingRight: theme.typography.pxToRem(8),
    },
    pr3: {
      paddingRight: theme.typography.pxToRem(16),
    },
    pr4: {
      paddingRight: theme.typography.pxToRem(24),
    },
    pr5: {
      paddingRight: theme.typography.pxToRem(48),
    },
    prAuto: {
      paddingRight: 'auto',
    },

    pl0: {
      paddingLeft: theme.typography.pxToRem(0),
    },
    pl1: {
      paddingLeft: theme.typography.pxToRem(4),
    },
    pl2: {
      paddingLeft: theme.typography.pxToRem(8),
    },
    pl3: {
      paddingLeft: theme.typography.pxToRem(16),
    },
    pl4: {
      paddingLeft: theme.typography.pxToRem(24),
    },
    pl5: {
      paddingLeft: theme.typography.pxToRem(48),
    },
    plAuto: {
      paddingLeft: 'auto',
    },

    pt0: {
      paddingTop: theme.typography.pxToRem(0),
    },
    pt1: {
      paddingTop: theme.typography.pxToRem(4),
    },
    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },
    pt3: {
      paddingTop: theme.typography.pxToRem(16),
    },
    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },
    pt5: {
      paddingTop: theme.typography.pxToRem(48),
    },
    ptAuto: {
      paddingTop: 'auto',
    },

    pb0: {
      paddingBottom: theme.typography.pxToRem(0),
    },
    pb1: {
      paddingBottom: theme.typography.pxToRem(4),
    },
    pb2: {
      paddingBottom: theme.typography.pxToRem(8),
    },
    pb3: {
      paddingBottom: theme.typography.pxToRem(16),
    },
    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },
    pb5: {
      paddingBottom: theme.typography.pxToRem(48),
    },
    pbAuto: {
      paddingBottom: 'auto',
    },

    py0: {
      paddingTop: theme.typography.pxToRem(0),
      paddingBottom: theme.typography.pxToRem(0),
    },
    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },
    py2: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },
    py3: {
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },
    py5: {
      paddingTop: theme.typography.pxToRem(48),
      paddingBottom: theme.typography.pxToRem(48),
    },
    pyAuto: {
      paddingTop: 'auto',
      paddingBottom: 'auto',
    },

    px0: {
      paddingLeft: theme.typography.pxToRem(0),
      paddingRight: theme.typography.pxToRem(0),
    },
    px1: {
      paddingLeft: theme.typography.pxToRem(4),
      paddingRight: theme.typography.pxToRem(4),
    },
    px2: {
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    px3: {
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
    },
    px4: {
      paddingLeft: theme.typography.pxToRem(24),
      paddingRight: theme.typography.pxToRem(24),
    },
    px5: {
      paddingLeft: theme.typography.pxToRem(48),
      paddingRight: theme.typography.pxToRem(48),
    },
    pxAuto: {
      paddingLeft: 'auto',
      paddingRight: 'auto',
    },

    pbCardContentCustom: {
      paddingBottom: '0.5rem !important',
    },
    /***************************************************************/
    /**** Setting up the default PADDING values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the default FONT values - starts here *****/
    /***************************************************************/
    fs1: {
      fontSize: theme.typography.pxToRem(40),
    },
    fs2: {
      fontSize: theme.typography.pxToRem(32),
    },
    fs3: {
      fontSize: theme.typography.pxToRem(28),
    },
    fs4: {
      fontSize: theme.typography.pxToRem(24),
    },
    fs5: {
      fontSize: theme.typography.pxToRem(20),
    },
    fs6: {
      fontSize: theme.typography.pxToRem(16),
    },
    fs7: {
      fontSize: theme.typography.pxToRem(14),
    },
    fs8: {
      fontSize: theme.typography.pxToRem(12),
    },
    fontSemiBold: {
      fontFamily: 'OpenSans-semibold',
    },
    fontBold: {
      fontFamily: 'OpenSans-bold',
    },
    /***************************************************************/
    /**** Setting up the default FONT values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the BACKGROUND values - starts here *****/
    /***************************************************************/
    bgPrimary: {
      backgroundColor: '#2179FE',
    },
    bgTransparent: {
      backgroundColor: 'transparent',
    },
    bgLightBlue: {
      backgroundColor: '#EFF4FF',
    },
    bgLightGrey: {
      backgroundColor: '#fafafa',
    },
    bgRed: {
      backgroundColor: '#e85c5c',
    },
    bgPaleYellow: {
      backgroundColor: '#d58b00',
    },
    /***************************************************************/
    /**** Setting up the BACKGROUND values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the COLOR values - starts here *****/
    /***************************************************************/
    textGrey: {
      color: '#4A5174',
    },
    textBlack: {
      color: '#000000',
    },
    textWhite: {
      color: '#ffffff',
    },
    textBlue: {
      color: '#2179FE',
    },
    textViolet: {
      color: '#2e31be',
    },
    textDisabled: {
      color: '#818181',
    },
    /***************************************************************/
    /**** Setting up the COLOR values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the TEXT TREATMNET values - starts here *****/
    /***************************************************************/
    textIndent: {
      textIndent: '-9999px',
    },
    justifySpaceBetween: {
      justifyContent: 'space-between',
    },
    textCenter: {
      textAlign: 'center',
    },
    textRight: {
      textAlign: 'right',
    },
    /***************************************************************/
    /**** Setting up the TEXT TREATMNET values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the BORDER and RADIUS values - starts here *****/
    /***************************************************************/
    borderBottom: {
      borderBottom: '1px solid #d9d9d9',
    },
    borderLeftViolet: {
      borderLeft: '3px solid #2d31be',
    },
    borderRadius0: {
      borderRadius: '0 !important',
    },
    borderRadius5: {
      borderRadius: theme.typography.pxToRem(5),
    },
    borderRadius10: {
      borderRadius: theme.typography.pxToRem(10),
    },
    borderRadius50: {
      borderRadius: theme.typography.pxToRem(50),
    },
    borderNone: {
      border: theme.typography.pxToRem(0),
    },
    /***************************************************************/
    /***** Setting up the BORDER and RADIUS values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the COMPONENT ALIGNMENT - starts here *****/
    /***************************************************************/
    componentLeft: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
    componentCenter: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    componentRight: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      display: 'flex',
    },
    componentSpaceBetween: {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
    },
    alignCenter: {
      alignItems: 'center',
    },
    dflex: {
      display: 'flex',
    },
    /***************************************************************/
    /**** Setting up the COMPONENT ALIGNMENT - starts here *****/
    /***************************************************************/

    shadow0: {
      boxShadow: 'none',
    },

    w100: {
      width: '100%',
    },
    w95: {
      width: '95%',
    },
    widthAuto: {
      width: 'auto',
    },

    positionRelative: {
      position: 'relative',
    },

    positionAbsolute: {
      position: 'absolute',
    },

    racCard: {
      borderRadius: theme.typography.pxToRem(10),
      boxShadow: '0 0 3px #d5d4d4',
    },

    /**** Need to revisit the CSS below ****/
    denominationBtn: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(24),
      paddingRight: theme.typography.pxToRem(24),
      border: '1px solid #2468ff',
      fontFamily: 'Opensans-bold',
      minWidth: '100px',
    },
    denominationTextBox: {
      width: '115px',
      '@media (min-width: 360px) and (max-width: 1280px)': {
        width: '50px',
      },
    },
    fixedFooter: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.typography.pxToRem(16),
      backgroundColor: '#ffffff',
      boxShadow: '0 0 3px #d9d9d9',
    },

    racGlobalSearchBtn: {
      cursor: 'pointer',
      width: '25px',
      float: 'left',
      paddingTop: '0.2rem',
    },

    customPageSize: {
      width: '400px',
    },

    /**** Filter styles ****/
    /**** New styles ****/

    filterBtn: {
      padding: '10px',
      borderColor: '#D9E2ED !important',
      minWidth: '50px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    searchSpacing: {
      '& input': {
        padding: '10px',
        backgroundImage: `url(${GlobalSearchBtn})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '15px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
      },
    },

    filterDropDown: {
      zIndex: 1,
      width: '400px',
      right: '0',
      left: 'auto !important',
    },

    InlineFlex: {
      display: 'inline-flex',
    },
    TextUnderline: {
      textDecoration: 'underline',
    },
    BtnHover: {
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    overFlowVisible: {
      overflow: 'visible !important',
    },
    processBtn: {
      backgroundColor: '#E0EAFF',
      color: '#2468FF',
      borderColor: '#E0EAFF',
    },

    /**** Filter styles ****/
    floatLeft: {
      float: 'left',
    },
    formcontrol: {
      backgroundColor: '#fff',
      width: '100%',
    },
    denominationTxtBox: {
      width: '100px',
      '@media (min-width: 360px) and (max-width: 1279px)': {
        width: '50px',
      },
    },
    tabletdwrap: {
      '& td': {
        whiteSpace: 'nowrap',
      },
    },
    formlabel: {
      color: '#111111',
      fontSize: '0.875rem',
      fontFamily: 'OpenSans-semibold',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    w50: {
      width: '50%',
    },
    floatRight: {
      float: 'right',
    },
    borderTop: {
      borderTop: '1px solid #EBEBEB',
    },
    racCardblue: {
      backgroundColor: '#2179FE',
    },
    textwhite: {
      color: '#fff',
    },
    textLeft: {
      textAlign: 'left',
    },
    totalsummarybg: {
      backgroundColor: '#EFF4FF',
      borderRadius: '8px',
    },
    justifycontentcenter: {
      justifyContent: 'center',
    },
    mandatory: {
      content: '*',
      color: 'red',
    },
    btnedit: {
      backgroundColor: '#E0EAFF',
      color: '#2468FF',
      boxShadow: 'none',
      padding: '8px 20px!important',
      '&:hover': {
        backgroundColor: '#E0EAFF',
        color: '#2468FF',
        boxShadow: 'none',
      },
    },
    btnremove: {
      backgroundColor: '#FFD1D1',
      padding: '8px 20px!important',
      color: '#B50000',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#FFD1D1',
        color: '#B50000',
        boxShadow: 'none',
      },
    },
    ractable: {
      '@media (min-width: 300px) and (max-width: 1279px)': {
        overflow: 'auto!important',
      },
      '& td': {
        '@media (min-width: 360px) and (max-width: 1279px)': {
          whiteSpace: 'nowrap',
        },
      },
      '& th': {
        '@media (min-width: 360px) and (max-width: 1279px)': {
          whiteSpace: 'nowrap',
        },
      },
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    /**** New styles ****/
  }));
  //eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = useClasses();
  return classes;
};
