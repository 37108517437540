/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/no-children-prop */
/* eslint-disable sonarjs/no-duplicate-string */
/* esint-disable */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useMemo, useContext } from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    RACDatePicker,
    RACButton,
    RACSelect,
    RACTextbox,
    RACBadge,
    RACTableCell,
    List,
    ListItem,
    RACModalCard,
    RACTable,
    RACTableRow,
    Box,
    CircularProgress,
    RACToggle,
    RACTimePicker
} from "@rentacenter/racstrap";
import { StoreMgmtContext } from "../../context/storeMgmtContext";
import Modal from 'react-modal';
import moment from "moment";
import { DapCalendarStyles } from "./DapCalendarStyles";
import { ReactComponent as Alerticon } from '../../assets/images/No-records.svg';
import { ReactComponent as Successimage } from "../../assets/images/success-icon.svg";
import { fieldDeviceStyles } from "./FieldDeviceStyles";
import { getTimeSlot, searchStoreTask, getStoreTask, getStoreTaskHistory, createStoreTask, getSalesPersonOptions } from '../../api/user'
function Task() {
    const { createTaskModal, setcreateTaskModal, TaskandGoals, setTaskandGoals } = useContext(StoreMgmtContext)

    const [taskEventNotesHistory, settaskEventNotesHistory] = useState<any>({
        notes: true,
        history: false
    }
    )
    const [updatingTaskNotesLoader, setupdatingTaskNotesLoader] = useState(false)
    const [taskEventNotes, settaskEventNotes] = useState('')
    const classes = DapCalendarStyles();

    const [dateDap, setDateDap] = useState<any>({
        Date: moment().format('YYYY-MM-DD'),
    });
    const [eventType, setEventType] = useState<any>('ALL');
    const [documentName, setDocumentName] = useState('')
    const [appointmentDetails, setAppointmentDetails] = useState<any>();
    const [modals, setModals] = useState<any>({ storeEventDetailModal: false, cancelEventReaspnModal: false, timeSlotExceedModal: false, apiErrorInfoMsg: false, reopenConfirmModal: false, completeConfirmModal: false });
    const [currentappointmentDetails, setCurrentappointmentDetails] = useState<any>();
    const [filterStateData, setFilterStateData] = useState<any>({ date: moment().format('YYYY-MM-DD'), category: '', typesOfEvent: '', searchBarData: '', filterRecords: [], timeSlotDate: '' });
    const [loader, setloader] = useState(false);
    const [isStoreClosed, setIsStoreClosed] = useState(false);


    const nextpaymentobj = {
        nextamountdue: '',
        nextduedate: '',
    };
    const [eventLenght, seteventLenght] = useState<any>();

    const [timeSlotExceedRecordsDetails, setTimeSlotExceedRecordsDetails] = useState<any>({ timeSlot: '', appointmentDetails: [] });



    const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState(false);
    const [manageAgrErrMessage, setmanageAgrErrMessage] = useState('');
    const [taskCreatedPopup, settaskCreatedPopup] = useState(false)
    const [salesDropdownOptions, setsalesDropdownOptions]: any = useState([]);
    const [errorMessages, setErrorMessages] = useState({ taskTitle: '', editTaskTitle: '' });



    const classes1 = fieldDeviceStyles()
    const [eventStatusOptions, seteventStatusOptions] = useState([
        {
            label: 'All',
            value: 'ALL',
        },
        {
            label: 'To Do',
            value: 'TD',
        },
        {
            label: 'Completed',
            value: 'CMP',
        },
        {
            label: 'Cancelled',
            value: 'CAN',
        }
    ])
    const [taskTypes, settaskTypes] = useState([
        {
            label: 'All',
            value: 'ALL',
        },
        {
            label: 'Field',
            value: 'FIELD',
        },
        {
            label: 'Store',
            value: 'STORE',
        }
    ]);
    const [taskCategoryOptions, settaskCategoryOptions] = useState([
        {
            label: 'Select',
            value: '',
        },
        {
            label: 'Store',
            value: 'STORE',
        },
        {
            label: 'Field',
            value: 'FIELD',
        },
    ]);
    const [taskPriorityOption, settaskPriorityOption] = useState([
        {
            label: 'Select',
            value: '',
        },
        {
            label: 'High',
            value: 'HIGH'
        },
        {
            label: 'Medium',
            value: 'MEDIUM'
        },
        {
            label: 'Low',
            value: 'LOW'
        },
    ])
    const [createTaskModalValues, setCreateTaskModalValues] = useState({
        taskDescription: '',
        taskTitle: '',
        priority: '',
        dueDate: '',
        category: '',
        dueTime: '',
        assignee: ''

    })
    const [taskDetailsModalValues, settaskDetailsModalValues] = useState<any>({
        taskDetailsPopup: false,
        taskDetailValues: {},
        taskEventHistory: {},
    })
    const [editTaskModalValues, seteditTaskModalValues] = useState({
        editTaskModal: false,
        taskTitle: '',
        category: '',
        assignee: '',
        priority: '',
        dueDate: '',
        dueTime: '',
        description: '',
    })
    const [completeTask, setCompleteTaskModal] = useState(false)
    const [filteringValues, setfilteringValues] = useState({
        category: 'ALL',
        status: 'ALL',
        task: 'ALL'
    })

    useEffect(() => {
        console.log('WindowSize', window.innerWidth)
        windowSizeFn()
    }, [])
    useEffect(() => {
        pageLoad()
    }, [])
    useEffect(() => {
        getSalesPersonOptionsFn()
    }, [])
    const windowSizeFn = async () => {
        if (window.innerWidth <= 1280) {
            seteventLenght(2)
        }
        else {
            seteventLenght(4)
        }
    }



    const getSalesPersonOptionsFn = async () => {
        const salesPersonRes = await getSalesPersonOptions(window.sessionStorage.getItem("storeNumber"))

        if (salesPersonRes?.status === 200) {
            const salesPersonVal = salesPersonRes?.data;
            const SPArray: any = salesPersonVal.salespersonsData.map((ele: any) => {
                return {
                    value: ele.appUserName,
                    label: ele.appUserName,
                };
            });
            const DefaultsalesDropdownValue = {
                value: '',
                label: 'Select',
            };
            SPArray.splice(0, 0, DefaultsalesDropdownValue);
            setsalesDropdownOptions(SPArray);
        }
    }
    const pageLoad = async (date?: any, status?: any) => {
        try {
            setCreateTaskModalValues({
                ...createTaskModalValues, taskDescription: '',
                taskTitle: '',
                priority: '',
                dueDate: '',
                category: '',
                dueTime: '',
                assignee: ''
            })
            const payloadForGetTimeSlot = {
                isLoaner: 'Y',
                customerId: '108348922',
                endDate: date || filterStateData.date,
                eventSource: 'STO',
                startDate: date || filterStateData.date,
                storeId: sessionStorage.getItem('storeNumber'),
            };
            let searchStoreTaskPayload = {
                storeNumber: window.sessionStorage.getItem("storeNumber"),
                startDate: date || filterStateData.date,
                endDate: date || filterStateData.date,
            }
            setloader(true)
            const apiCallStack: any = []
            if (!date && !status) {
                apiCallStack.push(getTimeSlot(payloadForGetTimeSlot))
                apiCallStack.push(searchStoreTask(searchStoreTaskPayload))
            }
            if (date) {
                apiCallStack.push(getTimeSlot(payloadForGetTimeSlot))
                apiCallStack.push(searchStoreTask(searchStoreTaskPayload))
            }
            const response: any = await Promise.all(apiCallStack)
            setloader(false)
            const buildData = new Map()
            const curDate = date || filterStateData.date
            let timeSlotResponse: any = []
            let taskAppiontmentRes: any = []
            if (!date && !status) {
                timeSlotResponse = response[0].data.timeSlots[curDate]
                setFilterStateData({ ...filterStateData, timeSlotDate: response[0].data.timeSlots[curDate] })
            }
            if (date) {
                timeSlotResponse = response[0].data.timeSlots[curDate]
                if (response[1]?.data?.storeTasks) {
                    taskAppiontmentRes = response[1]?.data?.storeTasks
                }
            }
            if (timeSlotResponse && timeSlotResponse.length == 0) {
                setIsStoreClosed(true)
                return
            }
            if (response[1].data && response[1]?.data?.storeTasks && response[1]?.data?.storeTasks.length > 0 || timeSlotResponse.length > 0) {
                let appointmentRespone: any = []
                if (response[1]?.data?.storeTasks) {
                    taskAppiontmentRes = response[1]?.data?.storeTasks
                }
                if (taskAppiontmentRes) {
                    taskAppiontmentRes.map((obj) => {
                        appointmentRespone.push({
                            storeNumber: obj.storeNumber,
                            appointmentInfo: {
                                storeTaskId: obj.storeTaskId,
                                appointmentTypeDesc: 'TASK',
                                appointmentStatus: obj.status == 'COMPLETED' ? 'CMP' : obj.status == 'CANCELLED' ? 'CAN' : 'TD',
                                priorityDesc: obj.priorityDesc,
                                priority: obj.priority,
                                category: obj.category,
                                coworkerId: obj.coworkerId,
                                taskTitle: obj.title,
                            },
                            timeSlotInfo: {
                                startTime: taskStartTimeFn(obj.dueDate.split('T')[1]),
                                endTime: taskEndTimeFn(obj.dueDate.split('T')[1]),
                                endTimeStamp: obj.dueDate.replace('T', ' '),
                            },
                            notes: obj.notes,
                        })
                    })
                }

                timeSlotResponse?.forEach((el: any) => {
                    appointmentRespone?.push({ timeSlotInfo: el })
                })
                appointmentRespone.push({
                    timeSlotInfo: {
                        "timeSlot": "3P",
                        "startTime": "12:00:00",
                        "endTime": "01:00:00",
                        "status": "BREAK"
                    }
                })
                console.log("appointmentRespone", appointmentRespone);
                let sortedRecords = appointmentRespone.sort(compareStartTime);
                sortedRecords.forEach((el: any) => {

                    let startTime: any;
                    let endTime: any
                    startTime = moment(el.timeSlotInfo.startTime, 'HH:mm:ss').format(
                        'hh:mm A'
                    );
                    endTime = moment(el.timeSlotInfo.startTime, 'HH:mm:ss').format(
                        'hh:mm A'
                    );
                    console.log("sortedRecords", sortedRecords);

                    const keyName = startTime + '-' + endTime
                    if (buildData.has(keyName)) {
                        if (el.appointmentInfo) {
                            const data = buildData.get(keyName)
                            data.push(el)
                            buildData.set(keyName, data)
                        }
                    } else {
                        buildData.set(keyName, [el])
                    }
                })
            }
            const res = [...buildData.values()]
            setAppointmentDetails(res)
        }
        catch (e) {
            setloader(false)
            setModals({ ...modals, apiErrorInfoMsg: true })
            console.log(e)
        }

    }
    // function compareStartTime(a: any, b: any) {
    //     const startTimeA = a.timeSlotInfo.startTime;
    //     const startTimeB = b.timeSlotInfo.startTime;
    //     if (startTimeA < startTimeB) {
    //         return -1;
    //     } else if (startTimeA > startTimeB) {
    //         return 1;
    //     }
    //     return 0;
    // }


    function compareStartTime(a, b) {
        const startTimeA = a.timeSlotInfo.startTime;
        const startTimeB = b.timeSlotInfo.startTime;

        if (startTimeA < startTimeB) {
            return -1;
        } else if (startTimeA > startTimeB) {
            return 1;
        } else {
            // If start times are the same, compare end times
            const endTimeA = a.timeSlotInfo.endTime;
            const endTimeB = b.timeSlotInfo.endTime;

            if (endTimeA < endTimeB) {
                return -1;
            } else if (endTimeA > endTimeB) {
                return 1;
            } else {
                return 0; // If start and end times are equal, return 0
            }
        }
    }


    function taskStartTimeFn(timeString: any) {

        const inputTime = moment(timeString, 'HH:mm:ss');
        const time1Start = moment('10:00:00', 'HH:mm:ss');
        const time1End = moment('12:00:00', 'HH:mm:ss');
        const time2Start = moment('13:00:00', 'HH:mm:ss');
        const time2End = moment('15:00:00', 'HH:mm:ss');
        const time3Start = moment('15:00:00', 'HH:mm:ss');
        const time3End = moment('17:00:00', 'HH:mm:ss');
        const time4Start = moment('17:00:00', 'HH:mm:ss');
        const time4End = moment('19:00:00', 'HH:mm:ss');
        if (inputTime.isBetween(time1Start, time1End)) {
            return '10:00:00';
        } else if (inputTime.isBetween(time2Start, time2End)) {
            return '13:00:00';
        } else if (inputTime.isBetween(time3Start, time3End)) {
            return '15:00:00';
        }
        else if (inputTime.isBetween(time4Start, time4End)) {
            return '17:00:00';
        } else {
            return timeString;
        }
    }
    function taskEndTimeFn(timeString: any) {

        const inputTime = moment(timeString, 'HH:mm:ss');
        const time1Start = moment('10:00:00', 'HH:mm:ss');
        const time1End = moment('12:00:00', 'HH:mm:ss');
        const time2Start = moment('13:00:00', 'HH:mm:ss');
        const time2End = moment('15:00:00', 'HH:mm:ss');
        const time3Start = moment('15:00:00', 'HH:mm:ss');
        const time3End = moment('17:00:00', 'HH:mm:ss');
        const time4Start = moment('17:00:00', 'HH:mm:ss');
        const time4End = moment('19:00:00', 'HH:mm:ss');
        if (inputTime.isBetween(time1Start, time1End)) {
            return '12:00:00';
        } else if (inputTime.isBetween(time2Start, time2End)) {
            return '15:00:00';
        } else if (inputTime.isBetween(time3Start, time3End)) {
            return '17:00:00';
        }
        else if (inputTime.isBetween(time4Start, time4End)) {
            return '19:00:00';
        } else {
            return timeString;
        }
    }

    const UpdateInfoMsgPopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    <Alerticon></Alerticon>
                    <Typography
                        variant={'body2'}
                        className={`${classes.my3} ${classes.RACPOPMsg}`}
                    >
                        Something went wrong
                    </Typography>
                </Grid>
                <Grid
                    item
                    className={`${classes.textCenter} ${classes.mt4} ${classes.w100}`}
                >
                    <RACButton
                        variant="contained"
                        color="primary"
                        onClick={() => setModals({ ...modals, apiErrorInfoMsg: false })}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const getFontBackgroundColour = (appointmentStatus: any, appointmentEndTime: any) => {
        const currentTime = new Date();
        const targetDateTime = new Date(appointmentEndTime);
        const isCurrentTimeGreaterThanTarget = currentTime > targetDateTime;
        if (appointmentStatus == 'CAN') {
            return {
                fontColor: '#5a5a5a',
                backgroundColor: '#f6f6f6'
            }
        }
        if (appointmentStatus == 'CMP') {
            return {
                fontColor: '#539b4d',
                backgroundColor: '#f7fff7'
            }
        }
        if (isCurrentTimeGreaterThanTarget) {
            return {
                fontColor: '#cc1f1f',
                backgroundColor: '#fff1ef'
            }
        }
        if (!isCurrentTimeGreaterThanTarget) {
            return {
                fontColor: '#2e31be',
                backgroundColor: '#f4f7ff'
            }
        }
    }
    const getPriorityBatchBackGroundColor = (priorityDesc: any) => {
        if (priorityDesc == 'Low') {
            return '#D2DEFF'
        }
        if (priorityDesc == 'High') {
            return '#FFF1EF'
        }
        if (priorityDesc == 'Medium') {
            return '#FFF0D5'
        }
        return '#FFF0D5'
    }

    const getPriorityBatchColor = (priorityDesc: any) => {
        if (priorityDesc == 'Low') {
            return '#2E31BE'
        }
        if (priorityDesc == 'High') {
            return '#C60000'
        }
        if (priorityDesc == 'Medium') {
            return '#F46700'
        }
        return '#FFF0DS'
    }


    //Function for Showing the Delivery popup.
    const handleEventDetails: any = async (popupVal: any) => {
        try {
            setModals({ ...modals, timeSlotExceedModal: false })
            if (popupVal.appointmentInfo.appointmentTypeDesc == 'TASK') {
                setloader(true)
                setCurrentappointmentDetails({ ...popupVal })
                taskModalApiFn(popupVal?.appointmentInfo?.storeTaskId)
                return
            }
        } catch (e: any) {
            setloader(false)
            setModals({ ...modals, apiErrorInfoMsg: true })
        }

    }
    // Appoinment Events to bind.
    const eventToBind: any = (binder: any, index: any) => {
        if (filteringValues.status !== 'ALL') {
            binder = binder.filter((filterEl: any) => (!filterEl.storeNumber || filterEl.appointmentInfo.appointmentStatus == filteringValues.status))
        }
        if (filteringValues.task !== 'ALL') {
            binder = binder.filter((filterEl: any) => (!filterEl.storeNumber || filterEl.appointmentInfo.category == filteringValues.task))
        }
        if (documentName) {
            binder = binder.filter((filterEl: any) => (!filterEl.storeNumber || (filterEl.appointmentInfo && filterEl.appointmentInfo.instructions && filterEl.appointmentInfo.instructions.toLowerCase().includes(documentName)) || (filterEl.customerInfo && filterEl.customerInfo.firstName && filterEl.customerInfo.firstName.toLowerCase().includes(documentName.toLowerCase())) || (filterEl.customerInfo && filterEl.customerInfo.lastName && filterEl.customerInfo.lastName.toLowerCase().includes(documentName))))
        }
        const InactiveCheck = ['Paid in Full', 'Same As Cash', 'Early Purchase Option', 'Charged Off', 'Agreement Returned To Store (Repossession)', 'Return To Vendor']
        binder = binder.filter((filterEl: any) => !InactiveCheck.includes(filterEl?.agreement?.agreementStatusDesc))
        console.log(binder, "...bind")
        return binder?.map((el: any, index: any) => {
            // return binder?.agreementInfo.map((agree:any)=>{
            if (index < eventLenght) {
                return (
                    <Grid style={{
                        width: "24%",
                        margin: "0.5%",
                        zIndex: 0, textDecoration: 'none', fontSize: 'medium',
                        fontWeight: 'bold', color: '#2179FE',
                        borderLeft: `4px solid ${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.fontColor}`, display: 'flex',
                        position: 'relative',


                    }} className={`${classes1.DAPcardwidget} ${classes1.DAPsmallcardwidget}`}
                        key={index} >
                        <a style={{
                            width: "100%",
                            position: 'relative',
                            display: 'block',
                            padding: '0.5rem1rem',
                            color: '#212529',
                            textDecoration: 'none',
                            backgroundColor: `${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.backgroundColor}`,
                            // border: '1px solid rgba(0, 0, 0, 0.125)',
                            paddingLeft: "5%",
                            cursor: 'pointer',
                            borderRadius: "3px",
                            paddingTop: "2%",
                            paddingBottom: "2%",
                            boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
                        }}
                            className={classes1.DAPeventcardparent}
                            onClick={() => { el.appointmentInfo?.appointmentSource !== 'OFFSITE' ? handleEventDetails(el) : null }}
                        // onClick={() => handleEventDetails({ wholeEl: el, agreementInfo: el?.agreementInfo, customerInfo: el?.custumInfo, inventory: el?.inventories })}

                        >
                            <Grid style={{ width: '100%', display: 'flex' }}>
                                <Grid style={{ width: '60%', display: 'flex' }}>

                                    <Typography style={{
                                        fontFamily: 'OpenSans-bold',
                                        fontSize: ' 13px',
                                        display: 'flex',
                                        width: `${el.appointmentInfo?.appointmentSource == 'OFFSITE' ? '60%' : '100%'}`,
                                        color: `${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.fontColor}`
                                    }} className={classes1.DAPeventcardfont}>{
                                            el.appointmentInfo?.taskTitle ? el.appointmentInfo?.taskTitle : ''
                                        }
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '40%' }}>
                                    {el.appointmentInfo.appointmentType !== 'SE' ?
                                        <RACBadge className={`${classes.w100Px} ${classes1.w100Px}`} handleOnClick={function (event: any): void {
                                            throw new Error("Function not implemented.");
                                        }} backgroundColor={getPriorityBatchBackGroundColor(el.appointmentInfo?.priorityDesc)} color={getPriorityBatchColor(el.appointmentInfo?.priorityDesc)} TextValue={el?.appointmentInfo?.priorityDesc} OnclickNeed={false}>
                                        </RACBadge> : null}
                                </Grid>
                            </Grid>

                            <Grid className={classes.mb1} style={{ width: '100%', display: 'flex' }}>
                                <Grid style={{ width: '60%' }}>

                                    <Typography className={`${classes.fs12}`} style={{ marginTop: '18px' }}>
                                        <Grid>{el.appointmentInfo?.coworkerId ? el?.appointmentInfo?.coworkerId : ''}</Grid>
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '40%', alignSelf: 'end' }}>
                                    <Typography className={`${classes.fs12}`}>
                                        <Grid style={{ textAlign: 'center' }}>{el.appointmentInfo.appointmentType == 'SE' ? 'Store Event' : el.appointmentInfo.category == 'STORE' ? 'Task - Store' : el.appointmentInfo.category == 'FIELD' ? 'Task - Field' : 'Event'}</Grid>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </a>
                    </Grid>
                )
            }
            // })
        })
    }
    // color to be binded

    const onChangeDate = (val: any) => {
        setIsStoreClosed(false)
        pageLoad(moment(val).format('YYYY-MM-DD'))
        setFilterStateData({ ...filterStateData, date: val })
    };
    const handleDisable = () => {
        const Date = moment().format('YYYY-MM-DD');
        console.log('Date', Date);
        console.log('dateDap', dateDap);
        if (filterStateData.date == Date.toString()) {
            console.log('true');
            return true;
        } else {
            console.log('false');
            return false;
        }
    };
    const handleFilteringDropdown = (val?: any, type?: any) => {
        appointmentDetails
        if (type == 'Category' && val.target.value == 'ALL') {
            setfilteringValues({ ...filteringValues, task: 'ALL', category: val.target.value })
        }
        if (type == 'Category') {
            setfilteringValues({ ...filteringValues, category: val.target.value })
        }
        else if (type == 'Task') {
            setfilteringValues({ ...filteringValues, task: val.target.value })
        }
        else if (type == 'Status') {
            setfilteringValues({ ...filteringValues, status: val.target.value })
        }
    }
    const storeClosedGrid = () => {
        return (
            <Grid style={{ width: '100%', height: '150px' }}>
                <Typography style={{ textAlign: 'center', paddingTop: '50px' }}>Store closed</Typography>
            </Grid>
        )
    }
    const LunchBreakGrid = () => {
        return (
            <Grid style={{ width: '90%', height: '100%', display: 'flex' }} className={classes1.DAPlunchbreak}>
                <span style={{
                    width: '46%',
                    height: '1px', alignSelf: 'center',
                    color: 'gray',
                    background: 'repeating-linear-gradient(to right, transparent, transparent 5px, gray 5px, gray 10px)'
                }}></span>
                <Typography style={{ display: 'inline-block', textAlign: 'center', alignSelf: 'center', width: '8%', color: 'gray' }} className={classes1.breaktext}>Lunch Break</Typography>
                <span style={{
                    width: '46%', alignSelf: 'center',
                    height: '1px',
                    color: 'gray',
                    background: 'repeating-linear-gradient(to right, transparent, transparent 5px, gray 5px, gray 10px)'
                }}></span>
            </Grid>
        )
    }
    const TimeSlotExceedRecord = () => {
        return (<Grid style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            {timeSlotExceedRecordsDetails?.appointmentDetails?.map((el: any, index: any) => {
                // return binder?.agreementInfo.map((agree:any)=>{
                return (
                    <Grid key={el?.appointmentInfo?.appointmentId} style={{ width: '32%', display: 'flex', padding: '0.5rem' }} className={classes1.popupcard}>
                        <Grid style={{
                            width: "100%",
                            margin: "0.5%",
                            zIndex: 0, textDecoration: 'none', fontSize: 'medium',
                            fontWeight: 'bold', color: '#2179FE',
                            borderLeft: `4px solid ${getFontBackgroundColour(el?.appointmentInfo?.appointmentStatus, el?.timeSlotInfo?.endTimeStamp)?.fontColor}`, display: 'flex',
                            position: 'relative',

                        }} key={index} >
                            <a style={{
                                width: "100%",
                                position: 'relative',
                                display: 'block',
                                padding: '0.5rem1rem',
                                color: '#212529',
                                textDecoration: 'none',
                                backgroundColor: `${getFontBackgroundColour(el?.appointmentInfo?.appointmentStatus, el?.timeSlotInfo?.endTimeStamp)?.backgroundColor}`,
                                // border: '1px solid rgba(0, 0, 0, 0.125)',
                                paddingLeft: "5%",
                                cursor: 'pointer',
                                borderRadius: "3px",
                                paddingTop: "2%",
                                paddingBottom: "2%",
                                boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
                            }}
                                onClick={() => { el.appointmentInfo?.appointmentSource !== 'OFFSITE' ? handleEventDetails(el) : null }}


                            // onClick={() => handleEventDetails({ wholeEl: el, agreementInfo: el?.agreementInfo, customerInfo: el?.custumInfo, inventory: el?.inventories })}

                            >
                                <Grid style={{ width: '100%', display: 'flex' }}>
                                    <Grid style={{ width: '60%' }} className={classes1.eventnamewidth}>

                                        <Typography style={{
                                            fontFamily: 'OpenSans-bold',
                                            fontSize: ' 13px',
                                            display: 'flex',
                                            width: `${el.appointmentInfo?.appointmentSource == 'OFFSITE' ? '60%' : '100%'}`,
                                            color: `${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.fontColor}`
                                        }} className={classes1.DAPeventcardfont}>{
                                                el.appointmentInfo?.taskTitle ? el.appointmentInfo?.taskTitle : ''
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid style={{ width: '40%' }} className={classes1.eventbadgewidth}>
                                        {el.appointmentInfo.appointmentType !== 'SE' ?
                                            <RACBadge className={`${classes.w100Px} ${classes1.badgefontsize}`} handleOnClick={function (event: any): void {
                                                throw new Error("Function not implemented.");
                                            }} backgroundColor={getPriorityBatchBackGroundColor(el.appointmentInfo.priorityDesc)} color={getPriorityBatchColor(el.appointmentInfo?.priorityDesc)}
                                                TextValue={el.appointmentInfo.priorityDesc}
                                                OnclickNeed={false} >
                                            </RACBadge> : null}
                                    </Grid>
                                </Grid>


                                <Grid className={classes.mb1} style={{ width: '100%', display: 'flex' }}>
                                    <Grid style={{ width: '60%' }}>
                                        <Typography className={`${classes.fs12}`} style={{ marginTop: '18px' }}>
                                            <Grid>{el.appointmentInfo.coworkerId ? el.appointmentInfo.coworkerId : ''}</Grid>
                                        </Typography>

                                    </Grid>

                                    <Grid style={{ width: '40%', alignSelf: 'end' }}>
                                        <Typography className={`${classes.fs12}`}>
                                            <Grid style={{ textAlign: 'center' }}>{el.appointmentInfo.appointmentType == 'SE' ? 'Store Event' : el.appointmentInfo.category == 'STORE' ? 'Task - Store' : el.appointmentInfo.category == 'Field' ? 'Task - Field' : 'Event'}</Grid>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </a>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>)

    }
    const validateDate = (date) => {
        const selectedDate = moment(date);
        const today = moment().startOf('day');
        if (selectedDate.isBefore(today)) {
            return today.format('YYYY-MM-DD');
        } else {
            return date;
        }
    }
    const bindAppointmentDetails = (): any => {
        return appointmentDetails?.map((el: any) => {
            const [firstEl] = el
            const startTime: any = moment(firstEl.timeSlotInfo.startTime, 'HH:mm:ss').format(
                'hh:mm A'
            );
            console.log('startTime', startTime);
            const endTime: any = moment(firstEl.timeSlotInfo.endTime, 'HH:mm:ss').format(
                'hh:mm A'
            );
            console.log('endTime', endTime);
            return (
                <>
                    <CardContent className={classes.cardBody}>
                        <Grid
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingLeft: 0,
                                marginBottom: 0,
                                borderRadius: '0.25rem',
                            }}
                            className={classes1.responsiveeventcontainer}
                        >
                            <Grid className={`${classes.row} ${classes1.DAPcardwidgetmain} `} style={{ width: '100%', height: '80px' }}>
                                <Grid style={{ width: '10%', justifyContent: 'center', height: '100%' }} className={classes1.eventtimecontainer}>
                                    <Typography
                                        className={`${classes.racstrapTablecellBgColor} ${classes1.DAPmargintopcustom}`}
                                    >
                                        {startTime + `-` + endTime}
                                    </Typography>
                                </Grid>
                                {firstEl?.timeSlotInfo?.status == 'BREAK' ? <LunchBreakGrid /> : <Grid style={{ width: '88%', height: '100%' }} className={classes1.eventitemcontainer}>
                                    {/* <RACTableCell style={{width:"100%"}}> */}
                                    {firstEl.storeNumber ? <Grid className={classes.row}>{eventToBind(el)}</Grid> : <Grid className={classes.row}></Grid>}
                                    {/* </RACTableCell>  */}
                                </Grid>}

                                {el && el.length > eventLenght ?
                                    <Grid style={{ width: '2%', paddingLeft: '15px', paddingTop: '25px', height: '100%' }} className={classes1.DAPextraevents}>
                                        {/* <RACTableCell style={{width:"100%"}}> */}
                                        <a
                                            onClick={() => { setTimeSlotExceedRecordsDetails({ timeSlot: `${startTime + ' ' + endTime}`, appointmentDetails: el }); setModals({ ...modals, timeSlotExceedModal: true }) }}
                                            className={classes.customerRaclink}
                                        >
                                            +{el.length - eventLenght}
                                        </a>
                                        {/* </RACTableCell>  */}
                                    </Grid> : null}


                            </Grid>
                        </Grid>
                    </CardContent>
                </>
            );
        });
    };
    const taskModalApiFn = async (storeTaskId?: any) => {
        let getStoreTaskRes: any = {}
        let getStoreTaskHistoryRes: any = {}

        await Promise.all([
            getStoreTask(Number(storeTaskId)),
            getStoreTaskHistory(Number(storeTaskId))
        ]).then((resp) => {
            [getStoreTaskRes, getStoreTaskHistoryRes] = resp
        })
        setloader(false)
        if (getStoreTaskRes.data && getStoreTaskRes.status == 200 && getStoreTaskHistoryRes.data && getStoreTaskHistoryRes.status == 200) {

            settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailValues: getStoreTaskRes?.data, taskEventHistory: getStoreTaskHistoryRes?.data, taskDetailsPopup: true })
            seteditTaskModalValues({ ...editTaskModalValues, taskTitle: getStoreTaskRes.data?.title, priority: getStoreTaskRes.data?.priority, description: getStoreTaskRes.data?.description, category: getStoreTaskRes.data?.category, dueDate: moment(getStoreTaskRes.data?.dueDate).format('YYYY-MM-DD'), dueTime: moment(getStoreTaskRes.data?.dueDate.split('T')[1], 'hh:mm').format('hh:mm'), assignee: getStoreTaskRes.data?.coworkerId })

        }

    }
    const createTaskFn = async () => {
        setcreateTaskModal(false);
        setloader(true)
        const payLoad = {
            storeNumber: window.sessionStorage.getItem('storeNumber'),
            priority: createTaskModalValues.priority,
            category: createTaskModalValues.category,
            title: createTaskModalValues.taskTitle,
            dueDate: moment(createTaskModalValues.dueDate).format('YYYY-MM-DD') + 'T' + createTaskModalValues.dueTime + ':' + '01',
            description: createTaskModalValues.taskDescription,
            coworkerId: createTaskModalValues.assignee
        }
        const createTaskResponse = await createStoreTask(payLoad)
        setloader(false)
        if (createTaskResponse?.data && createTaskResponse?.status == 200) {
            settaskCreatedPopup(true)
        }
        else if (createTaskResponse?.status == 400) {
            setmanageAgrErrMessage(createTaskResponse?.data?.errors[0]?.error);
            seterrorMessagePopupOpen(true)
        }
        else {
            setModals({ ...modals, apiErrorInfoMsg: false })
        }
    }
    const taskCreatedPopupFn = () => {
        return (
            <div>
                <Grid item md={12} className={classes.textCenter}>
                    <Successimage></Successimage>
                    <Typography className={`${classes.popupText} ${classes.mt3}`}>
                        Task Created Successfully.
                    </Typography>
                </Grid>
                <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => { settaskCreatedPopup(false); pageLoad() }}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );
    };
    const createTaskModalFn = () => {
        return (
            <>
                <Grid style={{ padding: "10px" }}>
                    <Grid container spacing={3} md={12} sm={12} style={{ padding: '10px' }}>
                        <Grid item md={4} sm={6}>
                            <RACTextbox
                                value={createTaskModalValues.taskTitle}
                                OnChange={(e) => {
                                    let value = e.target.value;
                                    if (value.length > 20) {
                                        value = value.slice(0, 20);
                                        setErrorMessages({ ...errorMessages, taskTitle: 'Maximum of 20 characters' });
                                    } else {
                                        setErrorMessages({ ...errorMessages, taskTitle: '' });
                                    }
                                    setCreateTaskModalValues({ ...createTaskModalValues, taskTitle: value.trimStart() })
                                }}
                                placeHolder={'Enter Task Title'}
                                inputlabel='Task Title'
                                required={true}
                                errorMessage={errorMessages.taskTitle}
                            />
                        </Grid>
                        <Grid item md={4} sm={6}>
                            <RACSelect
                                inputLabel='Category'
                                options={taskCategoryOptions}
                                onChange={(e: any) => {
                                    setCreateTaskModalValues({ ...createTaskModalValues, category: e.target.value })
                                }}
                                defaultValue={createTaskModalValues.category}
                                required={true}

                            />
                        </Grid>
                        <Grid item md={4} sm={6}>
                            <RACSelect

                                inputLabel='Assignee'
                                options={salesDropdownOptions}
                                onChange={(e: any) => {
                                    setCreateTaskModalValues({ ...createTaskModalValues, assignee: e.target.value })
                                }}
                                defaultValue={createTaskModalValues.assignee}
                                required={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} md={12} sm={12} style={{ padding: '10px' }}>
                        <Grid item md={4} sm={6}>
                            <RACSelect
                                inputLabel='Priority'
                                options={taskPriorityOption}
                                onChange={(e: any) => {
                                    setCreateTaskModalValues({ ...createTaskModalValues, priority: e.target.value })
                                }}
                                defaultValue={createTaskModalValues.priority}
                                required={true}

                            />
                        </Grid>
                        <Grid item md={4} sm={6}>
                            <RACDatePicker
                                label="Due Date"
                                required={true}
                                classes={{ label: classes.timePickerRedClr }}
                                value={createTaskModalValues.dueDate}
                                inputProps={{
                                    min: moment().format('YYYY-MM-DD'),
                                    max: moment().add(118, "years").format("YYYY-MM-DD")
                                }}
                                onChange={(e: any) => {
                                    setCreateTaskModalValues({
                                        ...createTaskModalValues,
                                        dueDate: validateDate(e),
                                    });
                                }}
                                onKeyDown={(e: any) => {
                                    e.preventDefault();
                                }}
                            />
                        </Grid>
                        <Grid item md={4} sm={6}>
                            <span style={{
                                fontFamily: 'OpenSans-semibold',
                            }}>Due Time <span style={{ color: 'red' }}>*</span></span>
                            <RACTimePicker
                                label=''
                                value={createTaskModalValues.dueTime}
                                onChange={(e: any) => setCreateTaskModalValues({
                                    ...createTaskModalValues,
                                    dueTime: e
                                })}
                            />
                        </Grid>
                    </Grid>
                    <Grid style={{ padding: '10px' }}>
                        <Typography>Descritption <span style={{ color: 'red' }}>*</span></Typography>
                        <textarea
                            style={{ resize: 'none', padding: '10px', width: '-webkit-fill-available' }}
                            rows={7}
                            value={createTaskModalValues.taskDescription}
                            onChange={(e: any) => {
                                let value = e.target.value;
                                if (value.length > 250) {
                                    value = value.slice(0, 250);
                                }
                                setCreateTaskModalValues({ ...createTaskModalValues, taskDescription: value.trimStart() })
                            }}
                            placeholder='Enter Description'
                        />
                    </Grid>
                    <Grid style={{ display: 'flex', float: 'right', padding: '10px' }}>
                        <RACButton
                            variant='outlined'
                            color='default'
                            style={{ 'marginRight': '10px' }}
                            onClick={() => {
                                setcreateTaskModal(false);
                                setCreateTaskModalValues({
                                    ...createTaskModalValues, taskDescription: '',
                                    taskTitle: '',
                                    priority: '',
                                    dueDate: '',
                                    category: '',
                                    dueTime: ''
                                })
                            }}
                        >
                            Close
                        </RACButton>
                        <RACButton
                            variant="contained"
                            color="primary"
                            onClick={() => { createTaskFn() }}
                            disabled={createTaskModalValues.category && createTaskModalValues.dueDate && createTaskModalValues.priority && createTaskModalValues.taskTitle && createTaskModalValues.dueTime && createTaskModalValues.assignee && createTaskModalValues.taskDescription ? false : true}
                        >
                            Create
                        </RACButton>
                    </Grid>
                </Grid>
            </>
        )
    }
    const renderTaskEventTableHistoryHeader = () => {
        return (
            <>
                <React.Fragment>
                    <RACTableCell>
                        <Typography
                            variant="h5"
                        >
                            Activity Date & Time
                        </Typography>
                    </RACTableCell>
                    <RACTableCell>
                        <Typography
                            variant="h5"
                        >
                            Coworker
                        </Typography>
                    </RACTableCell>
                    <RACTableCell>
                        <Typography
                            variant="h5"
                        >
                            Action
                        </Typography>
                    </RACTableCell>
                </React.Fragment>
            </>)
    }
    const renderTaskEventTableHistoryContent = () => (
        <>
            {taskDetailsModalValues?.taskEventHistory?.storeTaskHistory?.map((data: any) => {
                return (
                    <>
                        <React.Fragment>
                            {/* <Grid className={classes.racLightBlue}> */}
                            <RACTableRow style={{ backgroundColor: "white", padding: "4px 14px" }}>
                                <RACTableCell
                                    className={`${classes.racLightBlue}`}
                                >
                                    <Typography>
                                        {moment(data?.lastModifiedDate).format('MM/DD/YYYY hh:mm A')}
                                    </Typography>
                                </RACTableCell>
                                <RACTableCell className={`${classes.racLightBlue}`}>
                                    <Typography>
                                        {data?.createdBy}
                                    </Typography>
                                </RACTableCell>
                                <RACTableCell className={`${classes.racLightBlue}`}>
                                    <Typography>
                                        {data.actionName == 'CANCELLED' ? 'Cancelled' : data.actionName == 'EDITED' ? 'Edited' : data.actionName == 'COMPLETED' ? 'Completed' : data.actionName == 'CREATED' ? 'Created' : data.actionName == 'REOPENED' ? 'Reopened' : data.actionName}
                                    </Typography>
                                </RACTableCell>
                            </RACTableRow>
                            {/* </Grid> */}
                        </React.Fragment>
                    </>
                )
            })
            }
        </>
    )

    const updateTaskEvent = async (type?: any) => {
        settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailsPopup: false })
        seteditTaskModalValues({ ...editTaskModalValues, editTaskModal: false })
        setCompleteTaskModal(false)
        setloader(true)
        let payload: any;

        if (type == 'Reopen') {
            payload = {
                storeNumber: window.sessionStorage.getItem('storeNumber'),
                storeTaskId: currentappointmentDetails?.appointmentInfo?.storeTaskId,
                actionName: 'Reopen',
                coworkerId: taskDetailsModalValues?.taskDetailValues?.coworkerId
            }
        }
        else if (type == 'Cancel') {
            payload = {
                storeNumber: window.sessionStorage.getItem('storeNumber'),
                storeTaskId: currentappointmentDetails?.appointmentInfo?.storeTaskId,
                actionName: 'cancel',
                coworkerId: taskDetailsModalValues?.taskDetailValues?.coworkerId

            }
        }
        else if (type == 'Edit') {
            payload = {
                storeNumber: window.sessionStorage.getItem('storeNumber'),
                storeTaskId: currentappointmentDetails?.appointmentInfo?.storeTaskId,
                priority: editTaskModalValues.priority,
                title: editTaskModalValues.taskTitle,
                dueDate: moment(editTaskModalValues.dueDate).format('YYYY-MM-DD') + 'T' + editTaskModalValues.dueTime + ':' + '01',
                description: editTaskModalValues.description,
                category: editTaskModalValues.category,
                coworkerId: editTaskModalValues.assignee
            }

        }
        else if (type == 'Complete') {
            payload = {
                storeNumber: window.sessionStorage.getItem('storeNumber'),
                storeTaskId: currentappointmentDetails?.appointmentInfo?.storeTaskId,
                actionName: 'complete',
                coworkerId: taskDetailsModalValues?.taskDetailValues?.coworkerId

            }

        }

        const createTaskResponse = await createStoreTask(payload)
        setloader(false)
        if (createTaskResponse?.data && createTaskResponse?.status == 200) {
            pageLoad()
        }
        else if (createTaskResponse?.status == 400) {
            setmanageAgrErrMessage(createTaskResponse?.data?.errors[0]?.error);
            seterrorMessagePopupOpen(true)
        }
        else {
            setModals({ ...modals, apiErrorInfoMsg: false })
        }
    }
    const updateTaskNotes = async () => {
        setupdatingTaskNotesLoader(true)
        try {
            const payload: any = {
                storeNumber: sessionStorage.getItem('storeNumber'),
                note: taskEventNotes,
                storeTaskId: currentappointmentDetails?.appointmentInfo?.storeTaskId
            }
            const response = await createStoreTask(payload);
            setupdatingTaskNotesLoader(false);
            settaskEventNotes('')
            settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailsPopup: false })
        } catch (e) {
            setupdatingTaskNotesLoader(false)
            setModals({ ...modals, apiErrorInfoMsg: true })
        }

    }

    const taskDetailsModalFn = () => {
        return (
            <>
                <Grid>
                    <Grid style={{ padding: "10px" }}>
                        <Grid container md={12} sm={12} spacing={3} style={{ padding: '10px' }}>
                            <Grid item md={4} sm={6}>
                                <Typography>
                                    Task Title
                                </Typography>
                                <Typography style={{ color: 'gray' }}>
                                    {taskDetailsModalValues?.taskDetailValues?.title || '-'}
                                </Typography>
                            </Grid>
                            <Grid item md={4} sm={6}>
                                <Typography>
                                    Category
                                </Typography>
                                <Typography style={{ color: 'gray' }}>
                                    {taskDetailsModalValues?.taskDetailValues?.categoryDesc || '-'}
                                </Typography>
                            </Grid>
                            <Grid item md={4} sm={6}>
                                <Typography>
                                    Assignee
                                </Typography>
                                <Typography style={{ color: 'gray' }}>
                                    {taskDetailsModalValues?.taskDetailValues?.coworkerId || '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container md={12} sm={12} spacing={3} style={{ padding: '10px' }}>
                            <Grid item md={4} sm={6}>
                                <Typography>
                                    Priority
                                </Typography>
                                <Typography style={{ color: 'gray' }}>
                                    {taskDetailsModalValues?.taskDetailValues?.priorityDesc || '-'}
                                </Typography>
                            </Grid>
                            <Grid item md={4} sm={6}>
                                <Typography>
                                    Due Date
                                </Typography>
                                <Typography style={{ color: 'gray' }}>
                                    {taskDetailsModalValues.taskDetailValues.dueDate ? moment(taskDetailsModalValues.taskDetailValues.dueDate).format("MM/DD/YYYY") : '-'}
                                </Typography>
                            </Grid>
                            <Grid item md={4} sm={6}>
                                <Typography>
                                    Due Time
                                </Typography>
                                <Typography style={{ color: 'gray' }}>
                                    {taskDetailsModalValues.taskDetailValues.dueDate ? moment(taskDetailsModalValues.taskDetailValues.dueDate).format("h:mm A") : '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container md={12} sm={12} spacing={3} style={{ padding: '10px' }}>
                            <Grid item md={4} sm={6}>
                                <Typography>
                                    Description
                                </Typography>
                                <Typography style={{ color: 'gray' }}>
                                    {taskDetailsModalValues?.taskDetailValues?.description || '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid>
                            {currentappointmentDetails?.appointmentInfo?.appointmentStatus == 'CAN' || currentappointmentDetails?.appointmentInfo?.appointmentStatus == 'CMP' ? <Grid style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', marginLeft: '30px', marginTop: '10px' }}>
                                <Typography variant="h5" style={{ alignSelf: 'center', color: 'black' }}>Status :</Typography>
                                {currentappointmentDetails?.appointmentInfo?.appointmentStatus == 'CAN' ? <span style={{ marginLeft: '10px', marginTop: '2px', color: 'black', border: '1px solid #58af52', padding: '5px', borderRadius: '5px', fontWeight: '800' }}>Cancelled</span> : <span style={{ marginLeft: '10px', marginTop: '2px', color: 'green', border: '1px solid #58af52', padding: '5px', borderRadius: '5px', fontWeight: '800' }}>Completed</span>}

                            </Grid> : null}

                            {currentappointmentDetails && (currentappointmentDetails?.appointmentInfo?.appointmentStatus != 'CMP') ?

                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    {currentappointmentDetails?.appointmentInfo?.appointmentStatus == 'CAN' ? <RACButton
                                        variant="outlined"
                                        className={classes.unassignBtnClass}
                                        onClick={() => {
                                            updateTaskEvent('Reopen')
                                        }}
                                    >Reopen Task
                                    </RACButton> : <RACButton
                                        variant="outlined"
                                        className={classes.unassignBtnClass}
                                        onClick={() => {
                                            updateTaskEvent('Cancel')
                                        }}
                                    >Cancel Task
                                    </RACButton>}
                                    {currentappointmentDetails?.appointmentInfo?.appointmentStatus == 'CAN' ? null : <><RACButton
                                        color="primary"
                                        variant="contained"
                                        className={classes.ms2}
                                        onClick={() => {
                                            settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailsPopup: false })
                                            seteditTaskModalValues({ ...editTaskModalValues, editTaskModal: true })
                                        }}
                                    >Edit Task
                                    </RACButton>
                                        <RACButton
                                            color="primary"
                                            variant="contained"
                                            className={classes.ms2}

                                            onClick={() => {
                                                settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailsPopup: false })
                                                setCompleteTaskModal(true)
                                            }}
                                        >Mark as complete</RACButton></>}

                                </Grid> : null
                            }


                        </Grid >
                    </Grid >
                </Grid >
                <Grid
                    item
                    className={`${classes.floatLeft} ${classes.w100}`}
                >
                    <Box className={classes.floatLeft}>
                        <List
                            className={`${classes.racpadTab} ${classes.py0}`}
                            id="CustTab"
                            role="tabList"
                        >
                            <ListItem
                                className={
                                    taskEventNotesHistory.notes
                                        ? `${classes.navLinkActive} ${classes.navLinkItem}`
                                        : `${classes.navLinkItem}`
                                }
                                id="employment-tab"
                                data-testid="employmentInfoTab"
                                data-bs-toggle="tab"
                                data-bs-target="#employmentinfo"
                                role="tab"
                                aria-controls="employment-tab"
                                aria-selected="false"
                                onClick={() => settaskEventNotesHistory({ ...taskEventNotesHistory, notes: true, history: false })}                            >
                                Notes
                            </ListItem>
                            <ListItem
                                className={
                                    taskEventNotesHistory.history
                                        ? `${classes.navLinkActive} ${classes.navLinkItem}`
                                        : `${classes.navLinkItem}`
                                }
                                id="Customerinfo-tab"
                                data-testid="customerInfoTab"
                                data-bs-toggle="tab"
                                data-bs-target="#customerinfo"
                                role="tab"
                                aria-controls="Customerinfo-tab"
                                aria-selected="true"
                                onClick={() => settaskEventNotesHistory({ ...taskEventNotesHistory, notes: false, history: true })}
                            >
                                History
                            </ListItem>

                        </List>
                    </Box>

                </Grid>
                {updatingTaskNotesLoader ? <div className={classes.Norecords}>
                    <CircularProgress />
                </div> : null}
                {taskEventNotesHistory.notes && !updatingTaskNotesLoader ? <Grid about="notesContent" style={{ width: '100%', minHeight: '150px', marginTop: '40px' }}>
                    {taskDetailsModalValues.taskDetailValues && taskDetailsModalValues.taskDetailValues.notes && taskDetailsModalValues.taskDetailValues.notes.map((el: any) => {
                        return (<Grid about="notesHeader" key={el.storeTaskNoteId} style={{ padding: '1rem', backgroundColor: 'aliceblue', marginBottom: '10px' }}>
                            <Grid about="messageDetails" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ display: 'inline-block' }}>{el.lastModifiedBy}</Typography>
                                <span>{moment(el.lastModifiedDate).format('MM/DD/YYYY')} {moment(el.lastModifiedDate, 'HH:mm:ss').format(
                                    'hh:mm A'
                                )}</span>
                            </Grid>
                            <Grid about="message" style={{ marginTop: '5px' }}>
                                <span style={{ fontWeight: '500' }}>{el.noteContent}</span>
                            </Grid>
                        </Grid>)
                    })}
                    <Grid about="notesFooter" style={{ width: '100%', padding: '1rem' }}>
                        <Typography>Write a new Note</Typography>
                        <Grid about="textFeilds" className={classes.storeEventNots}>
                            <RACTextbox
                                value={taskEventNotes}
                                placeHolder="Enter the text here"
                                OnChange={(e: any) => {
                                    settaskEventNotes(e.target.value);
                                }}
                                className={`${classes.searchSpacing} ${classes.w100} ${classes.mr1}`}
                            />
                            <RACButton
                                type="button"
                                className={`${classes.ms2} ${classes.todayButton}`}
                                color="primary"
                                disabled={taskEventNotes && taskEventNotes.trim().length !== 0 ? false : true}
                                style={{ height: '36px', marginLeft: '3px', marginTop: '0px' }}
                                variant="contained"
                                onClick={() => updateTaskNotes()}
                            >
                                Add
                            </RACButton>
                        </Grid>

                    </Grid>
                </Grid> : null}
                {taskEventNotesHistory.history ?
                    <RACTable
                        renderTableHead={renderTaskEventTableHistoryHeader}
                        renderTableContent={renderTaskEventTableHistoryContent}
                    />
                    : null}
            </>
        )
    }
    const editTaskModalFn = () => {
        return (
            <>
                <Grid style={{ padding: "10px" }}>
                    <Grid container md={12} sm={12} spacing={3} style={{ padding: '10px' }}>
                        <Grid item md={4} sm={6}>
                            <RACTextbox
                                value={editTaskModalValues.taskTitle}
                                OnChange={(e) => {
                                    let value = e.target.value;
                                    if (value.length > 20) {
                                        value = value.slice(0, 20);
                                        setErrorMessages({ ...errorMessages, editTaskTitle: 'Maximum of 20 characters' });
                                    } else {
                                        setErrorMessages({ ...errorMessages, editTaskTitle: '' });
                                    }
                                    seteditTaskModalValues({ ...editTaskModalValues, taskTitle: value.trimStart() })
                                }}
                                placeHolder={'Enter Task Title'}
                                inputlabel='Task Title'
                                required={true}
                                errorMessage={errorMessages.editTaskTitle}
                            />
                        </Grid>
                        <Grid item md={4} sm={6}>
                            <RACSelect
                                inputLabel='Category'
                                options={taskCategoryOptions}
                                onChange={(e: any) => {
                                    seteditTaskModalValues({ ...editTaskModalValues, category: e.target.value })
                                }}
                                defaultValue={editTaskModalValues.category}
                                required={true}

                            />
                        </Grid>
                        <Grid item md={4} sm={6}>
                            <RACSelect
                                inputLabel='Assignee'
                                options={salesDropdownOptions}
                                onChange={(e: any) => {
                                    seteditTaskModalValues({ ...editTaskModalValues, assignee: e.target.value })
                                }}
                                defaultValue={editTaskModalValues.assignee}
                                required={true}

                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} md={12} sm={12} style={{ padding: '10px' }}>
                        <Grid item md={4} sm={6}>
                            <RACSelect
                                inputLabel='Priority'
                                options={taskPriorityOption}
                                onChange={(e: any) => {
                                    seteditTaskModalValues({ ...editTaskModalValues, priority: e.target.value })
                                }}
                                defaultValue={editTaskModalValues.priority}
                                required={true}

                            />
                        </Grid>
                        <Grid item md={4} sm={6}>
                            <RACDatePicker
                                label="Due Date"
                                required={true}
                                name="duedate"
                                classes={{ label: classes.timePickerRedClr }}
                                value={editTaskModalValues.dueDate}
                                inputProps={{
                                    min: moment().format('YYYY-MM-DD'),
                                    max: moment().add(118, "years").format("YYYY-MM-DD")
                                }}
                                onChange={(e: any) => {
                                    seteditTaskModalValues({
                                        ...editTaskModalValues,
                                        dueDate: validateDate(e),
                                    });
                                }}
                                onKeyDown={(e: any) => {
                                    e.preventDefault();
                                }}
                            />
                        </Grid>
                        <Grid item md={4} sm={6}>
                            <span style={{
                                fontFamily: 'OpenSans-semibold',
                            }}>Due Time <span style={{ color: 'red' }}>*</span></span>

                            <RACTimePicker
                                value={editTaskModalValues.dueTime}
                                label=''
                                onChange={(e: any) => seteditTaskModalValues({
                                    ...editTaskModalValues,
                                    dueTime: e,
                                })}
                            />
                        </Grid>
                    </Grid>
                    <Grid style={{ padding: '10px' }}>
                        <Typography>Descritption <span style={{ color: 'red' }}>*</span></Typography>
                        <textarea
                            style={{ resize: 'none', padding: '10px', width: '-webkit-fill-available' }}
                            rows={7}
                            value={editTaskModalValues.description}
                            onChange={(e: any) => {
                                let value = e.target.value;
                                if (value.length > 250) {
                                    value = value.slice(0, 250);
                                }
                                seteditTaskModalValues({ ...editTaskModalValues, description: value.trimStart() })
                            }}
                            placeholder='Enter Description'
                        />
                    </Grid>
                    <Grid style={{ display: 'flex', float: 'right', padding: '10px' }}>
                        <RACButton
                            variant='outlined'
                            color='default'
                            style={{ 'marginRight': '10px' }}
                            onClick={() => {
                                seteditTaskModalValues({ ...editTaskModalValues, editTaskModal: false })
                                settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailsPopup: true })

                            }}
                        >
                            Cancel
                        </RACButton>
                        <RACButton
                            variant="contained"
                            color="primary"
                            onClick={() => { updateTaskEvent('Edit') }}
                            disabled={editTaskModalValues.taskTitle && editTaskModalValues.category && editTaskModalValues.priority && editTaskModalValues.dueDate && editTaskModalValues.assignee && editTaskModalValues.dueTime ? false : true}
                        >
                            Save
                        </RACButton>
                    </Grid>
                </Grid>
            </>
        )
    }
    const completeTaskModalFn = () => {
        return (
            <>
                <Typography style={{
                    fontFamily: 'OpenSans-Bold',
                    fontSize: '17px',
                    textAlign: 'center'

                }}>Are you sure want to complete this event</Typography>
                <Grid style={{ textAlign: 'center', marginTop: '20px' }}>
                    <RACButton
                        color='inherit'
                        variant='outlined'
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                            setCompleteTaskModal(false);
                            settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailsPopup: true })

                        }}
                    >No</RACButton>
                    <RACButton
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            updateTaskEvent('Complete')
                        }}
                    >Yes</RACButton>
                </Grid>

            </>
        )

    }
    const errorMessagePopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Alerticon></Alerticon>
                    <Typography className={classes.formLabel}>
                        {manageAgrErrMessage}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            seterrorMessagePopupOpen(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };

    return (
        <div>
            <CardContent className={classes.cardBody}>
                <RACModalCard
                    isOpen={createTaskModal}
                    title="Task Details"
                    maxWidth="md"
                    onClose={() => {
                        setcreateTaskModal(false); setCreateTaskModalValues({
                            ...createTaskModalValues, taskDescription: '',
                            taskTitle: '',
                            priority: '',
                            dueDate: '',
                            category: '',
                            dueTime: ''
                        })
                    }}
                    closeIcon={true}
                    borderRadius="20px"
                    children={createTaskModalFn()}
                />

                <RACModalCard
                    isOpen={taskDetailsModalValues.taskDetailsPopup}
                    title="Task Details"
                    maxWidth="md"
                    onClose={() => { settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailsPopup: false }) }}
                    closeIcon={true}
                    borderRadius="20px"
                    children={taskDetailsModalFn()}
                />
                <RACModalCard
                    isOpen={editTaskModalValues.editTaskModal}
                    title="Task Details"
                    maxWidth="md"
                    onClose={() => {
                        seteditTaskModalValues({ ...editTaskModalValues, editTaskModal: false }); settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailsPopup: true })
                    }}
                    closeIcon={true}
                    borderRadius="20px"
                    children={editTaskModalFn()}
                />
                <RACModalCard
                    isOpen={completeTask}
                    maxWidth="xs"
                    onClose={() => {
                        setCompleteTaskModal(false); settaskDetailsModalValues({ ...taskDetailsModalValues, taskDetailsPopup: true })
                    }}
                    closeIcon={true}
                    borderRadius="20px"
                    children={completeTaskModalFn()}
                />
                <RACModalCard
                    isOpen={taskCreatedPopup}
                    maxWidth="xs"
                    onClose={() => {
                        settaskCreatedPopup(false);
                        pageLoad()
                    }}
                    closeIcon={true}
                    borderRadius="20px"
                    children={taskCreatedPopupFn()}
                />
                <RACModalCard
                    isOpen={errorMessagePopupOpen}
                    closeIcon={false}
                    maxWidth="xs"
                    borderRadius="20px"
                >
                    {errorMessagePopup()}
                </RACModalCard>


                <RACModalCard
                    borderRadius="20px"
                    isOpen={modals.apiErrorInfoMsg}
                    maxWidth="xs"
                    closeIcon={true}
                    onClose={() => setModals({ ...modals, apiErrorInfoMsg: false })}
                    // eslint-disable-next-line react/no-children-prop
                    children={UpdateInfoMsgPopup()}
                    title=""
                />
                <RACModalCard
                    isOpen={modals.timeSlotExceedModal}
                    onClose={() => setModals({ ...modals, timeSlotExceedModal: false })}
                    maxWidth="md"
                    closeIcon={true}
                    borderRadius="20px"
                    title={timeSlotExceedRecordsDetails.timeSlot}
                    // eslint-disable-next-line react/no-children-prop
                    children={TimeSlotExceedRecord()}
                />
                <Modal
                    isOpen={loader}
                    className={classes.loaderStyle}
                    id="openpopup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-hidden="true"
                >
                    <Grid>
                        <Grid>
                            <Grid className={classes.RACLoaderPage}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>

                <Grid className={`${classes.filterContainer} ${classes1.DAPresponsivefilter}`}>
                    <Grid className={`${classes.leftContainer} ${classes1.leftContainer}`}>
                        {/* New changes */}


                        <Grid style={{ display: "flex", width: "100%" }} >
                            <Grid style={{ width: '20%', }} className={classes.filterContainerItems}>
                                <RACDatePicker
                                    classes={{ input: `${classes.p3} ${classes.ml24} ${classes.h65} ${classes1.spacingDAC}` }}
                                    label={""}
                                    onChange={(val: any) => onChangeDate(val)}
                                    value={filterStateData && filterStateData?.date ? filterStateData?.date : ''} />
                                <RACButton
                                    type="button"
                                    className={`${classes.ms2} ${classes.todayButton}`}
                                    color="primary"
                                    disabled={handleDisable()}
                                    style={{ height: '30px', marginTop: '15px' }}
                                    variant="contained"
                                    onClick={() => {
                                        setFilterStateData({ ...filterStateData, date: moment().format('YYYY-MM-DD') });
                                        setIsStoreClosed(false)
                                        onChangeDate(moment().format('YYYY-MM-DD'))
                                    }
                                    }
                                >
                                    Today
                                </RACButton>
                            </Grid>
                            <Grid style={{ width: '20%', display: 'flex' }} >
                                <Typography style={{ marginTop: '20px' }}>
                                    Status
                                </Typography>
                                <RACSelect
                                    name="typeofEvent"
                                    classes={{ formControl: classes.p3 }}
                                    options={eventStatusOptions}
                                    onChange={(e: any) => handleFilteringDropdown(e, 'Status')}
                                    defaultValue={filteringValues.status}

                                />
                            </Grid>
                            <Grid style={{ width: '20%', display: 'flex' }} >
                                <Typography style={{ marginTop: '20px', width: '157px' }}>
                                    Type of Task
                                </Typography>
                                <RACSelect
                                    name="typeofEvent"
                                    classes={{ formControl: classes.p3 }}
                                    options={taskTypes}
                                    onChange={(e: any) => handleFilteringDropdown(e, 'Task')}
                                    defaultValue={filteringValues.task}
                                />
                            </Grid>
                        </Grid>

                        {/* New changes End */}
                    </Grid>
                </Grid>
                <Grid style={{ width: '30%', float: 'right', display: 'flex' }} className={classes1.legendstyles}>
                    <Grid
                        style={{
                            borderLeft: '3px solid #2E31BE',
                            backgroundColor: 'aliceblue',
                            width: '23%',
                            marginRight: '2%'
                        }}
                    >
                        <Typography style={{ color: '#2E31BE', marginLeft: '10px', paddingLeft: '10px' }}>
                            Upcoming
                        </Typography>
                    </Grid>
                    <Grid
                        style={{
                            borderLeft: '3px solid red',
                            backgroundColor: '#fcf2f2',
                            width: '23%',
                            marginRight: '2%'
                        }}
                    >
                        <Typography style={{ color: 'red', marginLeft: '10px', paddingLeft: '10px' }}>
                            Due
                        </Typography>
                    </Grid>
                    <Grid
                        style={{
                            borderLeft: '3px solid green',
                            backgroundColor: '#ebfdeb',
                            width: '23%',
                            marginRight: '2%'
                        }}
                    >
                        <Typography style={{ color: 'green', marginLeft: '10px', paddingLeft: '10px' }}>
                            Completed
                        </Typography>
                    </Grid>
                    <Grid
                        style={{
                            borderLeft: '3px solid #5a5a5a',
                            backgroundColor: '#f6f6f6',
                            width: '23%',
                            marginRight: '2%'
                        }}
                    >
                        <Typography style={{ color: '#5a5a5a', marginLeft: '10px', paddingLeft: '10px' }}>
                            Cancelled
                        </Typography>
                    </Grid>

                </Grid>
            </CardContent>
            {appointmentDetails && appointmentDetails.length > 0 && !isStoreClosed
                ? // <Grid style={{width:"100%"}}>
                bindAppointmentDetails()
                : // </Grid>
                null}
            {isStoreClosed ? storeClosedGrid() : null}
        </div>
    );
}

export default Task;
