/* eslint-disable */
import React, { HTMLProps, useEffect, useState } from 'react';
import { Card, ToastType, useToastActionsContext } from '@rentacenter/legacyracstrap';
import { isThisWeek, isFuture } from 'date-fns';
import styles from './SalesGoalCard.module.scss';

import { SalesGoal } from '../../../../../domain/Calendar/Goal';
import {
  ActionType,
  GoalCardAction
} from '../../common/GoalCardAction/GoalCardAction';
import { SalesFormData, SalesValueBox } from './SalesValueBox/SalesValueBox';
import { setSalesGoal } from '../../../../../api/goals';
import { useUserPermissions } from '../../../../../context/permission/PermissionsProvider';

export const salesGoalCardTestId = 'salesGoalCardTestId';

export interface SalesGoalCardProps extends HTMLProps<HTMLDivElement> {
  goal: SalesGoal;
  startDate: Date;
}

export const SalesGoalCard = (props: SalesGoalCardProps) => {
  const { goal, startDate, ...rest } = props;
  const [editMode, setEditMode] = useState(false);
  const [pending, setPending] = useState(false);
  const [renderedGoal, setRenderedGoal] = useState(goal);
  const { canCreateGoal, canEditGoal } = useUserPermissions();
  const { showToast } = useToastActionsContext();
  console.log("canCreateGoal",canCreateGoal)
  console.log("renderedGoal.goal",renderedGoal.goal)
  console.log("canEditGoal",canEditGoal)
  console.log("editModeeditMode", editMode)


  useEffect(
    function maintainGoal() {
      setRenderedGoal(goal);
    },
    [goal]
  );

  const onSave = (formData: SalesFormData) => {
    setPending(true);

    const { storeNumber, coworkerId, storeMetricGoalId } = renderedGoal;

    setSalesGoal(
      storeNumber,
      coworkerId,
      formData.goal,
      startDate,
      storeMetricGoalId
    )
      .then(response => {
        const newGoal = Number(response.metricGoal);
        const variance = goal.actual - newGoal;
        setRenderedGoal({
          ...goal,
          ...response,
          goal: newGoal,
          variance
        });
        showToast(ToastType.Success, `Your Goal has been successfully saved!`);
      })
      .catch(() => {
        showToast(ToastType.Error, 'An error has occurred. Please try again.');
      })
      .finally(() => {
        setPending(false);
        setEditMode(false);
      });
  };

  const renderActionBar = () => {
    return (
      <>
        {canCreateGoal && !renderedGoal.goal && (
          <GoalCardAction
            type={ActionType.Add}
            onClick={() => setEditMode(true)}
          />
        )}
        {canEditGoal && !!renderedGoal.goal && (
          <GoalCardAction
            type={ActionType.Edit}
            onClick={() => setEditMode(true)}
          />
        )}
      </>
    );
  };

  return (
    <Card
      className={styles.salesGoalCard}
      data-testid={salesGoalCardTestId}
      {...rest}
    >
      <div className={`${styles.titleContainer} ${styles.cardheader}`}>
        <div className={styles.title}>
          {goal.coworkerFirstName} {goal.coworkerLastName}
        </div>

        <div className={styles.actionWrapper}>
          {!editMode &&
            (isThisWeek(startDate) || isFuture(startDate)) &&
            renderActionBar()}
        </div>
      </div>

      <div className={`${styles.content} ${styles.test}`}>
        <SalesValueBox
          title="Goal"
          value={renderedGoal.goal}
          editMode={editMode}
          pending={pending}
          onCancel={() => setEditMode(false)}
          onSave={onSave}
        />
        <SalesValueBox title="Actual" value={renderedGoal.actual} />
        <SalesValueBox
          title="Variance"
          value={renderedGoal.variance}
          goal={renderedGoal}
        />
      </div>
    </Card>
  );
};
