/* esint-disable */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/no-children-prop */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    Typography,
    RACButton,
    RACRadio,
    RACModalCard,
    RACTextbox,
    RACCheckBox,
    CircularProgress,
    Card,
} from '@rentacenter/racstrap';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import LogoAcima from '../../assets/images/Acima.png';
import { ReactComponent as AcimaLogo } from '../../assets/images/Acima Logo.svg'
import { ReactComponent as PDFImage } from '../../assets/images/pdf-icon.svg';
import { ReactComponent as Successlogo } from '../../assets/images/success-icon.svg'


// import {
//   LoadDeliveryreceipt,
//   getnextpaymentamount,
//   GetCustomerId,
//   GenerateReceipt,
//   getPrintDocument,
//   getAgreementInfo,
//   getAgreementDelivery,
//   GenerateSwitchoutReceipt,
//   LoadswitchoutDeliveryreceipt
// } from '../../api/user';
import SignatureCanvas from 'react-signature-canvas';
import html2pdf from 'html2pdf.js';
// import { agreementGlobalStyles } from '../../../stylesJS/agreementGlobalStyles';
import { agreementGlobalStyles } from '../../storeComponent/ComponentStyles/agreementGlobalStyles';
import { GenerateReceipt, getPrintDocument } from '../../api/user';
import moment from 'moment';

interface DeliveryDigitalSignInferface {
    agreementId?: string;
    sundaySky?: any;
    deliveryDigitalSignCompletedFn: any;
    deliveryDigitalSignCanceledFn: any;
    customerDetails?: any;
    itemDetails?: any;
    isManual?: any;
    poNumber?: any;
    openPop?: any
}
export default function DeliveryDigitalSign(
    props: any
) {
    const signatureRef: any = React.useRef();
    const storeDetails = sessionStorage.getItem('storeDetails') || '';
    const encodeStoreDetails = Buffer.from(storeDetails, 'base64');

    let decodestoreDetails: any;

    try {
        decodestoreDetails = JSON.parse(
            encodeStoreDetails.toString('ascii')
        );
    } catch (error: any) {
        console.log(error.message)
    }


    //Added the manipulare sign;
    function manipulateTime(utcTimestamp: any) {
        // const date = new Date(utcTimestamp);
        // utcTimestamp = date.toISOString();
        console.log('The UTC time passed to the manipulateTime in the storemgmt', utcTimestamp);
        
        let timeModified = moment(utcTimestamp).format("HH");
        let time = parseInt(timeModified);
        let timeValidater
        if (time > 12 && time < 24) {
            if (time >= 22) {
                switch (time) {
                    case 22:
                        timeValidater = `${time - 12}:00 PM - ${"0"}:00 AM`;
                        break;
                    case 23:
                        timeValidater = `${time - 12}:00 PM - ${"1"}:00 AM`;
                        break;
                    case 24:
                        timeValidater = `00:00 AM - ${'2'}:00 AM`
                }
            }
            else {
                // let calc = time - 12;
                timeValidater = `${time - 12}:00 - ${time - 10}:00 PM`;
            }
        }
        else {
            if (time >= 10) {
                switch (time) {
                    case 10:
                        timeValidater = `${time}:00 AM - ${"12"}:00 PM`
                        break;
                    case 11:
                        timeValidater = `${time}:00 AM - ${"1"}:00 PM`
                        break;
                    case 12:
                        timeValidater = `${time}:00 AM - ${"1"}:00 PM`
                        break;
                }
            } else {
                timeValidater = `${time}:00 - ${time + 2}:00 AM`
            }
        }
        console.log(timeValidater, "manipulate time in card", utcTimestamp);

        return timeValidater;
    }




    useEffect(() => {

        console.log("I am called useeffe")
        if (props.isManual) {
            document.getElementById("CheckButtonForMannual")?.click();
            setIsDeliveryDocOpen(true);
        }
        else {
            setIsDeliveryReceiptOpen(true)
        }
    }, [props.isManual]);


    const {
        agreementId,
        deliveryDigitalSignCompletedFn,
        deliveryDigitalSignCanceledFn,
        sundaySky
    } = props;
    const classes: any = agreementGlobalStyles();
    const modalCardRadius = '20px !important';
    const PaddingConst = "0px 8px 0px 8px";
    const Constinter = "1px solid  #545454";
    const borderRadiuss = "3px";
    const borderbotttom = "1px solid #545454";
    const borderrr = "1px solid #DFDFDF";
    const borderrbottommm = "1px dashed #545454";
    const borderalone = "1px solid   #545454";
    const [hiddenLoader, sethiddenLoader] = useState(false);
    const [damagedescription, setDamagedescription] = useState("");
    const [uploadeDocLoader, setUploadeDocLoader] = useState(false);
    const [sendDocLoader, setSendDocLoader] = useState(false);
    const [isDeliveryReceiptOpen, setIsDeliveryReceiptOpen] = useState(false);
    const [isConfirmedOpen, setIsConfirmedOpen] = useState(false)
    const [isDeliveryPickupReceipt, setisDeliveryPickupReceipt] = useState(false)
    const [isPickupEnabled, setisPickupEnabled] = useState(false);
    const [isSwitchoutEnabled, setisSwitchoutEnabled] = useState(false);
    const [isSignaturePanelOpen, setIsSignaturePanelOpen] = useState(false);
    const [isCowokerSignPanelOpen, setIsCowokerSignPanelOpen] = useState(false);
    const [isSendDeliveryReceiptOpen, setIsSendDeliveryReceiptOpen] =
        useState(false);
    const [getAgreementDeliveryRes, setgetAgreementDeliveryRes] = useState<any>()
    const [getAgreementinfoRes, setgetAgreementinfoRes] = useState<any>()
    const [deliveryChecklistEnabled, setdeliveryChecklistEnabled] = useState(false)
    const [isDeliveryDocOpen, setIsDeliveryDocOpen] = useState(false);
    const [somethingWentWrongOpen, setsomethingWentWrongOpen] = useState(false);
    const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState(false);
    const [serviceErrorMsg, setServiceErrorMsg] = useState('');
    const [customerSignContent, setCustomerSignContent]: any = useState('');
    const [coworkerSignContent, setCoworkerSignContent]: any = useState('');
    const [currentSign, setCurrentSign] = useState('');
    const [deliveryAddendumB64, setDeliveryAddendumB64] = useState('')
    const [isCOAgr, setisCOAgr] = useState(false);
    const [nameOfThePersonSigned, setNameOfThePersonsigned] = useState("");
    const [damageCheckBox, setdamageCheckbox] = useState({
        Walls: false,
        Floors: false,
        Doorway: false,
        Statirs: false,
        Other: false
    })
    const [acknowledgeInputs, setAcknowledgeInputs] = useState({
        acceptChecked: false,
        declineChecked: false,
    });
    const deliverReceiptObj = {
        storeInfo: {
            address: decodestoreDetails.addressLine1,
            city: decodestoreDetails.city,
            state: decodestoreDetails.state,
            zip: decodestoreDetails.zip,
            phoneNumber: decodestoreDetails.workPhoneNumber,
        },
        customerInfo: {
            customerId: '',
            customerName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            phoneNumber: '',
        },
        agreementNumber: '',
        customerNumber: '',
        nextPaymentDue: '',
        inventoryInfo: [
            {
                itemDescription: '',
                serialNumber: '',
                itemNumber: '',
            },
        ],
        customerSigned: false,
        nameOftheCustomerSigned: '',
        coWorkerSigned: false,
    };

    const signaturePanelInputsObj = {
        customerOptionChecked: true,
        othersOptionChecked: false,
        nameOfThePerson: '',
        isNextButtonDisabled: true,
    };
    const sendDeliveryReceiptInputsObj = {
        emailChecked: true,
        textChecked: false,
        emailValue: props.customerDetails.emailId,
        invalidEmail: false,
        invalidPhone: false,
        phoneNumberValue: props.customerDetails.phoneNumber,
    };
    const [deliverReceiptInfo, setDeliverReceiptInfo] =
        useState(deliverReceiptObj);
    const [signaturePanelInputs, setSignaturePanelInputs] = useState(
        signaturePanelInputsObj
    );
    const [sendDeliveryReceiptInputs, setSendDeliveryReceiptInputs] = useState(
        sendDeliveryReceiptInputsObj
    );
    const [coWorkerSignSaveDisable, setCoWorkerSignSaveDisable] = useState(true);
    const [deliveryReceiptB64, setDeliveryReceiptB64] = useState('');
    const [deliveryCheckListB64, setDeliveryCheckListB64] = useState('');
    const [pickupReceiptB64, setpickupReceiptB64] = useState('');
    const [pickupCheckListB64, setpickupCheckListB64] = useState('');
    const [otherPersonCheck, setOtherPersonCheck] = useState(false);


    useEffect(() => {
        console.log(signaturePanelInputs, "Signin options");
        console.log(nameOfThePersonSigned, "Person chage");
    }, [signaturePanelInputs, nameOfThePersonSigned])


    // props.customerDetails = {
    //     "customerName": "John Doe",
    //     "addressLine1": "8025 Ohio",
    //     "addressLine2": "123z",
    //     "zip": "12345",
    //     "city": "Cityville",
    //     "state": "Texas",
    //     "phoneNumber":"958285855654",
    //     "itemDetails": [
    //       {
    //         "poDetailReceivedId": "252",
    //         "purchaseOrderDetailId": "845",
    //         "model": "514038/35",
    //         "description": "ASHLEY",
    //         "brand": "ASHLEY",
    //         "itemCost": "100.00",
    //         "receivingNow": "All",
    //         "partialReason": null,
    //         "comments": null,
    //         "bol": "BOL000",
    //         "itemStatus": "Received"
    //       }
    //     ]
    //   }


    useEffect(() => {
        // getFieldValues();
        getAgreementInfoFn()
    }, []);


    const getAgreementInfoFn = async () => {
        // try {
        //   sethiddenLoader(true);
        //   let getAgreementInfoRes = await getAgreementInfo(agreementId);
        //   setgetAgreementinfoRes(getAgreementInfoRes?.data)
        //   if (getAgreementInfoRes.status == 200 && getAgreementInfoRes.data) {
        //     let getAgreementDeliveryRes = await getAgreementDelivery(agreementId, getAgreementInfoRes?.data?.agreementInformation?.appointmentId)
        //     setgetAgreementDeliveryRes(getAgreementDeliveryRes?.data)
        //     if (getAgreementDeliveryRes.status == 200 && getAgreementDeliveryRes.data) {
        //       if ((getAgreementInfoRes?.data?.agreementWorkflow?.isSwitchoutEnabled == '0')) {
        //         getFieldValues(getAgreementInfoRes?.data, getAgreementDeliveryRes.data)
        //       }
        //       else if ((getAgreementInfoRes?.data?.agreementWorkflow?.isSwitchoutEnabled == '1')) {
        //         getFieldValues(getAgreementInfoRes?.data, getAgreementDeliveryRes.data)
        //       }
        //     }
        //   }
        // }
        // catch {
        //   setsomethingWentWrongOpen(true);

        // }
    }

    const getFieldValues = async (getAgrInfoRes: any, getAgreementDeliveryRes: any) => {
        // debugger;
        // try {
        //   const getnextpaymentPayload = {
        //     agreementIdCustomerId: [{ agreementId: Number(agreementId) }],
        //   };
        //   let deliveryReceiptResponse: any = {};
        //   let getnextpaymentResponse: any = {};
        //   sethiddenLoader(true);

        //   if (getAgrInfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0') {
        //     await Promise.all([
        //       LoadDeliveryreceipt(agreementId),
        //       getnextpaymentamount(getnextpaymentPayload),
        //     ]).then((resp) => {
        //       [deliveryReceiptResponse, getnextpaymentResponse] = resp;
        //     });
        //   }
        //   else {
        //     setisSwitchoutEnabled(true)
        //     await Promise.all([
        //       LoadswitchoutDeliveryreceipt(agreementId),
        //       getnextpaymentamount(getnextpaymentPayload),
        //     ]).then((resp) => {
        //       [deliveryReceiptResponse, getnextpaymentResponse] = resp;
        //     });
        //   }

        //   sethiddenLoader(false);
        //   if (
        //     deliveryReceiptResponse.responseData &&
        //     getnextpaymentResponse.status == 200
        //   ) {
        //     debugger;
        //     const response = deliveryReceiptResponse.responseData;
        //     const amountDueResponse =
        //       getnextpaymentResponse.data?.amountDueResponse[0].nextDueDate;
        //     const customerInfoObj = {
        //       customerName: response[0].customerName,
        //       address: response[0].addressLine1,
        //       city: response[0].storeCity,
        //       state: response[0].storeState,
        //       zip: response[0].customerZip,
        //       phoneNumber: response[0].customerPhoneNumber,
        //       customerId: response[0].customerId,
        //     };

        //     let inventoryDetailsArray: any = ''
        //     if (getAgrInfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0') {
        //       inventoryDetailsArray = response?.map((obj: any) => ({
        //         itemDescription: obj.inventoryDesc,
        //         serialNumber: obj.serialNumber,
        //         itemNumber: obj.inventoryNumber,
        //       }));
        //     }
        //     else {
        //       inventoryDetailsArray = response?.map((obj: any) => ({
        //         itemDescription: obj.switchInItem.inventoryDesc,
        //         serialNumber: obj.switchInItem.serialNumber,
        //         itemNumber: obj.switchInItem.inventoryNumbCer,
        //       }));
        //     }


        //     setDeliverReceiptInfo({
        //       ...deliverReceiptInfo,
        //       customerInfo: customerInfoObj,
        //       inventoryInfo: inventoryDetailsArray,
        //       agreementNumber: response[0].agreementNumber,
        //       customerNumber: response[0].customerId,
        //       nextPaymentDue: amountDueResponse,
        //     });
        //     // setSendDeliveryReceiptInputs({...sendDeliveryReceiptInputs,phoneNumberValue:response[0].customerPhoneNumber})
        //     setSignaturePanelInputs({
        //       ...signaturePanelInputs,
        //       nameOfThePerson: customerInfoObj.customerName,
        //     });
        //     debugger;
        //     console.log("checkResponse", getAgrInfoRes?.agreementWorkflow?.isSwitchoutEnabled)
        //     console.log("appointmentTypeRes", getAgreementDeliveryRes?.appointmentInfo?.appointmentType)
        //     if ((getAgrInfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0') && (getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'P' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CI' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SP' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SCI')) {
        //       setisDeliveryPickupReceipt(true);
        //       setisPickupEnabled(true)
        //     } else {
        //       setIsDeliveryReceiptOpen(true);
        //     }

        //     const getCustomerResponse = await GetCustomerId(response[0].customerId);
        //     if (getCustomerResponse?.status == 200) {
        //       const getCustomerData = getCustomerResponse?.data;
        //       console.log("phnNum", getCustomerData)
        //       setSendDeliveryReceiptInputs({
        //         ...sendDeliveryReceiptInputs,
        //         emailValue: getCustomerData?.emailAddress,
        //         phoneNumberValue: response[0].customerPhoneNumber
        //       });
        //       console.log("phncEmailCheck", getCustomerData?.emailAddress, response[0].customerPhoneNumber)

        //     }
        //   } else if (deliveryReceiptResponse.status == 400) {
        //     setServiceErrorMsg(deliveryReceiptResponse?.data?.errors[0]?.error);
        //     seterrorMessagePopupOpen(true);
        //   } else if (getnextpaymentResponse.status == 400) {
        //     setServiceErrorMsg(getnextpaymentResponse?.data?.errors[0]?.error);
        //     seterrorMessagePopupOpen(true);
        //   } else {
        //     setsomethingWentWrongOpen(true);
        //   }
        // } catch {
        //   setsomethingWentWrongOpen(true);
        // }
    };

    // const [isConfirmedOpen, setIsConfirmedOpen] = useState(false);


    const getCurrentDateWithFormat = (segregationKey: string) => {
        const currentDate = moment(props.customerDetails.date);
        // const year = currentDate.getFullYear();
        // const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        // const day = currentDate.getDate().toString().padStart(2, '0');
        // return `${month}${segregationKey}${day}${segregationKey}${year}`;
        return currentDate.format('MM/DD/YYYY');
    };
    const getCurrentTimeWithMeridian = () => {
        const currentTime = new Date();
        return currentTime.toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        });
    };
    const somethingWentWrongOkClick = () => {
        setsomethingWentWrongOpen(false);

        deliveryDigitalSignCanceledFn();
    };
    const addSignatureClick = (value: any) => {
        setCurrentSign(value);
        if (value == 'Customer') {
            setIsSignaturePanelOpen(true);
        } else {
            setIsCowokerSignPanelOpen(true);
        }
        {
            isPickupEnabled ? setisDeliveryPickupReceipt(false) : setIsDeliveryReceiptOpen(false);
        }
    };
    const presentToSignOnChange = (value: any) => {
        if (value == 'Customer') {
            setSignaturePanelInputs({
                ...signaturePanelInputs,
                customerOptionChecked: true,
                othersOptionChecked: false,
                nameOfThePerson: deliverReceiptInfo.customerInfo.customerName,
                isNextButtonDisabled: true,
            });
            signatureRef.current.clear()
        } else {
            setSignaturePanelInputs({
                ...signaturePanelInputs,
                customerOptionChecked: false,
                othersOptionChecked: true,
                nameOfThePerson: '',
                isNextButtonDisabled: true,
            });
            signatureRef.current.clear()

        }
    };
    const nameOfThePersonOnChange = (value: any) => {
        const alphabetRegex = /^[A-Za-z]+$/;
        if (alphabetRegex.test(value)) {
            setSignaturePanelInputs({
                ...signaturePanelInputs,
                nameOfThePerson: value,
            });
        }
    };
    const resetSignaturePanelInput = () => {
        setSignaturePanelInputs({
            customerOptionChecked: true,
            othersOptionChecked: false,
            nameOfThePerson: deliverReceiptInfo.customerInfo.customerName,
            isNextButtonDisabled: true,
        });
    };
    const signaturePanelCancelClick = () => {
        resetSignaturePanelInput();
        setIsSignaturePanelOpen(false);
        {
            isPickupEnabled ? setisDeliveryPickupReceipt(true) : setIsDeliveryReceiptOpen(true);
        }
    };
    const signaturePanelSaveClick = () => {
        const signatureData = signatureRef.current.toDataURL();
        if (currentSign == 'Customer') {
            setCustomerSignContent(signatureData);
            setDeliverReceiptInfo({
                ...deliverReceiptInfo,
                customerSigned: true,
                nameOftheCustomerSigned: signaturePanelInputs.nameOfThePerson,
            });
            setIsSignaturePanelOpen(false);
        } else {
            setCoworkerSignContent(signatureData);
            setDeliverReceiptInfo({ ...deliverReceiptInfo, coWorkerSigned: true });
            setIsCowokerSignPanelOpen(false);
        }
        resetSignaturePanelInput();
        {
            isPickupEnabled ? setisDeliveryPickupReceipt(true) : setIsDeliveryReceiptOpen(true);
        }
    };
    const deliveryReceiptCancelClick = () => {
        deliveryDigitalSignCanceledFn();
    };

    const deliveryDocumentContinueClick = () => {
        debugger;
        if (!props.isManual) {
            // deliveryDigitalSignCompletedFn();
            props.openPop(true);
            setCoworkerSignContent('')
            setCustomerSignContent('')
            setDeliverReceiptInfo({ ...deliverReceiptInfo, "coWorkerSigned": false, "customerSigned": false })
            setNameOfThePersonsigned("");
            setDamagedescription("");
            setOtherPersonCheck(false)
        } else {
            deliveryDigitalSignCompletedFn('Manual');
            deliveryDigitalSignCanceledFn();
            setCoworkerSignContent('')
            setCustomerSignContent('')
            setDeliverReceiptInfo({ ...deliverReceiptInfo, "coWorkerSigned": false, "customerSigned": false })
            setNameOfThePersonsigned("");
            setDamagedescription("");
            setOtherPersonCheck(false)
        }

        setIsDeliveryDocOpen(false);
    };


    const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays: any = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    // console.log(signInData, "This is the signin data");
    console.log(" i am called from the function");
    const viewPDFclick = (b64File: any) => {
        console.log('viewPDFclick triggered');
        const blobResult = b64toBlob(b64File, `application/pdf`);
        const fileURL = URL.createObjectURL(blobResult);
        window.open(fileURL);
    };
    const deliveryDocumentPopup = () => {
        return (
            <div>
                <Grid container spacing={2} className={classes.p3}>
                    <Grid item md={12}>
                        <PDFImage className={classes.me2}></PDFImage>
                        <Typography variant="caption" className={classes.formLabel}>
                            {
                                isPickupEnabled ? "Pickup Receipt" : "Delivery Receipt"
                            }
                        </Typography>
                        <RACButton
                            className={classes.agrPrintDoc}
                            onClick={() => {
                                viewPDFclick(deliveryReceiptB64);
                            }}
                        >
                            View PDF
                        </RACButton>
                    </Grid>
                    <Grid item md={12}>
                        <PDFImage className={classes.me2}></PDFImage>
                        <Typography variant="caption" className={classes.formLabel}>
                            {
                                isPickupEnabled ? "Pickup Checklist" : "Delivery Checklist"
                            }
                        </Typography>
                        <RACButton
                            className={classes.agrPrintDoc}
                            onClick={() => {
                                viewPDFclick(deliveryCheckListB64);
                            }}
                        >
                            View PDF
                        </RACButton>
                    </Grid>

                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
                    style={{ textAlign: 'right' }}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => deliveryDocumentContinueClick()}
                    >
                        Continue
                    </RACButton>
                </Grid>
            </div>
        );
    };

    const isConfirmedPopup = () => {
        return (
            <div>
                <Grid container spacing={2} className={classes.p3}>
                    <Grid item md={12} style={{ "textAlign": "center", "fontWeight": "bolder" }}>
                        <Successlogo />
                    </Grid>
                    <Grid item md={12} style={{ "textAlign": "center", "fontWeight": "bolder" }}>
                        <Typography variant="caption" className={classes.formLabel}>
                            Delivery has been confirmed
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
                    style={{ textAlign: "center" }}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIsConfirmedOpen(false);
                            setCoworkerSignContent('');
                            setCustomerSignContent('');
                            setDeliverReceiptInfo({ ...deliverReceiptInfo, "coWorkerSigned": false, "customerSigned": false })
                            setNameOfThePersonsigned("");
                            setDamagedescription("");
                            setOtherPersonCheck(false)
                            deliveryDigitalSignCanceledFn();
                        }}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );
    };
    const makeUploadDocument = async (
        deliveryReceiptFile?: any,
        deliveryCheckListFile?: any
    ) => {
        console.log(deliveryReceiptFile, "Receive file");
        console.log(deliveryCheckListFile, "THTH");
        let uploadDeliveryReceiptPayload: any
        uploadDeliveryReceiptPayload = {
            "poNumber": Number(props?.poNumber),
            "documents": [
                {
                    "documentType": "DLRCT",
                    "file": deliveryReceiptFile
                },
                {
                    "documentType": "DCL",
                    "file": deliveryCheckListFile
                }
            ],
            "isAcima": "Y",
            "appoinmentId": Number(props.customerDetails.appointmentId),
            "documentSignStatus": "SIGNED"
        }

        console.log(uploadDeliveryReceiptPayload, "There is a commitment");
        const uploadDeliveryReceiptResponse = await GenerateReceipt(uploadDeliveryReceiptPayload)
        console.log("\n\n\n\n\n\n\n", uploadDeliveryReceiptResponse, "\nthis is the upload receipt response\n")
        if (uploadDeliveryReceiptResponse.status == "500") {
            return false;
        }
        else if (uploadDeliveryReceiptResponse.status == "200") {
            return true;
        }
        // setIsDeliveryDocOpen(true);
    };

    const UploadeDocLoaderFn = async () => {
        setUploadeDocLoader(true);
    }
    const deliveryReceiptContinueClick = async () => {
        if (!props.isManual) {
            setIsDeliveryReceiptOpen(false)
            const UploadeDocLoaderRes = await UploadeDocLoaderFn();
        }
        setisDeliveryPickupReceipt(false);

        debugger;
        let deliveryReceiptElement: any
        let deliveryChecklistElement: any

        deliveryReceiptElement = document.getElementById('deliveryReceiptContentMannual');
        deliveryReceiptElement.style.border = "none";
        deliveryChecklistElement = document.getElementById('deliveryCheckListContent');

        html2pdf()
            .from(deliveryReceiptElement)
            .outputPdf('blob')
            .then((pdfBlob) => {
                const DRreader: any = new FileReader();
                console.log('reader ddelivveryReceipt', DRreader);
                DRreader.onloadend = async () => {
                    console.log('after Reader deliveryReceipt', DRreader);
                    const b64DeliveryReceipt = DRreader?.result.split(',')[1];
                    setDeliveryReceiptB64(b64DeliveryReceipt);
                    html2pdf()
                        .from(deliveryChecklistElement)
                        .outputPdf('blob')
                        .then((pdfBlob) => {
                            const CLreader: any = new FileReader();
                            console.log('reader deliveryChecklist', CLreader);
                            CLreader.onloadend = async () => {
                                console.log('after Reader deliveryReceipt', CLreader);
                                const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                                setDeliveryCheckListB64(b64DeliveryCheckList);
                                console.log(b64DeliveryReceipt, "delevery list");
                                console.log(b64DeliveryCheckList, "Check list");
                                if (!props.isManual) {
                                    // makeUploadDocument(b64DeliveryReceipt, b64DeliveryCheckList,);
                                    let response = await makeUploadDocument(b64DeliveryReceipt, b64DeliveryCheckList,);
                                    if (!response) {
                                        setUploadeDocLoader(false);
                                        setsomethingWentWrongOpen(true);
                                        // setUploadeDocLoader(false);
                                    }
                                    else if (!props.isManual && response) {
                                        await deliveryDigitalSignCompletedFn();
                                        setUploadeDocLoader(false);
                                        setIsSendDeliveryReceiptOpen(true);
                                    }

                                }

                            }
                            CLreader.readAsDataURL(pdfBlob);
                        });
                };
                DRreader.readAsDataURL(pdfBlob);
            });

        if (props.isManual) {
            setIsDeliveryDocOpen(true);
        }
    };
    const sendDeliveryReceiptRadioOnchange = (value: any) => {
        if (value == 'Email') {
            setSendDeliveryReceiptInputs({
                ...sendDeliveryReceiptInputs,
                emailChecked: true,
                textChecked: false,
            });
        } else {
            setSendDeliveryReceiptInputs({
                ...sendDeliveryReceiptInputs,
                emailChecked: false,
                textChecked: true,
            });
        }
    };
    const sendDeliveryReceiptEmailOnchange = (value: any) => {
        setSendDeliveryReceiptInputs({
            ...sendDeliveryReceiptInputs,
            emailValue: value,
            invalidEmail: false,
        });
    };
    const sendDeliveryReceiptPhoneNumberOnchange = (value: any) => {
        setSendDeliveryReceiptInputs({
            ...sendDeliveryReceiptInputs,
            phoneNumberValue: value,
            invalidPhone: false,
        });
    };
    const resetSendDeliveryReceiptInputs = () => {
        setSendDeliveryReceiptInputs({
            emailChecked: true,
            textChecked: false,
            emailValue: '',
            invalidEmail: false,
            invalidPhone: false,
            phoneNumberValue: '',
        });
    };

    //send mail call for the data;
    const sendDeliveryReceiptCall = async () => {

        const sendDeliveryReceiptPayload: any = {
            "identifier": props?.customerDetails?.appointmentId,
            "identifierType": "Y",
            "documentStatus": "signed",
            "actionType": sendDeliveryReceiptInputs.emailChecked ? 'acimaEmail' : 'text',
            "customerFirstName": props?.customerDetails?.customerName.split(' ')[0],
            "customerLastName": props?.customerDetails?.customerName.split(' ')[1],
            "customerName": props?.customerDetails?.customerName,
            "emailId": sendDeliveryReceiptInputs.emailValue,
            "agreementNumber": props?.customerDetails?.appointmentId,
        }

        if (!sendDeliveryReceiptInputs.emailChecked) {

            sendDeliveryReceiptPayload.customerPhoneNumber = sendDeliveryReceiptInputs.phoneNumberValue.replace(/\D/g, '');

            sendDeliveryReceiptPayload.customerId = "3456789";

            sendDeliveryReceiptPayload.customerPartyId = "34567890"
        }
        console.log(sendDeliveryReceiptPayload, "Agreemen")
        setSendDocLoader(true);
        const sendData = await getPrintDocument(sendDeliveryReceiptPayload);
        console.info(sendData, "this is the send receipt data");
        setSendDocLoader(false);
        setIsDeliveryDocOpen(true);
    }
    const formatePhoneNumber = (value: any) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return cleaned.replace(PhoneRegex, '($1) $2-$3');
    }
    const sendDeliveryEmailValidation = () => {
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return regex.test(sendDeliveryReceiptInputs.emailValue) ? true : false;
    }
    const sendDeliveryPhoneNumberValidation = () => {
        return sendDeliveryReceiptInputs.phoneNumberValue?.length < 10 ? false : true;
    }
    const sendDeliveryReceiptSendClick = async () => {
        if (sendDeliveryReceiptInputs.emailChecked) {
            const isEmailCheckPassed = sendDeliveryEmailValidation();
            if (isEmailCheckPassed) {
                setIsSendDeliveryReceiptOpen(false);
                sendDeliveryReceiptCall();
            } else {
                setSendDeliveryReceiptInputs({ ...sendDeliveryReceiptInputs, invalidEmail: true })
            }
        }
        else {
            const isPhoneNumberCheckPassed = sendDeliveryPhoneNumberValidation();
            if (isPhoneNumberCheckPassed) {
                setIsSendDeliveryReceiptOpen(false);
                sendDeliveryReceiptCall();
            }
            else {
                setSendDeliveryReceiptInputs({ ...sendDeliveryReceiptInputs, invalidPhone: true })
            }
        }

    };

    function deliveryInfoInventoriesInfoBind(source?: any) {
        return (
            props?.customerDetails?.itemDetails?.map((value: any, index: number) => {
                return (
                    <tr key={""} style={{ width: "100%" }}>
                        <td
                            style={{
                                textAlign: 'start',
                                padding: '10px 6px',
                                fontFamily: 'arial',
                                fontSize: 12,
                                color: '#4A5174',
                                width: '70%',
                            }}
                        >
                            {value.description}
                        </td>
                        <td
                            style={{
                                textAlign: 'start',
                                padding: '10px 10px ',
                                fontFamily: 'arial',
                                fontSize: 12,
                                color: '#4A5174',
                                width: '30%',
                            }}
                        >
                            {value.model}
                        </td>
                    </tr>
                )
            })
        );
    };


    /*
     ! Delivery receipts popup starts here;
     */
    const deliveryReceiptPopup = () => {
        return (
            <div>
                <div
                    id="deliveryReceiptContent"
                    style={{
                        border: '3.5px solid #6c757d',
                        borderRadius: '10px',
                        fontFamily: 'Arial',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            borderTop: '20px solid #6bc4e8',
                            borderRadius: `4px 4px 0px 0px`,
                            marginBottom: 20,
                        }}
                    ></div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '95%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: 20,
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <img src={"./Acima.png"} width={"10%"} /> */}
                                    <AcimaLogo />
                                    <h2
                                        style={{
                                            display: 'inline',
                                            margin: '0px 20px',
                                            fontFamily: 'arial',
                                            fontWeight: 'bolder',
                                            fontSize: 'x-large',
                                        }}
                                    >
                                        DELIVERY RECEIPT
                                    </h2>
                                    <div style={{ "display": "flex", marginLeft: "60px" }}>
                                        <p
                                            style={{

                                                fontFamily: 'arial',
                                                fontWeight: 600,

                                                fontSize: 14
                                            }}
                                        >
                                            Delivery Date &amp; Time :
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: 'arial',
                                                fontWeight: 600,
                                                color: '#4A5174',
                                                fontSize: 14
                                            }}
                                        >

                                            {' ' + getCurrentDateWithFormat('/') + " " + manipulateTime(props.customerDetails.time)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderBottom: '1px solid #DFDFDF',
                                }}
                            >
                                <div
                                    style={{
                                        width: '50%',
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                    }}
                                >
                                    <div style={{ width: '45%' }}>
                                        <p
                                            style={{
                                                fontFamily: 'arial',
                                                marginBottom: 8,
                                                fontSize: 14,
                                                fontWeight: 700,
                                            }}
                                        >
                                            RAC Store
                                        </p>
                                        <p
                                            style={{
                                                display: 'inline',
                                                fontFamily: 'arial',
                                                fontWeight: 500,
                                                lineHeight: '1.8',
                                                color: '#4A5174',
                                                fontSize: 12,
                                            }}
                                        >
                                            {`${deliverReceiptInfo.storeInfo.address},`}
                                            <br />
                                            {`${deliverReceiptInfo.storeInfo.city},`}
                                            <br />
                                            {`${deliverReceiptInfo.storeInfo.zip},`}
                                            <br />
                                            {`${deliverReceiptInfo.storeInfo.phoneNumber}`}
                                        </p>
                                    </div>
                                </div>
                                <div style={{ width: '50%', textAlign: "left", marginLeft: "50%" }}>
                                    <p
                                        style={{
                                            fontFamily: 'arial',
                                            marginBottom: 10,
                                            fontSize: 14,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Customer
                                    </p>
                                    <p

                                        style={{

                                            fontFamily: 'arial',

                                            fontWeight: 600,

                                            lineHeight: '1.8',

                                            color: '#4A5174',

                                            fontSize: 12,

                                            margin: 0,

                                        }}

                                    >

                                        {props?.customerDetails?.customerName}<br />

                                        {props?.customerDetails.addressLine1}<br />

                                        {props?.customerDetails.addressLine2}{props?.customerDetails.addressLine2 ? <br /> : null}

                                        {props?.customerDetails.city + ", " + props?.customerDetails.state + ", " + props?.customerDetails.zip}<br />

                                        {phoneNumberFormat(props?.customerDetails.phoneNumber)}

                                    </p>
                                    {/* <p
                                        style={{
                                            fontFamily: 'arial',
                                            fontWeight: 500,
                                            lineHeight: '1.8',
                                            color: '#4A5174',
                                            fontSize: 12,
                                            margin: 0,
                                        }}
                                    >
                                        {props?.customerDetails?.customerName}
                                    </p>
                                    <p
                                        style={{
                                            fontFamily: 'arial',
                                            fontWeight: 500,
                                            lineHeight: '1.8',
                                            color: '#4A5174',
                                            fontSize: 12,
                                            marginTop: 0,
                                        }}
                                    >
                                        {props.customerDetails?.addressLine1}
                                    </p>
                                    <p
                                        style={{
                                            fontFamily: 'arial',
                                            fontWeight: 500,
                                            lineHeight: '1.8',
                                            color: '#4A5174',
                                            fontSize: 12,
                                            marginTop: '-12PX',
                                        }}
                                    >
                                        {props.customerDetails?.addressLine2 +
                                            ',' +
                                            props.customerDetails?.city + ' ' + props.customerDetails?.zip}
                                    </p>
                                    <p
                                        style={{
                                            fontFamily: 'arial',
                                            fontWeight: 500,
                                            lineHeight: '1.8',
                                            color: '#4A5174',
                                            fontSize: 12,
                                            marginTop: '-12PX',
                                        }}
                                    >
                                        {phoneNumberFormat(props.customerDetails?.phoneNumber)}
                                    </p> */}
                                </div>
                            </div>
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontFamily: 'Arial',
                                    fontWeight: 600,
                                    fontSize: 18,
                                }}
                            >
                                ITEMS
                            </p>
                            <div style={{ borderBottom: '1px solid #DFDFDF' }}>
                                <table
                                    style={{
                                        width: '100%',
                                        borderCollapse: 'collapse',
                                        marginBottom: 10,
                                    }}
                                >
                                    <thead>
                                        <tr
                                            style={{
                                                width: "100%",
                                                background: '#EFF4FF',
                                                WebkitPrintColorAdjust: 'exact',
                                            }}
                                        >
                                            <th
                                                style={{
                                                    width: "70%",
                                                    textAlign: 'start',
                                                    padding: '10px 6px',
                                                    fontFamily: 'arial',
                                                    fontSize: 14,
                                                }}
                                            >
                                                Description
                                            </th>
                                            <th
                                                style={{
                                                    width: "30%",
                                                    textAlign: 'start',
                                                    padding: '10px 6px',
                                                    fontFamily: 'arial',
                                                    fontSize: 14,
                                                }}
                                            >
                                                Model#
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{deliveryInfoInventoriesInfoBind('ResendVideo')}</tbody>
                                </table>
                            </div>
                            <p
                                style={{
                                    fontFamily: 'arial',
                                    fontWeight: 600,
                                    fontSize: 14,
                                    lineHeight: '1.8',
                                }}
                            >
                                Before merchandise is taken from the customer's home, please check the condition of the following areas and note
                                :
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 20,
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        style={{ height: 16, width: 16 }}
                                        type="checkbox"
                                        defaultValue=""
                                        id="Walls"
                                        checked={damageCheckBox.Walls}
                                        onClick={() => { { damageCheckBox.Walls ? setdamageCheckbox({ ...damageCheckBox, Walls: false }) : setdamageCheckbox({ ...damageCheckBox, Walls: true }) } }}
                                    />
                                    <label
                                        htmlFor="flexCheckDefault"
                                        style={{
                                            marginLeft: 10,
                                            fontFamily: 'arial',
                                            fontSize: 12,
                                            color: '#4A5174',
                                        }}
                                    >
                                        Wall(s)
                                    </label>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        style={{ height: 16, width: 16 }}
                                        type="checkbox"
                                        defaultValue=""
                                        id="Floors-2"
                                        checked={damageCheckBox.Floors}
                                        onClick={() => { { damageCheckBox.Floors ? setdamageCheckbox({ ...damageCheckBox, Floors: false }) : setdamageCheckbox({ ...damageCheckBox, Floors: true }) } }}
                                    />
                                    <label
                                        htmlFor="flexCheckDefault-2"
                                        style={{
                                            marginLeft: 10,
                                            fontFamily: 'arial',
                                            fontSize: 12,
                                            color: '#4A5174',
                                        }}
                                    >
                                        Floors
                                    </label>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        style={{ height: 16, width: 16 }}
                                        type="checkbox"
                                        defaultValue=""
                                        id="Doorway-3"
                                        checked={damageCheckBox.Doorway}
                                        onClick={() => { { damageCheckBox.Doorway ? setdamageCheckbox({ ...damageCheckBox, Doorway: false }) : setdamageCheckbox({ ...damageCheckBox, Doorway: true }) } }}



                                    />
                                    <label
                                        htmlFor="flexCheckDefault-3"
                                        style={{
                                            marginLeft: 10,
                                            fontFamily: 'arial',
                                            fontSize: 12,
                                            color: '#4A5174',
                                        }}
                                    >
                                        Door(s)/Doorway(s)
                                    </label>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        style={{ height: 16, width: 16 }}
                                        type="checkbox"
                                        defaultValue=""
                                        id="Stairs-4"
                                        checked={damageCheckBox.Statirs}

                                        onClick={() => { { damageCheckBox.Statirs ? setdamageCheckbox({ ...damageCheckBox, Statirs: false }) : setdamageCheckbox({ ...damageCheckBox, Statirs: true }) } }}

                                    />
                                    <label
                                        htmlFor="flexCheckDefault-4"
                                        style={{
                                            marginLeft: 10,
                                            fontFamily: 'arial',
                                            fontSize: 12,
                                            color: '#4A5174',
                                        }}
                                    >
                                        Stairs
                                    </label>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        style={{ height: 16, width: 16 }}
                                        type="checkbox"
                                        defaultValue=""
                                        id="Other-5"
                                        checked={damageCheckBox.Other}
                                        onClick={() => { { damageCheckBox.Other ? setdamageCheckbox({ ...damageCheckBox, Other: false }) : setdamageCheckbox({ ...damageCheckBox, Other: true }) } }}
                                    />
                                    <label
                                        htmlFor="flexCheckDefault-5"
                                        style={{
                                            marginLeft: 10,
                                            fontFamily: 'arial',
                                            fontSize: 12,
                                            color: '#4A5174',
                                        }}
                                    >
                                        Other
                                    </label>
                                </div>
                            </div>
                            <label
                                style={{
                                    display: 'block',
                                    fontFamily: 'arial',
                                    fontWeight: 600,
                                    fontSize: 14,

                                    lineHeight: '1.8',
                                }}
                                htmlFor="text-area"
                            >
                                If any Boxes were checked, explain damage:
                            </label>
                            <textarea
                                rows={3}
                                style={{ width: '100%', resize: 'none', borderColor: "#cecece" }}
                                id="text-area"
                                defaultValue={''}
                                maxLength={240}
                                value={damagedescription}
                                onChange={(e) => {
                                    setDamagedescription(e.target.value)
                                }}
                            />
                            <p
                                style={{
                                    fontFamily: 'arial',
                                    fontWeight: 600,
                                    fontSize: 14,
                                    lineHeight: '1.8',
                                }}
                            >
                                Acknowledgement
                            </p>
                            <p>By Signing below, I acknowledge that Acima Leasing (through an affiliate or third party) has delivered and installed (if applicable) to my satisfaction, the leased property listed above.</p>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 10,
                                }}
                            >
                                <div
                                    style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                                >
                                    <label
                                        style={{
                                            fontFamily: 'arial',
                                            display: 'block',
                                            marginBottom: 20,
                                            fontSize: 14,
                                            fontWeight: 600,
                                        }}
                                    >
                                        Customer Signature
                                    </label>
                                    <div style={{ marginBottom: 10, textAlign: 'center' }}>
                                        {!deliverReceiptInfo.customerSigned ? (
                                            <div>
                                                <button
                                                    style={{
                                                        WebkitPrintColorAdjust: 'exact',
                                                        backgroundColor:
                                                            '#2468FF',
                                                        color: '#fff',
                                                        fontFamily: 'arial',
                                                        border: 0,
                                                        padding: '10px 20px',
                                                        borderRadius: 5,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                        addSignatureClick('Customer');
                                                    }}
                                                    hidden={props.isManual}
                                                >
                                                    Click here to add Signature
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <img className={classes?.digitalSigantureImg} style={{ width: "100%" }} src={customerSignContent} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={{ width: "20%" }}></div>
                                <div
                                    style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                                >
                                    <label
                                        style={{
                                            fontFamily: 'arial',
                                            display: 'block',
                                            marginBottom: 20,
                                            fontSize: 14,
                                            fontWeight: 600,
                                        }}
                                    >
                                        Co-Worker Signature
                                    </label>
                                    <div style={{ marginBottom: 10, textAlign: 'center' }}>
                                        {!deliverReceiptInfo.coWorkerSigned ? (
                                            <div>
                                                <button
                                                    style={{
                                                        WebkitPrintColorAdjust: 'exact',
                                                        backgroundColor: '#2468FF',
                                                        color: '#fff',
                                                        fontFamily: 'arial',
                                                        border: 0,
                                                        padding: '10px 20px',
                                                        borderRadius: 5,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                        console.log("I am called the developent ")
                                                        addSignatureClick('CoWorker');
                                                    }}
                                                    // disabled={
                                                    //     acknowledgeInputs.acceptChecked ||
                                                    //         acknowledgeInputs.declineChecked
                                                    //         ? false
                                                    //         : true
                                                    // }
                                                    hidden={props.isManual}
                                                >
                                                    Click here to add Signature
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <img className={classes?.digitalSigantureImg} style={{ width: "100%" }} src={coworkerSignContent} />
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '40%' }}>
                                    <div style={{ marginBottom: 10 }}>
                                        <label
                                            style={{
                                                fontFamily: 'arial',
                                                fontSize: 14,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Name of the person signed :
                                        </label>
                                        <label
                                            style={{
                                                fontFamily: 'arial',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#4A5174',
                                            }}
                                        >


                                            {(deliverReceiptInfo.customerSigned && otherPersonCheck) ? nameOfThePersonSigned : (deliverReceiptInfo.customerSigned && !otherPersonCheck) ? ' ' + props.customerDetails.customerName : ''}
                                        </label>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <label
                                            style={{
                                                fontFamily: 'arial',
                                                fontSize: 14,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Date :
                                        </label>
                                        <label
                                            style={{
                                                fontFamily: 'arial',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#4A5174',
                                            }}
                                        >
                                            {' ' + getCurrentDateWithFormat('/')}
                                        </label>
                                    </div>
                                </div>
                                <div style={{ width: "20%" }}></div>
                                <div style={{ width: '40%' }}>
                                    <label
                                        style={{
                                            fontFamily: 'arial',
                                            fontSize: 14,
                                            fontWeight: 600,
                                        }}
                                    >
                                        Date :
                                    </label>
                                    <label
                                        style={{
                                            fontFamily: 'arial',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: '#4A5174',
                                        }}
                                    >
                                        {' ' + getCurrentDateWithFormat('/')}
                                    </label>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Grid
                    item
                    md={12}
                    className={`${classes.pb4} ${classes.px3} ${classes.textRight}`}
                    style={{ marginTop: '100px' }}
                >
                    <RACButton
                        color="primary"
                        variant="outlined"
                        className={classes.mx1}
                        // onClick={() => deliveryReceiptCancelClick()}
                        onClick={() => {
                            setIsDeliveryReceiptOpen(false);
                            deliveryDigitalSignCanceledFn()
                            setCoworkerSignContent('')
                            setCustomerSignContent('')
                            setDeliverReceiptInfo({ ...deliverReceiptInfo, "coWorkerSigned": false, "customerSigned": false })
                            setNameOfThePersonsigned("");
                            setDamagedescription("");
                            setOtherPersonCheck(false)
                        }}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.mx1}
                        disabled={customerSignContent && coworkerSignContent ? false : true}
                        onClick={() => deliveryReceiptContinueClick()}
                    >
                        Continue
                    </RACButton>
                </Grid>
            </div>
        );
    };
    const pickupReceiptPopup = () => {
        return (
            <></>
        )
    }

    const phoneNumberFormat = (phonenum: any) => {
        if (phonenum == '') return phonenum;
        if (!phonenum) return phonenum;
        const currentValue = phonenum.replace(/[^\d]/g, '');

        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
            3,
            6
        )}-${currentValue.slice(6, 10)}`;
    };
    const bindChecklist = () => {
        return deliverReceiptInfo.inventoryInfo.map((value: any, index: any) => {
            return (
                <tr key={index} style={{ width: '100%' }}>
                    <td
                        style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                        }}
                    >
                        <span
                            style={{
                                padding: '0px 8px 0px 8px',
                                borderRadius: '3px',
                                border: '1px solid  #545454',
                                marginRight: '10px',
                            }}
                        />
                    </td>
                    <td
                        style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                        }}
                    >
                        {value?.inventoryDesc}
                    </td>
                    <td
                        style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                        }}
                    >
                        {value?.serialNumber}
                    </td>
                    <td
                        style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                        }}
                    >
                        {value?.inventoryNumber}
                    </td>
                </tr>
            );
        });
    };

    // const deliveryChecklist = () => {
    //   return (
    //   );
    // };
    // Pickup Receipt Html End
    const signaturePanelPopup = () => {
        return (
            <Grid className={classes.p3}>
                <Grid container>
                    <Grid item sm={12} md={12} className={classes.mb3}>
                        <Typography
                            component="label"
                            className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
                        >
                            Who is present to sign?
                        </Typography>
                        <span style={{ "color": "red" }}> *</span>
                    </Grid>
                    <Grid item sm={12} md={12} className={classes.mb3}>
                        <RACRadio
                            value={signaturePanelInputs.customerOptionChecked}
                            checked={!otherPersonCheck}
                            onChange={() => {
                                setOtherPersonCheck(false)
                                signatureRef.current.clear();
                            }}
                            label="Customer"
                        />
                        <RACRadio
                            value={signaturePanelInputs.othersOptionChecked}
                            checked={otherPersonCheck}
                            onChange={() => {
                                setOtherPersonCheck(true);
                                signatureRef.current.clear();
                            }}
                            label="Others"
                        />
                    </Grid>
                    <Grid>
                        <Typography
                            component="label"
                            className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
                            style={{ "margin": "10px 0px" }}
                        >
                            Name of the person
                        </Typography>
                        <RACTextbox
                            maxlength={40}
                            disabled={
                                !otherPersonCheck ? true : false
                            }
                            value={!otherPersonCheck ? props.customerDetails.customerName : nameOfThePersonSigned}
                            OnChange={(e) => {
                                setNameOfThePersonsigned(e.target.value);
                            }}
                        />
                        <Typography
                            component="label"
                            className={`${classes.formLabel} ${classes.w100} ${classes.fontBold} ${classes.my2}`}
                            style={{ marginTop: '30px' }}
                        >
                            Add the signature here
                        </Typography>
                        <span style={{ "color": "red" }}> *</span>
                        <div id="SignatureContent">
                            <div
                                style={{
                                    border: '1px solid rgb(223, 223, 223)',
                                    borderRadius: '5px',
                                }}
                            >
                                <SignatureCanvas
                                    penColor="black"
                                    ref={signatureRef}
                                    canvasProps={{
                                        width: 350,
                                        height: 200,
                                        className: 'sigCanvas',
                                    }}
                                    onEnd={() => {
                                        setSignaturePanelInputs({
                                            ...signaturePanelInputs,
                                            isNextButtonDisabled: false,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <span style={{ "fontWeight": "bold" }}>Note : </span>
                        <Typography component={"label"}
                            className={`${classes.formLabel} ${classes.w100} ${classes.my2}`} style={{ color: "#5A6181" }}>
                            Only individuals who are 18 years or older are eligible to sign the delivery receipt.
                        </Typography>

                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={`${classes.pb4} ${classes.px3}`}
                        style={{ marginTop: '30px', marginLeft: '8rem' }}
                    >
                        <RACButton
                            variant="outlined"
                            color="primary"
                            className={classes.mx1}
                            onClick={() => signaturePanelCancelClick()}
                        >
                            Cancel
                        </RACButton>
                        <RACButton
                            variant="contained"
                            color="primary"
                            className={classes.mx1}
                            onClick={() => signaturePanelSaveClick()}
                            disabled={signaturePanelInputs.isNextButtonDisabled}
                        >
                            Save
                        </RACButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    const coWorkerSignPanelPopup = () => {
        return (
            <Grid className={classes.p3}>
                <Grid container>
                    <Grid>
                        <div id="SignatureContent">
                            <div
                                style={{
                                    border: '1px solid rgb(223, 223, 223)',
                                    borderRadius: '5px',
                                }}
                            >
                                <SignatureCanvas
                                    penColor="black"
                                    ref={signatureRef}
                                    canvasProps={{
                                        width: 350,
                                        height: 200,
                                        className: 'sigCanvas',
                                    }}
                                    onEnd={() => {
                                        setCoWorkerSignSaveDisable(false);
                                    }}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={`${classes.pb4} ${classes.px3}`}
                        style={{ marginTop: '30px', marginLeft: '8rem' }}
                    >
                        <RACButton
                            variant="outlined"
                            color="primary"
                            className={classes.mx1}
                            onClick={() => {
                                setIsCowokerSignPanelOpen(false);
                                {
                                    isPickupEnabled ? setisDeliveryPickupReceipt(true) : setIsDeliveryReceiptOpen(true);
                                };
                                setCoWorkerSignSaveDisable(true);
                            }}
                        >
                            Cancel
                        </RACButton>
                        <RACButton
                            variant="contained"
                            color="primary"
                            className={classes.mx1}
                            onClick={() => signaturePanelSaveClick()}
                            disabled={coWorkerSignSaveDisable}
                        >
                            Save
                        </RACButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    function decideSendDeliverySend() {
        if (sendDeliveryReceiptInputs.textChecked) {
            return false;
        } else if (sendDeliveryReceiptInputs.phoneNumberValue.length > 0) {
            return false;
        } else {
            true;
        }
    }
    const sendDeliveryReceiptPopup = () => {
        return (
            <Grid className={classes.p3}>
                <Grid container>
                    <Grid item sm={12} md={12} className={classes.mb3}>
                        <Typography
                            component="label"
                            className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
                        >
                            How do you want the delivery receipt to be shared?
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={12} className={classes.mb3}>
                        <RACRadio
                            value={sendDeliveryReceiptInputs.emailChecked}
                            checked={sendDeliveryReceiptInputs.emailChecked}
                            onChange={() => sendDeliveryReceiptRadioOnchange('Email')}
                            label="Email"
                        />
                         <RACRadio
                            value={sendDeliveryReceiptInputs.textChecked}
                            checked={sendDeliveryReceiptInputs.textChecked}
                            onChange={() => sendDeliveryReceiptRadioOnchange('Text')}
                            label="Text"
                            disabled={ true }
                        /> 
                    </Grid>
                    <Grid item md={6}>
                        {sendDeliveryReceiptInputs.emailChecked ? (
                            <div>
                                <RACTextbox
                                    inputlabel="Email"
                                    inputLabelClassname="form-label"
                                    name="Email"
                                    value={sendDeliveryReceiptInputs.emailValue}
                                    OnChange={(e) =>
                                        sendDeliveryReceiptEmailOnchange(e.target.value)
                                    }
                                    isCurrency={false}
                                    type="email"
                                />
                                <label
                                    style={{
                                        color: 'red',
                                        fontSize: 'small',
                                        marginLeft: '3%',
                                        visibility: sendDeliveryReceiptInputs.invalidEmail ? 'visible' : 'hidden'
                                    }}
                                >
                                    Please enter valid email
                                </label>

                            </div>
                        ) : <div>
                            <RACTextbox
                                inputlabel="Phone Number"
                                inputLabelClassname="form-label"
                                name="Phone Number"
                                value={formatePhoneNumber(sendDeliveryReceiptInputs.phoneNumberValue)}
                                OnChange={(e) =>
                                    sendDeliveryReceiptPhoneNumberOnchange(e.target.value)
                                }
                                isCurrency={false}
                                type="text"
                                maxlength={10}
                            />
                            <label
                                style={{
                                    color: 'red',
                                    fontSize: 'small',
                                    marginLeft: '3%',
                                    visibility: sendDeliveryReceiptInputs.invalidPhone ? 'visible' : 'hidden'
                                }}
                            >
                                Please enter valid phone number
                            </label>
                        </div>}
                    </Grid>
                </Grid>

                <Grid
                    item
                    md={12}
                    className={`${classes.pb4} ${classes.px3} ${classes.textRight}`}
                >
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={classes.mx1}
                        onClick={() => {
                            setIsSendDeliveryReceiptOpen(false);
                            setIsDeliveryDocOpen(true);
                            resetSendDeliveryReceiptInputs();
                        }}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.mx1}
                        onClick={() => sendDeliveryReceiptSendClick()}
                        disabled={decideSendDeliverySend()}
                    >
                        Send
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const loadingPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Typography className={classes.formLabel}>Please wait...</Typography>
                    <CircularProgress style={{ marginTop: '30px' }} size={25} />
                </Grid>
            </div>
        );
    };
    const uploadingDocPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Typography className={classes.formLabel}>
                        Uploading documents
                    </Typography>
                    <Typography className={classes.formLabel}>Please wait...</Typography>
                    <CircularProgress style={{ marginTop: '30px' }} size={25} />
                </Grid>
            </div>
        );
    };
    const sendingDocPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Typography className={classes.formLabel}>
                        Sending documents
                    </Typography>
                    <Typography className={classes.formLabel}>Please wait...</Typography>
                    <CircularProgress style={{ marginTop: '30px' }} size={25} />
                </Grid>
            </div>
        );
    };
    const somethingWentWrongPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Alerticon></Alerticon>

                    <Typography className={classes.formLabel}>
                        Something went wrong!
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => somethingWentWrongOkClick()}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };
    const errorMessagePopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Alerticon></Alerticon>
                    <Typography className={classes.formLabel}>
                        {serviceErrorMsg}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            seterrorMessagePopupOpen(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };
    return (
        <>
            {/* {deliveryChecklist()} */}
            {/* {deliveryChecklistEnabled ? deliveryChecklist() : null} */}
            <RACModalCard
                isOpen={hiddenLoader}
                closeIcon={false}
                maxWidth="xs"
                children={loadingPopup()}
                borderRadius={modalCardRadius}
            />
            <RACModalCard
                isOpen={uploadeDocLoader}
                closeIcon={false}
                maxWidth="xs"
                children={uploadingDocPopup()}
                borderRadius={modalCardRadius}
            />
            <RACModalCard
                isOpen={sendDocLoader}
                closeIcon={false}
                maxWidth="xs"
                children={sendingDocPopup()}
                borderRadius={modalCardRadius}
            />
            <RACModalCard
                isOpen={isDeliveryReceiptOpen}
                closeIcon={false}
                maxWidth="md"
                borderRadius={modalCardRadius}
            >
                {deliveryReceiptPopup()}
            </RACModalCard>
            <RACModalCard
                isOpen={isDeliveryPickupReceipt}
                closeIcon={false}
                maxWidth="md"
                borderRadius={modalCardRadius}
            >
                {pickupReceiptPopup()}
            </RACModalCard>
            <RACModalCard
                isOpen={isSignaturePanelOpen}
                closeIcon={false}
                maxWidth="xs"
                children={signaturePanelPopup()}
                borderRadius={modalCardRadius}
                title="Signature Panel"
            />
            <RACModalCard
                isOpen={isCowokerSignPanelOpen}
                closeIcon={false}
                maxWidth="xs"
                children={coWorkerSignPanelPopup()}
                borderRadius={modalCardRadius}
                title="Add the Co-worker signature here"
            />
            <RACModalCard
                isOpen={isSendDeliveryReceiptOpen}
                closeIcon={true}
                maxWidth="sm"
                children={sendDeliveryReceiptPopup()}
                borderRadius={modalCardRadius}
                title="Send Delivery Receipt"
                onClose={() => {
                    setIsSendDeliveryReceiptOpen(false);
                    setIsDeliveryDocOpen(true);
                }}
            />
            <RACModalCard
                isOpen={isDeliveryDocOpen}
                closeIcon={true}
                maxWidth="xs"
                children={deliveryDocumentPopup()}
                borderRadius={modalCardRadius}
                title="Delivery Document"
                onClose={() => {
                    setIsDeliveryDocOpen(false)
                    if (!props.isManual) {
                        props.openPop(true)
                    }
                    else {
                        deliveryDigitalSignCanceledFn()
                        // deliveryDigitalSignCompletedFn('Manual')
                    }
                }}
            />
            <RACModalCard
                isOpen={isConfirmedOpen}
                closeIcon={false}
                maxWidth="xs"
                children={isConfirmedPopup()}
                borderRadius={modalCardRadius}
                onClose={() => {
                    setCoworkerSignContent('')
                    setCustomerSignContent('')
                    setIsConfirmedOpen(false);
                    setDeliverReceiptInfo({ ...deliverReceiptInfo, "coWorkerSigned": false, "customerSigned": false })
                    setNameOfThePersonsigned("");
                    setDamagedescription("");
                    setOtherPersonCheck(false)
                    deliveryDigitalSignCanceledFn();
                }}
            />
            <RACModalCard
                isOpen={somethingWentWrongOpen}
                closeIcon={false}
                maxWidth="xs"
                children={somethingWentWrongPopup()}
                borderRadius={modalCardRadius}
            />
            <RACModalCard
                isOpen={errorMessagePopupOpen}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={modalCardRadius}
                children={errorMessagePopup()}
            />
            <Grid style={{ display: 'none' }}>
                {/* Delivery Checklist Html Start Here */}
                <div
                    style={{
                        width: '90%',
                        fontFamily: 'Arial',
                        margin: '10px auto 0px auto',
                    }}
                    id="deliveryCheckListContent"

                >
                    {/*first part starts here*/}
                    <div
                        style={{ width: '100%', float: 'left' }}
                    >
                        <div style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}>
                            <div style={{ width: '30%', float: 'left' }}>
                                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                    Delivery Checklist
                                </h5>
                            </div>
                            <div
                                style={{
                                    width: '35%',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    float: 'left',
                                    height: '100px',
                                    marginBottom: '10px',
                                }}
                            >
                                <AcimaLogo />
                                {/* <Logo style={{ width: '100%', height: '100%' }}></Logo> */}
                            </div>
                            <div></div>
                            <table style={{ width: '35%', float: 'left', marginTop: '30px' }}>
                                <thead />
                                <tbody style={{ width: '100%', float: 'right' }}>
                                    {/*child-parent of first widget*/}



                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/*first part ends here*/}
                    {/*second part starts here */}
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            borderBottom: '1px solid #DFDFDF',
                        }}
                    >
                        <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                            <div style={{ width: '33.33%', float: 'left' }}>
                                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                    RAC Store
                                </h5>
                                <>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {deliverReceiptInfo.storeInfo.address}
                                    </p>
                                </>
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {deliverReceiptInfo.storeInfo.city +
                                        ',' +
                                        deliverReceiptInfo.storeInfo.zip}
                                </p>
                                <p
                                    style={{
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {phoneNumberFormat(deliverReceiptInfo.storeInfo.phoneNumber)}
                                </p>
                            </div>
                            <div style={{ width: '33.33%', float: 'left', marginLeft: '33.33%' }}>
                                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                    Customer
                                </h5>
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {props.customerDetails.customerName}
                                </p>
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {props.customerDetails?.addressLine1}
                                </p>
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {props.customerDetails?.addressLine2}
                                </p>
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {props.customerDetails?.city + ', ' + props.customerDetails?.state + ', ' + props.customerDetails?.zip
                                    }
                                </p>

                                <p
                                    style={{
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {phoneNumberFormat(props.customerDetails?.phoneNumber)}
                                </p>
                            </div>

                        </div>
                    </div>
                    {/*second part ends here */}
                    {/*3rd part starts*/}
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            borderBottom: '1px solid #DFDFDF',
                        }}
                    >
                        <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
                            <h5
                                style={{
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    margin: '0px',
                                    marginBottom: '10px',
                                }}
                            >
                                Delivery Checklist
                            </h5>
                        </div>
                        <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                            <table style={{ marginBottom: '20px', fontSize: '12px', width: "100%" }}>
                                <thead>
                                    <tr style={{ width: '100%' }}>
                                        <th
                                            style={{
                                                width: '50%',
                                                textAlign: 'left',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                            }}
                                        >
                                            Description of Property
                                        </th>
                                        <th
                                            style={{
                                                width: '50%',
                                                textAlign: 'left',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                            }}
                                        >
                                            Model #
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{deliveryInfoInventoriesInfoBind('ResendVideo')}</tbody>
                            </table>
                        </div>
                    </div>
                    {/*3rd part ends*/}
                    {/*fourth part starts*/}
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            marginBottom: '400px',
                            marginTop: '10px',
                        }}
                    >
                        <h5
                            style={{
                                fontSize: '14px',
                                textDecoration: 'underline',
                                float: 'left',
                            }}
                        >
                            Delivery Information
                        </h5>
                        <table style={{ width: '100%', float: 'left' }}>
                            <thead />
                            <tbody style={{ width: '100%', marginTop: '20px', float: 'left' }}>
                                <tr style={{ width: '18%', float: 'left', whiteSpace: 'nowrap' }}>
                                    <td
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        CoWorker Signature:
                                    </td>
                                </tr>
                                <tr
                                    style={{
                                        width: '28%',
                                        float: 'left',
                                        paddingRight: '25px',
                                        whiteSpace: 'nowrap',
                                        marginLeft: '8px',
                                    }}
                                >
                                    <td
                                        style={{
                                            marginTop: '18px',
                                            color: '#656565',
                                            borderBottom: '1px solid #545454',
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </tr>
                                <tr style={{ width: '8%', float: 'left', whiteSpace: 'nowrap' }}>
                                    <td
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Date:
                                    </td>
                                </tr>
                                <tr style={{ width: '32%', float: 'left', whiteSpace: 'nowrap' }}>
                                    <td
                                        style={{
                                            marginTop: '15px',
                                            color: '#656565',
                                            borderBottom: '1px solid #545454',
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*fourth part ends*/}
                    {/*fifth part starts*/}
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            borderBottom: '1px solid #DFDFDF',
                            marginTop: '18rem'
                        }}
                    >
                        <div style={{ width: '100%', float: 'left' }}>
                            <h5
                                style={{
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    marginTop: '0px',
                                    marginBottom: '10px',
                                }}
                            >
                                For Office Use Only
                            </h5>
                        </div>
                        <div style={{ width: '100%', float: 'left' }}>
                            <span
                                style={{
                                    fontSize: '14px',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    fontStyle: 'italic',
                                }}
                            >
                                I acknowledge that Rent-A-Center has delivered, installed and
                                demonstrated, to my satisfaction, the rental property listed
                                above.
                            </span>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ width: '100%', float: 'left', marginTop: '20px' }}>
                                <span
                                    style={{
                                        fontSize: '14px',
                                        marginBottom: '10px',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    Customer Call back survey
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                marginTop: '20px',
                                marginBottom: '30px',
                            }}
                        >
                            <div style={{ width: '50%', float: 'left' }}>
                                <div style={{ width: '35%', float: 'left' }}>
                                    <span
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Date of Call Back
                                    </span>
                                </div>
                                <div style={{ width: '65%', float: 'right' }}>
                                    <span
                                        style={{
                                            marginTop: '12px',
                                            color: '#656565',
                                            borderBottom: '1px solid #545454',
                                            width: '80%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '50%', float: 'left' }}>
                                <div style={{ width: '30%', float: 'left' }}>
                                    <span
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Mgr/Asst Initials
                                    </span>
                                </div>
                                <div style={{ width: '70%', float: 'right' }}>
                                    <span
                                        style={{
                                            marginTop: '12px',
                                            color: '#656565',
                                            borderBottom: '1px dashed #545454',
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*fifth part ends*/}
                    {/*letter format starts*/}
                    <div style={{ width: '100%', fontSize: '14px', float: 'left' }}>
                        <p
                            style={{
                                fontSize: '14px',
                                marginBottom: '5px',
                                lineHeight: '26px',
                            }}
                        >
                            Hello Mr./Mrs./Ms., this is{' '}
                            <span
                                style={{
                                    marginTop: '12px',
                                    color: '#656565',
                                    borderBottom: '1px dashed #545454',
                                    paddingRight: '135px',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}
                            />
                            , the manager of the Rent-A-Center in{' '}
                            <span
                                style={{
                                    marginTop: '12px',
                                    color: '#656565',
                                    borderBottom: '1px dashed #545454',
                                    paddingRight: '135px',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}
                            />
                            I want to welcome you as a customer and ask you a few questions
                            about the delivery we made to you the other day. Is this a
                            convenient time for you to answer a few questions?
                        </p>
                        <p style={{ fontStyle: 'italic', marginTop: '5px' }}>
                            {' '}
                            (If yes, continue with survey. If no, ask for a convenient time to
                            call back)
                        </p>
                    </div>
                    {/*letter format ends*/}
                    {/*grid starts here*/}
                    <div style={{ width: '100%', float: 'left' }}>
                        <table style={{ marginBottom: '10px', fontSize: '14px' }}>
                            <tbody>
                                <tr style={{ width: '100%' }}>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        1. Was the delivery on time ?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        2. Was the unit&apos; operation explained to your satisfaction
                                        ?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        3. Have we answered all of your questions regarding the rental
                                        agreement?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        3. Have we answered all of your questions regarding the rental
                                        agreement?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        4. Can you tell me when your next renewal is due and the
                                        amount you will pay?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        5. Did we explain our customer referral program?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        6. Did you take advantage of our Benefits Plus program when
                                        you rented your item?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: '30px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                            marginRight: '20px',
                                        }}
                                    >
                                        If No explain to them the benefits &amp; close the deal.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        7. Do you have any other questions or is there anything I can
                                        do for you?
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td
                                        style={{
                                            width: '14%',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ width: '100%', float: 'left' }}>
                        <p style={{ fontSize: '14px', marginBottom: '5px' }}>Notes :</p>
                        <span
                            style={{
                                marginTop: '20px',
                                color: '#656565',
                                borderBottom: '1px solid #545454',
                                width: '100%',
                                float: 'left',
                            }}
                        />
                        <span
                            style={{
                                marginTop: '30px',
                                color: '#656565',
                                borderBottom: '1px solid #545454',
                                width: '100%',
                                float: 'left',
                            }}
                        />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                marginTop: '20px',
                                marginBottom: '20px',
                            }}
                        >
                            <p style={{ fontSize: '14px', marginBottom: '5px' }}>
                                Thank you for your time and please do not hesitate to call us at
                                Rent-A-Center with any questions.
                            </p>
                        </div>
                    </div>
                    {/*grid ends here*/}
                </div>


                {
                    /**Hidden recipt property for mannual */
                }
                <div hidden={true}>
                    <div
                        id="deliveryReceiptContentMannual"
                        style={{
                            border: '3.5px solid #6c757d',
                            borderRadius: '10px',
                            fontFamily: 'Arial',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                borderTop: '20px solid #6bc4e8',
                                borderRadius: `4px 4px 0px 0px`,
                                marginBottom: 20,
                            }}
                        ></div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ width: '95%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 20,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <img src={./"Acima.png"} width={"10%"} /> */}
                                        <AcimaLogo />
                                        <h2
                                            style={{
                                                display: 'inline',
                                                margin: '0px 20px',
                                                fontFamily: 'arial',
                                                fontWeight: 'bolder',
                                                fontSize: 'x-large',
                                            }}
                                        >
                                            DELIVERY RECEIPT
                                        </h2>
                                        <div style={{ "display": "flex", marginLeft: "60px" }}>
                                            <p
                                                style={{

                                                    fontFamily: 'arial',
                                                    fontWeight: 600,

                                                    fontSize: 10
                                                }}
                                            >
                                                Delivery Date &amp; Time :
                                            </p>
                                            <p
                                                style={{
                                                    fontFamily: 'arial',
                                                    fontWeight: 600,
                                                    color: '#4A5174',
                                                    fontSize: 10
                                                }}
                                            >
                                                {' ' + getCurrentDateWithFormat('/') + " " + manipulateTime(props.customerDetails.time)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderBottom: '1px solid #DFDFDF',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '50%',
                                            justifyContent: 'space-between',
                                            display: 'flex',
                                        }}
                                    >
                                        <div style={{ width: '45%' }}>
                                            <p
                                                style={{
                                                    fontFamily: 'arial',
                                                    marginBottom: 8,
                                                    fontSize: 14,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                RAC Store
                                            </p>
                                            <p
                                                style={{
                                                    display: 'inline',
                                                    fontFamily: 'arial',
                                                    fontWeight: 500,
                                                    lineHeight: '1.8',
                                                    color: '#4A5174',
                                                    fontSize: 12,
                                                }}
                                            >
                                                {`${deliverReceiptInfo.storeInfo.address},`}
                                                <br />
                                                {`${deliverReceiptInfo.storeInfo.city},`}
                                                <br />
                                                {`${deliverReceiptInfo.storeInfo.zip},`}
                                                <br />
                                                {`${deliverReceiptInfo.storeInfo.phoneNumber}`}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%', textAlign: "left", marginLeft: "50%" }}>
                                        <p
                                            style={{
                                                fontFamily: 'arial',
                                                marginBottom: 10,
                                                fontSize: 14,
                                                fontWeight: 700,
                                            }}
                                        >
                                            Customer
                                        </p>
                                        <p

                                            style={{

                                                fontFamily: 'arial',

                                                fontWeight: 600,

                                                lineHeight: '1.8',

                                                color: '#4A5174',

                                                fontSize: 12,

                                                margin: 0,

                                            }}

                                        >

                                            {props?.customerDetails?.customerName}<br />

                                            {props?.customerDetails.addressLine1}<br />

                                            {props?.customerDetails.addressLine2}{props?.customerDetails.addressLine2 ? <br /> : null}

                                            {props?.customerDetails.city + ", " + props?.customerDetails.state + ", " + props?.customerDetails.zip}<br />

                                            {phoneNumberFormat(props?.customerDetails.phoneNumber)}

                                        </p>
                                        {/* <p
                                            style={{
                                                fontFamily: 'arial',
                                                fontWeight: 500,
                                                lineHeight: '1.8',
                                                color: '#4A5174',
                                                fontSize: 12,
                                                margin: 0,
                                            }}
                                        >
                                            {props?.customerDetails?.customerName}
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: 'arial',
                                                fontWeight: 500,
                                                lineHeight: '1.8',
                                                color: '#4A5174',
                                                fontSize: 12,
                                                marginTop: 0,
                                            }}
                                        >
                                            {props.customerDetails?.addressLine1}
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: 'arial',
                                                fontWeight: 500,
                                                lineHeight: '1.8',
                                                color: '#4A5174',
                                                fontSize: 12,
                                                marginTop: '-12PX',
                                            }}
                                        >
                                            {props.customerDetails?.addressLine2 +
                                                ',' +
                                                props.customerDetails?.city + ' ' + props.customerDetails?.zip}
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: 'arial',
                                                fontWeight: 500,
                                                lineHeight: '1.8',
                                                color: '#4A5174',
                                                fontSize: 12,
                                                marginTop: '-12PX',
                                            }}
                                        >
                                            {phoneNumberFormat(props.customerDetails?.phoneNumber)}
                                        </p> */}
                                    </div>
                                </div>
                                <p
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: 'Arial',
                                        fontWeight: 600,
                                        fontSize: 18,
                                    }}
                                >
                                    ITEMS
                                </p>
                                <div style={{ borderBottom: '1px solid #DFDFDF' }}>
                                    <table
                                        style={{
                                            width: '100%',
                                            borderCollapse: 'collapse',
                                            marginBottom: 10,
                                        }}
                                    >
                                        <thead>
                                            <tr
                                                style={{
                                                    width: '100%',
                                                    background: '#EFF4FF',
                                                    WebkitPrintColorAdjust: 'exact',
                                                }}
                                            >
                                                <th
                                                    style={{
                                                        width: "70%",
                                                        textAlign: 'start',
                                                        padding: '10px 6px',
                                                        fontFamily: 'arial',
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    Description
                                                </th>

                                                <th
                                                    style={{
                                                        width: "30%",
                                                        textAlign: 'start',
                                                        padding: '10px 6px',
                                                        fontFamily: 'arial',
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    Model#
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>{deliveryInfoInventoriesInfoBind('ResendVideo')}</tbody>
                                    </table>
                                </div>
                                <p
                                    style={{
                                        fontFamily: 'arial',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        lineHeight: '1.8',
                                    }}
                                >
                                    Before merchandise is taken from the customer's home, please check the condition of the following areas and note any damage:
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: 20,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            style={{ height: 16, width: 16 }}
                                            type="checkbox"
                                            defaultValue=""
                                            id="Walls"
                                            checked={(!props.isManual) ? damageCheckBox.Walls : false}
                                            onClick={() => { { damageCheckBox.Walls ? setdamageCheckbox({ ...damageCheckBox, Walls: false }) : setdamageCheckbox({ ...damageCheckBox, Walls: true }) } }}
                                        />
                                        <label
                                            htmlFor="flexCheckDefault"
                                            style={{
                                                marginLeft: 10,
                                                fontFamily: 'arial',
                                                fontSize: 12,
                                                color: '#4A5174',
                                            }}
                                        >
                                            Wall(s)
                                        </label>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            style={{ height: 16, width: 16 }}
                                            type="checkbox"
                                            defaultValue=""
                                            id="Floors-2"
                                            checked={(!props.isManual) ? damageCheckBox.Floors : false}
                                            onClick={() => { { damageCheckBox.Floors ? setdamageCheckbox({ ...damageCheckBox, Floors: false }) : setdamageCheckbox({ ...damageCheckBox, Floors: true }) } }}
                                        />
                                        <label
                                            htmlFor="flexCheckDefault-2"
                                            style={{
                                                marginLeft: 10,
                                                fontFamily: 'arial',
                                                fontSize: 12,
                                                color: '#4A5174',
                                            }}
                                        >
                                            Floors
                                        </label>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            style={{ height: 16, width: 16 }}
                                            type="checkbox"
                                            defaultValue=""
                                            id="Doorway-3"
                                            checked={(!props.isManual) ? damageCheckBox.Doorway : false}
                                            onClick={() => { { damageCheckBox.Doorway ? setdamageCheckbox({ ...damageCheckBox, Doorway: false }) : setdamageCheckbox({ ...damageCheckBox, Doorway: true }) } }}



                                        />
                                        <label
                                            htmlFor="flexCheckDefault-3"
                                            style={{
                                                marginLeft: 10,
                                                fontFamily: 'arial',
                                                fontSize: 12,
                                                color: '#4A5174',
                                            }}
                                        >
                                            Door(s)/Doorway(s)
                                        </label>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            style={{ height: 16, width: 16 }}
                                            type="checkbox"
                                            defaultValue=""
                                            id="Stairs-4"
                                            checked={(!props.isManual) ? damageCheckBox.Statirs : false}

                                            onClick={() => { { damageCheckBox.Statirs ? setdamageCheckbox({ ...damageCheckBox, Statirs: false }) : setdamageCheckbox({ ...damageCheckBox, Statirs: true }) } }}

                                        />
                                        <label
                                            htmlFor="flexCheckDefault-4"
                                            style={{
                                                marginLeft: 10,
                                                fontFamily: 'arial',
                                                fontSize: 12,
                                                color: '#4A5174',
                                            }}
                                        >
                                            Stairs
                                        </label>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            style={{ height: 16, width: 16 }}
                                            type="checkbox"
                                            defaultValue=""
                                            id="Other-5"
                                            checked={(!props.isManual) ? damageCheckBox.Other : false}
                                            onClick={() => { { damageCheckBox.Other ? setdamageCheckbox({ ...damageCheckBox, Other: false }) : setdamageCheckbox({ ...damageCheckBox, Other: true }) } }}
                                        />
                                        <label
                                            htmlFor="flexCheckDefault-5"
                                            style={{
                                                marginLeft: 10,
                                                fontFamily: 'arial',
                                                fontSize: 12,
                                                color: '#4A5174',
                                            }}
                                        >
                                            Other
                                        </label>
                                    </div>
                                </div>
                                <label
                                    style={{
                                        display: 'block',
                                        fontFamily: 'arial',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        lineHeight: '1.8',
                                    }}
                                    htmlFor="text-area"
                                >
                                    If any Boxes were checked, explain damage:
                                </label>
                                {(props.isManual || damagedescription == '') ? <textarea
                                    rows={3}
                                    style={{ width: '100%', resize: 'none', borderColor: "#cecece" }}
                                    id="text-area"
                                    value={(!props.isManual) ? damagedescription : ""}
                                    defaultValue={''}
                                    maxLength={240}
                                /> :
                                    <p style={{ border: "1px solid #cecece", padding: "5px", "overflowWrap": "break-word" }}>
                                        {damagedescription}
                                    </p>}
                                    
                                <p
                                    style={{
                                        fontFamily: 'arial',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        lineHeight: '1.8',
                                    }}
                                >
                                    Acknowledgement
                                </p>
                                <p>By Signing below, I acknowledge that Acima Leasing (through an affiliate or third party) has delivered and installed (if applicable) to my satisfaction, the leased property listed above.</p>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: 10,
                                    }}
                                >
                                    <div
                                        style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                                    >
                                        <label
                                            style={{
                                                fontFamily: 'arial',
                                                display: 'block',
                                                marginBottom: 20,
                                                fontSize: 14,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Customer Signature
                                        </label>
                                        <div style={{ marginBottom: 10, textAlign: 'center' }}>
                                            {!deliverReceiptInfo.customerSigned ? (
                                                <div>
                                                    <button
                                                        style={{
                                                            WebkitPrintColorAdjust: 'exact',
                                                            backgroundColor:
                                                                '#2468FF',
                                                            color: '#fff',
                                                            fontFamily: 'arial',
                                                            border: 0,
                                                            padding: '10px 20px',
                                                            borderRadius: 5,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            addSignatureClick('Customer');
                                                        }}
                                                        hidden={props.isManual}
                                                    >
                                                        Click here to add Signature
                                                    </button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <img className={classes?.digitalSigantureImg} src={customerSignContent} style={{ width: "100%" }} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ width: "20%" }}></div>
                                    <div
                                        style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                                    >
                                        <label
                                            style={{
                                                fontFamily: 'arial',
                                                display: 'block',
                                                marginBottom: 20,
                                                fontSize: 14,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Co-Worker Signature
                                        </label>
                                        <div style={{ marginBottom: 10, textAlign: 'center' }}>
                                            {!deliverReceiptInfo.coWorkerSigned ? (
                                                <div>
                                                    <button
                                                        style={{
                                                            WebkitPrintColorAdjust: 'exact',
                                                            backgroundColor: '#2468FF',
                                                            color: '#fff',
                                                            fontFamily: 'arial',
                                                            border: 0,
                                                            padding: '10px 20px',
                                                            borderRadius: 5,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            console.log("I am called the developent ")
                                                            addSignatureClick('CoWorker');
                                                        }}
                                                        hidden={props.isManual}
                                                    // disabled={
                                                    //     acknowledgeInputs.acceptChecked ||
                                                    //         acknowledgeInputs.declineChecked
                                                    //         ? false
                                                    //         : true
                                                    // }

                                                    >
                                                        Click here to add Signature
                                                    </button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <img className={classes?.digitalSigantureImg} src={coworkerSignContent} style={{ width: "100%" }} />
                                                </div>)}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '40%' }}>
                                        <div style={{ marginBottom: 10 }}>
                                            <label
                                                style={{
                                                    fontFamily: 'arial',
                                                    fontSize: 14,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Name of the person signed :
                                            </label>
                                            <label
                                                style={{
                                                    fontFamily: 'arial',
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: '#4A5174',
                                                }}
                                            >
                                                {' ' + props.customerDetails.customerName}
                                            </label>
                                        </div>
                                        <div style={{ marginBottom: 10 }}>
                                            <label
                                                style={{
                                                    fontFamily: 'arial',
                                                    fontSize: 14,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Date :
                                            </label>
                                            <label
                                                style={{
                                                    fontFamily: 'arial',
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: '#4A5174',
                                                }}
                                            >
                                                {' ' + getCurrentDateWithFormat('/')}
                                            </label>
                                        </div>
                                    </div>
                                    <div style={{ width: "20%" }}></div>
                                    <div style={{ width: '40%' }}>
                                        <label
                                            style={{
                                                fontFamily: 'arial',
                                                fontSize: 14,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Date :
                                        </label>
                                        <label
                                            style={{
                                                fontFamily: 'arial',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#4A5174',
                                            }}
                                        >
                                            {' ' + getCurrentDateWithFormat('/')}
                                        </label>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Grid
                        item
                        md={12}
                        className={`${classes.pb4} ${classes.px3} ${classes.textRight}`}
                        style={{ marginTop: '100px' }}
                    >
                        <RACButton
                            color="primary"
                            variant="outlined"
                            className={classes.mx1}
                            // onClick={() => deliveryReceiptCancelClick()}
                            onClick={() => {
                                setIsDeliveryReceiptOpen(false);
                                setCoworkerSignContent('')
                                setCustomerSignContent('')
                                setDeliverReceiptInfo({ ...deliverReceiptInfo, "coWorkerSigned": false, "customerSigned": false })
                                setNameOfThePersonsigned("");
                                setDamagedescription("");
                                setOtherPersonCheck(false)
                            }}
                        >
                            Cancel
                        </RACButton>
                        <RACButton
                            variant="contained"
                            color="primary"
                            className={classes.mx1}
                            disabled={customerSignContent && coworkerSignContent ? false : true}
                            onClick={() => deliveryReceiptContinueClick()}
                        >
                            Continue
                        </RACButton>
                    </Grid>
                </div>
                {/*Delivery status */}


                {/* Delivery Checklist Html End Here */}
                <button hidden onClick={() => deliveryReceiptContinueClick()}
                    id='CheckButtonForMannual' ></button>
            </Grid>
        </>
    );
}