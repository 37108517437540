/* eslint-disable */
import React, { useState } from 'react';
import { WeekPicker } from '@rentacenter/legacyracstrap';
import { add, startOfWeek, sub } from 'date-fns';

import styles from './Goals.module.scss';

import { StoreGoals } from './StoreGoals/StoreGoals';
import { SalesGoals } from './SalesGoals/SalesGoals';
import { CollectionGoals } from './CollectionGoals/CollectionGoals';

export const goalsTestId = 'goalsTestId';

export const Goals = () => {
  const today = new Date();
  const [week, setWeek] = useState<Date>(startOfWeek(today));

  return (
    <div data-testid={goalsTestId} className={styles.goals}>
      <WeekPicker
        className={`${styles.weekPicker} ${styles.custweekpicker}`}
        name="weekPicker"
        datePickerProps={{
          maxDate: add(today, { years: 1 }),
          minDate: sub(today, { years: 1 })
        }}
        value={week}
        onChange={setWeek}
      />

      <div className={styles.section}>
        <h3>Store Goals</h3>
        <StoreGoals startDate={week} />
      </div>

      <div className={styles.section}>
        <h3>Sales Goals</h3>
        <SalesGoals startDate={week} />
      </div>

      <div className={styles.section}>
        <h3>Collection Goals</h3>
        <CollectionGoals startDate={week} />
      </div>
    </div>
  );
};
