/* eslint-disable */
import { endOfWeek, startOfWeek } from 'date-fns';
import { CancelToken } from 'axios';

import { client } from './client';
import { ensureNumber, formatDate } from './utils';

import { MappedGoals,
  SalesGoal,
  SetSalesGoalResponse,
  StoreGoal,
  StoreGoalResponse,
  StoreGoalUpdate,
  StoreMetricType

 } from '../domain/Calendar/Goal';
import { getEmployeeList } from './tasks';
import { Employee } from '../domain/User/Employee';

export const parseGoalResponse = (
  response: StoreGoalResponse,
  metricType: StoreMetricType
): StoreGoal => {
  return {
    ...response,
    metricType,
    metricGoal: parseInt(response.metricGoal, 10) || 0,
    metricCount: parseInt(response.metricCount, 10) || 0,
    targetGoal: parseInt(response.targetGoal, 10) || 0
  };
};

export const getGoal = async (
  storeNumber: string,
  startDate: Date,
  metricType: StoreMetricType,
  isExactDate: boolean,
  cancelToken?: CancelToken
): Promise<StoreGoal> => {
  const endDate = isExactDate ? startDate : endOfWeek(startDate);
  const response: StoreGoalResponse = await client(
    `stores/${storeNumber}/storeGoals`,
    {
      method: 'GET',
      params: {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        metricType
      },
      cancelToken,
      
    },
  );

  return parseGoalResponse(response, metricType);
};

export const getGoals = async (
  storeNumber: string,
  startDate: Date,
  cancelToken?: CancelToken
): Promise<MappedGoals> => {
  const deliveries = await getGoal(
    storeNumber,
    startDate,
    StoreMetricType.Deliveries,
    false,
    cancelToken
  );

  const aor = await getGoal(
    storeNumber,
    startDate,
    StoreMetricType.AOR,
    false,
    cancelToken
  );

  const customerGain = await getGoal(
    storeNumber,
    startDate,
    StoreMetricType.CustomerGain,
    false,
    cancelToken
  );

  return {
    [StoreMetricType.Deliveries]: deliveries,
    [StoreMetricType.AOR]: aor,
    [StoreMetricType.CustomerGain]: customerGain,
    [StoreMetricType.DeliveriesCoreStoreWeekly]: {} as StoreGoal
  };
};

export const getGoalsCoreStore = async (
  storeNumber: string,
  storeGoalsStartDate: Date,
  cancelToken?: CancelToken
): Promise<MappedGoals> => {
  const [dailyDeliveries, weeklyDeliveries] = await Promise.all([
    getGoal(
      storeNumber,
      storeGoalsStartDate,
      StoreMetricType.Deliveries,
      true,
      cancelToken
    ),
    getGoal(
      storeNumber,
      startOfWeek(storeGoalsStartDate),
      StoreMetricType.Deliveries,
      false,
      cancelToken
    )
  ]);

  return {
    [StoreMetricType.DeliveriesCoreStoreWeekly]: weeklyDeliveries,
    [StoreMetricType.Deliveries]: dailyDeliveries,
    [StoreMetricType.AOR]: {} as StoreGoal,
    [StoreMetricType.CustomerGain]: {} as StoreGoal
  };
};

export const setStoreGoal = async (
  storeNumber: string,
  startDate: Date,
  metricType: StoreMetricType,
  metricGoal: string
): Promise<StoreGoalResponse> => {
  const endDate = endOfWeek(startDate);

  return client(`stores/${storeNumber}/storeGoals`, {
    method: 'POST',
    body: {
      metricGoalDates: {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      },
      metricType,
      metricGoal
    }
  });
};

export const getCollectionGoals = async (
  storeNumber: string,
  startDate: Date,
  cancelToken?: CancelToken
) => {
  const endDate = endOfWeek(startDate);
  return client(`stores/collections`, {
    method: 'GET',
    params: {
      storeNumbers: storeNumber,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    },
    cancelToken
  });
};

export const updateStoreGoal = async (
  storeNumber: string,
  goalId: string,
  payload: StoreGoalUpdate
): Promise<StoreGoalResponse> =>
  client(`stores/${storeNumber}/storeGoals/${goalId}`, {
    method: 'PUT',
    body: payload
  });

export const getSalesGoals = async (
  storeNumber: string,
  startDate: Date,
  cancelToken: CancelToken
): Promise<SalesGoal[]> => {
  const endDate = endOfWeek(startDate);

  const employees: { employeeInfoList: Employee[] } = await getEmployeeList(
    storeNumber,
    cancelToken
  );

  const goals: SalesGoal[] = await client(
    `stores/${storeNumber}/salesGoalsMetrics`,
    {
      method: 'GET',
      params: {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      },
      cancelToken
    }
  );

  // const response = new Promise<SalesGoal[]>(resolve => {
  //   setTimeout(() => {
  //     resolve([
  //       new SalesGoalMock().setActual(0).setVariance(0),
  //       new SalesGoalMock()
  //         .setGoal(10)
  //         .setActual(5)
  //         .setVariance(5),
  //       new SalesGoalMock()
  //         .setGoal(20)
  //         .setActual(5)
  //         .setVariance(15)
  //     ]);
  //   }, 1000);
  // });

  employees.employeeInfoList.sort(
    (a, b) =>
      a.firstName.localeCompare(b.firstName) ||
      a.lastName.localeCompare(b.lastName)
  );

  return employees.employeeInfoList.map((employee: Employee) => {
    const foundGoal = goals.find(goal => goal.coworkerId === employee.userId);

    if (foundGoal) {
      foundGoal.goal = ensureNumber(foundGoal.goal);
      foundGoal.actual = ensureNumber(foundGoal.actual);
      foundGoal.variance = ensureNumber(foundGoal.variance);
    }

    return {
      storeNumber: storeNumber,
      coworkerFirstName: employee.firstName,
      coworkerLastName: employee.lastName,
      coworkerId: employee.userId,
      goal: foundGoal?.goal || 0,
      actual: foundGoal?.actual || 0,
      variance: foundGoal?.variance || 0,
      storeMetricGoalId: foundGoal?.storeMetricGoalId
    };
  });
};

export const setSalesGoal = async (
  storeNumber: string,
  coworkerId: string,
  metricGoal: string,
  startDate: Date,
  goalId?: string
): Promise<SetSalesGoalResponse> => {
  const endDate = endOfWeek(startDate);

  let url;
  let method;

  if (goalId) {
    url = `stores/${storeNumber}/salesGoals/${goalId}`;
    method = 'PUT';
  } else {
    url = `stores/${storeNumber}/salesGoals`;
    method = 'POST';
  }

  return await client(url, {
    method,
    body: {
      metricGoal,
      coworkerId,
      metricGoalDates: {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      }
    }
  });
};
