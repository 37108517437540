/* eslint-disable */
import { NumericInput } from '@rentacenter/legacyracstrap';
import React, { HTMLProps } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { SalesGoal } from '../../../../../../domain/Calendar/Goal';
import { GoalFormButtons } from '../../../common/GoalFormButtons/GoalFormButtons';

import styles from './SalesValueBox.module.scss';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { validationHandler } from '../../../StoreGoals/StoreGoalCard/StoreGoalForm/StoreGoalForm';

export const salesValueBoxTestId = 'salesValueBoxTestId';
export const salesGoalFormTestId = 'salesGoalFormTestId';
export const salesGoalTooltipTestId = 'salesGoalTooltipTestId';
export interface SalesFormData {
  goal: string;
}

export interface SalesValueBoxProps extends HTMLProps<HTMLDivElement> {
  value?: number;
  title: string;
  editMode?: boolean;
  pending?: boolean;
  onCancel?: () => void;
  onSave?: (formData: SalesFormData) => void;
  goal?: SalesGoal;
  showZero?: boolean;
}

// eslint-disable-next-line
export const SalesValueBox = (props: SalesValueBoxProps) => {
  console.log("onSaveonSave", props.onSave)
  console.log("editModeeditMode",props.editMode)
  console.log("SalesValueBoxprops",props)
  const {
    value,
    title,
    editMode,
    onCancel,
    showZero,
    goal,
    onSave,
    pending,
    ...rest
  } = props;

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm<SalesFormData>({
    mode: 'onSubmit',
    defaultValues: {
      goal: value === 0 ? '' : String(value)
    }
  });

  const renderValue = () => {
    let colorClass;

    if (value !== undefined && goal?.goal !== undefined) {
      if (value === 0) {
        colorClass = styles.green;
      } else if (value <= goal.goal / 2) {
        colorClass = styles.orange;
      } else {
        colorClass = styles.red;
      }
    }

    const isEditable = !!onCancel;

    if (!value && isEditable) {
      return <span className={styles.empty}>-</span>;
    }

    return <span className={colorClass}>{value}</span>;
  };

  return (
    <div
      className={styles.salesValueBox}
      data-testid={salesValueBoxTestId}
      {...rest}
    >
      <div className={styles.title}>
        {title}{' '}
        {editMode && (
          <Tooltip
            data-testid={salesGoalTooltipTestId}
            arrow
            placement="bottom-start"
            title="Value should be between 1 and 999"
          >
            <span>
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </Tooltip>
        )}
      </div>
      <div className={styles.value}>
        {editMode && onSave ? (
          <form
            id={salesGoalFormTestId}
            className={styles.salesGoalForm}
            onSubmit={handleSubmit(onSave)}
          >
            <GoalFormButtons
              pending={pending}
              onSave={handleSubmit(onSave)}
              onCancel={onCancel}
            />
            <Controller
              control={control}
              name="goal"
              rules={{
                required: 'This field is required.',
                validate: validationHandler
              }}
              render={({ ref, onChange, value, ...rest }, { invalid }) => (
                <NumericInput
                  size="small"
                  invalid={invalid}
                  className={styles.custFormInput}
                  disabled={pending}
                  onChange={(e: any) => {
                    clearErrors('goal');
                    onChange(e);
                  }}
                  value={value}
                  required
                  errorMessage={errors?.goal?.message}
                  options={{
                    decimalPlaces: 0,
                    minimumValue: 0,
                    maximumValue: 999
                  }}
                  {...rest}
                />
              )}
            />



          </form>
        ) : (
          renderValue()
        )}
      </div>
    </div>
  );
};
