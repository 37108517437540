/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useContext } from 'react';
import {
    Card,
    Grid,
    CardContent,
    ListItem,
    List,
    Box,
} from '@rentacenter/racstrap';
import { customerStyles } from './customerStyles';
import { StoreMgmtContext } from '../../context/storeMgmtContext';
import { ManageCustomerStyles } from './ManageCustomerStyles';
enum tabList {
    CALENDAR,
    GOALS
}
function Tab(props: any) {
    const customerStyleClasses = customerStyles();
    const classes = ManageCustomerStyles();
    const { TaskandGoals, goals } = useContext(StoreMgmtContext)

    const [activetab, setactivetab] = useState(
        {
            calender: true,
            tasks: false,
            goals: false
        }

    );

    const { stateC, setTab } = props;

    return (
        <Grid container>
            <Card
                className={`${customerStyleClasses.card} ${customerStyleClasses.w100} ${customerStyleClasses.mb3} ${customerStyleClasses.borderRadiusZero}`}
            >
                <CardContent
                    className={`${classes.headerTab} ${customerStyleClasses.floatLeft} ${customerStyleClasses.w100} ${classes.py0}`}
                >
                    <Grid
                        item
                        className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.w100}`}
                    >
                        <Box className={customerStyleClasses.floatLeft}>
                            <List
                                className={`${classes.racpadTab} ${classes.py0}`}
                                id="CustTab"
                                role="tabList"
                            >
                                <ListItem
                                    className={
                                        activetab.calender
                                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                                            : `${classes.navLinkItem} ${classes.p3}`
                                    }
                                    id="Customerinfo-tab"
                                    data-testid="customerInfoTab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#customerinfo"
                                    role="tab"
                                    aria-controls="Customerinfo-tab"
                                    aria-selected="true"
                                    onClick={() => { setactivetab({ ...activetab, calender: true, tasks: false, goals: false }); setTab('calender') }}                                 >
                                    Calender
                                </ListItem>

                                {TaskandGoals == '1' ? <ListItem
                                    className={
                                        activetab.tasks
                                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                                            : `${classes.navLinkItem} ${classes.p3}`
                                    }
                                    id="Customerinfo-tab"
                                    data-testid="customerInfoTab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#customerinfo"
                                    role="tab"
                                    aria-controls="Customerinfo-tab"
                                    aria-selected="true"
                                    onClick={() => { setactivetab({ ...activetab, calender: false, tasks: true, goals: false }); setTab('Task') }}                                 >
                                    Tasks
                                </ListItem> : null}
                                {goals == '1' ? <ListItem
                                    className={
                                        activetab.goals
                                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                                            : `${classes.navLinkItem} ${classes.p3}`
                                    }
                                    id="Customerinfo-tab"
                                    data-testid="customerInfoTab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#customerinfo"
                                    role="tab"
                                    aria-controls="Customerinfo-tab"
                                    aria-selected="true"
                                    onClick={() => { setactivetab({ ...activetab, calender: false, tasks: false, goals: true }); setTab('Goals') }}>
                                    Goals
                                </ListItem> : null}

                            </List>
                        </Box>

                    </Grid>
                </CardContent>
            </Card>
        </Grid>)
}

export default Tab;