/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    RACButton,
    RACSelect,
    RACTextbox,
    RACDatePicker,
    List,
    ListItem,
    CircularProgress,
    RACModalCard,
} from '@rentacenter/racstrap';
import { addDays } from 'date-fns';
import moment from 'moment';
import clsx from 'clsx';
import { getTimeSlot, CoworkerRequired, postAppointment, manageAppointment } from '../../api/user';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { agreementGlobalStyles } from './DAPStyles';
import Modal from 'react-modal';
// let prevEl: any, currEl: any = ''
export default function StoreEvent() {
    const history = useHistory();
    const historyData: any = history.location.state

    let BindtimeslotDate = '';

    const classes = agreementGlobalStyles();
    const [data, setData] = useState<any>({
        EventTitle: '',
        Time: '',
        Description: '',
    });
    const [dropDown, setDropDown] = useState<any>({
        scheduleArray: '1',
        coWorkerArray: '1',
    });
    const [Color, setColor] = useState(`${classes.blue}`);
    const [timeSlotData, SettimeSlotData]: any = useState([]);
    const [BindtimeslotId, setBindtimeslotId] = useState('');
    const [ContextStartDate, SetContextStartDate] = useState('');
    const [ContextEndDate, SetContextEndDate] = useState('');
    const [Time, SetTime] = useState('');
    const [masterLoader, setmasterLoader] = React.useState(false);
    const [timeslotid, Settimeslotid] = useState('');
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    // const [SaveTrigger, SetSaveTrigger] = useState(false);
    const [AppoinmentSource, setAppoinmentSource]: any = React.useState('');
    const [Apierror, setApierror] = React.useState(false);
    const [ApierrorContent, setApierrorContent] = React.useState('');
    const [errorMsg, setErrorMsg] = useState<boolean>(false);
    const [coWorkerRequiredArray, setcoWorkerRequiredArray]: any = useState([]);
    const [conditionLoader, SetconditionLoader] = useState(true);
    const [Coworkers, SetCoworkers]: any = useState('');
    const storeNumber = window.sessionStorage.getItem('storeNumber');
    const [loader, setloader] = useState(false);
    const [paramValue, setparamValue] = useState<any>('');
    const [currentBtnClass, setCurrentBtnClass] = useState<any>('');



    const handleInputChange = (e: any) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    useEffect(() => {
        // getEventFnc();
        const queryParams = new URLSearchParams(window.location.search);

        // Get the value of a specific parameter
        const queryparamValue = queryParams.get('editevent');
        setparamValue(queryparamValue)
        if (queryparamValue) {
            setData({
                EventTitle: historyData?.appointmentInfo?.title || '',
                Time: '',
                Description: historyData?.appointmentInfo?.instructions || ''
            })
            const startTime = moment(historyData.timeSlotInfo.startTime, 'HH:mm:ss').format('hh:mm A');
            const endTime = moment(historyData.timeSlotInfo.endTime, 'HH:mm:ss').format('hh:mm A');

            SetTime(startTime + '-' + endTime)
            SetCoworkers(historyData.appointmentInfo.requiredCoworkers || '1')
            setStartDate(moment(historyData.timeSlotInfo.startTimeStamp).format('YYYY-MM-DD'),)
        }
        getCoworkerRequiredData();
        GetTimeSlot(moment().format('YYYY/MM/DD'));
    }, []);

    const handleSave = async () => {
        try {
            const payloadToCreateSE: any = {
                storeNumber: storeNumber,
                eventInformation: {
                    isLoaner: 'Y',
                    loanerCreate: {
                        storeNumber: storeNumber,
                        timeSlot: timeslotid,
                        appointmentInfo: {
                            appointmentType: 'SE',
                            appointmentSource: AppoinmentSource,
                            appointmentDate: startDate,
                            instructions: data.Description,
                            requiredCoworkers: Coworkers,
                            title: data.EventTitle
                        },
                    },
                },
            };
            setloader(true);
            let CreateEvent: any, updateEvent: any = ''
            if (paramValue) {
                const payloadToUpdateSE: any = {
                    appointmentId: historyData.appointmentInfo.appointmentId,
                    storeNumber: storeNumber,
                    timeSlot: timeslotid,
                    appointmentInfo: {
                        appointmentType: 'SE',
                        appointmentSource: AppoinmentSource,
                        appointmentDate: startDate,
                        instructions: data.Description,
                        requiredCoworkers: Coworkers,
                        title: data.EventTitle
                    },
                };
                updateEvent = await manageAppointment(payloadToUpdateSE);
            } else {
                CreateEvent = await postAppointment(payloadToCreateSE);
            }
            // console.log(CreateEvent.data);
            setloader(false);
            if (CreateEvent?.status == 200 || updateEvent.status == 200) {
                history.push({ pathname: `/storemgmt/dap` });
            } else if (CreateEvent?.status == 400 || updateEvent.status == 400) {
                setApierror(true);
                setApierrorContent('Something went wrong');
            } else {
                setErrorMsg(true);
            }
        } catch (e: any) {
            setApierrorContent('Something went wrong');
            setloader(false);
            setApierror(true);
        }
    };

    const ApiErrorPopup = () => {
        return (
            <div>
                <Grid item md={12} className={classes.textCenter}>
                    <AlertIcon></AlertIcon>
                    <Typography className={`${classes.popupText} ${classes.mt3}`}>
                        {ApierrorContent}
                    </Typography>
                </Grid>
                <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => setApierror(false)}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );
    };
    const noRecordPopup = () => {
        return (
            <Grid>
                <Grid>
                    <Grid className={classes.textCenter}>
                        <AlertIcon />
                        <Typography> Something Went Wrong.</Typography>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                    >
                        <RACButton
                            className={classes.mx1}
                            variant="contained"
                            color="primary"
                            onClick={() => setErrorMsg(false)}
                        >
                            Ok
                        </RACButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const CoworkerOptionsArray = () => {
        const coworkerOptions: any = []
        if (coWorkerRequiredArray !== undefined) {
            return coWorkerRequiredArray.map((entry: any, index: any) => {
                if (index == 0) {
                    coworkerOptions.push({ label: 'Select', value: '0' })
                }
                const some: any = entry.toString();
                if (entry == 'Select') {
                    coworkerOptions.push({ label: entry, value: '1' });
                } else {
                    coworkerOptions.push({ label: entry, value: some });
                }
            });
        } else {
            coworkerOptions.push({ label: 'Select', value: '0' });
        }
        return coworkerOptions
    };
    const getCoworkerRequiredData = async () => {
        const bodyobject = {
            storeNumbers: [storeNumber],

            paramKeyNames: ['CoworkerRequired'],
        };
        const CoworkerNum = await CoworkerRequired(bodyobject);
        if (CoworkerNum.status === 200) {
            const value =
                CoworkerNum.data !== null &&
                    CoworkerNum.data.storeProfileResponse !== undefined &&
                    CoworkerNum.data.storeProfileResponse &&
                    CoworkerNum.data.storeProfileResponse.configDetails !== undefined &&
                    CoworkerNum.data.storeProfileResponse.configDetails.length > 0 &&
                    CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails !==
                    undefined &&
                    CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails
                        .length > 0
                    ? CoworkerNum.data.storeProfileResponse.configDetails[0]
                        .configDetails[0].paramValue
                    : 0;

            // eslint-disable-next-line no-console
            console.log(value);
            const integervalue = parseInt(value);
            const arrayNumber: any = [{ label: 'Select', value: 'SELECT' }];

            for (let i = 0; i <= integervalue; i++) {
                arrayNumber.push({ label: String(i), value: String(i) });
            }
            // eslint-disable-next-line no-console
            console.log('ArrayNumber', arrayNumber);
            setcoWorkerRequiredArray(arrayNumber);
            SetconditionLoader(false);
        } else {
            const arrayNumber: any = [{ label: 'Select', value: 'SELECT' }];
            setcoWorkerRequiredArray(arrayNumber);
            SetconditionLoader(false);
        }
    };

    const changeDateFormat = (date: Date) => {
        // eslint-disable-next-line prefer-const
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            // eslint-disable-next-line prefer-const
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    };
    const Timebind = (event: any) => {
        SetTime(moment(event.target.value).format('hh:mm A'));
        // SetSaveTrigger(false);
    };
    const dates = async (selectedFormatdate: any) => {
        SettimeSlotData([]);
        setStartDate(moment(selectedFormatdate).format('YYYY-MM-DD'));
        const startDate = selectedFormatdate;
        // if (props.triggerdateChaged) {
        //   props.triggerdateChaged();
        // }
        GetTimeSlot(startDate);
        SetTime('');
        Settimeslotid('');
        console.log('beoefevcdvbh', BindtimeslotId);
        setBindtimeslotId('');
    };
    function* numCount() {
        let count = 99;
        while (true) yield count++; /*  ww w  .  j av a2s.co  m*/
    }
    const irt = numCount();
    const GetTimeSlot = async (StartingDate: any) => {
        const startingdate = new Date(StartingDate);
        const endingdate = addDays(startingdate, 9);
        console.log(endingdate);
        const formattedstartingdate = changeDateFormat(startingdate);
        const formattedenddate = changeDateFormat(endingdate);
        console.log('DAtsssssssrefd', formattedenddate);
        const payload = {
            isLoaner: 'Y',
            customerId: '108348922',
            endDate: formattedenddate,
            eventSource: 'STO',
            startDate: formattedstartingdate,
            storeId: sessionStorage.getItem('storeNumber'),
        };
        const timeslotresponse = await getTimeSlot(payload);
        if (timeslotresponse.data !== undefined) {
            console.log('timeslotresponse', timeslotresponse);

            setAppoinmentSource(timeslotresponse.data.appointmentSource);
            // SetTimeslotReason(timeslotresponse);
            const TimeSlotsDetailsArrayValue: any = [];
            interface TimeSlotDetails {
                timeSlot: string;
                startTime: string;
                endTime: string;
                status: string;
            }

            interface TimeSlotDetailsArray {
                Date: string;
                TimeSlots: TimeSlotDetails;
            }

            const TimeSlotVariable = timeslotresponse.data.timeSlots;
            const sorted: any = Object.keys(TimeSlotVariable)
                .sort(function (a: any, b: any) {
                    const date1: any = new Date(a);
                    const date2: any = new Date(b);
                    return date1 - date2;
                })
                .reduce((accumulator: any, key: any) => {
                    accumulator[key] = TimeSlotVariable[key];

                    return accumulator;
                }, {});
            Object.keys(sorted).forEach(function (key) {
                const value = sorted[key];
                //  console.log(key + ':' + value);
                const Timeslots: any = [];

                value.map((value: any) => {
                    const timeslot_object = {} as TimeSlotDetails | any;
                    timeslot_object.timeSlot = value.timeSlot;
                    timeslot_object.startTime = value.startTime;
                    timeslot_object.endTime = value.endTime;
                    timeslot_object.status = value.status;
                    timeslot_object.id = irt?.next()?.value
                    // 
                    Timeslots.push(timeslot_object);
                });

                const TotalTimeSlots = {} as TimeSlotDetailsArray;
                TotalTimeSlots.Date = key;
                TotalTimeSlots.TimeSlots = Timeslots;
                TimeSlotsDetailsArrayValue.push(TotalTimeSlots);
            });
            SettimeSlotData(TimeSlotsDetailsArrayValue);
            console.log(TimeSlotsDetailsArrayValue, 'timeslot converted array');
        }
        console.log(timeslotresponse.data, 'testingresponsetimeslot');
    };
    const getslotdetails = (
        avail: any,
        modifiedStarttime: any,
        modifiedEndtime: any,
        uniqueItem?: any
    ) => {

        // if(!prevEl){
        //     const prevEls:any = document.getElementById(avail.target.id)
        // prevEls.style.backgroundColor = "blue";
        // // }else{
        //     currEl = document.getElementById(avail.target.id)
        //     prevEl = currEl;
        //     currEl.style.backgroundColor = "blue";

        // }
        console.log('ContextStart', modifiedStarttime);
        console.log('ContextEnd', modifiedEndtime);
        console.log('avail', avail);
        setColor(`${classes.blue}`);
        SetContextStartDate(modifiedStarttime);
        SetContextEndDate(modifiedEndtime);
        SetTime(avail.target.value);
        Settimeslotid(avail.target.id);
        setBindtimeslotId(avail.target.id);
        setCurrentBtnClass(uniqueItem)
        // BindtimeslotId = avail.target.id;
        BindtimeslotDate = avail.target.name;
        setStartDate(moment(BindtimeslotDate).format('YYYY-MM-DD'));
        buildTimeSlot();
        // SetSaveTrigger(false);
    };

    const returnBGColor = (id: any) => {

        return currentBtnClass == id ? 'blue' : 'white'
        // return 'whilte'
    }
    const timeslotbind = (objdate: any) => {
        // var itr = 0;
        if (objdate.TimeSlots != undefined) {
            return objdate.TimeSlots.map((objtime: any) => {

                // itr += 1;
                const modifiedStarttime = moment(objtime.startTime, 'HH:mm:ss').format('hh:mm A');
                const modifiedEndtime = moment(objtime.endTime, 'HH:mm:ss').format('hh:mm A');
                const slicedTimeslot =
                    modifiedStarttime.slice(0, 5) +
                    modifiedStarttime.slice(8, modifiedStarttime.length);

                const Currently = new Date();
                const todayDate = String(Currently.getDate()).padStart(2, '0');
                const todayMonth = String(Currently.getMonth() + 1).padStart(2, '0'); //January is 0!
                const todayYear = Currently.getFullYear();

                const todaysDate = todayMonth + '/' + todayDate + '/' + todayYear;

                if (todaysDate == objdate.Date || todaysDate > objdate.Date) {
                    const startTime: any = objtime.startTime;
                    const hoursdisplayed = startTime.split(':')[0];
                    const minutesdisplayed = startTime.split(':')[1];
                    const displayedTime = hoursdisplayed + ':' + minutesdisplayed;
                    console.log(displayedTime);

                    const Currently = new Date();
                    const TimeatPresent = new Date(Currently.getTime());
                    const currentHours = TimeatPresent.getHours();
                    console.log(currentHours);
                    const currentMinutes = TimeatPresent.getMinutes();
                    console.log(currentMinutes);
                    const Currenttime = currentHours + ':' + currentMinutes;
                    console.log(Currenttime);

                    if (objtime.status !== 'Available') {
                        let slotColor = '';

                        if (
                            BindtimeslotId !== '' &&
                            BindtimeslotId == objtime.timeSlot &&
                            BindtimeslotDate !== '' &&
                            BindtimeslotDate == objdate.Date
                        ) {
                            slotColor = classes.slotBooked;
                        } else {
                            slotColor = classes.slotBusy;
                        }
                        return (
                            //
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={`${slotColor} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}
                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                                }
                                disabled={true}
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    } else if (
                        todaysDate > objdate.Date ||
                        (todaysDate == objdate.Date && displayedTime <= Currenttime)
                        // eslint-disable-next-line sonarjs/no-duplicated-branches
                    ) {
                        let slotColor = '';

                        if (
                            BindtimeslotId !== '' &&
                            BindtimeslotId == objtime.timeSlot &&
                            BindtimeslotDate !== '' &&
                            BindtimeslotDate == objdate.Date
                        ) {
                            slotColor = classes.slotBooked;
                        } else {
                            slotColor = classes.slotBusy;
                        }
                        return (
                            //
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={`${slotColor} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}

                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                                }
                                disabled={true}
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    } else {
                        let slotColor = '';

                        if (
                            BindtimeslotId !== '' &&
                            BindtimeslotId == objtime.timeSlot &&
                            BindtimeslotDate !== '' &&
                            BindtimeslotDate == objdate.Date
                        ) {
                            slotColor = classes.slotBooked;
                        } else {
                            slotColor = classes.slotAvailable;
                        }
                        return (
                            //
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={`${slotColor} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}

                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                                }
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    }
                } else {
                    if (objtime.status !== 'Available') {
                        return (
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={`${classes.slotBusy} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}

                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                                }
                                disabled={true}
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    } else {
                        let slotColor = '';
                        if (
                            BindtimeslotId !== '' &&
                            BindtimeslotId == objtime.timeSlot &&
                            BindtimeslotDate !== '' &&
                            BindtimeslotDate == objdate.Date
                        ) {
                            slotColor = classes.slotBooked;
                        } else {
                            slotColor = classes.slotAvailable;
                        }
                        return (
                            //
                            <button
                                type="button"
                                name={objdate.Date}
                                value={slicedTimeslot + '-' + modifiedEndtime}
                                id={objtime.timeSlot}
                                className={`${slotColor} ${objtime.id == currentBtnClass ? classes.currentTimeSlotBooking : classes.currentTimeSlotCanceling}`}

                                onClick={(e) =>
                                    getslotdetails(e, modifiedStarttime, modifiedEndtime, objtime.id)
                                }
                            >
                                {slicedTimeslot} - {modifiedEndtime}
                            </button>
                        );
                    }
                }
            });
        }
    };

    const formatDate = (dates: any) => {
        // 
        // const dateFormat = new Date(dates);
        // return (
        //     dateFormat.toString().slice(0, 3) +
        //     ', ' +
        //     dateFormat.toString().slice(4, 10)
        // );
        const dateFormat = moment(dates);
        return dateFormat.format('ddd, MMM DD');
    };

    const buildTimeSlot = () => {
        if (timeSlotData != undefined && timeSlotData.length > 0) {
            let slotNumbers = 0;
            return timeSlotData.map((objdate: any) => {
                if (
                    objdate.TimeSlots != null &&
                    objdate.TimeSlots != '' &&
                    slotNumbers < 5
                ) {
                    slotNumbers++;
                    const formattedDate = formatDate(objdate.Date);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <Grid item className={classes.agrTimeSlot}>
                            <Typography
                                className={`${classes.semiBold} ${classes.font14} ${classes.MarginDate}`}
                            >
                                {formattedDate}
                            </Typography>
                            <Grid className={classes.agrListGroup}>
                                {timeslotbind(objdate)}
                            </Grid>
                        </Grid>
                        // </div>
                        // </div>
                        // </div>
                    );
                }
            });
        } else {
            return (
                <div className={classes.Norecords}>
                    <CircularProgress />
                </div>
            );
        }
    };
    return (
        <>
            <div>
                <Modal
                    isOpen={loader}
                    className={classes.loaderStyle}
                    id="openpopup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-hidden="true"
                >
                    <Grid>
                        <Grid>
                            <Grid className={classes.RACLoaderPage}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
                <Grid>
                    <List className={`${classes.breadcrumb}`}>
                        <ListItem
                            className={`${classes.breadcrumbItemActive}`}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                history.push({
                                    pathname: `/dashboard`,
                                });
                            }}
                        >
                            Dashboard
                        </ListItem>
                        <ListItem
                            className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.breadcrumbItemActive} `}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                history.push({
                                    pathname: `/storemgmt/dap`,
                                });
                            }}
                        >
                            DAP Events
                        </ListItem>
                        <ListItem
                            className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}  `}
                            style={{ cursor: 'pointer' }}
                        >
                            {paramValue ? 'Edit' : 'Create'} Store Event
                        </ListItem>
                    </List>
                </Grid>
                <Grid>
                    <Typography
                        variant="h4"
                        style={{ marginTop: '1%', marginLeft: '1%' }}
                    >
                        {paramValue ? 'Edit' : 'Create'} Store Event
                    </Typography>
                </Grid>
                <Grid style={{ marginBottom: '100px' }}>
                    <Card className={classes.cardidle} style={{ marginTop: '0%' }}>
                        <CardContent>
                            <Grid>
                                <Grid container spacing={2}>
                                    <Grid
                                        style={{
                                            width: '12%',
                                            marginTop: '1%',
                                            marginLeft: '2%',
                                        }}
                                    >
                                        <RACTextbox
                                            inputlabel="Event Title"
                                            name="EventTitle"
                                            required={true}
                                            // className={classes.margintextsize}
                                            value={data.EventTitle}
                                            OnChange={(e: any) => {
                                                handleInputChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        style={{
                                            width: '11%',
                                            marginTop: '1%',
                                            marginLeft: '2%',
                                        }}
                                    >
                                        <RACDatePicker
                                            label="Date"
                                            required={true}
                                            // className={classes.margintextsize}
                                            // style={{ paddingLeft: '40%' }}
                                            // classes={classes.margintextsize}
                                            value={startDate}
                                            onChange={(e: any) => dates(e)}
                                            inputProps={{
                                                min: moment().format('YYYY-MM-DD'),
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        style={{
                                            width: '11%',
                                            marginTop: '1%',
                                            marginLeft: '2%',
                                        }}
                                    >
                                        <RACTextbox
                                            inputlabel="Time"
                                            name="Time"
                                            // className={classes.margintextsize}
                                            value={Time}
                                            disabled={true}
                                            OnChange={Timebind}
                                        />
                                    </Grid>
                                    <Grid
                                        style={{
                                            width: '17%',
                                            marginTop: '1%',
                                            marginLeft: '2%',
                                        }}
                                    >
                                        <RACTextbox
                                            inputlabel="Description"
                                            required={true}
                                            name="Description"
                                            // className={classes.margintextsize}
                                            value={data.Description}
                                            OnChange={(e: any) => {
                                                handleInputChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        style={{
                                            width: '11%',
                                            marginTop: '1%',
                                            marginLeft: '2%',
                                        }}
                                    >
                                        <RACSelect
                                            inputLabel="# Co-workers Required"
                                            loading={conditionLoader}
                                            required={true}
                                            options={coWorkerRequiredArray}
                                            defaultValue={Coworkers !== '' ? Coworkers : 'SELECT'}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                SetCoworkers(e.target.value);
                                            }}
                                            {...(coWorkerRequiredArray.length == 1 &&
                                                !conditionLoader && {
                                                errorMessage: 'Unable to fetch Data',
                                            })}
                                        />
                                    </Grid>
                                    <Grid container style={{ marginTop: '3%', marginLeft: '2%' }}>
                                        <Grid item md={8} className={classes.spacerMT8}>
                                            <Grid className={classes.floatLeft}>
                                                <Typography variant="h5" className={classes.subTitle}>
                                                    Select Available Timeslot
                                                </Typography>
                                                <Typography className={classes.deliveryEventDesc}>
                                                    Only 4 events can be added in one time slot.
                                                </Typography>
                                            </Grid>
                                            <Grid className={classes.floatRight}>
                                                <Grid
                                                    className={`${classes.floatLeft} ${classes.me48}`}
                                                >
                                                    <span className={classes.circleAvailable} />
                                                    <span>Available</span>
                                                </Grid>
                                                <Grid
                                                    classes={{
                                                        root: clsx(classes.floatLeft, classes.spacerMR4),
                                                    }}
                                                >
                                                    <span className={classes.circleBusy} />
                                                    <span>Busy</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    className={classes.spacerMB5}
                                    style={{ marginLeft: '1.5%' }}
                                >
                                    <Grid
                                        style={{ width: '60%' }}
                                        className={`${classes.mt2} ${classes.me2}`}
                                    >
                                        {buildTimeSlot()}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid container classes={{
                        root: clsx(classes.customerToolbar, classes.fixedBottom),
                    }}
                        style={{ zIndex: 0 }}>
                        <Grid className={classes.footerFixed}>
                            <Grid className={classes.floatLeft}>
                                <RACButton
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => history.push(`/storemgmt/dap`)}
                                >
                                    Cancel
                                </RACButton>
                            </Grid>
                            <Grid style={{ float: 'right' }}>
                                <RACButton
                                    variant="contained"
                                    color="primary"
                                    style={{ float: 'right' }}
                                    disabled={(data.EventTitle?.trim().length != 0 && Time && data.Description?.trim().length != 0 && (Coworkers && Coworkers != 'SELECT')) ? false : true}
                                    onClick={(e) => {
                                        handleSave();
                                    }}
                                >
                                    {paramValue ? 'Update' : 'Create'}
                                </RACButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid>
                    {masterLoader ? (
                        <Grid
                            style={{
                                position: 'fixed',
                                background: '#f7f5f5',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                opacity: 0.6,
                                zIndex: 1050,
                                textAlign: 'center',
                                margin: '0px 0px',
                            }}
                        >
                            <Grid
                                style={{
                                    display: 'block',
                                    position: 'fixed',
                                    zIndex: 9999999,
                                    top: '40%',
                                    right: '50%',
                                }}
                            >
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    ) : null}
                    <RACModalCard
                        isOpen={Apierror}
                        closeIcon={false}
                        maxWidth="xs"
                        borderRadius={'9px !important'}
                    >
                        {ApiErrorPopup()}
                    </RACModalCard>
                    <RACModalCard
                        isOpen={errorMsg}
                        closeIcon={false}
                        maxWidth="xs"
                        borderRadius={'9px !important'}
                    >
                        {noRecordPopup()}
                    </RACModalCard>
                </Grid>
            </div >
        </>
    );
}

