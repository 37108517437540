/* eslint-disable */
export enum StoreMetricType {
  DeliveriesCoreStoreWeekly = 'DeliveriesCoreStoreWeekly',
  Deliveries = 'Deliveries',
  AOR = 'AOR',
  CustomerGain = 'CustomerGain'
}

export type MappedGoals = Record<StoreMetricType, StoreGoal>;

export interface StoreGoal {
  storeMetricGoalId?: string;
  metricType: StoreMetricType;
  metricGoal: number;
  metricCount: number;
  storeNumber: string;
  metricGoalDates: {
    startDate: string;
    endDate: string;
  };
  targetGoal: number;
}

export interface StoreGoalRequestBase {
  metricType: StoreMetricType;
  metricGoal: string;
}

export interface StoreGoalResponse extends StoreGoalRequestBase {
  storeMetricGoalId: string;
  metricCount: string;
  storeNumber: string;
  metricGoalDates: {
    startDate: string;
    endDate: string;
  };
  targetGoal: string;
}

export interface StoreGoalCreate extends StoreGoalRequestBase {
  metricGoalDates: {
    startDate: string;
    endDate?: string;
  };
}

export type StoreGoalUpdate = StoreGoalCreate;

export enum CollectionMetricType {
  Open = 'Open',
  Close = 'Close',
  AgreementsNeeded = 'AgreementsNeeded'
}

export interface SingleDayMetric {
  metricType: CollectionMetricType;
  oneToSixPct: string;
  oneToSixCount: string;
  sevenPlusPct: string;
  sevenPlusCount: string;
  fifteenPlusPct: string;
  fifteenPlusCount: string;
  totalPct: string;
  totalCount: string;
}

export interface GoalMetric {
  metricDate: string;
  totalAOR: string;
  singleDayMetrics: SingleDayMetric[];
}

export interface CollectionGoalDTO {
  storeNumber?: string;
  metrics: GoalMetric[];
}

export interface SalesGoal {
  storeMetricGoalId?: string;
  storeNumber: string;
  coworkerFirstName: string;
  coworkerLastName: string;
  coworkerId: string;
  goal: number;
  actual: number;
  variance: number;
}

export interface SetSalesGoalResponse {
  storeMetricGoalId: string;
  storeNumber: string;
  coworkerId: string;
  metricGoal: string;
  metricGoalDates: {
    startDate: string;
    endDate: string;
  };
}
