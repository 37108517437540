/* eslint-disable */
import React, { useContext, useState } from 'react';
import { Button, DatePicker, WeekPicker } from '@rentacenter/legacyracstrap';
import { add, isSameDay, startOfWeek, sub } from 'date-fns';

import styles from './Goals.module.scss';

import { StoreGoals } from './StoreGoals/StoreGoals';
import { SalesGoals } from './SalesGoals/SalesGoals';
import { CollectionGoals } from './CollectionGoals/CollectionGoals';
import { StoreContext } from '../../../context/Storeprovider';
export const goalsTestId = 'goalsCoreUserTestId';

export const GoalsCoreUser = () => {
  const today = new Date();
  const [week, setWeek] = useState<Date>(startOfWeek(today));
  const { storeGoalsSelectedDate, setStoreGoalsSelectedDate } = useContext(
    StoreContext
  );

  return (
    <div data-testid={goalsTestId} className={styles.goals}>
      <div className={styles.datesRow}>
        <div className={styles.datePickerWrap}>
          <DatePicker
            value={storeGoalsSelectedDate}
            className={`${styles.datePicker} ${styles.custweekpicker}`}
            name="date"
            placeholder="Select a date"
            onChange={setStoreGoalsSelectedDate}
            format="eee, MMM dd, yyyy"
          />
          <Button
            data-testid="todayBtn"
            variant="outlined"
            disabled={isSameDay(storeGoalsSelectedDate, new Date())}
            onClick={() => setStoreGoalsSelectedDate(new Date())}
            className={styles.todayBtn}
          >
            Today
          </Button>
        </div>
      </div>

      <div className={styles.section}>
        <h3>Store Goals</h3>
        <StoreGoals startDate={week} />
      </div>
      <WeekPicker
        className={`${styles.weekPicker} ${styles.custweekpicker}`}
        name="weekPicker"
        datePickerProps={{
          maxDate: add(today, { years: 1 }),
          minDate: sub(today, { years: 1 })
        }}
        value={week}
        onChange={setWeek}
      />
      <div className={styles.section}>
        <h3>Sales Goals</h3>
        <SalesGoals startDate={week} />
      </div>

      <div className={styles.section}>
        <h3>Collection Goals</h3>
        <CollectionGoals startDate={week} />
      </div>
    </div>
  );
};
