/* eslint-disable */
import { SearchableSelect, SelectOption, Label } from '@rentacenter/legacyracstrap';
import React, { useContext, useEffect, useState } from 'react';

import styles from './StoreSelector.module.scss';
import { getUserStores } from '../../../api/user'
import { StoreContext } from '../../../context/Storeprovider';
export const storeListTestId = 'storeListTestId';

export const getOptions = (stores: any): SelectOption[] => {
  return stores.map(
    (store: any) =>
    ({
      label: `${store.storeNum} - ${store.storeName} | ${store.storeState}`,
      value: store.storeNum
    } as SelectOption)
  ) as SelectOption[];
};

export const StoreSelector = () => {
  const { selectedStore, setSelectedStore, setAllStores } = useContext(
    StoreContext
  );
  const [loadingStores, setLoadingStores] = useState<boolean>(false);
  const [storeOptions, setStoreOptions] = useState<SelectOption[]>([]);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    setLoadingStores(true);
    setApiError('');
    getUserStores()
      .then(stores => {
        setAllStores(stores);
        if (stores.length === 1) {
          setSelectedStore?.(stores[0]?.storeNum);
        }
        setStoreOptions(getOptions(stores));
      })
      .catch(() => {
        setApiError("Couldn't retrieve the stores. Try later!");
      })
      .finally(() => {
        setLoadingStores(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.wrapper} ${styles.storeSelector}`}>
      <div className={styles.container}>
        <Label forInput="selectStore">Select Store:</Label>
        {/* <div className={styles.check1}> */}
          <SearchableSelect
            data-testid={storeListTestId}
            classes={{ selection: styles.selection }}
            className={styles.check1}
            size="small"
            name="selectStore"
            placeholder="Select Store"
            options={storeOptions}
            onChange={setSelectedStore}
            id={String(selectedStore)}
            value={selectedStore}
            disabled={storeOptions?.length <= 1}
            isLoading={loadingStores}
            invalid={!!apiError}
            errorMessage={apiError}
          />
        {/* </div> */}

      </div>
    </div>
  );
};
