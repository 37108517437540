/* eslint-disable prettier/prettier */
/* eslint-disable no-dupe-keys */
/* eslint-disable sonarjs/no-duplicate-string */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
import accordianOpenIcon from "../../assets/images/Accordion-down.svg";
import accordianCloseIcon from "../../assets/images/Accordion-close.svg";
export const routeAssignmentStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    assignBtnClass: {
      height: "30px",
      backgroundColor: "#55bdee59",
      marginRight: "10px",
      color: "#0074b6",
      "&:hover": {
        color: "#0d6ef",
        outline: "none",
      },
    },
    customerProfileWidth:{width:"48%",marginLeft:"24%"},
    roleWidth:{width:"65%",marginLeft:"18%"},
    positionAbsolute: {
      position: "absolute",
    },
    unassignBtnClass: {
      height: "30px",
      color: "#c00808",
      backgroundColor: "#FFD1D1",
      "&:hover": {
        color: "#c00808",
        backgroundColor: "#FFD1D1",
        outline: "none",
      },
    },
    btlRadius: {
      borderTopLeftRadius: "10px",
    },
    btrRadius: {
      borderTopRightRadius: "10px",
    },
    bbrRadius: {
      borderBottomRightRadius: "10px",
    },
    bblRadius: {
      borderBottomLeftRadius: "10px",
    },
    tableClass: { width: "70%", marginLeft: "14%" },
    tableCoWorkerProfileClass: { width: "47%", marginLeft: "22%" },
    formLabelDefaultCashDrop: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
      width: "69%",
    },
    container: {
      width: "100%",
      paddingRight: "0.75rem",
      paddingLeft: "0.75rem",
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: "60px",
      paddingBottom: "80px !important",
    },
    scrollbar: { overflow: "auto", height: "250px", width: "100%" },
    RACLoaderPage: {
      textAlign: "center",
      marginTop: "250px",
      fontSize: "16px",
      marginLeft: "30px",
      marginRight: "30px",
      outline: "none",
    },
    businessDate: {
      float: "left",
      marginBottom: "3%",
    },
    printpopup: {
      width: "100%",
    },
    popupforprint: {
      padding: "3%",
      marginBottom: "25%",
    },
    anchorview: {
      marginTop: "0.1%",
    },
    header: {
      width: "100%",
      fontSize: "18px",
      fontFamily: "OpenSans-bold",
      marginBottom: "15px",
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
    rowMt2: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0.5rem !important",
    },
    colMd12mt3: {
      flex: "0 0 auto",
      width: "100%",
      marginTop: "1rem !important",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0",
      marginRight: "-0.75rem",
    },
    tableCell: {
      width: "30%",
    },
    tableCellDenomination: {
      width: "30%",
    },
    tableRow: {
      background: "none",
    },
    RACbutton: {
      color: "#0d6efd",
      borderColor: "#0d6efd",
      display: "inline-block",
      fontWeight: 400,
      lineHeight: "1.5",
      textAlign: "center",
      textDecoration: "none",
      verticalAlign: "middle",
      userSelect: "none",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "0.25rem",
      width: "56%",
      transition:
        "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#0d6efd",
        borderColor: "#0d6efd",
      },
    },
    RACbuttonOverShort: {
      color: "#0d6efd",
      borderColor: "black",
      display: "inline-block",
      fontWeight: 400,
      lineHeight: "1.5",
      textAlign: "center",
      textDecoration: "none",
      verticalAlign: "middle",
      userSelect: "none",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "0.25rem",
      width: "56%",
      transition:
        "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#0d6efd",
        borderColor: "#0d6efd",
      },
    },
    RACTextBox: {
      width: "50% !important",
      "&:focus": {
        color: "#212529",
        backgroundColor: "#fff",
        borderColor: "#86b7fe",
        outline: "0",
        boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)",
      },
      "& input": {
        padding: "9px",
      },
    },
    tableRow: {
      background: "none",
      width: "50%",
    },
    tableCell1: {
      paddingTop: "0 !important",
    },

    mt3: {
      marginTop: "1rem !important",
    },
    card: {
      border: "none",
      borderRadius: "1rem",
      boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
    },
    cardBody2: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
      paddingBottom: "10px !important",
      marginLeft: "1px",
    },
    p2: {
      padding: "0.5rem !important",
    },
    mb3: {
      marginBottom: "1rem !important",
    },
    racColMD12: {
      flex: "0 0 auto",
      width: "100%",
    },
    table: {
      width: "100%",
      color: "#212529",
      verticalAlign: "top",
      borderColor: "#dee2e6",
    },
    tableBorder: {
      captionSide: "bottom",
      borderCollapse: "collapse",
    },
    float: {
      float: "right",
    },
    tableRow1: {
      width: "96%",
      padding: "5px",
      background: "#EFF4FF",
      marginRight: "17px",
      marginLeft: "7px",
      // marginBottom:"1px"
    },
    floatLeft: {
      float: "left",
    },
    drawerTotal: {
      color: "#2179FE",
      padding: "1px",
      paddingLeft: "24px",
    },
    floatRight: {
      width: "96",
      float: "right",
      marginBottom: "18.5px",
      marginTop: "15px",
      marginRight: "15px",
    },
    racColMD7: {
      flex: "0 0 auto",
      width: "50%",
    },
    mt2: {
      marginTop: "0.5rem !important",
    },
    cardBody: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
      paddingBottom: "10px !important",
    },
    colMd2: {
      flex: "0 0 auto",
      width: "24.6666%",
    },
    tableCellTotal: {
      width: "40%",
      textAlign: "right",
      // textAlign:"center"
    },
    total: {
      textAlign: "right",
      paddingRight: "6%",
    },
    formLabelNonCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
      width: "49%",
    },
    formValue: {
      fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      color: "#4A5174",
    },
    formLabelCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
      width: "29%",
    },
    formLabelDefaultStarting: {
      marginBottom: "0.2rem",
      color: "#656565",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      float: "left",
      width: "100%",
    },
    formLabelTotalCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
      width: "29%",
    },
    formLabelDefaultCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
      width: "74%",
    },
    mb0: {
      marginBottom: "0 !important",
    },
    tableRow2: {
      width: "38%",
      padding: "5px",
      background: "#EFF4FF",
      marginRight: "30px",
    },
    dropNotes: {
      "&:focus": {
        width: "100%",
        color: "#212529",
        backgroundColor: "#fff",
        borderColor: "#86b7fe",
        outline: "0",
        boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)",
      },
    },
    footerGrid: {
      paddingTop: "120px !important",
      marginRight: "-0.75rem",
      marginLeft: "-0.75rem",
      display: "flex",
      flexWrap: "wrap",
    },
    footerFixed: {
      backgroundColor: "#fff !important",
      padding: "1rem !important",
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%) !important",
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
    },
    textWidth: {
      width: "75% !important",

      "& input": {
        height: "23px",
      },
    },
    textWidthamount: {
      // '&div':{padding:"4px"},

      width: "76% !important",

      "& input": {
        height: "28px",
      },
    },
    // '&div':{padding:"4px"}
    currency: {
      "&div": {
        "&div": {
          "&div": {
            "&div": {
              //       '&div':{
              padding: "10px",
              backgroundColor: "red",
            },
          },
        },
      },
      // }
    },
    dollarClass: {
      padding: "11px",
      width: "24%",
    },
    quantity: {
      width: "92%",
      "& input": {
        height: "23px",
      },
    },
    tableCellTotalQuantity: {
      width: "34.33%",
      paddingLeft: "4%",
      textAlign: "center",
    },
    currenttill: {
      width: "100%",
      "& input": {
        height: "23px",
      },
      marginRight: "2%",
    },
    overShort: {
      width: "100%",
      "& input": {
        height: "23px",
      },
      border: "null",
      borderRadius: "5px",
      // marginRight:"2%"
    },

    totalQuantity: {
      width: "100%",
      "& input": {
        height: "23px",
      },
      borderRadius: "2px",
      marginLeft: "14%",
    },
    validatebtn: {
      "& input": {
        height: "23px",
      },
    },
    textCenter: {
      textAlign: "center",
    },

    RACPOPMsg: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
    marginTop: {
      marginTop: "-1rem",
    },
    justifyContentCenter: {
      justifyContent: "center",
    },

    explanationContent: {
      justifyContent: "right",
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    foc: {
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "1px 1px 6px 3px #80bdff",
      },
    },
    rowMt2: {
      display: "flex",
      flexWrap: "wrap",
      //eslint-disable-next-line sonarjs/no-duplicate-string
      marginTop: "0.5rem !important",
    },
    colMd12mt3: {
      flex: "0 0 auto",
      width: "100%",
      marginTop: "1rem !important",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0",
      marginRight: "-0.75rem",
    },
    header: {
      width: "100%",
      fontSize: "18px",
      fontFamily: "OpenSans-bold",
      marginBottom: "15px",
    },
    card: {
      border: "none",
      borderRadius: "1rem",
      boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
    },
    cardBody: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
      paddingBottom: "10px !important",
    },
    mt3: {
      marginTop: "1rem !important",
    },
    cardBody2: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
      paddingBottom: "10px !important",
      marginLeft: "5%",
      marginRight: "6%",
    },
    p2: {
      padding: "0.5rem !important",
    },
    racColMD12: {
      flex: "0 0 auto",
      width: "100%",
    },
    mb3: {
      marginBottom: "1rem !important",
    },
    table: {
      width: "100%",
      color: "#212529",
      verticalAlign: "top",
      borderColor: "#dee2e6",
    },
    tableBorder: {
      captionSide: "bottom",
      borderCollapse: "collapse",
    },
    float: {
      float: "right",
    },
    tableCellDenomination: {
      width: "33.33%",
      // marginRight:"10% !important"
    },
    totalQuantity: {
      width: "80%",
      marginLeft: "20%",
      "& input": {
        height: "23px",
      },

      // marginLeft:"33px"
    },
    total: {
      textAlign: "right",

      // paddingRight:"9%"
    },
    typoTotal: {
      marginRight: "4%",
    },
    tableCellTotal: {
      width: "32.33%",
    },

    tableRow1: {
      width: "63%",
      padding: "5px",
      background: "#EFF4FF",
      marginRight: "30px",
    },
    floatLeft: {
      float: "left",
    },
    drawerTotal: {
      color: "#2179FE",
      padding: "4px",
      paddingLeft: "24px",
    },
    RACbutton: {
      color: "#0d6efd",
      borderColor: "#0d6efd",
      display: "inline-block",
      fontWeight: 400,
      lineHeight: "1.5",
      textAlign: "center",
      textDecoration: "none",
      verticalAlign: "middle",
      userSelect: "none",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "0.25rem",
      width: "56%",
      transition:
        "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#0d6efd",
        borderColor: "#0d6efd",
      },
    },
    RACTextBox: {
      width: "50% !important",
      "&:focus": {
        color: "#212529",
        backgroundColor: "#fff",
        borderColor: "#86b7fe",
        outline: "0",
        boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)",
      },
      "& input": {
        padding: "9px",
      },
    },
    currenttill: {
      width: "83%",
      "& input": {
        height: "23px",
      },
      marginRight: "0px",
    },
    tableRow: {
      background: "none",
    },
    tableCell1: {
      paddingTop: "0 !important",
    },
    formLabelDropEnvelope: {
      width: "30%",
      marginTop: "1.7%",
      marginLeft: "3%",
    },
    envelopeNumbertextBox: {
      width: "80% !important",

      "& input": {
        height: "23px",
      },
      // marginLeft: "5%",
      // padding: "1%",
    },
    formValue: {
      fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      color: "#4A5174",
    },
    racColMD7: {
      flex: "0 0 auto",
      width: "58.33%",
    },
    mt2: {
      marginTop: "0.5rem !important",
    },
    colMd2: {
      flex: "0 0 auto",
      width: "25%",
    },
    formLabelNonCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
      width: "40%",
    },
    formLabelCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
      width: "27% !important",
    },

    formLabelDefaultStarting: {
      marginBottom: "0.2rem",
      color: "#4A5174",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      float: "left",
      width: "100%",
    },
    formLabelTotalCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
      width: "25%",
    },
    formLabelDefaultCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
      width: "70%",
    },
    colLg4: {
      flex: "0 0 auto",
      width: "24.333333%",
      marginLeft: "2%",
    },
    columnMd2: {
      flex: "0 0 auto",
      width: "65%",
    },
    formLabelMaxDrop: {
      marginBottom: "0.2rem",
      color: "#111111",
      // fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      float: "left",
    },
    colMd4: {
      flex: "0 0 auto",
      width: "35%",
    },
    cashText: {
      fontFamily: "OpenSans-bold",
      fontSize: "18px",
      color: "#2179FE",
    },
    mb0: {
      marginBottom: "0 !important",
    },
    tableRow2: {
      width: "38%",
      padding: "5px",
      background: "#EFF4FF",
      marginRight: "30px",
    },
    dropNotes: {
      "&:focus": {
        color: "#212529",
        backgroundColor: "#fff",
        borderColor: "#86b7fe",
        outline: "0",
        boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)",
      },
    },
    footerGrid: {
      // paddingTop: "120px !important",
      marginRight: "-0.75rem",
      marginLeft: "-0.75rem",
      display: "flex",
      flexWrap: "wrap",
    },
    footerFixed: {
      backgroundColor: "#fff !important",
      padding: "1rem !important",
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%) !important",
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
    },
    textWidth: {
      width: "75% !important",

      "& input": {
        height: "23px",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    RACPOPMsg: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    marginTop: {
      marginTop: "-1rem",
    },
    justifyContentCenter: {
      justifyContent: "center",
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    foc: {
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "1px 1px 6px 3px #80bdff",
      },
    },
    quantitywid: {
      width: "100%",
      "& input": {
        height: "23px",
      },
      paddingLeft: "23px",
    },
    tableroW: {
      paddingTop: "0 !important",
      paddingBotttom: "0 !important",
    },
    denominationwidth: {
      width: "88%",
      "& input": {
        height: "23px",
      },
    },
    pendingPetty: {
      color: "#4A5174",
      width: "68% !important",

      "& input": {
        height: "17px",
      },
      border: "none",
      borderRadius: "3px",
      float: "right",
    },
    totalNonCash: {
      marginRight: "13%",
      marginBottom: "0.5%",
      marginTop: "3%",
      border: "none",
      borderRadius: "4px",
      width: "40%",
      padding: "9px",
      background: "#EFF4FF",
    },
    topBottomSpaceOverShort: {
      marginTop: "15px",
      marginBottom: "20px",
    },
    overShortNonCash: {
      marginBottom: "0.2rem",
      paddingTop: "2px",
      color: "#111111",
      fontSize: "14px",
      float: "left",
      width: "39%",
    },
    overShortCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      paddingTop: "2px",
      fontSize: "14px",
      float: "left",
      width: "30%",
    },
    overShortTotal: {
      marginBottom: "0.2rem",
      color: "#111111",
      paddingTop: "2px",
      fontSize: "14px",
      float: "left",
      width: "32%",
    },
    columnStyle: {
      float: "right",
      width: "100%",

      paddingBottom: "20px",
    },
    topBottomSpace: {
      marginTop: "15px",
      marginBottom: "10px",
    },
    textArea: {
      marginTop: "5px",
      width: "100%",
      padding: "6px",
      paddingBottom: "31px",
      resize: "none",
      border: "1px solid #C4C4C4",
      paddingTop: "5px",
      paddingRight: "5px",
      borderRadius: "0.6rem",
      backgroundColor: "#fff",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      fontSize: "14px",
      fontFamily: "OpenSans-semibold",
    },
    denomination: {
      width: "28%",
    },
    totalWidth: {
      width: "40%",
    },
    loaderStyle: {
      border: "none",
      backgroundColor: "transparent",
      // userFocus: 'none',
      // MozUserFocus: 'none',
      outline: "none",
    },
    dropdowntoggle: {
      display: "inline-block",
      marginLeft: "0.255em",
      verticalAlign: "0.255em",
      content: "",
      borderTop: "0.3em solid",
      borderRight: "0.3em solid transparent",
      borderBottom: "0",
      borderLeft: "0.3em solid transparent",
    },
    marginTop: {
      marginTop: "-1rem",
    },
    me1: {
      marginRight: theme.typography.pxToRem(4),
    },
    pr: {
      position: "relative",
    },
    hideRental: {
      width: "165px",
      position: "absolute",
      left: "0px",
      bottom: "100%",
      border: "1px solid #2468FF",
      backgroundColor: "white",
    },
    popupRental: {
      width: "165px",
      position: "absolute",
      left: "0px",
      bottom: "100%",
      border: "1px solid #2468FF",
      backgroundColor: "white",
    },
    dropdownitemcustom: {
      color: "#2468FF",
      fontFamily: "OpenSans-bold",
      backgroundColor: "white",
      textDecoration: "none",
      cursor: "pointer",
    },
    bgBlue: {
      backgroundColor: "white",
    },
    GridLoader: {
      textAlign: "center",
      marginBottom: theme.typography.pxToRem(20),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      margin: "30px 0px",
    },
    fixTableHead: {
      overflowY: "auto",
      maxHeight: "205px",
      "& th": {
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        fontFamily: "OpenSans-semibold",
        zIndex: 1,
        padding: "0.9375rem 0.2rem",
        whiteSpace: "nowrap",
      },
      "& tbody": {
        "& tr": {
          position: "sticky",
          top: 10,
          backgroundColor: "#ffffff",
        },
        "& td": {
          fontFamily: "OpenSans-semibold",
          padding: "0.9375rem 0.2rem",
          color: "#4A5174",
        },
      },
    },
    fixTableHeight300: {
      maxHeight: "300px",
    },
    fixTableHeight400: {
      maxHeight: "400px",
    },
    title: {
      color: "#212529",
      fontFamily: "OpenSans-bold",
      fontSize: "16px",
      marginBottom: "10px",
    },
    ssnBorderRed: {
      border: "1px solid red",
      borderRadius: "6px",
      outline: "none",
    },
    racGlobalSearchBtn: {
      cursor: "pointer",
      width: "25px",
      float: "left",
      paddingTop: "0.2rem",
    },
    widgetTitle: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: "OpenSans-bold",
      color: `${RACCOLOR.WASHED_BLACK}`,
    },
    spacer: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    routeWidthCustomerProfile:{
      width: "25%",marginLeft:"2%"
    },
    coWorkerWidthCustomerProfile:{
      width: "25%"
    },
    dateModifieldWidthCustomerProfile:{
      width: "26%"
    },
    routeWidthNoRecord:{
      width:"33%" ,
      marginLeft:"6%"
    },
    coWorkerWidthNoRecord:{
      width:"36%"
    },
    dateModifieldWidthNoRecord:{
      width:"23%"
    },
    routeWidth:{
      width:"32%" 
    },
    coWorkerWidth:{
      width:"31%"
    },
    dateModifieldWidth:{
      width:"20%"
    },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      width: "100%",
    },
    customerFloatright: {
      float: "right",
    },
    customerFloatleft: {
      float: "left",
    },
    foc: {
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "1px 1px 6px 3px #80bdff",
      },
    },
    focforLink: {
      "&:focus": {
        border: "1px solid #80bdff",
        outline: "none",
        borderRadius: "5px",
      },
    },
    focforLinkPaymentIssue: {
      outline: "none",
    },
    colRight: {
      textAlign: "right",
    },
    spacerMT5: {
      marginTop: theme.typography.pxToRem(48),
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: "none",
    },
    spacerME2: {
      marginRight: theme.typography.pxToRem(16),
    },
    fixedBottom: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      padding: "1rem",
    },
    customerRow: {
      width: "100%",
    },
    customerJustifycontentcenter: {
      justifyContent: "center",
    },

    customerToolbar: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    spacerP0: {
      padding: theme.typography.pxToRem(0),
    },
    spacerP2: {
      padding: theme.typography.pxToRem(16),
    },
    spacerMT0: {
      marginTop: theme.typography.pxToRem(0),
    },
    spacerMB3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    spacerMT3: {
      marginTop: theme.typography.pxToRem(16),
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMB1: {
      marginBottom: theme.typography.pxToRem(4),
    },
    spacerMT1: {
      marginTop: theme.typography.pxToRem(4),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    spacerMR1: {
      marginRight: theme.typography.pxToRem(16),
      marginTop: theme.typography.pxToRem(16),
    },
    spacerPX2: {
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    spacerMB3PX1: {
      marginBottom: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(4),
      paddingRight: theme.typography.pxToRem(4),
    },
    spacerPT1: {
      paddingTop: theme.typography.pxToRem(16),
    },
    spacerPB2: {
      paddingBottom: theme.typography.pxToRem(8),
    },
    spacerMB2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    spacerMS2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    customerDisplayinline: {
      display: "inline-block",
    },
    customerColmd: {
      flex: "0 0 auto",
      width: "100%",
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    sideTitle: {
      fontSize: theme.typography.pxToRem(18),
      color: `${RACCOLOR.WASHED_BLACK}`,
      marginBottom: theme.typography.pxToRem(10),
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: "OpenSans-bold",
    },
    customerTablecellbgcolor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    customerAccordionopen: {
      borderLeft: "2px solid #7bbffc",
      boxShadow: "0px -1px 2px 0px #eaeff5",
      borderTopLeftRadius: theme.typography.pxToRem(12),
      borderTopRightRadius: theme.typography.pxToRem(12),
    },
    customerAccordiontogglev3: {
      backgroundImage: `url(${accordianCloseIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: theme.typography.pxToRem(20),
      cursor: "pointer",
      width: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(20),
      display: "block",
    },
    accordionIconGrid: {
      backgroundImage: `url(${accordianOpenIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "23px",
      cursor: "pointer",
      width: "23px",
      height: "23px",
      display: "block",
      float: "left",
      marginTop: "1px",
    },
    accordionGridClose: {
      backgroundImage: `url(${accordianCloseIcon})`,
    },
    customerAccordionarrowchange: {
      backgroundImage: `url(${accordianCloseIcon})`,
      backgroundRepeat: "no-repeat",
    },
    customerHiddenrow: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    customerAccordianopen: {
      borderLeft: "5px solid #7bbffc",
      boxShadow: "0px -1px 2px 0px #eaeff5",
      borderTopLeftRadius: theme.typography.pxToRem(12),
      borderTopRightRadius: theme.typography.pxToRem(12),
    },
    customerCheckbox: {
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
    },
    customerRaclink: {
      color: `${RACCOLOR.BLUE_CRAYOLA}`,
      textDecoration: "none",
      fontFamily: "OpenSans-semibold",
      cursor: "pointer",
    },
    customerLinebreak: {
      whiteSpace: "nowrap",
    },
    customerPointercursor: {
      cursor: "pointer",
    },
    customerTextend: {
      textAlign: "right",
    },
    customerTextcenter: {
      textAlign: "center",
    },
    customerRacpopup: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: "0px",
    },
    spacerMS1: {
      marginLeft: theme.typography.pxToRem(4),
      position: 'absolute',
      cursor: 'pointer'
    },
    customerLineBreak: {
      whiteSpace: "nowrap",
    },
    checkBoxwidth: {
      width: theme.typography.pxToRem(10),
    },
    customerTextsubtilegrid: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(18),
      color: "#000",
    },
    customerNorecords: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },
    customerNorecordsAgr: {
      textAlign: "center",
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },
    customerNorecordsAlert: {
      textAlign: "center",
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      marginRight: "10px !important",
    },
    alertLoader: {
      width: "30px !important",
      height: "30px !important",
      marginTop: "10px",
    },
    formLabel: {
      marginBottom: "2px",
      float: "left",
      color: "#111111",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
    },
    fixedBottomAddCo: {
      position: "sticky",
      bottom: "-19px",
      right: "0px",
      backgroundColor: "white",
      padding: "14px 0px",
    },

    formLabelAddCo: {
      marginBottom: "0.2rem",
      color: "#111111",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
    },

    mandatoryfield: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
    },
    demooooo: {
      color: "yellow",
    },
    RACPOPMsg: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },
    ps2: {
      paddingLeft: theme.typography.pxToRem(8),
    },
    spacerMT2: {
      marginTop: theme.typography.pxToRem(16),
    },
    semiBold: {
      fontFamily: "OpenSans-semibold",
    },
    formCheck: {
      minHeight: "1.5rem",
      marginBottom: "0.125rem",
      display: "inline-block",
      marginRight: "1rem",
      paddingLeft: 0,
    },
    textRight: {
      textAlign: "right",
    },
    textCenterSSN: {
      "& input": {
        textAlign: "center",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    w100: {
      width: "100%",
    },
    floatLeft: {
      float: "left",
    },
    floatRight: {
      float: "right",
    },
    justifyContentCenter: {
      justifyContent: "center",
    },
    bold: {
      fontFamily: "OpenSans-bold",
    },
    font16: {
      fontSize: "16px",
    },
    customerTable: {
      "& tr": {
        backgroundColor: "transparent",
      },
    },
    paymentIssue: {
      "& td": {
        backgroundColor: "#ffefef",
      },
      "& td:first-child": {
        borderTopLeftRadius: "7px",
        borderBottomLeftRadius: "7px",
      },
      "& td:last-child": {
        borderTopRightRadius: "7px",
        borderBottomRightRadius: "7px",
      },
    },
    racpadAgrLink: {
      borderLeft: "3px solid transparent",
      paddingLeft: "10px",
      borderRadius: "2px",
    },
    racpadPaymentFails: {
      position: "relative",
      "&:before": {
        content: '""',
        height: "20px",
        width: "3px",
        background: "#fd6a63",
        position: "absolute",
        left: 0,
        top: 0,
        borderTopRightRadius: "2px",
        borderBottomRightRadius: "2px",
      },
    },
    racpadPaymentInActive: {
      borderColor: "#b1adac",
    },
    racpadPaymentSuccess: {
      position: "relative",
      "&:before": {
        content: '""',
        height: "20px",
        width: "3px",
        background: "#56e0d8",
        position: "absolute",
        left: 0,
        top: 0,
        borderTopRightRadius: "2px",
        borderBottomRightRadius: "2px",
      },
    },
    racstrapTablecellBgColor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    racpadLink: {
      color: "#2468ff",
      fontSize: "14px",
      textDecoration: "none",
      cursor: "pointer",
    },
    racpadClubLink: {
      color: "#000000",
      fontSize: "14px",
      textDecoration: "none",
    },
    lineBreak: {
      whiteSpace: "nowrap",
    },
    agreementRow: {
      borderBottom: "2px solid white",
    },
    RACLoaderPage: {
      textAlign: "center",
      marginTop: "250px",
      fontSize: "16px",
      marginLeft: "30px",
      marginRight: "30px",
      outline: "none",
    },
    racFixedFooterMargin: {
      marginBottom: "120px",
    },
    racGrid: {
      "& th": {
        fontFamily: "OpenSans-semibold",
        fontSize: "14px",
        color: "#000",
      },
      "& td": {
        fontFamily: "OpenSans-semibold",
        fontSize: "14px",
        color: "#4A5174",
      },
    },
    txtSubtileGrid: {
      fontFamily: "OpenSans-bold",
      fontSize: "18px",
      color: "#000000",
    },
    badgeContainer: {
      padding: "1px 13px",
      borderRadius: "10px",
      backgroundColor: "#ddf8ed",
      color: "#10523e",
      marginLeft: " 5px",
    },
    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },
    spacerP1: {
      padding: ".375rem .75rem",
    },
    mb100: {
      marginBottom: "120px",
    },
    loaderStyle: {
      border: "none",
      backgroundColor: "transparent",
      // userFocus: 'none',
      // MozUserFocus: 'none',
      outline: "none",
    },
    ssnpadding: {
      paddingTop: "0px",
    },
    datePicker: {
      paddingTop: "9px !important",
      marginBottom: "0px !important",
      "& div": {
        "& div": {
          "& fieldset": {
            borderColor: "#c4c4c4 !important",
          },

          "& input": {
            padding: "6px",

            paddingLeft: "12px",
          },
        },
      },
    },
    datePickerAddCo: {
      marginBottom: "0px !important",
      marginTop: "4px !important",
    },
    datePickerMB: {
      marginBottom: "1px !important",
      marginTop: "4px !important",
    },
    titleColor: {
      color: "gray",
    },
    infoTextStyle: {
      color: "gray",
      fontSize: theme.typography.pxToRem(13),
      textAlign: "center",
    },
    cardHeight: {
      height: theme.typography.pxToRem(271),
    },
    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },
    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },
    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },
    tablebggreen: {
      backgroundColor: "#F0FDF5",
    },
    masterLoader: {
      position: "fixed",
      backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 1050,
      textAlign: "center",
      margin: 0,
    },
    Loader: {
      display: "block",
      position: "fixed",
      zIndex: 1051,
      top: "50%",
      right: "50%",
    },
    marginbottom2: {
      marginBottom: "6px!important",
    },
    pb0: {
      paddingBottom: "!important",
    },
    dispInlineFlex: {
      display: "inline-flex",
    },
    txtStart: {
      textAlign: "start",
    },
    ssnPaddingCocust: {
      padding: "6px 8px 8px 8px !important",
    },
    ssnTextField: {
      "& input": {
        ["@media (min-width:768px) and (max-width:1280px)"]: {
          padding: "7px 9px!important",
        },
      },
    },
    agreementGrid: {
      width: "94%",
      margin: "0 auto !important",
      borderCollapse: "separate",
      borderSpacing: "0 5px !important",
    },
    accordianWidth: {
      width: "42px",
    },
    cusomerInfo: {
      borderLeft: "7px solid #7bbffc",
      boxShadow: "0 1px 25px 0 rgb(0 0 0 / 7%)",
    },
    agreementGridInnerRow: {
      marginLeft: "-1.5px",
      boxShadow: "4px 4px 4px #eaeaea",
      borderLeft: "5px solid #7bbffc",
      paddingBottom: "2rem",
      marginBottom: "2rem",
    },
    textAlign: {
      textAlign: "left",
    },
    borderRadiusZero: {
      borderRadius: "0px !important",
    },
    gridBorderBottom: {
      borderBottom: "1px solid #eaeef5",
    },
    RACPOPMsgforCC: {
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    clrntnstyle: {
      marginTop: "17px!important",
      padding: "6px 20px !important",
    },
    RACwidth: {
      width: "23%",
      marginLeft: "37%",
      maxHeight: "100%",
    },
    mr2: {
      marginRight: theme.typography.pxToRem(8),
    },
    componentRight: {
      justifyContent: "flex-end",
      alignItems: "center",
      display: "flex",
    },
    pl40: {
      paddingLeft: "32px!important",
      color: "#212529",
      fontSize: "16px",
      fontFamily: "OpenSans-bold",
      marginBottom: "10px",
    },
    pl28: {
      paddingLeft: "28px!important",
    },
    quantitywid: {
      width: "100%",
      "& input": {
        height: "23px",
      },
      paddingLeft: "23px",
    },
    tableroW: {
      paddingTop: "0 !important",
      paddingBotttom: "0 !important",
    },
    denominationwidth: {
      width: "88%",
      "& input": {
        height: "23px",
      },
    },
    pendingPetty: {
      color: "#4A5174",
      width: "68% !important",

      "& input": {
        height: "17px",
      },
      border: "none",
      borderRadius: "3px",
      float: "right",
    },
    totalNonCash: {
      marginRight: "13%",
      marginBottom: "0.5%",
      marginTop: "3%",
      border: "none",
      borderRadius: "4px",
      width: "40%",
      padding: "9px",
      background: "#EFF4FF",
    },
    topBottomSpaceOverShort: {
      marginTop: "15px",
      marginBottom: "20px",
    },
    overShortNonCash: {
      marginBottom: "0.2rem",
      paddingTop: "2px",
      color: "#111111",
      fontSize: "14px",
      float: "left",
      width: "39%",
    },
    overShortCash: {
      marginBottom: "0.2rem",
      color: "#111111",
      paddingTop: "2px",
      fontSize: "14px",
      float: "left",
      width: "30%",
    },
    overShortTotal: {
      marginBottom: "0.2rem",
      color: "#111111",
      paddingTop: "2px",
      fontSize: "14px",
      float: "left",
      width: "32%",
    },
    columnStyle: {
      float: "right",
      width: "100%",

      paddingBottom: "20px",
    },
    topBottomSpace: {
      marginTop: "15px",
      marginBottom: "10px",
    },
    textArea: {
      marginTop: "5px",
      width: "100%",
      padding: "6px",
      paddingBottom: "31px",
      resize: "none",
      border: "1px solid #C4C4C4",
      paddingTop: "5px",
      paddingRight: "5px",
      borderRadius: "0.6rem",
      backgroundColor: "#fff",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      fontSize: "14px",
      fontFamily: "OpenSans-semibold",
    },
    denomination: {
      width: "28%",
    },
    totalWidth: {
      width: "40%",
    },
    tableRow: {
      background: "none",
    },
    RACbutton: {
      color: "#0d6efd",
      borderColor: "#0d6efd",
      display: "inline-block",
      fontWeight: 400,
      lineHeight: "1.5",
      textAlign: "center",
      textDecoration: "none",
      verticalAlign: "middle",
      userSelect: "none",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "0.25rem",
      width: "56%",
      transition:
        "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#0d6efd",
        borderColor: "#0d6efd",
      },
    },
    RACbuttonOverShort: {
      color: "#0d6efd",
      borderColor: "black",
      display: "inline-block",
      fontWeight: 400,
      lineHeight: "1.5",
      textAlign: "center",
      textDecoration: "none",
      verticalAlign: "middle",
      userSelect: "none",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "0.25rem",
      width: "56%",
      transition:
        "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#0d6efd",
        borderColor: "#0d6efd",
      },
    },
    currenttill: {
      width: "100%",
      "& input": {
        height: "23px",
      },
      marginRight: "2%",
    },
    totalQuantity: {
      width: "100%",
      "& input": {
        height: "23px",
      },
      borderRadius: "2px",
      marginLeft: "14%",
    },
    validatebtn: {
      "& input": {
        height: "23px",
      },
    },
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = UseClasses();
  return classes;
};
