/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from "@rentacenter/racstrap";
export const fscstyles = () => {
  const useClasses = makeStyles((theme) => ({
    textEditorStyle: {
      "& div": {
        height: "300px",
        width: "80%",
      },
    },
    alertinfo: {
      color: "#ffff",
    },
    details: {
      color: "#696969",
    },
    datestyle: {
      color: "#A30000",
    },
    borderGreen: {
      borderLeft: "5px solid #4D9F7F",
    },
    borderBlue: {
      borderLeft: "5px solid #00BD26",
    },
    //MEDIUM
    borderViolet: {
      borderLeft: "5px solid #FFA61E",
    },
    //HIGH
    borderYellow: {
      borderLeft: "5px solid #E85C5C",
    },
  }));
  //eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = useClasses();
  return classes;
};
