/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable no-inner-declarations */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    RACTextbox,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACCheckBox,
    RACButton,
    RACModalCard,
    CircularProgress
} from '@rentacenter/racstrap';
import { useHistory } from 'react-router-dom';
import { closeEndOfDayStyles } from '../../storeComponent/closeEOD/closeEODStyle';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';
import {
    getDrawerDetail,
    manageAgreementTransaction,
    getPendingEvents,
    getPendingCustomerAgreementTransfer,
    getDropDocument,
    getConfigDetails
    , getTransferReceiveGridDetails,
    getPendingAgreementTransfer,
    UpdateVoidStatus,
    getDapEvent,
} from '../../api/user';
import SecondFactor from '../../storeComponent/SecondAuthen/secondAuthen';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg'
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import moment from 'moment';
import Modal from 'react-modal';
export function closeEndOfDay() {
    const history = useHistory();
    const [paramValue, setParamValue] = useState<any>({
        offset: 0, limit: 6
    });
    const [loaderFooter, setLoaderFooter] = useState(false)
    const [isSuccess, setIsSucess] = useState(true);
    const [successPopup, setSuccessPopup] = useState(false);
    const [hasMore, sethasMore] = useState<boolean>(true);
    const classes = closeEndOfDayStyles()
    const [closeEndOfDay, setCloseEndOfDay] = useState<any>({})
    const [loader, setLoader] = useState(false);
    const [isFinalDropCompleted, setIsFinalDropCompleted] = useState(false);
    const [recallCheck, setRecallCheck] = useState(true);
    const [voidCheck, setVoidCheck] = useState(true);
    const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState(false);
    const [modals, setModals] = useState({
        secondLevelAuthenOn: false,
        finalDropIsOpen: false,
        pendingCustomerAgreementTransferIsOpen: false,
        selectedPendingCustomerAgreementTransferIsOpen: false,
        noDataSelected: false,
        ConfiguredDropMaxTimeLimit: false,
        endOfDayProcessIsOpen: false,
        pendingVoidAgreements: false,
        selectedVoidAgreementIsOpen: false

    });
    const [showFinalDropText , setShowFinalDropText] = useState(false);
    const [countPndingCustomer, setCountPndingCustomer] = useState<any>(0);
    const [allCountPndingCustomer, setAllCountPndingCustomer] = useState<any>(0);
    const [noRecordDisableCheckBox, setNoRecordDisableCheckBox] = useState(true);
    const [noRecordVoidDisable, setNoRecordVoidDisable] = useState(true);
    const [summaryTotal, setSummaryTotal] = useState<any>();
    const [selectAllCheck, setSelectAllCheck] = useState(false);
    const [selectAllCheckVoid, setSelectAllCheckVoid] = useState(false);
    const [apiResponse, setApiResponse] = useState<any>();
    const [apiResPendingAgreement , setApiResPendingAgreement] = useState<any>();
    // const [storeTime, setStoreTime] = useState<any>();
    useEffect(() => {
        pageLoad();
    }, []);
    const pageLoad = async () => {
        try {
            setLoader(true)
            const storeNumber = window.sessionStorage.getItem('storeNumber');
            const payload: any = { storeNumber: sessionStorage.getItem('storeNumber'), appointmentTypes: ['CI', 'CO', 'D', 'P', 'S', 'SCI', 'SCO', 'SD', 'SO', 'SP', 'BE', 'SE'], startDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') }
            const closeEndOfDay: any = await Promise.all([
                getDapEvent(payload),
                getDropDocument({
                    'businessDate': moment().format('MM/DD/YYYY'),
                    'type': 'DROP',
                    storeNumber: storeNumber
                }),
                getTransferReceiveGridDetails({ storeNumber: storeNumber, setNumber: 0, type: true }),
                // getPendingAgreementTransfer({ "storeNumber": storeNumber, "agreementStatus": "PEND",limit:10,offset: 1 }),
                getDrawerDetail({storeNumber: storeNumber,businessDate: moment().format('YYYY-MM-DD')})
                // getConfigDetails({ storeNumbers: [sessionStorage.getItem('storeNumber')], paramKeyNames: ['DropMaxTimeLimit'], }),
            ])
            let indexval = '0';
            const resPendingAgreement: any = [];
            while (indexval) {
                const PendingAgrment: any =
                await getPendingAgreementTransfer({ "storeNumber": storeNumber, "agreementStatus": "PEND", limit: 10, offset: Number(indexval) });
                if (PendingAgrment?.agreementDetails?.length == 0 || PendingAgrment == '' || PendingAgrment == undefined || PendingAgrment == null) { break; }
                if (PendingAgrment !== undefined && PendingAgrment !== '' && PendingAgrment !== null) {
                    resPendingAgreement.push(...PendingAgrment?.agreementDetails)
                }
                indexval = String(Number(indexval) + 10)
            }
            const pendingAgreements:any = [];
            resPendingAgreement?.map((el: any) => {
                pendingAgreements.push({ ...el, isChecked: false })
            });
            setApiResPendingAgreement({...apiResPendingAgreement ,pendingAgreements})

            let index = 1;
            const responseArray: any = [];
            while (index) {
                const PendingCustomerAgreements: any =
                    await getPendingCustomerAgreementTransfer({ storeNumber: storeNumber, transferType: 'OG', offset: index });
                if (PendingCustomerAgreements?.transferDetails?.length == 0) { break; }
                if (PendingCustomerAgreements !== undefined && PendingCustomerAgreements !== '') {
                    responseArray.push(...PendingCustomerAgreements?.transferDetails)
                }
                index += 1
            }
            setLoader(false)
            if (responseArray.length == 0) {
                setNoRecordDisableCheckBox(false)
            }
            else {
                setNoRecordDisableCheckBox(true)
            }

            const pendingCustomerTransfer: any =
                responseArray?.map((el: any) => {
                    return {
                        'customerId': el?.customerId,
                        'customerName': el?.customerName,
                        'agreements': el?.agreements?.map((data: any) => ({
                            ...data,
                            isChecked: false
                        }))
                    }
                }
                )
            setApiResponse({ ...apiResponse, pendingCustomerTransfer })
            //  }
            const PendingEvents = closeEndOfDay[0];
            const DropDocument = closeEndOfDay[1];
            const PendingInventoryTranfers = closeEndOfDay[2];
            // const PendingAgrment = closeEndOfDay[3];
            const draweractivity = closeEndOfDay[3];
            // setStoreTime(storeConfigTime.storeProfileResponse.configDetails[0].configDetails[0].paramValue)
            console.log("PendingInventoryTranfers", PendingInventoryTranfers)
            const checkalldrop = DropDocument?.dropDetails?.every((el: any) => el?.isFinalDrop == 0);
            const check = DropDocument?.dropDetails?.some((el: any) => el?.isFinalDrop == 1);
            if((Number(draweractivity?.storeTransaction?.totalCash) + Number(draweractivity?.storeTransaction?.totalNonCash)) >0 && (DropDocument == undefined || DropDocument =='' || DropDocument == null )){
                setIsFinalDropCompleted(false);
                setShowFinalDropText(false);
            }
            else if(((Number(draweractivity?.storeTransaction?.totalCash) + Number(draweractivity?.storeTransaction?.totalNonCash)) == 0) && checkalldrop){
                setIsFinalDropCompleted(false);
                setShowFinalDropText(false);
            }
            else if((check || checkalldrop) && ((Number(draweractivity?.storeTransaction?.totalCash) + Number(draweractivity?.storeTransaction?.totalNonCash)) > 0)){
                setIsFinalDropCompleted(false);
                setShowFinalDropText(false);
            } else {
                setIsFinalDropCompleted(true);
                if(DropDocument?.dropDetails){
                    setShowFinalDropText(true);
                }
                else{
                    setShowFinalDropText(false);
                }
            }   
            // setIsFinalDropCompleted(DropDocument?.dropDetails?.some((el: any) => el.isFinalDrop == 1))
            if (pendingAgreements?.length == 0) {
                setNoRecordVoidDisable(false)
            }
            else {
                setNoRecordVoidDisable(true)
            }
            const resultValue: any[] = [];
            debugger
            PendingEvents?.data?.appointments?.map((el: any) => {
                if (el.customerInfo &&
                    (['D', 'CO', 'S', 'CI', 'P'].includes(el.appointmentInfo.appointmentType))
                    && (el.appointmentInfo.appointmentStatus != "CMP")) {
                    resultValue.push(el)
                }
            })
            // PendingEvents?.events?.map((el: any) => {
            //     if (el.customerInformation != undefined &&
            //         (el.appointmentInfo == "Delivery" || el.type == "CarryOut" || el.type == "Service" || el.type == "Pickup" || el.type == "Inhomeservice" || el.type == "CarryIn")
            //         && (el.status != "Completed")) {
            //         resultValue.push(el)
            //     }
            // })
            const initialArray: any = [];
            resultValue?.map((el) => {
                debugger
                const storeInd = initialArray?.findIndex((a: { timeSlot: { startTime: any; }; }) => a.timeSlot?.startTime == el.timeSlotInfo?.startTime)
                if (storeInd >= 0) {
                    const type = el.appointmentInfo.appointmentType;
                    const sdfgv = el.customerInfo;
                    const types = [...initialArray[storeInd].type, type];
                    initialArray[storeInd].type = types;
                    initialArray[storeInd].typeDesc = [...initialArray[storeInd].typeDesc, el?.appointmentInfo?.appointmentTypeDesc];
                    const ds = [...initialArray[storeInd].eventToBind, sdfgv];
                    initialArray[storeInd].eventToBind = ds;
                } else {
                    const formedOBJ = {
                        timeSlot: el.timeSlotInfo,
                        eventToBind: [el.customerInfo],
                        type: [el.appointmentInfo?.appointmentType],
                        typeDesc: [el.appointmentInfo?.appointmentTypeDesc]
                    }
                    initialArray.push(formedOBJ)
                }
            })
            let totalOverShort = 0;
            DropDocument?.dropDetails?.map((el: any) => {
                if (el.isFinalDrop == 1) {
                    totalOverShort += el.overShortCashAmount + el.overShortNonCashAmount;
                }
            })

            if (DropDocument?.dropDetails?.some((el: any) => el.isFinalDrop == 1)) {
                setSummaryTotal(totalOverShort)
            }
            else {
                setSummaryTotal('0.00')
            }
            setCloseEndOfDay({
                ...closeEndOfDay,
                // dropMaxTime: dropMaxTime,
                FinalDrop: DropDocument,
                PendingEvents: initialArray,
                // pendingAgreementDetails: pendingAgreements,
                PendingInventoryTranfers: PendingInventoryTranfers
            });
        }
        catch (e) {
            console.log(e);
        }
    };
    const nextScrollPendingAgreement = () => {
        console.log("nextScrollPendingAgreement");
    }
    const nextScroll = async () => {
        console.log("next")
    };
    //pending Customer Agreement transfer
    const handlePendingAgreement = (e: any, customerId: any) => {
        // debugger;
        const agreement =
            apiResponse?.pendingCustomerTransfer?.map((value: any) => {

                return {
                    'customerId': value.customerId,
                    'customerName': value.customerName,
                    'agreements': value.agreements.map((data: any) => ({
                        ...data,
                        isChecked: data.isChecked == false && value.customerId == customerId ? true : data.isChecked == true && value.customerId !== customerId ? true : false
                    }
                    ))
                }
            })

        setApiResponse({ ...apiResponse, pendingCustomerTransfer: agreement })
        const agreementId: boolean[] = [];
        // const agreementLength = 0;
        apiResponse.pendingCustomerTransfer.map((el: any) => {
            el.agreements.map((data: any) => {
                agreementId.push(data.agreementId)
            })
        })
        const isChecked: boolean[] = [];
        agreement?.map((value: any) => {
            value.agreements.map((data: any) => {
                isChecked.push(data.isChecked)
                if (isChecked.every(e => e === true) && agreementId.length == isChecked.length) {
                    // alert("Enters in")
                    setRecallCheck(true)
                    setSelectAllCheck(true)
                }
                else if (data.isChecked === false && selectAllCheck) {
                    setRecallCheck(false)
                }
            })
        })

    }
    //Pending Agreements
    const handlePendingAgreementCheckBox = (e: any, agreementId: any) => {
        // debugger;
        const pendingAgreements = apiResPendingAgreement?.pendingAgreements?.map((el: any) => {
            if (el.isChecked == false && el.agreementId == agreementId) {
                el.isChecked = true;
            }
            else if (el.isChecked == true && el.agreementId !== agreementId) {
                el.isChecked = true;
            }
            else {
                el.isChecked = false;
            }
            return el;
        })
        // setCloseEndOfDay({ ...closeEndOfDay, pendingAgreementDetails: pendingAgreements })
        setApiResPendingAgreement({...apiResPendingAgreement ,pendingAgreements: pendingAgreements})
        const agreementIds: boolean[] = [];
        apiResPendingAgreement?.pendingAgreements?.map((el: any) => {
            agreementIds.push(el.agreementId)
        })
        const isChecked: boolean[] = [];
        pendingAgreements?.map((value: any) => {
            isChecked.push(value.isChecked)
            if (isChecked.every(e => e === true) && agreementIds.length == isChecked.length) {
                // alert("Enters in")
                setVoidCheck(true)
                setSelectAllCheckVoid(true)
            }
            else if (value.isChecked === false && selectAllCheckVoid) {
                setVoidCheck(false)
            }
        })
    }
    function handleModels(key: string, value: boolean) {
        setModals({ ...modals, [key]: value });
    }
    function handleCancel() {
        history.push({
            pathname: `/dashboard/homepage`,
        });
    }
    function handlePendingEvent() {
        history.push({
            pathname: `/dap/calendar`,
        })
    }
    function handleCloseEODBtn() {
        setLoaderFooter(true);
        setSecondLevelAuthenOn(true);
        // setLoaderFooter(false);
    }
    function handleFinalDropYesBtn() {
        //  debugger;
        // const currentTime = moment().format('hh:mm A')
        // if (currentTime < storeTime) {
        //     setModals({
        //         ...modals,
        //         ConfiguredDropMaxTimeLimit: true,
        //         finalDropIsOpen: false,
        //     });
        // } else {
            history.push({
                pathname: `/cashmgmt/final/drop`,
                search:`?origin=closeeod`
            });
        // }
    }
    function ConfiguredDropMaxLimit_NoBtn() {
        handleModels('ConfiguredDropMaxTimeLimit', false)
    }
    function ConfiguredDropMaxLimit_YesBtn() {
        history.push({
            pathname: `/cashmgmt/final/drop`,
        });
    }
    function handleFinalDropNoBtn() {
        setIsFinalDropCompleted(true)
        handleModels('finalDropIsOpen', false)
    }
    function handlePendingCusAgreementTransferNoBtn() {
        // debugger;
        handleModels('pendingCustomerAgreementTransferIsOpen', false)
    }
    function noDataSelectedYesBtn() {
        setLoaderFooter(true)
        history.push({
            pathname: `/dashboard/homepage`,
        });
    }
    function noDataSelectedNoBtn() {
        setAllCountPndingCustomer(0)
        setCountPndingCustomer(0)
        handleModels('noDataSelected', false)
    }
    function handlePendingNoBtn() {
        setAllCountPndingCustomer(0)
        setCountPndingCustomer(0)
        handleModels('selectedPendingCustomerAgreementTransferIsOpen', false)
    }
    function EndOfDayProcessNoBtn() {
        setCountPndingCustomer(0)
        setAllCountPndingCustomer(0)
        handleModels('endOfDayProcessIsOpen', false)
    }
    const UpdateInfoMsgPopup = () => {
        return (
            <Grid item md={12} className="px-2">
                <Grid
                    item
                    md={12}
                    className="text-center"
                    style={{ textAlign: 'center' }}
                >
                    {isSuccess ? (
                        <SuccessIcon style={{ textAlign: 'center' }}></SuccessIcon>
                    ) : (
                        <Alerticon style={{ textAlign: 'center' }}></Alerticon>
                    )}
                    <Typography
                        variant={'body2'}
                        style={{ marginTop: '1%', marginBottom: '1%' }}
                    >
                        {isSuccess
                            ? 'Close EOD Successfully Completed'
                            : // eslint-disable-next-line sonarjs/no-duplicate-string
                            'Something went wrong'}
                    </Typography>
                </Grid>
                <Grid
                    container
                    className="text-center mt-4"
                    style={{ justifyContent: 'center', marginTop: '20px' }}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        color="primary"
                        variant="contained"
                        isRounded={false}
                        style={{
                            textTransform: 'none',
                            // eslint-disable-next-line sonarjs/no-duplicate-string
                            width: '100px !important',
                            height: '35px',
                        }}
                        onClick={() => {
                            history.push('/dashboard/homepage');
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const EndOfDayProcessYesBtn = async () => {
        setLoaderFooter(true)
        handleModels('endOfDayProcessIsOpen', false)
        try {
            let agreementTransferResponse: any;
            let customerTransferResponse: any;
            setLoader(true);
            const CustomerIds: any = [];

            apiResponse?.pendingCustomerTransfer?.map((value: any) => {

                const isChecked: boolean[] = [];
                value?.agreements?.map((data: any) => {
                    isChecked?.push(data.isChecked)
                })
                if (isChecked?.every(e => e === true)) { CustomerIds?.push(value.customerId) }
            })
            const payload = {
                "action": "TRL",
                "customerIds": CustomerIds,
                "currentStoreNumber": window.sessionStorage.getItem('storeNumber'),
            }
            if (CustomerIds.length > 0) {
                customerTransferResponse = await manageAgreementTransaction(payload);
            }
            //pending agreements
            const agreementId: any = [];
            apiResPendingAgreement?.pendingAgreements?.map((value: any) => {
                if (value?.isChecked === true) {
                    agreementId?.push(value?.agreementId)
                }
            })
            let payloadVoid: any;
            const requestPayloadArray: any = []
            agreementId.map((value: any) => {
                payloadVoid = {
                    "storeNumber": window.sessionStorage.getItem('storeNumber'),
                    "agreementId": 'agreementId',
                    "statusReason": "CCMV",
                    "type": "return"
                }
                payloadVoid.agreementId = value.toString()
                requestPayloadArray.push(payloadVoid)
            })
            if (agreementId.length > 0) {
                agreementTransferResponse = await Promise.all(requestPayloadArray.map((val: any) => UpdateVoidStatus(val)))
            }
            setLoader(false);
            if (agreementTransferResponse || customerTransferResponse) {
                setSuccessPopup(true)
            }
            // }
            else {
                setSuccessPopup(false); setIsSucess(false)
            }
        } catch (e: any) {
            setSuccessPopup(false);
            setLoader(false);
        }
    };
    function ConfiguredDropMaxLimit() {
        return (
            <RACModalCard
                isOpen={modals.ConfiguredDropMaxTimeLimit}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => handleModels('ConfiguredDropMaxTimeLimit', false)}
                // eslint-disable-next-line react/no-children-prop
                children={ConfiguredDropMaxLimitPopup()}
            // title="Validation Message"
            />
        );
    }
    function EndOfDayProcessModalCard() {
        return (
            <RACModalCard
                isOpen={modals.endOfDayProcessIsOpen}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => { handleModels('endOfDayProcessIsOpen', false); setAllCountPndingCustomer(0); setCountPndingCustomer(0) }}
                // eslint-disable-next-line react/no-children-prop
                children={EndOfDayProcessPopup()}
            // title="Validation Message"
            />
        );
    }
    //*********************** This is the function used to only recall or void the agreements  */
    const EndOfDayProcessPopup = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    className={`${classes.textCenter} ${classes.marginTop}`}
                >
                    <Typography className={classes.RACPOPMsg}>
                        The End of the Day Process will be Submitted to the background.
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                ><RACButton
                    className={`${classes.spacerMR2} ${classes.foc}`}
                    size="small"
                    variant="outlined"
                    color="primary"
                    isRounded={false}
                    onClick={() => EndOfDayProcessNoBtn()}
                // onClick={() => handleModels('validateNonCashIsOpen',false)}
                >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => EndOfDayProcessYesBtn()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const ConfiguredDropMaxLimitPopup = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    className={`${classes.textCenter} ${classes.marginTop}`}
                >
                    <Typography className={classes.RACPOPMsg}>
                        The time now is less than the configured final drop time.
                        <Typography className={classes.RACPOPMsg}> Do you want to continue?</Typography>
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                ><RACButton
                    className={`${classes.spacerMR2} ${classes.foc}`}
                    size="small"
                    variant="outlined"
                    color="primary"
                    isRounded={false}
                    onClick={() => ConfiguredDropMaxLimit_NoBtn()}
                // onClick={() => handleModels('validateNonCashIsOpen',false)}
                >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => ConfiguredDropMaxLimit_YesBtn()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const handlePendingYesBtn = async () => {
        const isCheckedVoid: boolean[] = [];
        apiResPendingAgreement?.pendingAgreements?.map((value: any) => {
            isCheckedVoid?.push(value.isChecked)
        })
        if (apiResPendingAgreement?.pendingAgreements?.length > 0 && isCheckedVoid.some(e => e === true) && countPndingCustomer === 0) {
            setCountPndingCustomer(1)
            if (isCheckedVoid.every(e => e === true)) {
                //all the agreement will be voided.
                setModals({
                    ...modals,
                    pendingVoidAgreements: true,
                    selectedPendingCustomerAgreementTransferIsOpen: false,
                });
            }
            else if (isCheckedVoid.some(e => e === true)) {
                //selcted void
                setModals({
                    ...modals,
                    selectedVoidAgreementIsOpen: true,
                    selectedPendingCustomerAgreementTransferIsOpen: false,
                });

            }
        }
        else {
            setModals({
                ...modals,
                endOfDayProcessIsOpen: true,
                selectedPendingCustomerAgreementTransferIsOpen: false,
            });
        }
    }
    const selectedVoidAgreementNOBtn = () => {
        setAllCountPndingCustomer(0)
        setCountPndingCustomer(0)
        handleModels('selectedVoidAgreementIsOpen', false)
    }

    const selectedVoidAgreementYesBtn = async () => {
        const ischeckedCusRecall: boolean[] = [];
        apiResponse?.pendingCustomerTransfer?.map((value: any) => {

            value?.agreements?.map((data: any) => {
                ischeckedCusRecall?.push(data.isChecked)
            })
        })
        if (apiResponse?.pendingCustomerTransfer.length > 0 && ischeckedCusRecall.some(e => e === true) && countPndingCustomer === 0 && allCountPndingCustomer === 0) {
            if (ischeckedCusRecall.every(e => e === true)) {
                //all pending customer agreement is recalled if iscked all is true
                // handleModels('pendingCustomerAgreementTransferIsOpen', true)
                setModals({
                    ...modals,
                    pendingCustomerAgreementTransferIsOpen: true,
                    selectedVoidAgreementIsOpen: false,
                });
            }
            else if (ischeckedCusRecall.some(e => e === true)) {
                // if some is true then slow selected customer agreement will be recalled
                // handleModels('selectedPendingCustomerAgreementTransferIsOpen', true)
                setModals({
                    ...modals,
                    selectedPendingCustomerAgreementTransferIsOpen: true,
                    selectedVoidAgreementIsOpen: false,
                });
            }
        }
        else {
            setModals({
                ...modals,
                endOfDayProcessIsOpen: true,
                selectedVoidAgreementIsOpen: false,
            });
            setCountPndingCustomer(0)
        }
    }
    const handlePendingCusAgreementTransferYesBtn = async () => {
        const isCheckedVoid: boolean[] = [];
        apiResPendingAgreement?.pendingAgreements?.map((value: any) => {
            isCheckedVoid?.push(value.isChecked)
        })
        if (apiResPendingAgreement?.pendingAgreements?.length > 0 && isCheckedVoid.some(e => e === true) && countPndingCustomer === 0 && allCountPndingCustomer === 0) {
            setAllCountPndingCustomer(1)
            if (isCheckedVoid.every(e => e === true)) {
                //all the agreement will be voided.
                setModals({
                    ...modals,
                    pendingVoidAgreements: true,
                    //    selectedPendingCustomerAgreementTransferIsOpen: false,
                    pendingCustomerAgreementTransferIsOpen: false,
                });
            }
            else if (isCheckedVoid.some(e => e === true)) {
                setModals({
                    ...modals,
                    selectedVoidAgreementIsOpen: true,
                    //    selectedPendingCustomerAgreementTransferIsOpen: false,
                    pendingCustomerAgreementTransferIsOpen: false,
                });

            }
        }
        else {
            setModals({
                ...modals,
                endOfDayProcessIsOpen: true,
                // selectedPendingCustomerAgreementTransferIsOpen: false,
                pendingCustomerAgreementTransferIsOpen: false,
            });
        }
    }
    const handlePendingAgreementTransferNoBtn = () => {
        setAllCountPndingCustomer(0)
        setCountPndingCustomer(0)
        handleModels('pendingVoidAgreements', false)
    }
    const handlePendingAgreementTransferYesBtn = async () => {
        const ischeckedCusRecall: boolean[] = [];
        apiResponse?.pendingCustomerTransfer?.map((value: any) => {

            value?.agreements?.map((data: any) => {
                ischeckedCusRecall?.push(data.isChecked)
            })
        })
        if (apiResponse?.pendingCustomerTransfer.length > 0 && ischeckedCusRecall.some(e => e === true) && allCountPndingCustomer === 0) {
            if (ischeckedCusRecall.every(e => e === true)) {
                //all pending customer agreement is recalled if iscked all is true
                // handleModels('pendingCustomerAgreementTransferIsOpen', true)
                setModals({
                    ...modals,
                    pendingCustomerAgreementTransferIsOpen: true,
                    // selectedVoidAgreementIsOpen: false,
                    pendingVoidAgreements: false
                });
            }
            else if (ischeckedCusRecall.some(e => e === true) && allCountPndingCustomer === 0) {
                // if some is true then slow selected customer agreement will be recalled
                // handleModels('selectedPendingCustomerAgreementTransferIsOpen', true)
                setModals({
                    ...modals,
                    selectedPendingCustomerAgreementTransferIsOpen: true,
                    // selectedVoidAgreementIsOpen: false,
                    pendingVoidAgreements: false
                });
            }
        }
        else {
            setModals({
                ...modals,
                endOfDayProcessIsOpen: true,
                // selectedVoidAgreementIsOpen: false,
                pendingVoidAgreements: false
            });
            // setAllCountPndingCustomer(0)
        }
    }

    const seconduthenResponses = async (e: Event) => {
        setLoaderFooter(false)
        setSecondLevelAuthenOn(false);
        const ischeckedCusRecall: boolean[] = [];
        apiResponse?.pendingCustomerTransfer?.map((value: any) => {

            value?.agreements?.map((data: any) => {
                ischeckedCusRecall?.push(data.isChecked)
            })
        })
        const isCheckedVoid: boolean[] = [];
        apiResPendingAgreement?.pendingAgreements?.map((value: any) => {
            isCheckedVoid?.push(value.isChecked)
        })
        if (!isFinalDropCompleted) {
            handleModels('finalDropIsOpen', true)
        }
        else if (apiResponse?.pendingCustomerTransfer?.length > 0 && ischeckedCusRecall.length > 0 && ischeckedCusRecall.some(e => e === true)) {
            if (ischeckedCusRecall.every(e => e === true)) {
                //all pending customer agreement is recalled if iscked all is true
                handleModels('pendingCustomerAgreementTransferIsOpen', true)
            }
            else {
                if (ischeckedCusRecall.some(e => e === true)) {

                    // if some is true then slow selected customer agreement will be recalled
                    handleModels('selectedPendingCustomerAgreementTransferIsOpen', true)
                }
            }
        }
        // eslint-disable-next-line sonarjs/no-identical-expressions
        else if (isCheckedVoid.length > 0 && isCheckedVoid.length > 0 && isCheckedVoid.some(e => e === true)) {
            if (isCheckedVoid.every(e => e === true)) {
                //all the agreement will be voided.
                handleModels('pendingVoidAgreements', true)
            }
            else {
                if (isCheckedVoid.some(e => e === true)) {
                    // some of them is voided
                    handleModels('selectedVoidAgreementIsOpen', true)
                }
            }
        }

        else if (ischeckedCusRecall.every(e => e === false) && isCheckedVoid.every(e => e === false)) {
            // if no data is present and also if the ischked is false for all
            handleModels('noDataSelected', true)
        }
    }
    function FinalDropPOpUpIsOpen() {
        return (
            <RACModalCard
                isOpen={modals.finalDropIsOpen}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => handleModels('finalDropIsOpen', false)}
                // eslint-disable-next-line react/no-children-prop
                children={finalDropIsOpenPopup()}
            // title="Validation Message"
            />
        );
    }
    function PendingIsOpen() {
        return (
            <RACModalCard
                isOpen={modals.selectedPendingCustomerAgreementTransferIsOpen}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => { handleModels('selectedPendingCustomerAgreementTransferIsOpen', false); setAllCountPndingCustomer(0); setCountPndingCustomer(0) }}
                // eslint-disable-next-line react/no-children-prop
                children={pendingIsOpenPopUp()}
            // title="Validation Message"
            />
        );
    }
    function SelectedVoidAgreementModelCard() {
        return (
            <RACModalCard
                isOpen={modals.selectedVoidAgreementIsOpen}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => { handleModels('selectedVoidAgreementIsOpen', false); setAllCountPndingCustomer(0); setCountPndingCustomer(0) }}
                // eslint-disable-next-line react/no-children-prop
                children={selectedVoidAgreementPopUp()}
            // title="Validation Message"
            />
        );
    }
    const selectedVoidAgreementPopUp = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    className={`${classes.textCenter} ${classes.marginTop}`}
                >
                    <Typography className={classes.RACPOPMsg}>
                        Selected pending agreement will be voided,
                        <Typography className={classes.RACPOPMsg}>do you want to continue?</Typography>
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="outlined"
                        color="primary"
                        isRounded={false}
                        // onClick={() => handleModels('selectedVoidAgreementIsOpen', false) }
                        onClick={() => selectedVoidAgreementNOBtn()}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => selectedVoidAgreementYesBtn()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    function NoDataSelectedIsOpen() {
        return (
            <RACModalCard
                isOpen={modals.noDataSelected}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => { handleModels('noDataSelected', false); setAllCountPndingCustomer(0); setCountPndingCustomer(0) }}
                // eslint-disable-next-line react/no-children-prop
                children={noDataSelectedPopUp()}
            // title="Validation Message"
            />
        );
    }
    function PendingVoidAgreements() {
        return (
            <RACModalCard
                isOpen={modals.pendingVoidAgreements}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => { handleModels('pendingVoidAgreements', false); setAllCountPndingCustomer(0); setCountPndingCustomer(0) }}
                // eslint-disable-next-line react/no-children-prop
                children={pendingVoidAgreements()}
            // title="Validation Message"
            />
        );
    }
    const pendingVoidAgreements = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    className={`${classes.textCenter} ${classes.marginTop}`}
                >
                    <Typography className={classes.RACPOPMsg}>
                        All pending agreements will be voided,
                        <Typography className={classes.RACPOPMsg}>do you want to continue?</Typography>
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="outlined"
                        color="primary"
                        isRounded={false}
                        // onClick={() => handleModels('pendingVoidAgreements', false)}
                        onClick={() => handlePendingAgreementTransferNoBtn()}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={(e: any) => handlePendingAgreementTransferYesBtn()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    function PendingCustomerAgreementTransferIsOpen() {
        return (
            <RACModalCard
                isOpen={modals.pendingCustomerAgreementTransferIsOpen}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => { handleModels('pendingCustomerAgreementTransferIsOpen', false); setAllCountPndingCustomer(0); setCountPndingCustomer(0) }}
                // eslint-disable-next-line react/no-children-prop
                children={pendingCustomerAgreementTransferPopUp()}
            // title="Validation Message"
            />
        );
    }

    //************************************* This is only used to navifgate to the dashboard */
    const noDataSelectedPopUp = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    className={`${classes.textCenter} ${classes.marginTop}`}
                >
                    <Typography className={classes.RACPOPMsg}>
                        The End of the Day Process will be Submitted to the background.
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="outlined"
                        color="primary"
                        isRounded={false}
                        onClick={() => noDataSelectedNoBtn()}
                    // onClick={() => handleModels('validateNonCashIsOpen',false)}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={(e: any) => noDataSelectedYesBtn()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const pendingCustomerAgreementTransferPopUp = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    className={`${classes.textCenter} ${classes.marginTop}`}
                >
                    <Typography className={classes.RACPOPMsg}>
                        All pending customer agreement transfer will be recalled,
                        <Typography className={classes.RACPOPMsg}>do you want to continue?</Typography>
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="outlined"
                        color="primary"
                        isRounded={false}
                        onClick={() => handlePendingCusAgreementTransferNoBtn()}
                    // onClick={() => handleModels('validateNonCashIsOpen',false)}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={(e: any) => handlePendingCusAgreementTransferYesBtn()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const pendingIsOpenPopUp = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    className={`${classes.textCenter} ${classes.marginTop}`}
                >
                    <Typography className={classes.RACPOPMsg}>
                        Selected pending customer agreement transfer will be recalled,
                        <Typography className={classes.RACPOPMsg}>do you want to continue?</Typography>
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="outlined"
                        color="primary"
                        isRounded={false}
                        onClick={() => handlePendingNoBtn()}
                    // onClick={() => handleModels('validateNonCashIsOpen',false)}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => handlePendingYesBtn()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const finalDropIsOpenPopup = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    className={`${classes.textCenter} ${classes.marginTop}`}
                >
                    <Typography className={classes.RACPOPMsg}>
                        Final drop has not been Completed for the day.
                        <Typography className={classes.RACPOPMsg}>Do you want to Continue to the Final Drop Window?</Typography>
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="outlined"
                        color="primary"
                        isRounded={false}
                        onClick={() => handleFinalDropNoBtn()}
                    // onClick={() => handleModels('validateNonCashIsOpen',false)}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => handleFinalDropYesBtn()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const tableStricky: any = () => {
        if (loaderFooter) {
            return -0;
        }
    }
    const colorBind: any = (index: any) => {

        if (index == 'D') {
            return '#FFA61E';
        }
        if (index == 'S') {
            return '#DD4F89';
        }
        if (index == 'CO') {
            return '#26C0C7';
        }
        if (index == 'CI') {
            return '#B36458';
        }
        if (index == 'P') {
            return '#3DCFA3';
        }
        if (index == 'In home service') {
            return '#AF7AB3';
        }
    }
    const eventToBind: any = (binder: any) => {
        return binder.eventToBind?.map((el: any, index: any) => {
            return (
                <Grid style={{
                    width: "29%",
                    margin: "0.5%",
                    zIndex: 0, textDecoration: 'none', fontSize: 'medium',
                    fontWeight: 'bold', color: '#2179FE',
                    borderLeft: '3px solid #2E31BE', display: 'flex',
                    position: 'relative',

                }} key={index} >
                    <a style={{
                        width: "100%",
                        position: 'relative',
                        display: 'block',
                        padding: '0.5rem1rem',
                        color: '#212529',
                        textDecoration: 'none',
                        backgroundColor: '#F4F7FF',
                        border: '1px solid rgba(0, 0, 0, 0.125)',
                        paddingLeft: "5%",
                        cursor: 'pointer',
                        borderRadius: "3px",
                        boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
                    }}
                        onClick={() => handlePendingEvent()}
                    >
                        <Grid className={classes.row}>
                            <Grid className={`${classes.collg12} ${classes.colxl8} ${classes.colrs7}`}>
                                <Typography style={{
                                    fontFamily: 'OpenSans-bold',
                                    fontSize: ' 14px',
                                    color: '#2E31BE'
                                }}>
                                    {el?.firstName + ` ` + el?.lastName}
                                </Typography>
                            </Grid>
                            {/* {type(binder.type)} */}
                            <Grid className={`${classes.collg12} ${classes.colxl4} ${classes.colrs5}`} style={{ width: "36%" }}>
                                <Grid className={`${classes.uefloatend}`} style={{ width: "68%" }}>
                                    <span className={`${classes.upcomingeventstatus}`} style={{ background: colorBind(binder.type[index]), marginRight: "8%" }}></span>
                                    <span className={`${classes.fs12}`} style={{ marginTop: "8%" }}>{binder.typeDesc[index]}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography className={`${classes.mb1} ${classes.fs12}`}>
                            <Grid>{(el.address?.addressLine1 || '')}</Grid>
                            <Grid>{(el.address?.city || '')}, {(el.address?.state || '') + `-` + (el.address?.postalCode || '')}</Grid>
                        </Typography>
                    </a>
                </Grid>
            )
        })
    }
    const handleVoidPendingAgreement = (voidAgreement: any) => {
        // debugger;
        const voidAllAgreement =
        apiResPendingAgreement?.pendingAgreements?.map((el: any) => {
                if (voidAgreement == false) {
                    el.isChecked = true;
                }
                else {
                    el.isChecked = false;
                }
                return el;
            })
            setApiResPendingAgreement({...apiResPendingAgreement,pendingAgreements:voidAllAgreement})
        setSelectAllCheckVoid(!voidAgreement);
    }

    const handleRecallPendingAgreement = (selectAll: any) => {

        // debugger;
        const agreement =
            apiResponse.pendingCustomerTransfer.map((value: any) => {

                return {
                    'customerId': value.customerId,
                    'customerName': value.customerName,
                    'agreements': value.agreements.map((data: any) => ({
                        ...data,
                        isChecked: selectAll == false ? true : false

                    }))
                }

            })
        setApiResponse({ ...apiResponse, pendingCustomerTransfer: agreement })
        setSelectAllCheck(!selectAll);
    }
    const handleAgreementTransferRequest = () => {
        history.push({
            pathname: `/agreement/transferreceive`,
        });
    }
    const handlePendingAgreementRequest = (data: any) => {
        history.push({
            pathname: `/agreement/info/details/${data.customerId}/${data.agreementId}`
        })
    }
    const handlePendingInventoryTransfer = (itemNo: any) => {
        history.push({
            pathname: `/inventory/${itemNo}/inventoryinformation`,
        });
    }
    const renderPendingInventoryTransfersHead = () => {
        return (
            <React.Fragment>
                <RACTableCell style={{ textAlign: "center" }}>
                    <Typography variant="h5">Item #</Typography>
                </RACTableCell>
                <RACTableCell style={{ textAlign: "center" }} >
                    <Typography
                        variant="h5"
                    >
                        Description
                    </Typography>
                </RACTableCell>
                <RACTableCell style={{ textAlign: "center" }} >
                    <Typography
                        variant="h5">
                        Store #
                    </Typography>
                </RACTableCell>
            </React.Fragment>
        );
    };
    const renderPendingAgreementsHead = () => {
        return (
            <React.Fragment>
                <RACTableCell style={{ zIndex: tableStricky() }}>

                </RACTableCell>
                <RACTableCell style={{ zIndex: tableStricky() }}>
                    <Typography variant="h5">Agreement #</Typography>
                </RACTableCell>
                <RACTableCell style={{ zIndex: tableStricky() }}>
                    <Typography
                        variant="h5"
                    >
                        Customer Name
                    </Typography>
                </RACTableCell>
            </React.Fragment>
        );
    };
    const renderPendingCustomerAgreementTransferHead = () => {
        return (
            <React.Fragment>
                <RACTableCell style={{ zIndex: tableStricky() }} >

                </RACTableCell>
                <RACTableCell style={{ zIndex: tableStricky() }} >
                    <Typography variant="h5">Agreement #</Typography>
                </RACTableCell>
                <RACTableCell style={{ zIndex: tableStricky() }}  >
                    <Typography
                        variant="h5"
                    >
                        Customer Name
                    </Typography>
                </RACTableCell>
            </React.Fragment>
        );
    };
    const renderPendingAgreementsContent = (): any => {
        // debugger;
        return apiResPendingAgreement?.pendingAgreements?.map((data: any) => {
            return (
                <>
                    <React.Fragment>
                        {/* Row 1 */}
                        <RACTableRow className={classes.tableRow}>
                            <RACTableCell >
                                <RACCheckBox
                                    checked={data.isChecked}
                                    onClick={(e: any) =>
                                        handlePendingAgreementCheckBox(e, data.agreementId)
                                    }
                                    style={{ marginLeft: '10px' }}
                                    size="medium"
                                />
                            </RACTableCell>
                            <RACTableCell>
                                <Typography style={{ color: '#4A5174' }}>
                                    <span style={{ color: '#3182FF', cursor: 'pointer' }}>
                                        <a
                                            onClick={() => handlePendingAgreementRequest(data)
                                            }
                                        >  {data.agreementNumber}</a>{' '}
                                    </span>
                                </Typography>
                            </RACTableCell>
                            <RACTableCell>
                                <Typography style={{ color: '#4A5174' }}>
                                    {data.customerFirstName + ` ` + data.customerLastName}
                                </Typography>
                            </RACTableCell>
                        </RACTableRow>
                    </React.Fragment>
                </>
            );
        });
    };
    const renderPendingCustomerAgreementTransferContent = (): any => {
        // debugger;
        return apiResponse?.pendingCustomerTransfer?.map((value: any) => {
            return value.agreements?.map((data: any) => {
                return (
                    <>
                        <React.Fragment>
                            {/* Row 1 */}
                            <RACTableRow className={classes.tableRow}>
                                <RACTableCell >
                                    <RACCheckBox
                                        checked={data.isChecked}
                                        onClick={(e: any) =>

                                            handlePendingAgreement(e, value.customerId)
                                        }
                                        style={{ marginLeft: '10px' }}
                                        size="medium"
                                    />
                                </RACTableCell>
                                <RACTableCell>
                                    <Typography >
                                        <span style={{ color: '#3182FF', cursor: 'pointer' }}>
                                            <a
                                                onClick={() => handleAgreementTransferRequest()}
                                            > {data.agreementNumber}</a>{' '}
                                        </span>
                                    </Typography>
                                </RACTableCell>
                                <RACTableCell>
                                    <Typography style={{ color: '#4A5174' }}>
                                        {value.customerName}
                                    </Typography>
                                </RACTableCell>
                            </RACTableRow>
                        </React.Fragment>
                    </>
                );
            });
        });
    };
    const bindPendingEvents = (): any => {
        //debugger;
        return closeEndOfDay?.PendingEvents?.map((el: any) => {
            const startTime: any = moment(el.timeSlot.startTime, "HH:mm:ss").format("hh:mm A")
            const endTime: any = moment(el.timeSlot.endTime, "HH:mm:ss").format("hh:mm A")
            return (
                <>
                    <CardContent className={classes.cardBody}>
                        <Grid style={{ display: 'flex', flexDirection: 'column', paddingLeft: 0, marginBottom: 0, borderRadius: '0.25rem' }}>
                            <Grid style={{ width: "100%" }}>
                                <Grid className={`${classes.row} `}>
                                    <Grid style={{ width: '10%' }}>
                                        <Typography style={{ marginTop: '15px' }}>
                                            {startTime + `-` + endTime}

                                        </Typography>

                                    </Grid>
                                    {/* <Grid style={{width:"90%"}}> */}
                                    {eventToBind(el)}
                                    {/* </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </>
            )
        })
    }
    const truncateString = (str: string): string => {
        if (str.length > 17) {
            return (str.substring(0, 16) + '...').toString();
        }
        return str;
    };
    const renderPendingInventoryTransfersContent = (): any => {
        // debugger;
        return closeEndOfDay?.PendingInventoryTranfers?.inventoryTransferList?.map((data: any) => {
            return (
                <>
                    <React.Fragment>
                        {/* Row 1 */}
                        <RACTableRow className={classes.tableRow}>
                            <RACTableCell style={{ textAlign: "center" }}>
                                <Typography style={{ color: '#4A5174' }}>
                                    <span style={{ color: '#3182FF', cursor: 'pointer' }}>
                                        <a
                                            onClick={() => handlePendingInventoryTransfer(data.inventoryNumber)
                                            }
                                        >   {data.inventoryNumber}
                                        </a>{' '}
                                    </span>
                                </Typography>
                            </RACTableCell>
                            <RACTableCell style={{ textAlign: "center" }} title={data.inventoryDesc}>
                                <Typography style={{ color: '#4A5174' }}>
                                    {truncateString(data.inventoryDesc)}
                                </Typography>
                            </RACTableCell>
                            <RACTableCell style={{ textAlign: "center" }}>
                                <Typography style={{ color: '#4A5174' }}>
                                    {data.transferringToStoreNumber}
                                </Typography>
                            </RACTableCell>
                        </RACTableRow>
                    </React.Fragment>
                </>
            );
        });
    };
    return (
        <>
            {secondLevelAuthenOn ? (
                <SecondFactor
                    setTwoFactorCancelClick={() => setSecondLevelAuthenOn(false)}
                    setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
                    currentRole="CAR"
                    moduleName="Accept Payment"
                //  storeNumber="02202"
                ></SecondFactor>
            ) : null}
            <Grid className={classes.container}>
                <FinalDropPOpUpIsOpen />
                <PendingCustomerAgreementTransferIsOpen />
                <PendingIsOpen />
                <NoDataSelectedIsOpen />
                <ConfiguredDropMaxLimit />
                <EndOfDayProcessModalCard />
                <PendingVoidAgreements />
                <SelectedVoidAgreementModelCard />
                <Grid className={classes.rowMt2}>
                    <Grid className={classes.colMd12mt3}>
                        <Grid className={classes.row}>
                            <Grid className={classes.row} style={{ width: "35%" }}>
                                <Grid style={{ width: "34%" }}>
                                    <Typography variant="h5" className={classes.header}>
                                        Close End of Day
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: "60%" }}>
                                    <Typography className={classes.header} style={{ fontSize: "15px", marginTop: "2px", fontFamily: 'OpenSans-semibold' }}>
                                        Business Day  <span style={{ color: "#6C86A5", marginLeft: "16px" }}>{moment().format('MM/DD/YYYY')}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '99%', marginRight: "1%" }}>
                            <Grid className={classes.row}>
                                <Grid style={{ width: '30%' }}>
                                    <Grid >
                                        <Typography variant="h5" style={{ marginBottom: "2%" }}>
                                            Final Drop
                                        </Typography>
                                        <Card className={classes.card} style={{ padding: "6%" }}>
                                            <CardContent className={classes.cardBody}>
                                                <Grid className={classes.row}>
                                                    <Grid style={{ width: '70%' }}>
                                                        <Typography>
                                                            {showFinalDropText ? 'Summary(Final Drop Completed)Over/Short' : 'Summary(Final Drop not Completed)Over/Short'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid style={{ width: '30%' }}>
                                                        <RACTextbox
                                                            className={`${classes.textWidthamount} ${classes.currency}`}
                                                            dollarTextClassName={classes.dollarClass}
                                                            disabled={true}
                                                            type="text"
                                                            name="envelope"
                                                            id="envelope"
                                                            value={(summaryTotal !== undefined && summaryTotal !== null && summaryTotal !== '' ? parseFloat(summaryTotal) < 0 ? '(' + (-parseFloat(summaryTotal)).toFixed(2) + ')' : (parseFloat(summaryTotal)).toFixed(2) : 0.00).toString()}
                                                            // maxlength={30}
                                                            required={false}
                                                            inputlabel=""
                                                            // OnChange={(e)=>{handleText(e)}}
                                                            isCurrency={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Grid style={{
                                    width: '67%'
                                    , marginLeft: "2.5%"
                                }} >
                                    <Grid container spacing={1}>
                                        <Typography variant="h5" style={{ marginBottom: "1%" }}>
                                            Pending Events
                                        </Typography>
                                        <Card className={classes.card} style={{ padding: "1.3%", overflowY: 'auto', height: '125px', width: '100%' }}  >
                                            <Grid style={{ width: "100%" }} >
                                                {(closeEndOfDay?.PendingEvents?.length) > 0 ?
                                                    bindPendingEvents() :
                                                    <CardContent>
                                                        <Grid style={{ marginLeft: "35%", marginTop: "1%" }}>
                                                            <Grid className={classes.row}>
                                                                <NoRecord style={{ width: '30px', height: '30px' }}></NoRecord>
                                                                <Typography style={{ marginTop: "1.5%" }}>
                                                                    No Record Found
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>

                                                }
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: "99%", marginTop: '1%', marginRight: "1%" }}>
                            <Grid className={classes.row}>
                                <Grid style={{ width: "31.3%", marginRight: '2.9%' }}>
                                    <Typography variant="h5" style={{ marginBottom: "2%" }}>
                                        Pending Inventory Transfers
                                    </Typography>
                                    <Card className={classes.card} style={{ padding: "0%", margin: "0%" }}>
                                        <CardContent className={`${classes.cardBody1} `} style={{ padding: "0%", margin: "0%" }}>
                                            {/* <Grid className={classes.row} style={{ padding: "0%", margin: "0%" }}> */}
                                            {(closeEndOfDay?.PendingInventoryTranfers !== undefined
                                                &&
                                                closeEndOfDay?.PendingInventoryTranfers?.inventoryTransferList !== null
                                                &&
                                                closeEndOfDay?.PendingInventoryTranfers?.inventoryTransferList?.length > 0) ?
                                                <InfiniteScroll
                                                    dataLength={3}
                                                    next={nextScroll}
                                                    hasMore={false}
                                                    height={'246px'}
                                                    loader={
                                                        false
                                                    }
                                                >
                                                    <RACTable
                                                        className={`${classes.table} ${classes.tableBorder}`}
                                                        renderTableHead={renderPendingInventoryTransfersHead}
                                                        renderTableContent={renderPendingInventoryTransfersContent}
                                                    />
                                                </InfiniteScroll> :
                                                (<Grid style={{ padding: "2%" }}>
                                                    <Grid className={classes.row}>
                                                        <Grid style={{ width: "33.3%", marginTop: "1%" }}>
                                                            <Typography variant="h5" >
                                                                Item #
                                                            </Typography>
                                                        </Grid>
                                                        <Grid style={{ width: "33.3%", marginTop: "1%" }}>
                                                            <Typography variant="h5">
                                                                Description
                                                            </Typography>
                                                        </Grid>
                                                        <Grid style={{ width: "33.3%", marginTop: "1%" }}>
                                                            <Typography variant="h5">
                                                                Store #
                                                            </Typography>
                                                        </Grid>
                                                        <Grid style={{ marginTop: "15%", marginBottom: "17%", width: "100%", textAlign: 'center' }}>
                                                            <Grid style={{ marginTop: '1px' }}>
                                                                <NoRecord style={{ height: "30px", width: "30px" }}></NoRecord> </Grid>
                                                            No Record Found
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                )
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid style={{ width: "31.3%", marginRight: '2.8%' }}>
                                    <Typography variant="h5" style={{ marginBottom: "2%" }}>
                                        Pending Agreements
                                    </Typography>
                                    <Card className={classes.card} style={{ height: "245px" }} >
                                        <CardContent className={classes.cardBody1} style={{ padding: "0%", margin: "0%" }}>
                                            {/* <Grid className={classes.row} style={{ padding: "0%", margin: "0%" }}> */}
                                            {apiResPendingAgreement?.pendingAgreements !== undefined &&
                                                apiResPendingAgreement?.pendingAgreements !== 'null' &&
                                                apiResPendingAgreement?.pendingAgreements !== '' &&
                                                apiResPendingAgreement?.pendingAgreements?.length > 0 ?
                                                <InfiniteScroll
                                                    dataLength={3}
                                                    next={nextScrollPendingAgreement}
                                                    hasMore={false}
                                                    height={'193px'}
                                                    loader={false}
                                                >
                                                    <RACTable
                                                        className={`${classes.table} ${classes.tableBorder} `}
                                                        renderTableHead={renderPendingAgreementsHead}
                                                        renderTableContent={renderPendingAgreementsContent}
                                                        stickyHeader
                                                    />
                                                </InfiniteScroll>
                                                :
                                                (<Grid style={{ padding: "2%" }}>
                                                    <Grid className={classes.row}>
                                                        <Grid style={{ width: "20%" }}>

                                                        </Grid>
                                                        <Grid style={{ width: "40%", marginTop: "1%" }}>
                                                            <Typography variant="h5">
                                                                Agreement #
                                                            </Typography>
                                                        </Grid>
                                                        <Grid style={{ width: "40%", marginTop: "1%" }}>
                                                            <Typography variant="h5">
                                                                Customer Name
                                                            </Typography>
                                                        </Grid>
                                                        <Grid style={{ marginTop: "14%", marginBottom: "6.9%", width: "100%", textAlign: 'center' }}>
                                                            <Grid style={{ marginTop: '1px' }}>
                                                                <NoRecord style={{ height: "30px", width: "30px" }}></NoRecord> </Grid>
                                                            No Record Found
                                                        </Grid>
                                                    </Grid>
                                                </Grid>)}
                                            {/* </Grid> */}
                                            <Grid
                                                style={{
                                                    border: 'none',
                                                    borderRadius: '6px',
                                                    width: '100%',
                                                    padding: '2%',
                                                    margin: '0%',
                                                    background: '#F7F7F7',
                                                }}
                                                className={`${classes.float} `}
                                            >
                                                <Grid
                                                    style={{
                                                        backgroundColor: '#fff !important',
                                                        padding: '1rem !important',
                                                        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%) !important',
                                                    }}
                                                >
                                                    <Grid className={classes.row}>

                                                        <RACCheckBox
                                                            checked={selectAllCheckVoid && voidCheck && noRecordVoidDisable}
                                                            onClick={(e: any) =>
                                                                handleVoidPendingAgreement(selectAllCheckVoid)
                                                            }
                                                            style={{ marginLeft: '10px' }}
                                                            size="medium"
                                                        />
                                                        <Typography className={classes.drawerTotal}>
                                                            Void all Pending Agreements
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid style={{ width: "31.3%" }}>
                                    <Typography variant="h5" style={{ marginBottom: "2%" }}>
                                        Pending Customer Agreement Transfers
                                    </Typography>
                                    <Card className={`${classes.card}`}
                                        style={{ height: "245px" }}
                                    >
                                        <CardContent style={{ padding: "0%", margin: "0%" }}>
                                            {/* className={`${classes.cardBody1} ${classes.tableStricky}`} */}
                                            {/* <Grid className={classes.row} style={{ padding: "0%", margin: "0%" }}> */}
                                            {apiResponse?.pendingCustomerTransfer?.length > 0 ?
                                                <InfiniteScroll
                                                    dataLength={3}
                                                    next={nextScroll}
                                                    hasMore={false}
                                                    height={'193px'}
                                                    loader={false}>
                                                    <RACTable
                                                        className={`${classes.table} ${classes.tableBorder}`}
                                                        renderTableHead={renderPendingCustomerAgreementTransferHead}
                                                        renderTableContent={renderPendingCustomerAgreementTransferContent}
                                                        stickyHeader
                                                    />
                                                </InfiniteScroll>
                                                : (
                                                    <Grid style={{ padding: "2%" }}>
                                                        <Grid className={classes.row}>
                                                            <Grid style={{ width: "20%" }}>

                                                            </Grid>
                                                            <Grid style={{ width: "40%", marginTop: "1%" }}>
                                                                <Typography variant="h5">
                                                                    Agreement #
                                                                </Typography>
                                                            </Grid>
                                                            <Grid style={{ width: "40%", marginTop: "1%" }}>
                                                                <Typography variant="h5">
                                                                    Customer Name
                                                                </Typography>
                                                            </Grid>
                                                            <Grid style={{ marginTop: "14%", marginBottom: "6.9%", width: "100%", textAlign: 'center' }}>
                                                                <Grid style={{ marginTop: '1px' }}>
                                                                    <NoRecord style={{ height: "30px", width: "30px" }}></NoRecord> </Grid>
                                                                No Record Found
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            <Grid
                                                style={{
                                                    border: 'none',
                                                    borderRadius: '6px',
                                                    width: '100%',
                                                    padding: '2%',
                                                    margin: '0%',
                                                    background: '#F7F7F7',
                                                }}
                                                className={`${classes.float} `}
                                            >
                                                <Grid className={classes.row}>

                                                    <RACCheckBox
                                                        checked={selectAllCheck && recallCheck && noRecordDisableCheckBox}
                                                        onClick={(e: any) =>
                                                            handleRecallPendingAgreement(selectAllCheck)
                                                        }
                                                        style={{ marginLeft: '10px' }}
                                                        size="medium"
                                                    />
                                                    <Typography className={classes.drawerTotal}>
                                                        Recall all Pending Customer Agreement Transfers?
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {/* </Grid> */}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container classes={{
                root: clsx(classes.customerToolbar, classes.fixedBottom),
            }} style={{ zIndex: 0 }}>
                <Grid container className={classes.footerFixed}>
                    <Grid style={{ float: 'left', width: "50%" }}>
                        <RACButton
                            variant="outlined"
                            color="secondary"
                            disabled={loader}
                            onClick={() => handleCancel()}
                        >
                            Cancel
                        </RACButton>
                    </Grid>

                    <Grid style={{ float: 'right', width: "50%", textAlign: "right" }}>
                        <RACButton
                            disabled={loader}
                            variant="contained"
                            color="primary"
                            onClick={() => handleCloseEODBtn()}
                        >
                            Close EOD
                        </RACButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <RACModalCard
                    isOpen={successPopup}
                    maxWidth="xs"
                    closeIcon={true}
                    borderRadius="20px"
                    onClose={() => { history.push("/dashboard/homepage") }
                    }
                    // eslint-disable-next-line react/no-children-prop
                    children={UpdateInfoMsgPopup()}
                />
            </Grid>
            <Modal
                isOpen={loader}
                className={classes.loaderStyle}
                id="openpopup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid>
                    <Grid>
                        <Grid className={classes.RACLoaderPage}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
}

