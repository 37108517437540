/* eslint-disable */
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { ProductPermissions } from '../../constants/constants';


import { useUserStateContext } from '../user/user-contexts';

export interface PermissionsState {
  canStoremgmt: boolean;
  canDelete: boolean;
  canSubmit: boolean;
  canCancel: boolean;
  canCreateGoal: boolean;
  canEditGoal: boolean;
  canBlockTimeslot: boolean;
  isSupportUser?: boolean;
  // actions are forbidden when a specific (DAP_VIEW_ONLY_TASKS_EVENTS) is present
  isCreateEventForbidden: boolean;
  isUpdateEventForbidden: boolean;
  isCreateTaskForbidden: boolean;
  isUpdateTaskForbidden: boolean;
}

export const defaultState = {
  canStoremgmt: false,
  canDelete: false,
  canSubmit: false,
  canCreateGoal: false,
  canCancel: false,
  canEditGoal: false,
  canBlockTimeslot: false,
  isCreateEventForbidden: true,
  isUpdateEventForbidden: true,
  isCreateTaskForbidden: true,
  isUpdateTaskForbidden: true
};

enum ActionType {
  MAP_PERMISSIONS = 'MAP_PERMISSIONS',
}

interface PermissionsAction {
  type: ActionType.MAP_PERMISSIONS;
  payload: ProductPermissions[];
}

export function permissionsReducer(
  state: PermissionsState,
  { type, payload }: PermissionsAction
): PermissionsState {
  console.log("payloadpayload", payload)
  console.log("typetype", type)


  if (type === ActionType.MAP_PERMISSIONS) {
    return {
      ...state,
      canStoremgmt: payload.includes(ProductPermissions.STOREMGMT),
      canDelete: payload.includes(ProductPermissions.DELETE_DAP),
      canSubmit: payload.includes(ProductPermissions.SUBMIT_DAP),
      canCancel: payload.includes(ProductPermissions.CANCEL_DAP),
      canCreateGoal: payload.includes(ProductPermissions.CREATE_GOAL),
      canEditGoal: payload.includes(ProductPermissions.EDIT_GOAL),
      canBlockTimeslot: payload.includes(ProductPermissions.BLOCK_TIMESLOT),
      isSupportUser: payload.includes(ProductPermissions.SUPPORT_USER),
      isCreateEventForbidden: payload.includes(
        ProductPermissions.DAP_VIEW_ONLY_TASKS_EVENTS
      ),
      isUpdateEventForbidden: payload.includes(
        ProductPermissions.DAP_VIEW_ONLY_TASKS_EVENTS
      ),
      isCreateTaskForbidden: payload.includes(
        ProductPermissions.DAP_VIEW_ONLY_TASKS_EVENTS
      ),
      isUpdateTaskForbidden: payload.includes(
        ProductPermissions.DAP_VIEW_ONLY_TASKS_EVENTS
      )
    };
  } else {
    return state;
  }
}

export const PermissionsContext = createContext<PermissionsState>(defaultState);

export const useUserPermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(permissionsReducer, defaultState);

  const { user } = useUserStateContext();

  useEffect(() => {
    /* eslint-disable no-console */
    console.log('insidePermission provider');
    if (!user) return;

    dispatch({
      type: ActionType.MAP_PERMISSIONS,
      payload: user.permissions,
    });
  }, [user]);

  return (
    <PermissionsContext.Provider value={state}>
      {props.children}
    </PermissionsContext.Provider>
  );
};


