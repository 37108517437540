/* eslint-disable */

import { CancelToken, CancelTokenSource } from 'axios';
import React, {
  PropsWithChildren,
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback
} from 'react';

import { getCancelTokenSource } from '../api/client';
import { getUserPreferences, setUserPreferences } from '../api/user';
import { SessionStorageKeys } from '../config/storage-config';
export interface StoreState {
  isFranchisee: boolean;
  selectedStore?: string;
  storeGoalsSelectedDate: Date;
  allStores: any[];
  setSelectedStore?: (storeId: string) => void;
  setAutoPreferences: (value: boolean) => void;
  saveToPreferences: (storeId: string, cancelToken?: CancelToken) => void;
  setAllStores: (stores: any) => void;
  setStoreGoalsSelectedDate: (date: Date) => void;
}

export const ProductType = 'RPDDAP';

export const StoreContext = createContext<StoreState>({} as StoreState);

export const StoreProvider = ({ children }: PropsWithChildren<{}>) => {
  const [allStores, setAllStores] = useState<any>([]);
  const [isFranchisee, setIsFranchisee] = useState(false);
  const [storeGoalsSelectedDate, setStoreGoalsSelectedDate] = useState<Date>(
    new Date()
  );

  const [selectedStore, setSelectedStoreState] = useState('');
  const [autoPreferences, setAutoPreferences] = useState(true);
  const initialCallToken = useRef<CancelTokenSource>();

  useEffect(() => {
    if (!selectedStore || !allStores?.length) return;

    const store = allStores.find(
      (storeObj: any) => storeObj.storeNum === selectedStore
    );

    if (!store) {
      return;
    }
    setIsFranchisee(store.franchiseeStore);
  }, [allStores, selectedStore]);

  useEffect(() => {
    getUserPreferences(ProductType).then(preferences => {
      if (preferences) {
        const lastViewedStore = preferences.filter(
          (item: any) => item.productType === ProductType
        )[0]?.preferences?.lastViewedStore;
        if (lastViewedStore) {
          sessionStorage.setItem(
            SessionStorageKeys.STORE_NUMBER,
            lastViewedStore
          );
          setSelectedStoreState(lastViewedStore);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveToPreferences = (storeId: string, cancelToken?: CancelToken) => {
    sessionStorage.setItem(
      SessionStorageKeys.STORE_NUMBER,
      storeId // looks like a storeNumber
    );

    setUserPreferences(
      ProductType,
      {
        op: 'add',
        path: '/lastViewedStore',
        value: storeId
      },
      cancelToken
    );
  };

  const setSelectedStore = (storeId: string) => {
    setSelectedStoreState(storeId);
    if (autoPreferences) {
      if (initialCallToken.current) {
        initialCallToken.current.cancel();
      }
      initialCallToken.current = getCancelTokenSource();
      saveToPreferences(storeId, initialCallToken.current.token);
    }
  };

  // A user can be temporarily assigned to a store.
  // If the given store is saved in the coworker's preferences,
  // but removed from it's store list, the selected store is expected to fallback to the first store from the list.
  const handleCaseWhenPreferredStoreHasBeenRemoved = useCallback(() => {
    if (!allStores?.length) {
      return;
    }

    setSelectedStore(allStores[0].storeNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores]);

  useEffect(() => {
    if (!selectedStore || !allStores?.length) return;

    const store = allStores.find(
      (storeObj: any) => storeObj.storeNum === selectedStore
    );

    if (!store) {
      handleCaseWhenPreferredStoreHasBeenRemoved();
    }
  }, [allStores, handleCaseWhenPreferredStoreHasBeenRemoved, selectedStore]);

  return (
    <StoreContext.Provider
      value={{
        selectedStore,
        setSelectedStore,
        setAutoPreferences,
        saveToPreferences,
        allStores,
        setAllStores,
        isFranchisee,
        storeGoalsSelectedDate,
        setStoreGoalsSelectedDate
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
