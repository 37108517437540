/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
    Grid, Typography, Card, CardContent, RACTable, RACTableCell, RACTableRow, RACTextbox, RACButton, RACSelect, RACCOLOR, RACModalCard, CircularProgress
  } from '@rentacenter/racstrap';
import { makeStyles } from '@rentacenter/racstrap';
// import searchbtn from '../../assets/images/search-btn.svg';
// import searchbtn from '../../assets/images/search-btn.svg';
import moment from 'moment';
import { getStateDropDown ,getStoreLookUpDetails } from '../../api/user'
import { ReactComponent as SearchIcon } from '../../assets/images/search-btn.svg';
import {ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg'
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg'
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
// import { ReactComponent as ErrorIcon} from '../../assets/images/erroricon.svg' ;
import Modal from 'react-modal';
// import { number } from 'yup'; 
const useClasses = makeStyles((theme: any) => ({ 
  mt2: {
      marginTop: theme.typography.pxToRem(8),
    },
    mt1: {
      marginTop: theme.typography.pxToRem(3),
    },
    floatLeft: {
      float:'left',
    },
    floatRight: {
      float:'right',
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },
    mL1:{
      marginLeft:theme.typography.pxToRem(10),
    },
    cancel:{
      // margin:'10px 10px 10px 10px',
      margin:theme.typography.pxToRem(10,10,10,10),
      padding:theme.typography.pxToRem(9,20,9,20),
      // padding:'9px 20px 9px 20px',
    },
    popUpBGColor:{
      backgroundColor:'#f1f2f4',
    },
    p2:{
      paddingLeft:theme.typography.pxToRem(20)
    },
    p4:{
      paddingLeft:theme.typography.pxToRem(15)
    },
    p3: {
      padding: theme.typography.pxToRem(16),
    },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
    },
    popUpTimeCard: {
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
    },
    dropDownLabelAlign: {
      marginTop: theme.typography.pxToRem(9)
    },
    title: {
      color: '#212529',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(10),
    },
    searchBtn: {
      // backgroundImage: `url(${searchbtn}) !important`,
      width:"102%",
      backgroundRepeat: 'no-repeat !important',
      backgroundPosition: '15px !important',
      paddingLeft: theme.typography.pxToRem(40),
      paddingRight: theme.typography.pxToRem(20),
      paddingTop:theme.typography.pxToRem(8),
      paddingBottom:theme.typography.pxToRem(8),
      marginTop:theme.typography.pxToRem(20),
      marginRight:theme.typography.pxToRem(10),
      marginBottom:theme.typography.pxToRem(0),
      marginLeft:theme.typography.pxToRem(0),
      fontFamily: 'OpenSans-Bold',
      fontSize: '13px',
      margin:theme.typography.pxToRem(2,0,0,5),
      // margin:'25px 0px 0px 5px'
    },
    textCenter: {
      textAlign: 'center',
    },

    RACPOPMsg: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    marginTop: {
      marginTop: '-1rem',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },

    explanationContent: {
      justifyContent: 'right',
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    foc: {
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '1px 1px 6px 3px #80bdff',
      },
    },
    p0: {
      padding: 0,
    },
    head:{
      backgroundcolor: 'white !important',
      fontWeight:'bold',
    },
    fontGray:{
      color:'#747994',
      fontWeight:'bold',
    },
    racpadLink: {
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
      fontSize: theme.typography.pxToRem(14),
      textDecoration: 'none',
      cursor: 'pointer',
      fontWeight:'bold',
      fontFamily: 'OpenSans-bold',
    },
    RACLoaderPage: {
      textAlign: 'center',
      marginTop: '250px',
      fontSize: '16px',
      marginLeft: '30px',
      marginRight: '30px',
      outline: 'none',
    },
    loaderStyle: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
    },
    ClearBtn:{
      marginTop:theme.typography.pxToRem(20),
       marginRight:theme.typography.pxToRem(10),
      marginBottom:theme.typography.pxToRem(0),
      marginLeft:theme.typography.pxToRem(20),
      paddingLeft: theme.typography.pxToRem(20),
      //  paddingRight: theme.typography.pxToRem(20),
    },
    overSearch:{
      marginBottom:theme.typography.pxToRem(1)
    },
    popupMess: {
      "& p":{
      color: '#000',
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(15)},
      marginBottom:theme.typography.pxToRem(40)
    },
    align: {
      textAlign:'center',
    },
    formLabel: {
      marginTop: '0.4rem',
      marginBottom: '0.2rem',
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      float:'left'
    },
    fixTableHead: {
      overflowY: 'auto',
      maxHeight: '290px',
      '& th': {
        // position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: 'OpenSans-semibold',
        zIndex: 1,
        padding: '0.9375rem 0.2rem',
        whiteSpace: 'nowrap',
      },
      '& tbody': {
        '& tr': {
          // position: 'sticky',
          top: 10,
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: 'OpenSans-semibold',
          padding: '0.9375rem 0.2rem',
          // color: '#4A5174',
        },
      },
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0",
      marginRight: "-0.75rem",
    },
    customerToolbar: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    fixedBottom: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      padding: '1rem',
    },
    footerGrid: {
      paddingTop: '120px !important',
      marginRight: '-0.75rem',
      marginLeft: '-0.75rem',
      display: 'flex',
      flexWrap: 'wrap',
  },
  footerFixed: {
      backgroundColor: '#fff !important',
      padding: '1rem !important',
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%) !important',
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
  }  
}))
export function LookupStore() {
const classes = useClasses();
const history = useHistory();
// PC_1.5 State Variable Declaration
const storeObj=
{ 
  storeNumber:"",
  phoneNumber:"",
  addressLine1:"",
  addressLine2:"",
  fullzip:"",
  city:"",
  state:"",
}
const [disableSearchBtn, setdisableSearchBtn] = useState(true);
const [phoneNumber, setPhoneNumber] = useState<any>();
const [textValues,settextValues]=useState(storeObj);
const [gridData,setgridData]=useState<any>([]);
const [stateDD,setstateDD ]=useState([{ label: 'Select', value: '0' }]);
const defaultDropdownValue={label: 'Select', value: ''};
const [showGrid,setShowGrid]=useState(false);
const [loader, setLoader] = useState(false);
const [nodataPopup,setNodataPopup] = useState(false);
const popUpObj=
{
  storeNum:"",
  storeAddress:"",
  phoneNumber:"",
  closedTime:[{ days:"" },{time:""}],
  openTime:[{ days:"" },{time:""}],
  Latitude:"",
  longtitude:""
}
const [popUpData,setpopUpData]=useState(popUpObj);
const objOpenPopUp  ={
  openModal:false,
    // wentWorng:false,
  conditionLoader:true
}
  const [popUp, setPopUp] = useState<any>(objOpenPopUp);
// const [openModal, setOpenModal] = useState(objPopup);



// PC_1.7 Invoke UseEffect Initialize a function fetchMyAPI
useEffect(()=>{
  const fetchMyAPI=async()=>{
  setPopUp({...popUp,conditionLoader:true})
  const response:any = await getStateDropDown();
  setPopUp({...popUp,conditionLoader:false})
  if(response.status==200)
  {
    if(response.data !== undefined && response.data !== null)
    {
      // PC_1.8 use map function map the data in the state variable stateDD
      const Temp=response.data.map((obj:any)=>{return {value:obj.stateProvinceId,label:obj.stateAbbreviation}});
      // response.data.sort((a: any, b: any) => a.stateAbbreviation>b.stateAbbreviation?1:-1)
      Temp.splice(0,0,defaultDropdownValue);
      setstateDD(Temp);
    }
  }
    // else{
    //   // setwentWorng(true)
    //   setPopUp({...popUp,wentWorng:true})
    // }
  
  }
  fetchMyAPI()
},[])
useEffect(()=>{
if((textValues.storeNumber.length!==5)&&
(phoneNumber?.length!==10)&&
(textValues.addressLine1==='')&&
(textValues.addressLine2==='')&&
(textValues.fullzip.length!==10) && (textValues.fullzip.length!==5)&&
(textValues.city==='')&&
(textValues.state==='')){
  setdisableSearchBtn(true);
}
else{
  setdisableSearchBtn(false);
}
},[textValues.storeNumber,
phoneNumber,
textValues.addressLine1,
textValues.fullzip,
textValues.city,
textValues.state
])
// PC_1.11 store number and phone number values set state in textValues
const onNumValueChange = (e: any) => {
  const number = /^[0-9\b]+$/;
  if (e.target.value == '' || number.test(e.target.value)) {
    // handle_OnchangeTxt_errorval(e);
    settextValues({ ...textValues, [e.target.name]: e.target.value });
  }
};
const onNumValueChangePhoneNumber =(e:any)=>{
const cleaned = ('' + e.target.value).replace(/\D/g, '');
// console.log(cleaned)
setPhoneNumber(cleaned)
settextValues({ ...textValues, [e.target.name]: e.target.value });
}
// PC_1.16 Addres line 1 and Address Line 2 values set state in textValues
const onValueChange = (e: any) => {
  if(e.target.value!==undefined && e.target.value!=='' && e.target.value!==null &&  e.target.value.trim().length !== 0){
    settextValues({ ...textValues, [e.target.name]: e.target.value });
  }
  if(e.target.value==''){
    settextValues({ ...textValues, [e.target.name]: e.target.value }); 
  }
};

window.matchMedia("print").addListener(function()
{
  // console.log("Printing")
})
// PC_1.20 zip value and set state in a variable textValues
const onZipValueChange = (e: any) => {

    const cleaned = ('' + e.target.value).replace(/\D/g, '');
    const ziptr = /^\(?([0-9]{5})[-. ]?([0-9]{4})$/;
    const temp = cleaned.replace(ziptr, '$1-$2');
  settextValues({ ...textValues, [e.target.name]: temp});
};


// PC_1.24 city value set state in textValues
const onCityValueChange = (e: any) => {
  // const number = /^[a-zA-Z]+$/;
  // if (e.target.value == '' || number.test(e.target.value)) {
    // handle_OnchangeTxt_errorval(e);
    if(e.target.value!==undefined && e.target.value!=='' && e.target.value!==null &&  e.target.value.trim().length !== 0){
    settextValues({ ...textValues, [e.target.name]: e.target.value });
    // }
    }
    if(e.target.value==''){
      settextValues({ ...textValues, [e.target.name]: e.target.value }); 
    }
};

// PC_1.26 the phone number is converts one format to another
function formatPhoneNumber(phoneNumberString : any) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}
function handleCancelBtn(){
history.push({
    pathname:`/dashboard/homepage`,
});
}
const SerachClick=async()=>
{
  setLoader(true)
  setShowGrid(false)
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  // console.log("storeNumber",storeNumber)
  const payloadReq:any = {};
  if(textValues.storeNumber!==''){
    payloadReq.storeNumbers=[textValues.storeNumber]
  }
  if(phoneNumber!==''){
    payloadReq.phoneNumber=phoneNumber 
  }
  if(textValues.addressLine1!==''){
    payloadReq.addressLine1=textValues.addressLine1  
  }
  if(textValues.addressLine2!==''){
    payloadReq.addressLine2=textValues.addressLine2  
  }
  if(textValues.fullzip!==''){
    payloadReq.zip=textValues.fullzip  
  }
  if(textValues.city!==''){
    payloadReq.city=textValues.city
  }
  if(textValues.state!==''){
    payloadReq.stateId= parseFloat(textValues.state) 
  }
  // const req=
  // { 
  //   storeNumbers:[textValues.storeNumber],
  //   phoneNumber:textValues.phoneNumber,
  //   addressLine1:textValues.addressLine1,
  //   addressLine2:textValues.addressLine2,
  //   zip:textValues.fullzip,
  //   city:textValues.city,
  //   stateId:textValues.state,
  //   currentStoreNumber:'04741'
  // }
  // currentStoreNumber:'04741'
  const Response = await getStoreLookUpDetails(payloadReq)
  // console.log('Response',Response)
   if(Response?.data?.response!==undefined && Response?.data?.response!==''&& Response?.data?.response!==null){
  setLoader(false)
  setgridData(Response.data.response)
  setShowGrid(true);
  setNodataPopup(false);
   }
   else{
    setLoader(false);
    setShowGrid(true);
    setNodataPopup(true);
   }
}
// {console.log("gridData",gridData)}
// PC_1.27 By use of map.set function omits the repeated data in the response to load the data in the lookup search grid And the distinct values push into variable searchData.
let item;
const searchData: any = [];
const map = new Map();
for ( item of gridData) {
  if(!map.has(item.storeNumber)){
      map.set(item.storeNumber, true); 
      searchData.push({
          store: item.storeNumber,    
          StoreDescription: item.storeName,  
          Address : item.addressLine1,
          city : item.city,
          State : item.stateName,
          ZipCode : item.zip,
          Phone : formatPhoneNumber(item.workPhoneNumber),  
      });
  }
}
const enterKeyPressed = (event: any) => {
  // debugger;
  // Number 13 is the "Enter" key on the keyboard
  /* eslint-disable no-console */

  // console.log('key pressed');
  /* eslint-enable no-console */

  if (event.charCode === 13 && !disableSearchBtn) {
    // Cancel the default action, if needed
    event.preventDefault();
    /* eslint-disable no-console */
    // console.log('enter pressed');
    /* eslint-enable no-console */

    // Trigger the button element with a click
    SerachClick();
  }
};
// PC_1.28 In RenderTableHeader function bind the tableHeadData to each td and return a single tr
const renderTableHead = () => {
return (
    <React.Fragment>
    <RACTableCell className={classes.head} style={{zIndex:-0}}>Store #</RACTableCell>
    <RACTableCell className={classes.head} style={{zIndex:-0}}>Store Description</RACTableCell>
    <RACTableCell className={classes.head} style={{zIndex:-0}}>Address</RACTableCell>
    <RACTableCell className={classes.head} style={{zIndex:-0}}>City</RACTableCell>
    <RACTableCell className={classes.head} style={{zIndex:-0}}>State</RACTableCell>
    <RACTableCell className={classes.head} style={{zIndex:-0}}>Zip Code</RACTableCell>
    <RACTableCell className={classes.head} style={{zIndex:-0}}>Phone #</RACTableCell>
    </React.Fragment>
)};


// PC_1.30 use map function load the data in to renderTableContent() by variable SearchData
const renderTableContent = ()=>
{
  // debugger;
    return(
      searchData.map((obj:any,index:any)=>
    {
        return (
            <RACTableRow key={index}>
              <RACTableCell color='primary' className={classes.racpadLink} onClick={()=>PopupDetail(obj.store)}>{obj.store}</RACTableCell>
              <RACTableCell className={classes.fontGray}>{obj.StoreDescription}</RACTableCell>
              <RACTableCell className={classes.fontGray}>{obj.Address}</RACTableCell>
              <RACTableCell className={classes.fontGray}>{obj.city}</RACTableCell>
              <RACTableCell className={classes.fontGray}>{obj.State}</RACTableCell>
              <RACTableCell className={classes.fontGray}>{obj.ZipCode}</RACTableCell>
              <RACTableCell className={classes.fontGray}>{obj.Phone}</RACTableCell>
            </RACTableRow>             
        );  
    }))
}

const openStoreTimings : {days :any}[]=[];
const closeStoreTiming : {days :any}[]=[];
// PC_1.33 get the store number by the OnClick method, and get the data for a particular store
//         from the response by use of filter method. And stored in a const variable particularStore
const PopupDetail = (currentStore:any) =>
{
  // debugger;
  // eslint-disable-next-line sonarjs/no-unused-collection
    // const timeStore = new Map();
  const particularStore:any = gridData.filter((q: { storeNumber: any; })=>q.storeNumber == currentStore)
  // console.log("particularStore",particularStore)
  const uniqueTime:any=[];
  particularStore[0].calendar.filter((o: { StartTime: any; end_time: any; }) => {
// PC_1.34 get the data for start time and end time equal for all the weekdays by use of find method and push the object into const variable uniqueTime in array type
  if(uniqueTime.find((i: { startTime: any; endTime: any; storeHoliday:any; racHoliday:any;}) => i.startTime === o.StartTime && i.endTime === o.end_time && i.storeHoliday==0 && i.racHoliday==0)) {
    return true
  }
  uniqueTime.push(o)
  return false;
  }
  )


//   let holiday:any = "";
//   let Tdays:any="";
//   uniqueTime.map((timevalue: { StartTime: any; end_time: any; StoreHoliday: number; day: any;Rac_holiday:any})=>{
//   Tdays=""
//   particularStore.map((value: { StartTime: any; end_time: any; StoreHoliday: number; day: any;Rac_holiday:any })=>{
//   // PC_1.35 map a UniqueTime variable, check the storeHoliday is equal to 1, then get the day of the week and append the days in the variable holiday.
//     if((value.StoreHoliday==1 || value.Rac_holiday==1) && !timeStore.has(value.day)){
//       timeStore.set(value.day,true)
//       holiday=(holiday==""?holiday:holiday+", ")+value.day;
//     }
//     // PC_1.35 And check startTime and end_time are equal in those variables particularStore and uniqueTime, the equal time of the Days are append in the variable similarTimeDay
//     else if(timevalue.StartTime==value.StartTime&&timevalue.end_time==value.end_time && timevalue.StoreHoliday==0 && timevalue.Rac_holiday==0 && !timeStore.has(value.day)){
//       Tdays=(Tdays==""?Tdays:Tdays+", ")+value.day;
//     }
//   })

//   // set Date with the Time
// })
const weekDays:any = {
  'SUN': 1,'MON': 2,'TUE': 3,'WED': 4,'THU': 5,'FRI': 6,'SAT': 7
};
particularStore[0].calendar.sort((a: any, b: any)=>{
  return weekDays[a.dayOfWeek] - weekDays[b.dayOfWeek];
})
particularStore[0].calendar.map((Days: { storeHoliday: number; racHoliday: number; dayOfWeek: string; startTime: string | number | Date; endTime: string | number | Date; })=>{
  if(Days.dayOfWeek!==undefined && Days.dayOfWeek!== '' && Days.dayOfWeek!== null){
  if(Days.storeHoliday !==1 && Days.racHoliday !==1 && String(Days.endTime) !== "00:00:00" && String(Days.startTime) !== "00:00:00")
  {
    // console.log("Days.dayOfWeek",Days.dayOfWeek ,"Days.startTime",Days.startTime,"Days.endTime",Days.endTime)
const temp : any = {
  days:(Days.dayOfWeek),
  time:(moment(Days.startTime ,"HH:mm:ss" ).format("hh:mm A")+" - "+ moment(Days.endTime,"HH:mm:ss").format("hh:mm A"))
}
openStoreTimings.push(temp)
// console.log('openStoreTimings',temp)
}
if(String(Days.endTime) == "00:00:00" || String(Days.startTime) == "00:00:00")
{
  const temp : any ={
    days:(Days.dayOfWeek),
    time:("Closed")
  }
  closeStoreTiming.push(temp)
  // console.log('closeStoreTiming',temp)
}}
else{
  openStoreTimings.push({days:''})
  closeStoreTiming.push({days:''})
}
})

  const popUpObj : any ={
    storeNum:currentStore,
    storeAddress : particularStore[0].addressLine1+", "+particularStore[0].city+", "+particularStore[0].stateName+", "+particularStore[0].zip,
    phoneNumber : formatPhoneNumber(particularStore[0].workPhoneNumber),
    closedTime: closeStoreTiming,
    openTime : openStoreTimings,
    Latitude:particularStore[0].latitude,
    longtitude:particularStore[0].longitude
  }

  // PC_1.36 set state object popUpObj in state variable popUpData
  setpopUpData(popUpObj)
  setPopUp({...popUp,openModal:true});
}

const bindDays:any = (days:any)=>{
  if(days == "MON"){
    return "Mon"
  }
  if(days == "TUE"){
    return "Tue"
  }
  if(days == "WED"){
    return "Wed"
  }
  if(days == "THU"){
    return "Thu"
  }
  if(days == "FRI"){
    return "Fri"
  }
  if(days == "SAT"){
    return "Sat"
  }
  if(days == "SUN"){
    return "Sun"
  }
}
// PC_1.37 map a OpenTime is Object in array of State Variable popUpData
const OpenTime = () => {
  return popUpData?.openTime?.map((el ,index)=>{
      return(
          // eslint-disable-next-line react/jsx-key
          <Grid key={index}>
            <Grid className={classes.row}>
            <Grid style={{width:"20%"}}>
            <Typography className={`${classes.fontGray} ${classes.mb3} ${classes.p4}`}>
                {/* {el.days} */}
               { bindDays(el.days)}
            </Typography>
            </Grid>
            <Grid style={{width:"59%"}}>
            <Typography className={`${classes.fontGray} ${classes.mb3} ${classes.p4}`}>
                {el.time}
            </Typography>
            </Grid>
            </Grid>
          </Grid>
      )
  })
}

const CloseTime = () => {
  return popUpData?.closedTime?.map((obj ,index)=>{
      return(
          // eslint-disable-next-line react/jsx-key
          <Grid key={index}>
            <Grid className={classes.row}>
            <Grid style={{width:"20%"}}>
            <Typography className={`${classes.fontGray} ${classes.mb3} ${classes.p4}`}>
                {bindDays(obj.days)}
            </Typography>
            </Grid>
            <Grid style={{width:"59%"}}>
            <Typography className={`${classes.fontGray} ${classes.mb3} ${classes.p4}`}>
                {obj.time}
            </Typography>
            </Grid>
            </Grid>
          </Grid>
      )
  })
}

const storeLocator=(lat:any ,long:any)=>{
  navigator.geolocation.getCurrentPosition(function (position) {
    // Build the Google Maps URL with the user's location and destination
    const url = `https://www.google.com/maps/dir/?api=1&origin=${position.coords.latitude},${position.coords.longitude}&destination=${lat},${long}`;
    // Open the URL in a new window or tab
    window.open(url);
  });
}

// PC_1.39 use map function load the data in to openModalContent() by state variable popUpData
const openModalContent = () =>(
  <Grid>
    <Grid>
      <Grid>
        <Typography variant='h6' className={`${classes.mL1} ${classes.mb3}`}>Store # : <Typography variant="inherit" className={classes.fontGray}>{popUpData.storeNum}</Typography></Typography>
      </Grid>
      <Grid>
        <Grid><LocationIcon className={`${classes.mL1} ${classes.floatLeft} ${classes.mb3} ${classes.mt1}`} /></Grid>
        <Grid><Typography className={`${classes.mL1} ${classes.fontGray} ${classes.mb3} ${classes.p2}`}>{popUpData.storeAddress}</Typography></Grid>
      </Grid>
      <Grid>
          <Grid><PhoneIcon className={`${classes.mL1} ${classes.floatLeft} ${classes.mb3} ${classes.mt1}`}/></Grid>
          <Grid><Typography className={`${classes.mL1} ${classes.fontGray} ${classes.mb3} ${classes.p2}`}> {popUpData.phoneNumber}</Typography></Grid>
      </Grid>
    </Grid>
    {popUpData?.closedTime[0]?.days!=='' && popUpData?.openTime[0]?.days!=='' ? 
    <Grid>
        <Card className={classes.popUpTimeCard}>
        <CardContent className={classes.popUpBGColor}>
          <Grid>
            <Typography variant='h6' className={`${classes.mb3} ${classes.p4}`}>Store Hours : </Typography>
          </Grid>
              <Grid><Typography>{OpenTime()}</Typography></Grid>
              <Grid><Typography>{CloseTime()}</Typography></Grid>
      </CardContent>
      </Card> 
    </Grid> : null }
    <RACButton color="primary" variant="contained" className={`${classes.floatRight} ${classes.mt2}`} onClick={()=>storeLocator(popUpData.Latitude,popUpData.longtitude)}>Store Locator</RACButton>      
    <RACButton className={`${classes.floatRight} ${classes.mt2} ${classes.cancel}`} onClick={() => {
          setPopUp({...popUp,openModal:false})
        }} >Cancel</RACButton>

  </Grid>
)

//   const someThingWentWrong = ()=>
//   (
//       <Grid>
//         <Grid className={`${classes.align}`}>
//            <ErrorIcon />
//         </Grid>
//         <Grid className={`${classes.align} ${classes.popupMess}`}>
//           <Typography>
//           Something Went Wrong
//           </Typography>
//         </Grid>
//     </Grid>
//   )
const noRecordfound = () => {
  return (
    <Grid item md={12}>
      <Grid
        item
        md={12}
        style={{
          marginLeft: '1.5%',
          marginRight: '1.5%',
        }}
        className={`${classes.textCenter}`}
      >
        <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
        Search criteria did not return any result.
        </Typography>
      </Grid>
      <Grid
        container
        className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
      >
        <RACButton
          className={`${classes.spacerMR2} ${classes.foc}`}
          size="small"
          variant="contained"
          color="primary"
          isRounded={false}
          onClick={() => setNodataPopup(false)}
        // onClick={() => handleModels('validateNonCashIsOpen',false)}
        >
          Ok
        </RACButton>
      </Grid>
    </Grid>
  );
};

return(
  <>
   <Grid>
    <CardContent>
     <Grid item md={12}>
       <Typography className={classes.title} variant="h4">
          Store Search Criteria
       </Typography>
     </Grid>
          <Grid>
            <Card className={classes.card}>
              <CardContent className={classes.overSearch}>
                <Grid container spacing={3}>
                  <Grid item md={1}>
                  <RACTextbox
                    inputlabel="Store #"
                     placeHolder={'Store #'}
                    inputLabelClassname={classes.formLabel}
                    id="Store_id"
                    name='storeNumber'
                    type="number"
                    maxlength={5}
                    value={textValues.storeNumber}
                    OnChange={(e) => onNumValueChange(e)}
                    onKeyPress={(e: any) => enterKeyPressed(e)}
                    // OnKeydown={(e) => EnterOnClick(e)}
                  />
                  </Grid>
                  <Grid item md={2}>
                    <RACTextbox
                      inputlabel="Phone Number"
                       placeHolder={'Phone Number'}
                      inputLabelClassname={classes.formLabel}
                      type="phoneno"
                      id="phone_id"
                      name="phoneNumber"
                      maxlength={14}
                      isCurrency={false}
                      value={textValues.phoneNumber}
                      OnChange={(e) => onNumValueChangePhoneNumber(e)}
                      onKeyPress={(e: any) => enterKeyPressed(e)}
                      // OnKeydown={(e) => EnterOnClick(e)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <RACTextbox
                      inputlabel="Address Line 1"
                       placeHolder={'Address Line 1'}
                      inputLabelClassname={classes.formLabel}
                      type="text"
                      id="a11y_Model"
                      name="addressLine1"
                      isCurrency={false}
                      value={textValues.addressLine1}
                      OnChange={(e) => onValueChange(e)}
                      onKeyPress={(e: any) => enterKeyPressed(e)}
                      // OnKeydown={(e) => EnterOnClick(e)}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <RACTextbox
                      inputlabel="Zip"
                       placeHolder={'Zip'}
                      inputLabelClassname={classes.formLabel}
                      type="text"
                      id="a11y_Serial"
                      name="fullzip"
                      isCurrency={false}
                      maxlength={9}
                      value={textValues.fullzip}
                      OnChange={(e) => onZipValueChange(e)}
                      onKeyPress={(e: any) => enterKeyPressed(e)}
                      // OnKeydown={(e) => EnterOnClick(e)}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <RACTextbox
                      inputlabel="City"
                       placeHolder={'City'}
                      inputLabelClassname={classes.formLabel}
                      type="text"
                      id="a11y_Serial"
                      name="city"
                      isCurrency={false}
                      value={textValues.city}
                      OnChange={(e) => onCityValueChange(e)}
                      onKeyPress={(e: any) => enterKeyPressed(e)}
                      // OnKeydown={(e) => EnterOnClick(e)}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <RACSelect
                      inputLabel="State"
                      inputLabelClassName={
                        classes.dropDownLabelAlign
                      }
                      // loading={conditionLoader}
                      options={stateDD}
                      loading={popUp.conditionLoader}
                      defaultValue={
                        textValues.state
                      }
                      
                      onChange={(e: any) => {
                        e.target.value == 'Select'
                          ? settextValues({
                              ...textValues,
                              state: '',
                            })
                          : settextValues({
                              ...textValues,
                              state: e.target.value,
                            });
                      }}
                      
                    />
                  </Grid>
                  <Grid item md={2}>

                  </Grid>
                  <Grid item md={1}>
                    <RACButton  className={`${classes.ClearBtn} ${classes.floatRight}`} 
                                // variant='outlined'
                                // size="small"
                                  color="primary"
                                onClick={()=>{settextValues({...textValues,addressLine1:"",
                                                                          addressLine2:"",
                                                                          city:"",
                                                                          fullzip:"",
                                                                          phoneNumber:"",
                                                                          state:"",
                                                                          storeNumber:"",
                                                                          }),setShowGrid(false)}}>Clear</RACButton>
                  </Grid>
                  <Grid item md={1}>
                      <RACButton 
                            startIcon={ <SearchIcon  style={{marginLeft:"-18px"}}/>}
                            variant="contained"
                            color="primary"
                            className={`${classes.searchBtn} ${classes.floatRight}`}
                            size="large"
                            onClick={()=>SerachClick()}
                            disabled={((textValues.storeNumber.length!==5)&&
                                       (phoneNumber?.length!==10)&&
                                       (textValues.addressLine1==='')&&
                                       (textValues.addressLine2==='')&&
                                       (textValues.fullzip.length!==10) && (textValues.fullzip.length!==5)&&
                                       (textValues.city==='')&&
                                       (textValues.state===''))? true:false}>Search</RACButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
    </CardContent>
    {showGrid ?
    <CardContent>
     <Grid item md={12}>
       <Typography className={classes.title} variant="h4">
         Search Results
       </Typography>
     </Grid>
     {nodataPopup ?
     <Grid>
      <Card className={`${classes.card} ${classes.mb4} ${classes.p3}`}>
      <CardContent className={classes.p0}>
        <Grid  className={classes.row}>
        <Grid style={{width:"14%"}}>
        <Typography className={classes.head}>
        Store #
        </Typography>
        </Grid>
        <Grid style={{width:"14%"}}>
        <Typography className={classes.head}>
        Store Description
        </Typography>
        </Grid>
        <Grid style={{width:"14%"}}>
        <Typography className={classes.head}>
        Address
        </Typography>
        </Grid>
        <Grid style={{width:"14%"}}>
        <Typography className={classes.head}>
        City
        </Typography>
        </Grid>
        <Grid style={{width:"14%"}}>
        <Typography className={classes.head}>
        State
        </Typography>
        </Grid>
        <Grid style={{width:"14%"}}>
        <Typography className={classes.head}>
       Zip Code
        </Typography>
        </Grid>
        <Grid style={{width:"14%"}}>
        <Typography className={classes.head}>
        Phone #
        </Typography>
        </Grid>
        </Grid>
        <Grid style={{marginBottom:"4%",marginLeft:"35%",color: '#A5A6A6',marginTop:"0%"}}>
        <NoRecord style={{ width: '38px', height: '55px',marginTop:"70px",marginLeft:"12%"}}></NoRecord>
        <Typography>
        Search criteria did not return any result.
       </Typography>
          </Grid>
      </CardContent>
      </Card>
     </Grid>
     :
     <Grid>
        <Card className={`${classes.card} ${classes.mb4} ${classes.p3}`}>
          <CardContent className={classes.p0}>
            <Grid className={classes.fixTableHead}>
              <RACTable
                renderTableHead={renderTableHead}
                renderTableContent={renderTableContent}
                stickyHeader
              />
            </Grid>
          </CardContent>
        </Card>
     </Grid> }
     </CardContent>:null}
     <Grid> 
     <RACModalCard
        isOpen={popUp.openModal}
        maxWidth="xs"
        closeIcon={true}
        title={'Store Info'}
        borderRadius="20px"
        onClose={() => {
          setPopUp({...popUp,openModal:false})
        }}
        // eslint-disable-next-line react/no-children-prop
        children={openModalContent()}
      />
      {/* <RACModalCard
        isOpen={popUp.wentWorng}
        maxWidth="xs"
        closeIcon={true}
        title={''}
        borderRadius="20px"
        onClose={() => {
          setPopUp({...popUp,wentWorng:false})
        }}
        // eslint-disable-next-line react/no-children-prop
        children={someThingWentWrong()}
        /> */}
         {/* <RACModalCard
        isOpen={nodataPopup}
        maxWidth="xs"
        closeIcon={true}
        title={''}
        borderRadius="20px"
        onClose={() => {
         setNodataPopup(false)
        }}
        // eslint-disable-next-line react/no-children-prop
        children={noRecordfound()}
      /> */}
     </Grid>
     {/* <Grid>
       <RACButton onClick={()=>setPopUp({...popUp,wentWorng:true})}>
         Button
       </RACButton>
     </Grid> */}
     <Grid container classes={{
          root: clsx(classes.customerToolbar, classes.fixedBottom),
        }}  style={{ zIndex: 0 }}>
              <Grid className={classes.footerFixed}>
                  <Grid className={classes.floatLeft}>
                      <RACButton    variant="outlined" color="secondary" 
                       onClick={()=> handleCancelBtn()}
                      >
                      Cancel
                      </RACButton>
                  </Grid>
              </Grid>
     </Grid>
    </Grid>
    <Modal
      isOpen={loader}
      className={classes.loaderStyle}
      id="openpopup"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <Grid>
        <Grid>
          <Grid className={classes.RACLoaderPage}>
            <CircularProgress />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  </>
)
}