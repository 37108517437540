/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastProvider } from '@rentacenter/legacyracstrap';

import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
// import { Placeholder1 } from '../components/PlaceHolder1/PlaceHolder1';
// import { Placeholder2 } from '../components/PlaceHolder2/PlaceHolder2';
import SendDigitalForm from '../components/DigitalOrder/DigitalOrderForm';
import { closeEndOfDay } from '../storeComponent/closeEOD/closeEOD';
import { PinManagement } from '../storeComponent/PinManagementDetail/PinManagement'
import { FscContestDetails } from '../storeComponent/FSC/FscContestDetails';
import { LookupStore } from '../storeComponent/LookUpStore/LookUpStore';
import { RouteAssignment } from '../storeComponent/RouteAssignment/RouteAssignment';
import { DapCalendar } from '../storeComponent/DapCalendar/DapCalendar'
import StoreEvent from '../storeComponent/DapCalendar/StoreEvent';
import StorePickupEvent from '../storeComponent/DapCalendar/StorePickupEvent';
import FieldVisitEvent from '../storeComponent/DapCalendar/FieldVisitEvent';


// import FSCUpdate from '../racstrapComponent/FSC/FscUpdates';
import { CreateAndUpdateFscContest } from '../storeComponent/FSC/CreateAndUpdateFscContest';
export const routesTestId = 'routesTestId';

export const Routes = () => (
  <UserProvider>
    <PermissionsProvider>
      <UserFetcher />
      <ToastProvider>
      <div data-testid={routesTestId}>
        <Switch>
          <Route
            exact
            path="/storemgmt/dap"
            key="/storemgmt/dap"
            component={DapCalendar}
          />
          <Route
            exact
            path="/storemgmt/createevent"
            key="/storemgmt/createevent"
            component={StoreEvent}
          />
          <Route
            exact
            path="/storemgmt/storepickupevent"
            key="/storemgmt/storepickupevent"
            component={StorePickupEvent}
          />
          <Route
            exact
            path="/storemgmt/fieldvisitevent"
            key="/storemgmt/fieldvisitevent"
            component={FieldVisitEvent}
          />
        <Route exact path={AppRoute.Index}>
            <Redirect to={AppRoute.DigitalOrder} />
          </Route>
          <Route exact path={AppRoute.Index}>
            <Redirect to={AppRoute.pinManagement} />
          </Route>
          <Route
            path={AppRoute.DigitalOrder}
            key="/storemgmt/digitalorderform"
            component={SendDigitalForm}
          />
          <Route
            path={AppRoute.closeEOD}
            key="/storemgmt/closeeod"
            component={closeEndOfDay}
          />
          <Route
            path={AppRoute.pinManagement}
            key="/storemgmt/pinmanagement"
            component={PinManagement}
          /> 
          <Route
            path={AppRoute.routeAssignment}
            key="/storemgmt/routeassignment"
            component={RouteAssignment}
          />  
            <Route
            exact
            path={AppRoute.lookUpStore}
            key="/storemgmt/lookupstore"
            component={LookupStore}
          /> 
          <Route
            exact
            path="/storemgmt/fsc/details"
            key="/storemgmt/fsc/details"
            component={FscContestDetails}
          />
          <Route
            exact
            path="/storemgmt/contest/details"
            key="/storemgmt/contest/details"
            component={FscContestDetails}
          />
          <Route
            exact
            path="/storemgmt/fsc/create"
            key="/storemgmt/fsc/create"
            component={CreateAndUpdateFscContest}
          />
          <Route
            exact
            path="/storemgmt/fsc/update"
            key="/storemgmt/fsc/update"
            component={CreateAndUpdateFscContest}
          />
          <Route
            exact
            path="/storemgmt/contest/create"
            key="/storemgmt/contest/create"
            component={CreateAndUpdateFscContest}
          />
          <Route
            exact
            path="/storemgmt/contest/update"
            key="/storemgmt/contest/update"
            component={CreateAndUpdateFscContest}
          />
        </Switch>
      </div>
      </ToastProvider>
    </PermissionsProvider>
  </UserProvider>
);
