import React, { useContext } from 'react';
import { StoreContext } from '../../../context/Storeprovider';
import { Goals } from './Goals';
import { GoalsCoreUser } from './GoalsCoreUser';

export const GoalsController = () => {
  const { isFranchisee } = useContext(StoreContext);

  if (isFranchisee) {
    return <Goals />;
  } else {
    return <GoalsCoreUser />;
  }
};
