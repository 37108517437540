/* eslint-disable */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from 'react';
import clsx from 'clsx';
import { format, isSameDay, isThisWeek } from 'date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { getCollectionGoals } from '../../../../api/goals';
import { SkeletonLoader } from './SkeletonLoader/SkeletonLoader';
import { CollectionGoalCard } from './CollectionGoalCard/CollectionGoalCard';
import { ApiError } from '../../../../common/ApiError/ApiError';


import {
  SingleDayMetric, GoalMetric,
  CollectionGoalDTO
} from '../../../../domain/Calendar/Goal';

import styles from './CollectionGoals.module.scss';
import { StoreContext } from '../../../../context/Storeprovider';
import { NoItems } from '../../NoItems/NoItems';
import { CancelTokenSource } from 'axios';
import { getCancelTokenSource } from '../../../../api/client';

export const collectionGoalsTestId = 'collectionGoalsTestId';
export const seeFullWeekBtnTestId = 'seeFullWeekBtnTestId';

export interface GoalsListProps {
  listData: SingleDayMetric[];
}

interface CollectionGoalsProps {
  startDate: Date;
}

interface DateViewerProps {
  date: string;
}

interface FullViewTextProps {
  isFullView: boolean;
}
interface DayCollectionGoalsProps {
  goals?: CollectionGoalDTO[];
  startDate: Date;
}

const dateFormat = 'MMMM d, yyyy';

type ListStatus = 'initial' | 'apiError' | 'success' | 'empty';

function isToday(date: string) {
  return isSameDay(new Date(date), new Date());
}

const hasTodayMetrics = (
  goals: CollectionGoalDTO[]
): GoalMetric[] | undefined => {
  let todayGoalMetric: GoalMetric[] | undefined;
  goals.forEach(goal => {
    const hasTodayMetric = goal.metrics.filter(metric =>
      isToday(metric.metricDate)
    );
    if (hasTodayMetric && hasTodayMetric.length > 0) {
      todayGoalMetric = hasTodayMetric;
      return false;
    }
  });
  return todayGoalMetric;
};

function formattedDate(date: string) {
  return format(new Date(date), dateFormat);
}

const DateViewer = (props: DateViewerProps) => {
  return (
    <span
      className={clsx(
        styles.selectedDate,
        isToday(props.date) && styles.highlighted
      )}
    >
      {formattedDate(props.date)}
    </span>
  );
};

export const GoalsList = (props: GoalsListProps) => {
  const { listData } = props;

  return (
    <>
      <div className={styles.cardsWrapper}>
        {listData?.map((listItem: SingleDayMetric, index: number) => (
          <CollectionGoalCard
            key={index}
            singleDayMetric={listItem}
            className={clsx(styles.collectionGoalCard)}
          />
        ))}
      </div>
    </>
  );
};

const FullViewText = ({ isFullView }: FullViewTextProps) => (
  <>
    <FontAwesomeIcon icon={isFullView ? faEyeSlash : faEye} />
    <span className={styles.text}>
      {isFullView ? 'Hide Full Week' : 'See Full Week'}
    </span>
  </>
);

const getStatus = (
  hasApiError: boolean,
  collectionsGoals: CollectionGoalDTO[]
): ListStatus => {
  if (hasApiError) return 'apiError';

  if (collectionsGoals) {
    return collectionsGoals.length > 0 ? 'success' : 'empty';
  } else {
    return 'initial';
  }
};

const DayCollectionGoals = ({ goals, startDate }: DayCollectionGoalsProps) => {
  if (goals === undefined) return null;

  const hasSameDayMetrics = hasTodayMetrics(goals);
  const dayCollectionGoalMetrics =
    isThisWeek(startDate) && hasSameDayMetrics
      ? hasSameDayMetrics
      : goals[0].metrics;

  return (
    <>
      {dayCollectionGoalMetrics?.map((collectionGoal: GoalMetric) => (
        <>
          <DateViewer date={collectionGoal?.metricDate} />
          <GoalsList listData={collectionGoal?.singleDayMetrics} />
        </>
      ))}
    </>
  );
};

export const CollectionGoals = (props: CollectionGoalsProps) => {
  const [collectionsGoals, setCollectionsGoals] = useState<CollectionGoalDTO[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [isFullWeekView, setIsFullWeekView] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);

  const { selectedStore } = useContext(StoreContext);

  const { startDate } = props;
  const initialCallToken = useRef<CancelTokenSource>();

  const updateCollectionGoals = useCallback(() => {
    if (!selectedStore) return;
    setLoading(true);
    setHasApiError(false);

    if (initialCallToken.current) {
      initialCallToken.current.cancel();
    }

    initialCallToken.current = getCancelTokenSource();

    getCollectionGoals(selectedStore, startDate, initialCallToken.current.token)
      .then((response: any) => {
        setCollectionsGoals(response);
      })
      .catch(err => {
        if (!err.__CANCEL__) {
          setHasApiError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedStore, startDate]);

  useEffect(() => {
    updateCollectionGoals();
  }, [updateCollectionGoals]);

  if (loading) {
    return <SkeletonLoader />;
  }

  const handleSeeFullWeekClick = () => {
    setIsFullWeekView(!isFullWeekView);
  };

  return (
    <div data-testid={collectionGoalsTestId}>
      {
        {
          initial: null,
          empty: <NoItems>No Results Found</NoItems>,
          apiError: (
            <ApiError
              className={styles.apiError}
              text="We are experiencing an internal server problem,
            please refresh the page or select another date"
            />
          ),
          success: (
            <>
              <div
                className={styles.seeFullWeek}
                onClick={handleSeeFullWeekClick}
                data-testId={seeFullWeekBtnTestId}
              >
                <FullViewText isFullView={isFullWeekView} />
              </div>
              {isFullWeekView ? (
                collectionsGoals?.map((collectionGoal: CollectionGoalDTO) => (
                  <>
                    <DateViewer date={collectionGoal?.metrics[0].metricDate} />
                    <GoalsList
                      listData={collectionGoal?.metrics[0].singleDayMetrics}
                    />
                  </>
                ))
              ) : (
                <DayCollectionGoals
                  goals={collectionsGoals}
                  startDate={startDate}
                />
              )}
            </>
          )
        }[getStatus(hasApiError, collectionsGoals)]
      }
    </div>
  );
};
