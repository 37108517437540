/* eslint-disable */
import React from 'react';

import styles from './NoStoreSelected.module.scss';

import NoStore from '../../../assets/images/noStore.png';

import { appConfig } from '../../../config/app-config';
let url = appConfig.apiUrls.micro
url = appConfig.apiUrls.micro.replace('/api', '')

export const NoStoreSelected = () => {
  return (
    <div className={styles.noStoreSelected}>
      <img src={`${url}${NoStore}`} alt="No Store Selected" />
      <h1>No Store Selected</h1>
      <span>Please select one of your stores to view the daily activities</span>
    </div>
  );
};
