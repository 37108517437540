/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
export const StoreMgmtContext = createContext<any>('');
export function StoreMgmtContextProvider(props: any) {
    const [createTaskModal, setcreateTaskModal] = useState(false)
    const [TaskandGoals, setTaskandGoals] = useState('');
    const [goals, setGoals] = useState('')
    const [updateRecieptKey, setUpdateRecieptKey] = useState(null)


    return (
        <div>
            <StoreMgmtContext.Provider
                value={{
                    createTaskModal,
                    setcreateTaskModal,
                    TaskandGoals,
                    setTaskandGoals,
                    updateRecieptKey,
                    setUpdateRecieptKey,
                    goals,
                    setGoals
                }}
            >
                {props.children}
            </StoreMgmtContext.Provider>
        </div>
    );
}
