/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState, useRef } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    RACButton,
    RACModalCard,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACSelect,
    CircularProgress
} from '@rentacenter/racstrap';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Modal from 'react-modal';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg'
import { ReactComponent as SortDownIcon } from '../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortUpIcon } from '../../assets/images/sort-up-icon.svg';
import { getRouteDetails, GetCoworkerDropDown, getmenuandstore, ManageRoute } from '../../api/user';
import { routeAssignmentStyles } from './routeAssignmentStyle';
import moment from 'moment';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
const routeHeader = ['Route', 'Co-worker', 'Date Modified', 'Action']
export function RouteAssignment() {
    const GetStoreRouteDetails: any = {
        userDetails: [
            {
                appUserId: 2202001,
                appUserName
                : 
                "Coworker1 Store02202",
                customerCount
                : 
                2605,
                modifiedDate
                : 
                "2023-04-12T16:00:51.516455-05:00",
                routeId
                : 
                1,
                routeName
                : 
                "Route 1",
                routeRefCode
                : 
                "1"
            },
            {
                appUserId: '2',
                appUserName: "After Hours",
                routeId: '33',
                routeName: 'Manager',
                routeRefCode: "M",
                modifiedDate: "2023-04-13T12:54:19.038059-05:00"
            },
            {
                appUserId: '1',
                appUserName: "Agent After Hours",
                routeId: '8',
                routeRefCode: "07",
                routeName: 'Route07',
                modifiedDate: "2023-04-15T12:54:19.038059-05:00"
            },
            {
                appUserId: "105395",
                appUserName: "Fletcher Session",
                routeId: '656',
                routeRefCode: "01",
                routeName: 'Route01',
                modifiedDate: "2023-04-5T12:54:19.038059-05:00"
            },
            {
                appUserId: "165597",
                appUserName: "Nicholas Brannan",
                routeId: '654',
                routeRefCode: "04",
                routeName: 'Route04',
                modifiedDate: "2023-04-1T12:54:19.038059-05:00"
            }
        ],
        routeDetails:
    [{ "routeId": 71, "routeName": "Route 1" },
    { "routeId": 2, "routeName": "Route 10" },
    { "routeId": 3, "routeName": "Route 11" },
    { "routeId": 4, "routeName": "Route 12" },
    { "routeId": 5, "routeName": "Route 13" },
    { "routeId": 6, "routeName": "Route 14" },
    { "routeId": 7, "routeName": "Route 15" },
    { "routeId": 8, "routeName": "Route 16" },
    { "routeId": 9, "routeName": "Route 17" },
    { "routeId": 10, "routeName": "Route 18" },
    { "routeId": 11, "routeName": "Route 19" },
    { "routeId": 12, "routeName": "Route 2" },
    { "routeId": 13, "routeName": "Route 20" },
    { "routeId": 14, "routeName": "Route 21" },
    { "routeId": 15, "routeName": "Route 22" },
    { "routeId": 16, "routeName": "Route 23" },
    { "routeId": 17, "routeName": "Route 24" },
    { "routeId": 18, "routeName": "Route 25" },
    { "routeId": 19, "routeName": "Route 26" },
    { "routeId": 20, "routeName": "Route 27" },
    { "routeId": 21, "routeName": "Route 28" },
    { "routeId": 22, "routeName": "Route 29" },
    { "routeId": 23, "routeName": "Route 3" },
    { "routeId": 24, "routeName": "Route 30" },
    { "routeId": 25, "routeName": "Route 31" },
    { "routeId": 26, "routeName": "Route 32" },
    { "routeId": 27, "routeName": "Route 33" },
    { "routeId": 28, "routeName": "Route 34" },
    { "routeId": 29, "routeName": "Route 35" },
    { "routeId": 30, "routeName": "Route 36" },
    { "routeId": 31, "routeName": "Route 37" },
    { "routeId": 32, "routeName": "Route 38" },
    { "routeId": 33, "routeName": "Route 39" },
    { "routeId": 34, "routeName": "Route 4" },
    { "routeId": 35, "routeName": "Route 40" },
    { "routeId": 36, "routeName": "Route 41" },
    { "routeId": 37, "routeName": "Route 42" },
    { "routeId": 38, "routeName": "Route 44" },
    { "routeId": 39, "routeName": "Route 45" },
    { "routeId": 40, "routeName": "Route 46" },
    { "routeId": 41, "routeName": "Route 47" },
    { "routeId": 42, "routeName": "Route 48" },
    { "routeId": 43, "routeName": "Route 49" },
    { "routeId": 44, "routeName": "Route 5" },
    { "routeId": 45, "routeName": "Route 50" },
    { "routeId": 46, "routeName": "Route 51" },
    { "routeId": 47, "routeName": "Route 52" },
    { "routeId": 48, "routeName": "Route 53" },
    { "routeId": 49, "routeName": "Route 54" },
    { "routeId": 50, "routeName": "Route 55" },
    { "routeId": 51, "routeName": "Route 56" },
    { "routeId": 52, "routeName": "Route 57" },
    { "routeId": 53, "routeName": "Route 58" },
    { "routeId": 54, "routeName": "Route 59" },
    { "routeId": 55, "routeName": "Route 6" },
    { "routeId": 56, "routeName": "Route 60" },
    { "routeId": 57, "routeName": "Route 61" },
    { "routeId": 58, "routeName": "Route 62" },
    { "routeId": 59, "routeName": "Route 63" },
    { "routeId": 60, "routeName": "Route 64" },
       ]
       }
    const history = useHistory();
    const classes: any = routeAssignmentStyles();
    const [assignedRoute, setAssignedRoute] = useState<any>();
    const defaultDropdownValue = { label: 'Select', value: '0' };
    const [popUp, setPopUp] = useState<any>({ editCoworkerRoute: false, coworkerRoute: false, unAssignCoworker: false, saveAssign: false })
    const [successPopup, setSuccessPopup] = useState(false);
    const [bindMessage, setBindMessage] = useState<any>()
    const [isSuccess, setIsSucess] = useState(true);
    const [loader, setLoader] = useState(false);
    const [routeValue, setRouteValue] = useState<any>([])
    const [newRoute, setNewRoute] = useState<any>([{label: 'Select', value: '0'}])
    const [newCoWorker, setNewCoWorker] = useState<any>([{label: 'Select', value: '0'}])
    const [newRoutePayload, setNewRoutePayload] = useState<any>()
    const [sortOrder, setSortOrder] = useState<any>({ Route: 'asc' });
    const [customerProfile, setCustomerProfile] = useState<any>(true);
    const [isTimeOutOccured, setisTimeOutOccured] = useState<any>(false);
    // const [currentCoworker,setCurrentCoworker] = useState<any>()
    const [payLoadValue, setPayLoadValues] = useState<any>(
        { currentCoworker: "0", currentRouteId: "0", currentRouteValue: "0", customerCount: "0" }
    )
    useEffect(() => {
        console.log("payloadvalueCurreny", payLoadValue.currentRouteId)
    }, [payLoadValue.currentRouteId]);
    useEffect(() => {
        pageLoad();
    }, []);
    const pageLoad = async () => {
        const coworkerDropDownVal: any = []
        const routeEditCoWorker: any = []
        const coWorker: any = []
        const coWorkerRoute: any = []
        try {
            // debugger;
            // eslint-disable-next-line sonarjs/no-unused-collection
            setLoader(true)
            const storeNumber = window.sessionStorage.getItem('storeNumber');
            const GetStoreRouteDetailsRes = await Promise.all([getRouteDetails(storeNumber), GetCoworkerDropDown(storeNumber), getmenuandstore()])
            setLoader(false)
            console.log("GetStoreRouteDetailsRes", GetStoreRouteDetailsRes)
           const coWorkerDetailsObj = GetStoreRouteDetailsRes[2]
            console.log('MenuandStore', coWorkerDetailsObj)
            const routeAssignmentRes = GetStoreRouteDetailsRes[0]?.data
            console.log("routeAssignmentRes", routeAssignmentRes)
            const coWorkerDropDownRes = GetStoreRouteDetailsRes[1]
            console.log("coWorkerDropDownRes", coWorkerDropDownRes)

            if (coWorkerDetailsObj?.coworkerProfile?.role == "CAR") {
               setCustomerProfile(false);
            }
            else {
                setCustomerProfile(true);
            }
            coWorkerDropDownRes?.salespersonsData?.map((value: any, index: any) => {
                if (index == 0) {
                    coWorker.push({ label: 'Select', value: '0' });
                } else {
                    coWorker.push({
                        label: String(value.appUserName),
                        value: String(value.appUserId),
                    });
                } 
                setNewCoWorker(coWorker)
            })
            routeEditCoWorker.splice(0, 0, defaultDropdownValue)
            routeAssignmentRes?.userDetails?.map((val: any) => {
                routeEditCoWorker.push({
                    label: String(val.routeName),
                    value: String(val.routeId),
                })
                console.log("routeEditCoWorker", routeEditCoWorker)
                setRouteValue(routeEditCoWorker);
            })
            coWorkerRoute.splice(0, 0, defaultDropdownValue)
            routeAssignmentRes?.routeDetails?.map((val: any) => {
                const arrayLength = routeAssignmentRes?.userDetails?.filter((e: any) => e.routeId == val.routeId)
                if (arrayLength.length == 0) {
                    coWorkerRoute.push({ 
                        label: String(val.routeName),
                        value: String(val.routeId)
                    })
                 }
                
                console.log("coWorkerRoutesssss", coWorkerRoute)
            setNewRoute(coWorkerRoute);
            })
            routeAssignmentRes?.userDetails.sort(function (a: any, b: any) {

                const a1 = typeof a?.routeName, b1 = typeof b?.routeName;
                
                return a1 < b1 ? -1 : a1 > b1 ? 1 : a?.routeName < b?.routeName ? -1 : a?.routeName > b?.routeName ? 1 : 0;
                
                });
            setAssignedRoute(routeAssignmentRes); 
        }
        catch (e) {
            console.log(e)
        }
    }
    const sortGrid = (columnToSort: string) => {
        // debugger;
        const assignedRouteSort = assignedRoute;
        columnToSort !== 'asc' ?
            assignedRouteSort?.userDetails?.sort(function (a: any, b: any) {
            console.log("descending")
                const a1 = typeof a?.routeName, b1 = typeof b?.routeName;
                return a1 < b1 ? 1 : a1 > b1 ? -1 : a?.routeName < b?.routeName ? 1 : a?.routeName > b?.routeName ? -1 : 0;
        })
        :
            assignedRouteSort?.userDetails?.sort(function (a: any, b: any) {
            console.log("ascending")
                const a1 = typeof a?.routeName, b1 = typeof b?.routeName;
                return a1 < b1 ? -1 : a1 > b1 ? 1 : a?.routeName < b?.routeName ? -1 : a?.routeName > b?.routeName ? 1 : 0;
        })
        console.log("assignedRouteSort", assignedRouteSort)
        setAssignedRoute({ ...assignedRouteSort })
        setSortOrder({ ...sortOrder, Route: columnToSort });
    }
    
        const handleCoWorkerRoute = (value: any, actionType: any) => {
        //  debugger;
        console.log(value)
        setPayLoadValues({ ...payLoadValue, currentCoworker: value?.appUserId ? value?.appUserId: "0" , currentRouteId: value?.routeId ? value?.routeId :"0", currentRouteValue: value?.routeName ? value?.routeName :"0", customerCount: value?.customerCount })
        // setEditCoworker(value.appUserId)
        if (actionType === "ASSIGN") {
            setPopUp({ ...popUp, editCoworker: true })
        }
        else if (actionType === "UNASSIGN") {
            setPopUp({ ...popUp, unAssignCoworker: true })
        }
        else if (actionType === "NEWROUTE") {
            setPopUp({ ...popUp, coworkerRoute: true })
        }
    }
    const handleAssignSave = async (actype: any) => {
        // debugger;
        let newAssign: any = ""
        try {
            setLoader(true)
            if (actype === "UNASSIGN") {
                setPopUp({ ...popUp, unAssignCoworker: false })
                const unAssign: any = {
                    userId: null,
                    routeId: String(payLoadValue?.currentRouteId),
                storeNumber: window?.sessionStorage?.getItem('storeNumber'),
                }
                      newAssign = await ManageRoute(unAssign)
                      setLoader(false)
                console.log("payloadNEWAssign", unAssign)
                if (newAssign == 'BADGATEWAY') {
                    setisTimeOutOccured(true)
                }
                if (newAssign?.data?.storeRouteId || newAssign == 'BADGATEWAY') {
                    setSuccessPopup(true);
                    setIsSucess(true);
                    setBindMessage({ ...bindMessage, unAssign: true });
                }
               else {
                    setBindMessage({ ...bindMessage, unAssign: true });
                setSuccessPopup(true); setIsSucess(false)
               }

               }
            else if (actype === "NEWASSIGN") {
                setPopUp({ ...popUp, coworkerRoute: false })
                const payloadNEWAssign: any = {
                    userId: newRoutePayload?.currentCoworker,
                    routeId: newRoutePayload?.currentRouteId,
            storeNumber: window.sessionStorage.getItem('storeNumber'),
               }
                newAssign = await ManageRoute(payloadNEWAssign)
              setLoader(false)
                console.log("payloadNEWAssign", payloadNEWAssign)
                if (newAssign?.data?.storeRouteId) {
                setSuccessPopup(true);
                    setIsSucess(true);
                    setBindMessage({ ...bindMessage, newAssign: true });
               }
               else {
                    setBindMessage({ ...bindMessage, newAssign: true });
                setSuccessPopup(true); setIsSucess(false)
               }
           }
           
        }
        catch (e) {
            console.log(e)
        }
    }
    const UpdateInfoMsgPopup = () => {
        return (
            <Grid item md={12} className="px-2">
                <Grid
                    item
                    md={12}
                    className="text-center"
                    style={{ textAlign: 'center' }}
                >
                    {!isTimeOutOccured && isSuccess ? (
                        <SuccessIcon style={{ textAlign: 'center' }}></SuccessIcon>
                    ) : !isTimeOutOccured ? (
                        <Alerticon style={{ textAlign: 'center' }}></Alerticon>
                    ) : null}
                    {bindMessage?.assign ?
                        <Typography
                            variant={'body2'}
                            style={{ marginTop: '1%', marginBottom: '1%' }}
                        >
                            {isSuccess ? 'Route Assigned Successfully' : 'Something went wrong'}
                        </Typography> : null}
                    {bindMessage?.unAssign ?
                        <Typography
                            variant={'body2'}
                            style={{ marginTop: '1%', marginBottom: '1%' }}
                        >
                            {isTimeOutOccured ? 'Timeout occurred: Records are processing in the background, please check the data after sometime.' :
                                isSuccess ? 'Route Unassigned Successfully' : 'Something went wrong'}
                        </Typography> : null}
                    {bindMessage?.newAssign ?
                        <Typography
                            variant={'body2'}
                            style={{ marginTop: '1%', marginBottom: '1%' }}
                        >
                            {isSuccess
                                ? 'New route is created Successfully'
                                : // eslint-disable-next-line sonarjs/no-duplicate-string
                                'Something went wrong'}
                        </Typography> : null}
                </Grid>
                <Grid
                    container
                    className="text-center mt-4"
                    style={{ justifyContent: 'center', marginTop: '20px' }}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        color="primary"
                        variant="contained"
                        isRounded={false}
                        style={{
                            textTransform: 'none',
                            // eslint-disable-next-line sonarjs/no-duplicate-string
                            width: '100px !important',
                            height: '35px',
                        }}
                        onClick={() => {
                            handleOKBtn()
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const handleOKBtn = () => {
        setSuccessPopup(false) 
        setNewRoutePayload({ ...newRoutePayload, currentCoworker: "0", currentRouteId: "0" })
        if (isSuccess) { pageLoad() }
        if (bindMessage?.unAssign) {
            setBindMessage({ ...bindMessage, unAssign: false })
        }
        else if (bindMessage?.newAssign) {
            setBindMessage({ ...bindMessage, newAssign: false })
        }
        else if (bindMessage?.assign) {
            setBindMessage({ ...bindMessage, assign: false })
        }
    }      
    const handleSaveAssign = async () => {
        // debugger;
        try {
            setLoader(true);
        const payloadAssign: any = {
                userId: String(payLoadValue?.currentCoworker),
                routeId: String(payLoadValue?.currentRouteId),
                   storeNumber: window.sessionStorage.getItem('storeNumber'),
               }
           console.log("payloadAssign", payloadAssign)
           const Assign:any = await ManageRoute(payloadAssign)
           setLoader(false)
            console.log("Assign", Assign)
            if (Assign?.data?.storeRouteId) {
            setSuccessPopup(true);
            setIsSucess(true);
                setBindMessage({ ...bindMessage, assign: true });
           }
           else {
                setBindMessage({ ...bindMessage, assign: true });
            setSuccessPopup(true); setIsSucess(false)
           }     
            }
        catch (e) {
                console.log(e)
            }
    }
    function AssignRouteModalCard() {
        return (
            <RACModalCard
                isOpen={popUp.saveAssign}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => setPopUp({ ...popUp, saveAssign: false })}
                // eslint-disable-next-line react/no-children-prop
                children={AssignRoutePopup()}
            // title="Validation Message"
            />
        );
    }
    const handleDropDown = (val: any, type: any) => {
        // debugger;
        console.log("val", val?.target?.value)
        setPayLoadValues({ ...payLoadValue, currentCoworker: val?.target?.value })
        if (type === "EDITCOWORKER") {
            setPayLoadValues({ ...payLoadValue, currentCoworker: val?.target?.value })
        }
        else if (type === "COWORKER") {
            setNewRoutePayload({ ...newRoutePayload, currentCoworker: val?.target?.value })
          }
        else if (type === "NEWROUTE") {
            setNewRoutePayload({ ...newRoutePayload, currentRouteId: val?.target?.value })
        }
    }
    function UnAssignRouteModalCard() {
        return (
            <RACModalCard
                isOpen={popUp.unAssignCoworker}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => setPopUp({ ...popUp, unAssignCoworker: false })}
                // eslint-disable-next-line react/no-children-prop
                children={UnAssignRoutePopup()}
            // title="Validation Message"
            />
        );
    }
    const AssignRoutePopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography className={`${classes.RACPOPMsg}`}>
                     Are you sure want to assign the route?
                     </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        isRounded={false}
                        onClick={() => setPopUp({ ...popUp, saveAssign: false })}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => { handleSaveAssign(), setPopUp({ ...popUp, saveAssign: false }) }}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );  
    }
    const UnAssignRoutePopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
                    The route you are unassigning contains {(payLoadValue?.customerCount ? payLoadValue?.customerCount : 0)} number of customers and will automatically assign these customers to the Manager Route.
                    </Typography>
                    <Typography className={`${classes.RACPOPMsg}`}>
                     Are you sure want to unassign the route?
                     </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        isRounded={false}
                        onClick={() => setPopUp({ ...popUp, unAssignCoworker: false })}
                    >
                        No, Keep It
                    </RACButton>
                    <RACButton
                        variant="contained"
                        style={{ background: '#CB324C', color: "white" }}
                        isRounded={false}
                     onClick={() => handleAssignSave("UNASSIGN")}
                    >
                        Yes, Unassign
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    function EditCoWorkerRouteModelCard() {
        return (
            <RACModalCard
                isOpen={popUp.editCoworker}
                className={classes.RACwidth}
                maxWidth="xs"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => setPopUp({ ...popUp, editCoworker: false })}
                // eslint-disable-next-line react/no-children-prop
                children={editCoWorkerRoutePopup()}
                title="Edit Co-Worker Route"
            />
        );
    }
    function CoWorkerRouteModelCard() {
        // debugger;
        return (
            <RACModalCard
                isOpen={popUp.coworkerRoute}
                className={classes.RACwidth}
                maxWidth="xs"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => setPopUp({ ...popUp, coworkerRoute: false })}
                // eslint-disable-next-line react/no-children-prop
                children={coWorkerRoutePopup()}
                title="Co-Worker Route"
            />
        );
    }
    const coWorkerRoutePopup = () => {
        // debugger;
        return (
            <CardContent>
                <Grid>
                    <RACSelect
                        inputLabel="Co-Worker"
                        name='coworker'
                        options={newCoWorker}
                        defaultValue={newRoutePayload?.currentCoworker !== undefined &&
                            newRoutePayload?.currentCoworker !== "" &&
                            newRoutePayload?.currentCoworker !== null ? newRoutePayload?.currentCoworker : "0"}
                        onChange={(e: any) => 
                            handleDropDown(e, 'COWORKER')
                        }
                    />
                    <RACSelect
                        inputLabel="Route"
                        name='route'
                        onChange={(e: any) => 
                            handleDropDown(e, 'NEWROUTE')
                        }
                     options={newRoute}
                        defaultValue={newRoutePayload?.currentRouteId !== undefined &&
                            newRoutePayload?.currentRouteId !== "" &&
                            newRoutePayload?.currentRouteId !== null ? newRoutePayload?.currentRouteId : "0"}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ marginBottom: "-17px" }}
                    className={` ${classes.mt4}  ${classes.componentRight}`}
                >
                    <RACButton
                        variant="outlined"
                        color="primary"
                        onClick={() => setPopUp({ ...popUp, coworkerRoute: false })}
                        className={classes.mr2}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.mr2}
                        onClick={() => handleAssignSave("NEWASSIGN")}
                    >
                        Save
                    </RACButton>
                </Grid>
            </CardContent>
        )
    }
    const editCoWorkerRoutePopup = () => {
        // debugger;
        return (
            <CardContent>
                <Grid>
                    <RACSelect
                        inputLabel="Co-Worker"
                        name='editCoworker'
                        options={newCoWorker}
                        defaultValue={payLoadValue?.currentCoworker ? String(payLoadValue?.currentCoworker) : "0"}
                        onChange={(e: any) => 
                            handleDropDown(e, 'EDITCOWORKER')
                        }
                    />
                    <RACSelect
                        inputLabel="Route"
                        name='route'
                        isDisabled={true}
                        options={routeValue}
                        defaultValue={payLoadValue?.currentRouteId !== undefined &&
                            payLoadValue?.currentRouteId !== '' &&
                            payLoadValue?.currentRouteId !== null ? String(payLoadValue?.currentRouteId) : "0"}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ marginBottom: "-17px" }}
                    className={` ${classes.mt4}  ${classes.componentRight}`}
                >
                    <RACButton
                        variant="outlined"
                        color="primary"
                        onClick={() => setPopUp({ ...popUp, editCoworker: false })}
                        className={classes.mr2}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.mr2}
                        onClick={() => setPopUp({ ...popUp, saveAssign: true, editCoworker: false })}

                    >
                        Save
                    </RACButton>
                </Grid>
             </CardContent>
        )
    }
    const renderRouteAssignmentTableHead = () => (
        <>
            <React.Fragment>
            <RACTableCell className={`${classes.racstrapTablecellBgColor} ${classes.btlRadius} ${!customerProfile ? classes.routeWidth:null} `} >
            <Grid className={classes.row} style={{ marginLeft: "15%" }}>
            <Typography variant="h5">Route</Typography>
            <span style={{ marginTop: "2px", marginLeft: "4px" }}>
                <a
                  onClick={() =>
                    sortGrid(sortOrder?.Route == 'asc' ? 'desc' : 'asc')
                  }
                >
                  {sortOrder?.Route == 'asc' ? (
                    <SortDownIcon className={classes.spacerMS1}></SortDownIcon>
                  ) : (
                    <SortUpIcon className={classes.spacerMS1}></SortUpIcon>
                  )}
                </a>
              </span>
              </Grid>
            </RACTableCell>
            <RACTableCell className={`${classes.racstrapTablecellBgColor} ${!customerProfile ? classes.coWorkerWidth:null}`} >
            <Typography variant="h5">Co-worker</Typography>
            </RACTableCell>
            <RACTableCell className={`${classes.racstrapTablecellBgColor} ${!customerProfile ? classes.btrRadius : null}  ${!customerProfile ? classes.dateModifieldWidth:null}`}>
            <Typography variant="h5">Date Modified</Typography>
            </RACTableCell>
            {customerProfile ?
            <RACTableCell className={`${classes.racstrapTablecellBgColor} ${classes.btrRadius}`}>
            <Typography variant="h5" style={{ marginLeft: "26%" }}>Action</Typography>
            </RACTableCell> : null}
            </React.Fragment>
        </>
    );
    const renderRouteAssignmentTableContent = (): any => {
        return assignedRoute?.userDetails?.map((el: any, i: any) => {
            const Date: any = moment(el?.modifiedDate?.split('T')[0]).format('MM/DD/YYYY');
            let disableVar = false;
            if (el?.routeRefCode == "M") {
                disableVar = true;
            }
            else {
                disableVar = false;
            }
            return (<React.Fragment key={i}>
                <RACTableRow> 
                    <RACTableCell className={`${i !== assignedRoute?.userDetails.length - 1 ? classes.racstrapTablecellBgColor : null}`} style={{ width: '17%', color: 'grey', fontWeight: '700', backgroundColor: 'white' }}>
                        <Grid style={{ marginLeft: "15%" }}>
                        {el?.routeName}
                        </Grid>
                    </RACTableCell>
                    <RACTableCell className={classes.racstrapTablecellBgColor} style={{ width: '17%', color: 'grey', fontWeight: '700' }}>
                        {el?.appUserName}
                    </RACTableCell>
                    <RACTableCell className={`${classes.racstrapTablecellBgColor}`} style={{ width: '17%', color: 'grey', fontWeight: '700' }}>
                        {Date}
                    </RACTableCell>
                    {customerProfile ? 
                    <RACTableCell className={`${classes.racstrapTablecellBgColor}`} style={{ width: '17%', color: 'grey', fontWeight: '700' }}>
                        <Grid style={{ display: 'flex' }}>
                            <RACButton
                                variant="outlined"
                                className={classes.assignBtnClass}
                                onClick={() => handleCoWorkerRoute(el, "ASSIGN")}
                            >Assign</RACButton>
                            <RACButton
                                variant="outlined"
                                disabled={disableVar}
                                className={classes.unassignBtnClass}
                                onClick={() => handleCoWorkerRoute(el, "UNASSIGN")}
                            >Unassign</RACButton>
                        </Grid>
                    </RACTableCell> : null }
                </RACTableRow>
            </React.Fragment>)
        })
    }
    function handleCancelBtn() {
        history.push({
            pathname: `/dashboard/homepage`,
        });
    }
    return (
        <>
            <Grid className={classes.container}>
                <EditCoWorkerRouteModelCard />
                <UnAssignRouteModalCard />
                <CoWorkerRouteModelCard />
                <AssignRouteModalCard />
                <Grid style={{ width: '100%', float: 'left' }}>
                    <Grid className={classes.colMd12mt3}>
                        <Grid className={classes.row} style={{ marginBottom: '3%' }}>
                            {/* Drawer total starts here */}
                            <Typography variant="h4" className={classes.header}>
                                Assigned Route
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={customerProfile ? classes.roleWidth: classes.customerProfileWidth} >
                    <Grid item md={12} className={classes.spacerMT2}>
                        {assignedRoute !== undefined && assignedRoute !== "" && assignedRoute !== null && assignedRoute?.userDetails !== undefined && assignedRoute?.userDetails !== "" && assignedRoute?.userDetails !== null && assignedRoute?.userDetails?.length !== 0 ?
                       <Card className={classes.card} >
                        <CardContent className={classes.cardBody}>
                        <RACTable
                        // className={`${classes.tableClass} ${customerProfile ? classes.tableCoWorkerProfileClass :null}`}
                        renderTableHead={renderRouteAssignmentTableHead}
                        renderTableContent={renderRouteAssignmentTableContent}
                      /> 
                        </CardContent>
                        </Card> : 
                          <Card className={classes.card}>
                              <CardContent className={classes.cardBody}>
                               <Grid className={classes.row}>
                                  <Grid className={!customerProfile ? classes.routeWidthNoRecord: classes.routeWidthCustomerProfile}>
                                  <Typography variant="h5">
                                  Route
                                  </Typography>
                                  </Grid>
                                  <Grid  className={!customerProfile ? classes.coWorkerWidthNoRecord: classes.coWorkerWidthCustomerProfile}>
                                  <Typography variant="h5">
                                  Co-worker
                                  </Typography>
                                  </Grid>
                                  <Grid className={`${!customerProfile ? classes.btrRadius:null}  ${!customerProfile ? classes.dateModifieldWidthNoRecord: classes.dateModifieldWidthCustomerProfile}`}>
                                  <Typography variant="h5">
                                  Date Modified
                                  </Typography>
                                  </Grid>
                                  {customerProfile ?
                                  <Grid style={{ width: "22%"}}>
                                  <Typography variant="h5">
                                  Action
                                  </Typography>
                                  </Grid> : null}
                                  <Grid style={{marginBottom:"15%",marginLeft:"46%",color: '#A5A6A6',marginTop:"10%"}}>
                                  <NoRecord style={{ width: '38px', height: '55px',marginTop:"70px",marginLeft:"30%"}}></NoRecord>
                                  <Typography>
                                  No Record Found
                                  </Typography>
                                  </Grid>
                              </Grid>   
                              </CardContent>
                          </Card>
                        
                        }
                    </Grid>
                </Grid>
            </Grid>
            {/* Footer */}
            <Grid container classes={{
                root: clsx(classes.customerToolbar, classes.fixedBottom),
            }} style={{ zIndex: 0 }} >
                <Grid className={classes.footerFixed}>
                    <Grid style={{ float: 'left' }}>
                        <RACButton
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleCancelBtn()}
                        >
                            Cancel
                        </RACButton>
                    </Grid>
                     {customerProfile ? 
                    <Grid style={{ float: 'right' }}>
                        <RACButton
                            variant="contained"
                            color="primary"
                            onClick={(e) => handleCoWorkerRoute(e, "NEWROUTE")}
                        >
                            Assign Route
                        </RACButton>
                        </Grid> : null}
                </Grid>
            </Grid>
            <Grid>
          <RACModalCard
          isOpen={successPopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => 
            handleOKBtn()
          }
          // eslint-disable-next-line react/no-children-prop
          children={UpdateInfoMsgPopup()}
        />
        </Grid>
            <Modal
                isOpen={loader}
                className={classes.loaderStyle}
                id="openpopup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid>
                    <Grid>
                        <Grid className={classes.RACLoaderPage}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}