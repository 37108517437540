/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { globalStyles } from '../ComponentStyles/globalstyles';
import { BreadCrumbStyles } from '../ComponentStyles/BreadCrumb';
// import { formStyles } from "./componentstyles/formstyles";
import { fscstyles } from '../ComponentStyles/fscstyles';
import { ReactComponent as RacFilter } from '../../assets/images/RacSort.svg';
import { ReactComponent as FilterClose } from '../../assets/images/filter-close-icon.svg';
import { ReactComponent as Info } from '../../assets/images/info-button.svg';
import { ReactComponent as Alerticon } from '../../assets/images/No-records.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';


import {
  Grid,
  RACButton,
  List,
  ListItem,
  Typography,
  Card,
  CardContent,
  RACSelect,
  RACDatePicker,
  RACTextbox,
  RACToggle,
  Box,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CircularProgress,
} from '@rentacenter/racstrap';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { GetFscContestDetail } from '../../api/user';
import Modal from 'react-modal';
export const FscContestDetails = () => {
  const objState = {
    contest: '',
    status: '',
    priority: '',
    startDate: '',
    endDate: '',
    operationCommunicationId: '',
    searchText: ''
  };
  //state for hide and show filter popup
  const [openFilter, setOpenFilter] = useState(false);
  const classes: any = globalStyles();
  const BCS = BreadCrumbStyles();
  const fscclass = fscstyles();
  const [filterObj, setFilterObj] = useState(objState);
  const [fscContestData, setFscContestData] = useState<any>();
  const [fscContestDataCopy, setFscContestDataCopy] = useState<any>();
  const [dropdownData, setDropdownData] = useState<any>();
  const [contestDropDown, setcontestDropDown] = useState<any>();
  const [loader, setloader] = useState(false);
  const [isNoRecords, setisNoRecords] = useState(false);
  const history: any = useHistory();
  console.log('history..', history, history?.location?.pathname.split("/").at(-2))
  const categoryName = history?.location?.pathname.split("/").at(-2)
  useEffect(() => {
    const PageLoad = async () => {
      try {
        setloader(true)
        const response = await GetFscContestDetail({ isAllDataRequired: true, type: categoryName.toUpperCase() })
        let data: any = {}
        if (categoryName == 'fsc') {
          data = { communicationRecords: response }
        } else {
          data = response
          const contestDropDown = buildDropdownList(response.contestData)
          setcontestDropDown(contestDropDown)
        }

        setFscContestData(data);
        setFscContestDataCopy(data);
        setloader(false)
        console.log(response)
      } catch (e: any) {
        setloader(false)
        console.log(e)
      }
      setDropdownData([
        {
          label: 'Select',
          value: '0',
        },
        {
          label: 'High',
          value: 'High',
        },
        {
          label: 'Medium',
          value: 'Medium',
        },
        {
          label: 'Low',
          value: 'Low',
        },
      ]);
      // const data: any = {
      //   "communicationRecords": [
      //     {
      //       "operationCommunicationId": 68,
      //       "priorityId": 1,
      //       "imagePath": "581398f4-c089-48d4-96c6-9f2b6d84f488_contestImage.png",
      //       "contestId": 15,
      //       "createdBy": "377001",
      //       "startDate": "2023-02-25",
      //       "endDate": "2023-02-27",
      //       "message": "Create a 50 Aggreement in a week ",
      //       "companyId": 1,
      //       "prorityLevel": "HIGH",
      //       "categoryName": "CONTEST",
      //       "associationId": 34,
      //       "associationRefCode": "US-MS",
      //       "associationTypeName": "STATE",
      //       "associationTypeId": 4,
      //       "contestName": " Mela",
      //       "associationVals": [
      //         "US-MS",
      //         "MX-ZT"
      //       ],
      //       "base64data": "JVBERi0xLjMKJf////8KOCM5ZGI3ZTg0MzA5Nzc4ZjExODY1YmFkYT4gPGQ0M2MwNDJiMzlkYjdlODQzMDk3NzhmMTE4NjViYWRhPl0KPj4Kc3RhcnR4cmVmCjQ1NDQ2CiUlRU9GCg=="
      //     },
      //     {
      //       "operationCommunicationId": 138,
      //       "priorityId": 1,
      //       "imagePath": "7221278d-b767-42fb-a212-0faab9581170_contestImage.png",
      //       "contestId": 51,
      //       "createdBy": "377001",
      //       "startDate": "2023-02-27",
      //       "endDate": "2023-02-28",
      //       "message": "Create a 50 Aggreement in a week ",
      //       "companyId": 1,
      //       "prorityLevel": "HIGH",
      //       "categoryName": "CONTEST",
      //       "associationId": 952,
      //       "associationRefCode": "02202",
      //       "associationTypeName": "STORE",
      //       "associationTypeId": 5,
      //       "contestName": "ghjjbvb",
      //       "associationVals": [
      //         "02202"
      //       ],
      //       "base64data": "JVBERi0xLjMKJf////8KOCAwIG9iago8PAovVHlwZSAvRXh0R1N0YXRlCi9jYSAxCj4iAwMDAwMCBuIAowMDAwMDMwMTA5IDAwMDAwIG4gCjAwMDAwMzA4NTEgMDAwMDAgbiAKMDAwMDA0MDcwNSAwMDAwMCBuIAowMDAwMDMxNDEwIDAwMDAwIG4gCjAwMDAwMzE2NzggMDAwMDAgbiAKMDAwMDAzMjA2MyAwMDAwMCBuIAp0cmFpbGVyCjw8Ci9TaXplIDI1Ci9Sb290IDMgMCBSCi9JbmZvIDEzIDAgUgovSUQgWzxkNDNjMDQyYjM5ZGI3ZTg0MzA5Nzc4ZjExODY1YmFkYT4gPGQ0M2MwNDJiMzlkYjdlODQzMDk3NzhmMTE4NjViYWRhPl0KPj4Kc3RhcnR4cmVmCjQ1NDQ2CiUlRU9GCg=="
      //     },
      //     {
      //       "operationCommunicationId": 139,
      //       "priorityId": 1,
      //       "imagePath": "7221278d-b767-42fb-a212-0faab9581170_contestImage.png",
      //       "contestId": 52,
      //       "createdBy": "377001",
      //       "startDate": "2023-02-27",
      //       "endDate": "2023-02-28",
      //       "message": "Create a 50 Aggreement in a week ",
      //       "companyId": 1,
      //       "prorityLevel": "HIGH",
      //       "categoryName": "CONTEST",
      //       "associationId": 952,
      //       "associationRefCode": "02202",
      //       "associationTypeName": "STORE",
      //       "associationTypeId": 5,
      //       "contestName": "EVENTS",
      //       "associationVals": [
      //         "02202"
      //       ],
      //       "base64data": "JVBERi0xLjMKJf////Hhtuox/MiAwMDAwMCBuIAowMDAwMDMwMTA5IDAwMDAwIG4gCjAwMDAwMzA4NTEgMDAwMDAgbiAKMDAwMDA0MDcwNSAwMDAwMCBuIAowMDAwMDMxNDEwIDAwMDAwIG4gCjAwMDAwMzE2NzggMDAwMDAgbiAKMDAwMDAzMjA2MyAwMDAwMCBuIAp0cmFpbGVyCjw8Ci9TaXplIDI1Ci9Sb290IDMgMCBSCi9JbmZvIDEzIDAgUgovSUQgWzxkNDNjMDQyYjM5ZGI3ZTg0MzA5Nzc4ZjExODY1YmFkYT4gPGQ0M2MwNDJiMzlkYjdlODQzMDk3NzhmMTE4NjViYWRhPl0KPj4Kc3RhcnR4cmVmCjQ1NDQ2CiUlRU9GCg=="
      //     },
      //     {
      //       "operationCommunicationId": 140,
      //       "priorityId": 1,
      //       "imagePath": "7221278d-b767-42fb-a212-0faab9581170_contestImage.png",
      //       "contestId": 53,
      //       "createdBy": "377001",
      //       "startDate": "2023-02-27",
      //       "endDate": "2023-02-28",
      //       "message": "Create a 50 Aggreement in a week ",
      //       "companyId": 1,
      //       "prorityLevel": "HIGH",
      //       "categoryName": "CONTEST",
      //       "associationId": 2636,
      //       "associationRefCode": "FRC",
      //       "associationTypeName": "LOB",
      //       "associationTypeId": 2,
      //       "contestName": "EVENT",
      //       "associationVals": [
      //         "FRC"
      //       ],
      //       "base64data": "JVBERi0xLjMKJf////8KOCAwIG9iago8PAovVHlwZSAvRXh0R1N0YXRlCi9jYSAxCj4IAp0cmFpbGVyCjw8Ci9TaXplIDI1Ci9Sb290IDMgMCBSCi9JbmZvIDEzIDAgUgovSUQgWzxkNDNjMDQyYjM5ZGI3ZTg0MzA5Nzc4ZjExODY1YmFkYT4gPGQ0M2MwNDJiMzlkYjdlODQzMDk3NzhmMTE4NjViYWRhPl0KPj4Kc3RhcnR4cmVmCjQ1NDQ2CiUlRU9GCg=="
      //     },
      //     {
      //       "operationCommunicationId": 141,
      //       "priorityId": 1,
      //       "imagePath": "7221278d-b767-42fb-a212-0faab9581170_contestImage.png",
      //       "contestId": 54,
      //       "createdBy": "377001",
      //       "startDate": "2023-02-27",
      //       "endDate": "2023-02-28",
      //       "message": "Create a 50 Aggreement in a week ",
      //       "companyId": 1,
      //       "prorityLevel": "HIGH",
      //       "categoryName": "CONTEST",
      //       "associationId": 952,
      //       "associationRefCode": "02202",
      //       "associationTypeName": "STORE",
      //       "associationTypeId": 5,
      //       "contestName": "EVEN",
      //       "associationVals": [
      //         "02202"
      //       ],
      //       "base64data": "JVBERi0xLjMKJf////8KOCAwIG9iago8PAovVHlwZSAvRXh0R1N0YXRlCi9jYSAxCj4DAwMDMwMTA5IDAwMDAwIG4gCjAwMDAwMzA4NTEgMDAwMDAgbiAKMDAwMDA0MDcwNSAwMDAwMCBuIAowMDAwMDMxNDEwIDAwMDAwIG4gCjAwMDAwMzE2NzggMDAwMDAgbiAKMDAwMDAzMjA2MyAwMDAwMCBuIAp0cmFpbGVyCjw8Ci9TaXplIDI1Ci9Sb290IDMgMCBSCi9JbmZvIDEzIDAgUgovSUQgWzxkNDNjMDQyYjM5ZGI3ZTg0MzA5Nzc4ZjExODY1YmFkYT4gPGQ0M2MwNDJiMzlkYjdlODQzMDk3NzhmMTE4NjViYWRhPl0KPj4Kc3RhcnR4cmVmCjQ1NDQ2CiUlRU9GCg=="
      //     },
      //     {
      //       "operationCommunicationId": 142,
      //       "priorityId": 1,
      //       "imagePath": "1b51653e-e297-4b4f-a79f-284e559ae7d6_contestImage.png",
      //       "contestId": 55,
      //       "createdBy": "377001",
      //       "startDate": "2023-02-27",
      //       "endDate": "2023-02-28",
      //       "message": "Create a 50 Aggreement in a week Region",
      //       "companyId": 1,
      //       "prorityLevel": "HIGH",
      //       "categoryName": "CONTEST",
      //       "associationId": 2637,
      //       "associationRefCode": "R0705",
      //       "associationTypeName": "REGION",
      //       "associationTypeId": 6,
      //       "contestName": "EVE",
      //       "associationVals": [
      //         "R0705",
      //         "R0710"
      //       ],
      //       "base64data": "JVBERi0xLjMKJf////8KOCAwIG9iago8PAovVHlwZSAvRXh0R1N0YXRlCi9jYSAxCj4iAKMDAwMDAyOTg0MiAwMDAwMCBuIAowMDAwMDMwMTA5IDAwMDAwIG4gCjAwMDAwMzA4NTEgMDAwMDAgbiAKMDAwMDA0MDcwNSAwMDAwMCBuIAowMDAwMDMxNDEwIDAwMDAwIG4gCjAwMDAwMzE2NzggMDAwMDAgbiAKMDAwMDAzMjA2MyAwMDAwMCBuIAp0cmFpbGVyCjw8Ci9TaXplIDI1Ci9Sb290IDMgMCBSCi9JbmZvIDEzIDAgUgovSUQgWzxkNDNjMDQyYjM5ZGI3ZTg0MzA5Nzc4ZjExODY1YmFkYT4gPGQ0M2MwNDJiMzlkYjdlODQzMDk3NzhmMTE4NjViYWRhPl0KPj4Kc3RhcnR4cmVmCjQ1NDQ2CiUlRU9GCg=="
      //     },
      //     {
      //       "operationCommunicationId": 143,
      //       "priorityId": 1,
      //       "imagePath": "1b51653e-e297-4b4f-a79f-284e559ae7d6_contestImage.png",
      //       "contestId": 56,
      //       "createdBy": "377001",
      //       "startDate": "2023-02-27",
      //       "endDate": "2023-02-28",
      //       "message": "Create a 50 Aggreement in a week DISTRICT ",
      //       "companyId": 1,
      //       "prorityLevel": "HIGH",
      //       "categoryName": "CONTEST",
      //       "associationId": 2639,
      //       "associationRefCode": "DR003",
      //       "associationTypeName": "DISTRICT",
      //       "associationTypeId": 7,
      //       "contestName": "EV",
      //       "associationVals": [
      //         "DR003"
      //       ],
      //       "base64data": "JVBERi0xLjMKJf////8KOCXuvvgZucpWQ0RdIHqFZ+9vVoYjC6HXXD6js1MloSYhGcCyH7j9gfKV75AeW2ZRan9z5+Fup/QyYjM5ZGI3ZTg0MzA5Nzc4ZjExODY1YmFkYT4gPGQ0M2MwNDJiMzlkYjdlODQzMDk3NzhmMTE4NjViYWRhPl0KPj4Kc3RhcnR4cmVmCjQ1NDQ2CiUlRU9GCg=="
      //     },
      //     {
      //       "operationCommunicationId": 144,
      //       "priorityId": 1,
      //       "imagePath": "5c31908d-0267-451c-8db6-37df461c781d_contestImage.png",
      //       "contestId": 57,
      //       "createdBy": "377001",
      //       "startDate": "2023-02-27",
      //       "endDate": "2023-02-28",
      //       "message": "Create a 50 Aggreement in a week LOB",
      //       "companyId": 2,
      //       "prorityLevel": "HIGH",
      //       "categoryName": "CONTEST",
      //       "associationId": 2636,
      //       "associationRefCode": "FRC",
      //       "associationTypeName": "LOB",
      //       "associationTypeId": 2,
      //       "contestName": "E",
      //       "associationVals": [
      //         "FRC",
      //         "HOC",
      //         "GIN"
      //       ],
      //       "base64data": "JVBERi0xLjMKJfVHlwZ"
      //     }
      //   ],
      //   "contestDetails": [
      //     {
      //       "contestid": 8,
      //       "contestname": "BiggBosss"
      //     },
      //     {
      //       "contestid": 10,
      //       "contestname": "Prize Pool"
      //     },
      //     {
      //       "contestid": 12,
      //       "contestname": "Prize Mela"
      //     },
      //     {
      //       "contestid": 15,
      //       "contestname": " Mela"
      //     },
      //     {
      //       "contestid": 17,
      //       "contestname": "Summa oru constest"
      //     },
      //     {
      //       "contestid": 19,
      //       "contestname": "Summa rendu constest"
      //     },
      //     {
      //       "contestid": 21,
      //       "contestname": "Summa naalu constest"
      //     },
      //     {
      //       "contestid": 22,
      //       "contestname": "Ava1000"
      //     },
      //     {
      //       "contestid": 24,
      //       "contestname": "fgh"
      //     },
      //     {
      //       "contestid": 26,
      //       "contestname": "ghj"
      //     },
      //     {
      //       "contestid": 28,
      //       "contestname": "sdfghj"
      //     },
      //     {
      //       "contestid": 31,
      //       "contestname": "n"
      //     },
      //     {
      //       "contestid": 33,
      //       "contestname": "nal"
      //     },
      //     {
      //       "contestid": 35,
      //       "contestname": "hilo"
      //     },
      //     {
      //       "contestid": 38,
      //       "contestname": "oru contest"
      //     },
      //     {
      //       "contestid": 39,
      //       "contestname": "rendu contest"
      //     },
      //     {
      //       "contestid": 40,
      //       "contestname": "moonu contest"
      //     },
      //     {
      //       "contestid": 41,
      //       "contestname": "naalu contest"
      //     },
      //     {
      //       "contestid": 42,
      //       "contestname": "gvb"
      //     },
      //     {
      //       "contestid": 43,
      //       "contestname": "SUMMA"
      //     },
      //     {
      //       "contestid": 44,
      //       "contestname": "SUMMAda"
      //     },
      //     {
      //       "contestid": 45,
      //       "contestname": "wfghjk"
      //     },
      //     {
      //       "contestid": 46,
      //       "contestname": "xcvbnm"
      //     },
      //     {
      //       "contestid": 47,
      //       "contestname": "xcfb"
      //     },
      //     {
      //       "contestid": 48,
      //       "contestname": "werthj"
      //     },
      //     {
      //       "contestid": 49,
      //       "contestname": "Lucky Champ"
      //     }
      //   ]
      // }

      // setFscContestData(data);
      // setFscContestDataCopy(data);
    };
    PageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const buildDropdownList = (
    optionsList: any
  ) => {
    const options: any = [];
    if (
      optionsList &&
      optionsList.length > 0
    ) {
      optionsList.map((value: any, index: any) => {
        if (index == 0) {
          options.push({ label: 'Select', value: '0' });
        }
        options.push({
          label: value.contestname,
          value: String(value.contestid),
        });
      });
    }
    return options;
  };
  console.log('fscContestData', fscContestData)
  const filterClick = () => {
    const el: any = document.getElementById('filterIcon');
    console.log('active el', el);
    if (el !== document.activeElement || openFilter === true) {
      setOpenFilter(false);
    }
    if (openFilter === false) {
      setOpenFilter(true);
    }
  };

  const startDateOnChange = (event: any) => {
    setFilterObj({ ...filterObj, startDate: event });
  };

  const endDateOnChange = (event: any) => {
    setFilterObj({ ...filterObj, endDate: event });
  };
  const applyFilter = () => {
    let filteredData = fscContestDataCopy.communicationRecords
    if (history?.location?.pathname.split("/").at(-2) == 'contest' && filterObj.contest) {
      filteredData = filteredData.filter((el: any) => filterObj.contest == el.contestName)
    }
    if (filterObj.status) {
      filteredData = filteredData.filter((el: any) => filterObj.status == 'Active' ? el.endDate >= moment().format('YYYY-MM-DD') : el.endDate < moment().format('YYYY-MM-DD'))
    }
    if (filterObj.priority) {
      filteredData = filteredData.filter((el: any) => el.prorityLevel.toLowerCase() == filterObj.priority.toLowerCase())
    }
    if (filterObj.startDate && filterObj.endDate) {
      filteredData = filteredData.filter((el: any) => el.startDate >= moment(filterObj.startDate).format('YYYY-MM-DD') && el.endDate <= moment(filterObj.endDate).format('YYYY-MM-DD'))
    }
    if (filterObj.endDate && !filterObj.startDate) {
      filteredData = filteredData.filter((el: any) => el.endDate <= moment(filterObj.endDate).format('YYYY-MM-DD'))
    }
    if (filterObj.startDate && !filterObj.endDate) {
      filteredData = filteredData.filter((el: any) => el.startDate >= moment(filterObj.startDate).format('YYYY-MM-DD'))
    }
    setFscContestData({
      ...fscContestData, communicationRecords: filteredData
    })
    if (filteredData.length == 0) {
      setisNoRecords(true)
    } else {
      setisNoRecords(false)
    }
  }

  const clearClick = () => {
    setFilterObj(objState);
  };

  // let ingRef = React.createRef()

  const statusDropdown = () => {
    return [
      { label: 'Select', value: '0' },
      { label: 'Active', value: 'Active' },
      { label: 'InActive', value: 'InActive' },
    ];
  };

  const statusContestOnChange = (event: any, name: any) => {
    setFilterObj({ ...filterObj, [name]: event.target.value });
    console.log('status format', event.target.value);
  };

  const searchOnChange = () => {
    const filteredData: any = fscContestDataCopy && fscContestDataCopy.communicationRecords.filter((data: any) => data.message.toLowerCase().includes(filterObj.searchText.toLowerCase()));
    setFscContestData({
      ...fscContestData, communicationRecords: filteredData
    })
  }

  const priorityOnChange = (event: any) => {
    setFilterObj({ ...filterObj, priority: event.target.value });
  };

  const createNewUpdate = () => {
    let pathName = ''
    if (history?.location?.pathname.split("/").at(-2) == 'fsc') {
      pathName = '/storemgmt/fsc/create'
    } else if (history?.location?.pathname.split("/").at(-2) == 'contest') {
      pathName = '/storemgmt/contest/create'
    }
    history.push({ pathname: pathName, state: { type: 'CREATE', data: {}, contestDropDown } })
  };

  const updateClick = (data: any) => {
    let pathName = ''
    if (history?.location?.pathname.split("/").at(-2) == 'fsc') {
      pathName = '/storemgmt/fsc/update'
    } else if (history?.location?.pathname.split("/").at(-2) == 'contest') {
      pathName = '/storemgmt/contest/update'
    }
    console.log(fscContestData)
    if (data.associationTypeName == 'STATE') {
      data.associationVals = data.associationVals.map((el: any) => el.split('-')[1])
    }
    history.push({ pathname: pathName, state: { type: 'UPDATE', data, contestDropDown } })
  };

  const cancelClick = () => {
    history.push({ pathname: '/dashboard' });
  };

  const search = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      searchOnChange()
    }
  };

  return (
    <Grid>
      <>
        <Modal
          isOpen={loader}
          className={classes.loaderStyle}
          id="openpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <Grid>
            <Grid>
              <Grid className={classes.RACLoaderPage}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Grid className={classes.mbFooterSpacing}>
          <Grid container>
            <Grid className={classes.m2}>
              <List className={`${classes.dflex} ${classes.p0}`}>
                <ListItem
                  className={`${classes.widthAuto} ${classes.p2} ${classes.fontSemiBold} ${BCS.BreadCrumbInactive}`}
                >
                  <Typography
                    className={`${classes.pr2} ${classes.textBlack}`}
                  >
                    Admin
                  </Typography>
                </ListItem>
                {
                  history?.location?.pathname.split("/").at(-2) == 'fsc' ?
                    <ListItem
                      className={`${classes.widthAuto} ${classes.p2} ${classes.fontSemiBold} ${classes.textBlue}`}
                    >
                      FSC Updates
                    </ListItem> : <ListItem
                      className={`${classes.widthAuto} ${classes.p2} ${classes.fontSemiBold} ${classes.textBlue}`}
                    >
                      Contest Updates
                    </ListItem>
                }

              </List>
            </Grid>
          </Grid>
          <Grid className={`${classes.alignCenter} ${classes.dflex}`}>
            <Typography variant="h5" className={classes.m2}>
              {history?.location?.pathname.split("/").at(-2) == 'fsc' ?
                'FSC Updates' : 'Contest Updates'}
            </Typography>
            <Info className={classes.ml1} title={`This page to create and update any message in the ${history?.location?.pathname.split("/").at(-2).toUpperCase()} Updates grid`}></Info>
          </Grid>

          <Card
            className={`${classes.racCard} ${classes.m2} ${classes.overFlowVisible}`}
          >
            <CardContent className={`${classes.pl4} ${classes.pr4}`}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={`${classes.componentRight} ${classes.m3}`}
              >
                <Grid className={`${classes.positionRelative}`}>
                  <RACButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    id="filterIcon"
                    className={`${classes.mr2} ${classes.filterBtn}`}
                    onClick={filterClick}
                  >
                    <RacFilter></RacFilter>
                  </RACButton>
                  {openFilter ? (
                    <Card
                      className={`${classes.positionAbsolute} ${classes.filterDropDown}`}
                    >
                      <CardContent>
                        <Grid
                          className={`${classes.componentSpaceBetween} ${classes.mb2}`}
                        >
                          <Typography variant="h5">
                            Advanced Filter
                          </Typography>
                          <FilterClose onClick={filterClick}></FilterClose>
                        </Grid>
                        <Grid container spacing={4} className={classes.mb2}>
                          {history?.location?.pathname.split("/").at(-2) == 'contest' ? <Grid item xs={12} sm={4} md={4} lg={6}>
                            <RACSelect
                              inputLabel="Contest"
                              options={contestDropDown}
                              name='contest'
                              onChange={(event) => statusContestOnChange(event, 'contest')}
                              defaultValue={
                                !filterObj.contest ? '0' : filterObj.contest
                              }
                            ></RACSelect>
                          </Grid> : null}
                          <Grid item xs={12} sm={4} md={4} lg={6}>
                            <RACSelect
                              inputLabel="Status"
                              name='status'
                              options={statusDropdown()}
                              onChange={(event) => statusContestOnChange(event, 'status')}
                              defaultValue={
                                !filterObj.status ? '0' : filterObj.status
                              }
                            ></RACSelect>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={6}>
                            <RACSelect
                              inputLabel="Priority"
                              options={dropdownData}
                              loading={
                                dropdownData != undefined ? false : true
                              }
                              onChange={(event) => priorityOnChange(event)}
                              defaultValue={
                                !filterObj.priority
                                  ? '0'
                                  : filterObj.priority
                              }
                            ></RACSelect>
                          </Grid>
                        </Grid>

                        <Grid className={`${classes.mb2}`}>
                          <Typography variant="h5">Date Range</Typography>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <RACDatePicker
                              label="From"
                              value={filterObj.startDate}
                              onChange={(event) => startDateOnChange(event)}
                            ></RACDatePicker>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <RACDatePicker
                              label="To"
                              value={filterObj.endDate}
                              // inputProps={{
                              //   max: moment()
                              //     .format('YYYY-MM-DD'),
                              // }}
                              onChange={(event) => endDateOnChange(event)}
                            ></RACDatePicker>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className={` ${classes.mt4}  ${classes.componentRight}`}
                        >
                          <RACButton
                            variant="outlined"
                            color="primary"
                            onClick={clearClick}
                            className={classes.mr2}
                          >
                            Clear
                          </RACButton>
                          <RACButton
                            variant="contained"
                            color="primary"
                            className={classes.mr2}
                            onClick={applyFilter}
                          >
                            Apply
                          </RACButton>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : null}
                </Grid>
                <Grid style={{ display: 'flex' }}>
                  <RACTextbox
                    onKeyPress={(event) => search(event)}
                    value={filterObj.searchText}
                    placeHolder="Search"
                    className={classes.searchSpacing}
                    OnChange={(e: any) => setFilterObj({ ...filterObj, searchText: e.target.value })}
                  ></RACTextbox>
                  <div style={{
                    height: '100%',
                    width: '30px',
                    border: '1px solid #c4c4c4',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                    cursor: 'pointer'

                  }}><SearchIcon style={{
                    marginTop: '4px',
                    marginBottom: '4px',
                    paddingLeft: '4px',
                    color: 'gray'
                  }} onClick={searchOnChange} /></div>
                </Grid>
              </Grid>
              <Grid>
                {fscContestData && fscContestData.communicationRecords && fscContestData.communicationRecords.length > 0 ? (
                  fscContestData.communicationRecords.map((obj: any) => {
                    const el: any = document.getElementById('htmlText')
                    if (el) {
                      el.innerHTML = obj.message
                      obj.message = el.textContent
                      el.innerHTML = ''
                    }
                    return (
                      <Grid
                        className={classes.borderBottom}
                        onClick={() => updateClick(obj)}
                        key={obj}
                      >
                        <Grid
                          className={`${classes.borderRadius5} ${obj.prorityLevel == 'HIGH'
                            ? fscclass.borderYellow
                            : obj.prorityLevel == 'MEDIUM'
                              ? fscclass.borderViolet
                              : obj.prorityLevel == 'LOW'
                                ? fscclass.borderBlue
                                : fscclass.borderGreen
                            } ${classes.py2} ${classes.my4}`}
                          container
                          spacing={2}
                        >
                          <Typography className={`${classes.pl2} ${classes.w100} ${classes.mt2} ${classes.mb2}`}>{history?.location?.pathname.split("/").at(-2) == 'contest' ? `${obj.contestName} -` : ''} {obj.message}<span id='htmlText'></span></Typography>
                          <Grid
                            className={classes.pb0}
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            lg={2}
                          >
                            <Typography
                              component="span"
                              className={fscclass.details}
                            >
                              Start Date:
                            </Typography>
                            <Typography component="span">
                              {obj.startDate}
                            </Typography>
                          </Grid>

                          <Grid
                            className={classes.pb0}
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            lg={2}
                          >
                            <Typography
                              component="span"
                              className={fscclass.datestyle}
                            >
                              End Date:{' '}
                            </Typography>
                            <Typography
                              component="span"
                              className={fscclass.datestyle}
                            >
                              {obj.endDate}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={4} md={3} lg={2}>
                            <Typography
                              component="span"
                              className={fscclass.details}
                            >
                              Created By:
                            </Typography>
                            <Typography component="span">
                              {obj.createdBy}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={4} md={3} lg={2}>
                            <RACToggle
                              toggleColor={'green'}
                              rightLabel={
                                obj.endDate >= moment().format('YYYY-MM-DD') ? 'Active' : 'InActive'
                              }
                              rightText={true}
                              GridClassName={classes.InlineFlex}
                              labelClassName={classes.ml2}
                              checked={obj.endDate >= moment().format('YYYY-MM-DD') ? true : false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })
                ) : null}
                {isNoRecords ? <Grid
                  container
                  className={`${classes.justifyContentCenter} ${classes.mb2}`}
                  style={{ justifyContent: 'center' }}
                >
                  <Alerticon style={{ width: '30px', height: '30px' }} />
                  <Box className={`${classes.ms2}`} style={{ marginTop: '5px' }}>
                    No Records Found
                  </Box>
                </Grid> : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container className={classes.fixedFooter}>
          <Grid>
            <RACButton
              variant="outlined"
              color="primary"
              onClick={cancelClick}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid>
            <RACButton
              onClick={createNewUpdate}
              variant="contained"
              color="primary"
            >
              Create New Update
            </RACButton>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};
