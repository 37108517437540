/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
export const BreadCrumbStyles = () => {
  const useClasses = makeStyles((theme) => ({
    BreadCrumbInactive: {
      "&:after": {
        content: "''",
        width: 0,
        height: 0,
        borderTop: "5px solid transparent",
        borderLeft: "10px solid #555",
        borderBottom: "5px solid transparent",
      },
    },
  }));
  //eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = useClasses();
  return classes;
};
