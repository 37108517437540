/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-dupe-keys*/
import { RACCOLOR, makeStyles } from '@rentacenter/racstrap';
// import { makeStyles } from '@material-ui/core';
//For F_Final Drop .
export const closeEndOfDayStyles = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const UseClasses = makeStyles((theme) => ({
    container: {
      width: "100%",
      paddingRight: "0.75rem",
      paddingLeft: "0.75rem",
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: "15px",
      paddingBottom: "80px !important",
    },
    customerToolbar: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    fixedBottom: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      padding: '1rem',
    },
    rowMt2: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '0.5rem !important',
    },
   colMd12mt3: {
      flex: "0 0 auto",
      width: "100%",
      marginTop: "1rem !important",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0",
      marginRight: "-0.75rem",
    },
    header: {
      width: "100%",
      fontSize: "18px",
      fontFamily: "OpenSans-bold",
      marginBottom: "15px",
    },
    racColMD7: {
      flex: '0 0 auto',
      width: '58.33%',
    },
    loaderStyle: {
        border: 'none',
        backgroundColor: 'transparent',
        // userFocus: 'none',
        // MozUserFocus: 'none',
        outline: 'none',
      },
    card: {
      border: "none",
      borderRadius: "1rem",
      boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
    },
    cardBody: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
      paddingBottom: "10px !important",
    },
    cardBody1: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
    },
    tableStricky:{
      '& th': {position: 'sticky',
       top: 0, backgroundColor: '#ffffff',
       fontFamily: 'OpenSans-semibold',
       zIndex: -0,
       padding: '0.9375rem 0.2rem'},
        '& tbody': {
           '& tr': {
             position: 'sticky',
              top: 10,backgroundColor: '#ffffff'},
               '& td': { fontFamily: 'OpenSans-semibold',
               padding: '0.9375rem 0.2rem',color: '#4A5174', }, }
    },
    mt2: {
      marginTop: '0.5rem !important',
    },
    table: {
      width: "100%",
      color: "#212529",
      // verticalAlign: "top",
      borderColor: "#dee2e6",
    },
    tableBorder: {
      captionSide: "bottom",
      borderCollapse: "collapse",
    },
    tableRow: {
      background: "none",
      width: "50%",
    },
    float: {
      float: "right",
    },
    floatLeft: {
      float: "left",
    },
    drawerTotal: {
      color: "#2179FE",
      padding: "4px",
      paddingLeft: "24px",
    },
    currency:{

      '&div':{
        '&div':{
          '&div':{
            '&div':{
        //       '&div':{
                padding:"10px",
                backgroundColor:'red'
              }
            }
          }
        }
      // }
    },
    dollarClass:{
      padding:"11px",
      width:'24%'
    },
    textWidthamount:{
      // '&div':{padding:"4px"},

      width:'76% !important',

      '& input':{

          height:'28px'

      },
},
footerGrid: {
  paddingTop: '120px !important',
  marginRight: '-0.75rem',
  marginLeft: '-0.75rem',
  display: 'flex',
  flexWrap: 'wrap',
},
footerFixed: {
  backgroundColor: '#fff !important',
  padding: '1rem !important',
  boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%) !important',
  position: 'fixed',
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1030,
},
spacerMT4: {
  marginTop: theme.typography.pxToRem(24),
},
spacerMR2: {
  marginRight: theme.typography.pxToRem(8),
},
textCenter: {
  textAlign: 'center',
},

RACPOPMsg: {
  fontFamily: 'OpenSans-bold',
  fontSize: theme.typography.pxToRem(16),
  letterSpacing: 0,
},
marginTop: {
  marginTop: '-1rem',
},
justifyContentCenter: {
  justifyContent: 'center',
},

foc: {
  '&:focus': {
    borderColor: '#80bdff',
    boxShadow: '1px 1px 6px 3px #80bdff',
  },
},
collg12:{
  flex: '0 0 auto',
    width: '100%'
},
colxl8:{
  flex:' 0 0 auto',
  width: '66.6666666667%',
},
colrs7: {
  flex: '0 0 auto',
  width: '56.3333333333%',
},
colxl4 :{
  flex:' 0 0 auto',
  width: '35.3333333333%',
},
colrs5:{
  flex:' 0 0 auto',
},
uefloatend:
{
    float: 'right',
},
upcomingeventstatus: {
  width: '7px',
  height: '7px',
  display:'inline-block',
  borderRadius: '50px',
},
upcomingeventdelivery:{
  background: '#FFA61E',
},
fs12:
{
    fontSize: '12px',
},
scrollbar: {
  overflow: "auto",

  height: "250px",

  width: "100%",
},
mb1: {
  marginbottom:'0.25rem !important',
},
GridLoader: {
  textAlign: 'center',
  marginBottom: theme.typography.pxToRem(30),
  fontSize: theme.typography.pxToRem(30),
  color: `${RACCOLOR.RICH_BLACK}`,
  margin: '30px 0px',
},
loaderStyle: {
  border: 'none',
  backgroundColor: 'transparent',
  // userFocus: 'none',
  // MozUserFocus: 'none',
  outline: 'none',
},
RACLoaderPage: {
  textAlign: 'center',
  marginTop: '250px',
  fontSize: '16px',
  marginLeft: '30px',
  marginRight: '30px',
  outline: 'none',
},
}));

return UseClasses();
};  
