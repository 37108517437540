/* eslint-disable prettier/prettier */
export enum AppRoute {
  Index = '/',
  Placeholder1 = '/placeholder1',
  Placeholder2 = '/placeholder2',
  DigitalOrder = '/storemgmt/digitalorderform',
  closeEOD = '/storemgmt/closeeod',
  pinManagement = '/storemgmt/pinmanagement',
  lookUpStore = '/storemgmt/lookupstore',
  routeAssignment = "/storemgmt/routeassignment"
}
