/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-dupe-keys */
/* eslint-disable prettier/prettier */
import { makeStyles } from '@rentacenter/racstrap';

export const fieldDeviceStyles = () => {
  const useClasses = makeStyles((theme) => ({
    DAPcardwidgetmain: {
      alignItems: 'center!important',
      height: 'auto!important'
    },

    [theme.breakpoints.between(800, 1281)]: {
      positionRelative: {
        position: 'relative'
      },
      spacingDAC: {
        paddingRight: '0px'
      },
      leftContainer: {
        width: '60%'
      },
      rightContainer: {
        width: '40%'
      },
      legendstyles: {
        width: '100% !important'
      },
      DAPresponsivefilter: {
        height: '100%'
      },
      legendstyles: {
        width: '100% !important',
        marginTop: '10px'
      },
      eventtimecontainer: {
        width: '20% !important'
      },
      eventitemcontainer: {
        width: '76% !important'
      },
      responsiveeventcontainer: {
        display: 'inline-block !important',
        width: '100%'
      },
      DAPcardwidget: {
        width: '49% !important',
        fontSize: '13px !important'
      },
      w100Px: {
        width: '95px !important',
        fontSize: '11px !important'
      },
      DAPeventcardfont: {
        fontSize: '13px !important'
      },
      DAPcardwidgetmain: {
        marginTop: '20px',
        height: 'auto !important',
        alignItems: 'center!important'
      },
      DAPlunchbreak: {
        width: '80%!important'
      },
      breaktext: {
        width: '15%!important'
      },
      DAPextraevents: {
        paddingLeft: '5px'
      },
      popupcard: {
        width: '50%!important'
      }
      // table styles ends599 801
    },

    [theme.breakpoints.between(599, 799)]: {
      positionRelative: {
        position: 'relative'
      },
      leftContainer: {
        width: '100%'
      },
      rightContainer: {
        width: '100%'
      },
      DAPresponsivefilter: {
        display: 'inline-block',
        height: '100%'
      },
      legendstyles: {
        width: '100% !important',
        marginTop: '10px'
      },
      responsiveeventcontainer: {
        display: 'inline-block !important',
        width: '100%'
      },
      eventtimecontainer: {
        width: '100% !important',
        height: 'auto !important'
      },
      eventitemcontainer: {
        width: '96% !important'
      },
      DAPcardwidget: {
        width: '45% !important',
        fontSize: '12px !important'
      },
      w100Px: {
        width: '90px !important',
        fontSize: '11px !important',
      },
      DAPcardwidget: {
        display: 'inline-block !important',
        height: 'auto !important'
      },
      DAPmargintopcustom: {
        marginTop: '20px !important',
        marginBottom: '10px',
        whiteSpace: 'nowrap'
      },
      DAPsmallcardwidget: {
        width: '48% !important'
      },
      DAPeventcardparent: {
        height: '100% !important'
      },
      DAPcardwidgetmain: {
        height: 'auto !important',
        alignItems: 'center!important'
      },
      breaktext: {
        width: '20%!important'
      },
      DAPeventcardfont: {
        fontSize: '12px !important'
      },
      DAPextraevents: {
        paddingLeft: '5px!important'
      },
      popupcard: {
        width: '50%!important'
      },
      badgefontsize: {
        fontSize: '11px',
        width: '96px'
      },
      fontsize13: {
        fontSize: '13px!important'
      },
      eventnamewidth: {
        width: '55%!important'
      },
      eventbadgewidth: {
        width: '45%!important'
      }
    }
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useClasses();
};
