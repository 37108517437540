import { Skeleton } from '@material-ui/lab';
import { Card } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { StoreContext } from '../../../../../context/Storeprovider';

import styles from './SkeletonLoader.module.scss';

export const skeletonLoaderTestId = 'skeletonLoaderTestId';

export const SkeletonLoader = () => {
  const { isFranchisee } = useContext(StoreContext);

  return (
    <>
      {!isFranchisee && (
        <>
          <Card
            className={styles.loadingCard}
            data-testid={skeletonLoaderTestId}
          >
            <Skeleton variant="text" height={21} width={118} animation="wave" />
            <Skeleton variant="text" height={21} animation="wave" />
          </Card>
          <Card
            className={styles.loadingCard}
            data-testid={skeletonLoaderTestId}
          >
            <Skeleton variant="text" height={21} width={118} animation="wave" />
            <Skeleton variant="text" height={21} animation="wave" />
          </Card>
        </>
      )}
      {isFranchisee && (
        <>
          <Card
            className={styles.loadingCard}
            data-testid={skeletonLoaderTestId}
          >
            <Skeleton variant="text" height={21} width={118} animation="wave" />
            <Skeleton variant="text" height={21} animation="wave" />
          </Card>
          <Card
            className={styles.loadingCard}
            data-testid={skeletonLoaderTestId}
          >
            <Skeleton variant="text" height={21} width={118} animation="wave" />
            <Skeleton variant="text" height={21} animation="wave" />
          </Card>

          <Card
            className={styles.loadingCard}
            data-testid={skeletonLoaderTestId}
          >
            <Skeleton variant="text" height={21} width={118} animation="wave" />
            <Skeleton variant="text" height={21} animation="wave" />
          </Card>
        </>
      )}
    </>
  );
};
