1/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/no-children-prop */
/* eslint-disable sonarjs/no-duplicate-string */
/* esint-disable */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACDatePicker,
  RACButton,
  RACSelect,
  RACTextbox,
  RACBadge,
  RACTableCell,
  List,
  ListItem,
  RACModalCard,
  RACTable,
  RACTableRow,
  Box,
  CircularProgress,
  RACToggle,
  RACRadio,
  makeStyles,
  RACCheckBox,
} from "@rentacenter/racstrap";
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import moment from "moment";
import { DapCalendarStyles } from "./DapCalendarStyles";
import { UserStateContext } from "../../context/user/user-contexts";
import { ReactComponent as Alerticon } from '../../assets/images/No-records.svg';
import AcimaDeliveryDigitalSign from "../../components/Shared/AcimaDeliveryDigitalSign";
import { ReactComponent as SearchbarIcon } from '../../assets/images/searchbarIcon.svg';
import { ReactComponent as CompletedIcon } from '../../assets/images/wizard-complete.svg';
import { ReactComponent as Racfont } from '../../assets/images/RAC-Banner.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as Images } from '../../assets/images/pdf-icon.svg';
import { ReactComponent as PDFImage } from '../../assets/images/pdf-icon.svg';
import { ReactComponent as Alert } from "../../assets/images/no-records-found.svg";
import { ReactComponent as Successimage } from "../../assets/images/success-icon.svg";
// PS_02
import { ReactComponent as WarningIcon } from "../../assets/images/Warning-icon.svg";
import CustomerSignature from "../../components/Shared/CustomerSignature";
import DeliveryDigitalSign from "../../components/Shared/DeliveryDigitalSign";
import { GetReferenceValues, getDapEvent, updateEventStatus, getTimeSlot, EventHistory, getAgreementDelivery, getAgreementInfo, getnextpaymentamount, LoadDeliveryreceipt, GenerateReceipt, Confirmdelivery, LoadswitchoutDeliveryreceipt, manageAppointment, getmenuandstore, Recieptsign, GetCustomerinfo, CheckSign, GetStatsStatus, managePoAcimaFn, getPrintDocument, getCurrentUser, storeException, saveSurveyForm, getSavedSurvey, logWorkedHistory } from '../../api/user'
import { customerStyles } from "./customerStyles";
import { ContainerContext } from '../../app/App';
import { CustomPropInterface } from '../../index';
import { StoreMgmtContext } from "../../context/storeMgmtContext";
import { fieldDeviceStyles } from "./FieldDeviceStyles";
import html2pdf from 'html2pdf.js';

const useClasses = makeStyles((theme: any) => ({
  w100: {
    width: '100%',
  },
  card: {
    borderRadius: '16px!important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
    width: '100%',
  },
  colMD12: {
    flex: '0 0 auto',
    width: '100%',
    paddingRight: '0.75rem',
    paddingLeft: '0.75rem',
  },
  reinforcementGrid: {
    marginLeft: '1.5rem'
  },
  floatStart: {
    float: 'left'
  },
  mb3: {
    marginBottom: '1rem'
  },
  formLabel: {
    marginBottom: '0.8rem !important',
    marginLeft: '0.4rem !important',
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: '14px',
  },
  accordionHeader: {
    color: '#2179FE',
    fontFamily: 'OpenSans-bold',
    fontSize: '20px',
    marginBottom: '1.0rem !important',
  },
  headerLabel: {
    marginBottom: '1.0rem !important',
    color: '#111111',
    fontFamily: 'OpenSans-bold',
    fontSize: '16px',
  },
  formCheckInline: {
    display: 'inline-block',
    marginRight: '1rem',
  },
  formCheck: {
    minHeight: '1.5rem',
    marginBottom: '1.0rem',
  },
  checkboxLabel: {
    fontSize: '14px',
    display: 'inline-block',
    fontFamily: 'OpenSans-regular',
  },
  buttonsContent: {
    float: 'right',
    marginTop: '1rem'
  },
  popUpContent: {
    width: '25%',
    fontFamily: 'OpenSans-semibold',
    color: '#2179FE'
  },
  tabSpace: {
    display: 'inline-block',
    marginLeft: '40px',
  },
}));
const PaddingConst = '0px 8px 0px 8px';
const Constinter = '1px solid  #545454';
const borderRadiuss = '3px';
const borderbotttom = '1px solid #545454';
const borderrr = '1px solid #DFDFDF';
const borderrbottommm = '1px dashed #545454';
const borderalone = '1px solid #545454';
enum tabList {
  HISTORYLOG,
  NOTES,
}
function Calender() {
  const { updateRecieptKey, setUpdateRecieptKey } = useContext(StoreMgmtContext)
  const customerSurveyClasses = useClasses();
  let UserStateContextRes: any;
  UserStateContextRes = useContext(UserStateContext)
  UserStateContextRes = UserStateContextRes?.user?.permissions?.NAV_MENU
  const storeDetails = sessionStorage.getItem('storeDetails') || '';
  const encodeStoreDetails = Buffer.from(storeDetails, 'base64');
  const decodestoreDetails: any = JSON.parse(
    encodeStoreDetails.toString('ascii')
  );
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const history = useHistory()

  const customerStyleClasses = customerStyles();
  // const [activetab, setactivetab] = useState<tabList | undefined>(
  //     tabList.HISTORYLOG
  // );
  const [activetab, setactivetab] = useState(
    {
      notes: true,
      history: false
    }

  );
  const classes = DapCalendarStyles();
  const options = {
    advancedFilter: [
      { label: 'All', value: '0' },
      { label: 'Events', value: '1' },
      //  { label: 'Tasks', value: "2" }
    ],
  };
  const [cancelEventPopup, setcancelEventPopup] = useState(false);
  const [cancelPOReason, setcancelPOReason] = useState('')
  const [acimaEventDetails, setacimaEventDetails] = useState(false)
  const [acimaManulaSign, setacimaManulaSign] = useState(false)
  const [manualSignature, setmanualSignature] = useState(false)
  const [isManual, setisManaul] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState<any>('')
  const [isSignedByCustomer, setisSignedByCustomer] = useState(false)
  const [acimaEventValues, setacimaEventValues] = useState({
    poNumber: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    zip: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    appointmentStatus: '',
    itemInformation: [],
    appointmentId: '',
    appiontmnetDate: '',
    appiontmnetTime: '',
    emailId: ''
  })
  const [acimaDeliveryDigitalsignEnbale, setacimaDeliveryDigitalsignEnbale] = useState(false)
  const [category, setCategory] = useState<any>();
  const [dateDap, setDateDap] = useState<any>({
    Date: moment().format('YYYY-MM-DD'),
  });
  const [eventType, setEventType] = useState<any>('ALL');
  const [documentName, setDocumentName] = useState('')
  const [storeEventNotes, setStoreEventNotes] = useState<any>('')
  const [customersArray, setcustomersArray]: any = useState([]);
  const [fieldDeviceParamKey, setfieldDeviceParamKey] = useState<any>('')
  const [pilotStoreParamKey, setPilotStoreParamKey] = useState('')
  const [GetCurrentUserRes, setgetCurrentUserRes] = useState('')

  const dynamicClasses: any = {
    complete: {
      anchor: classes.smartWizardListLinkActive,
      spanNum: `${classes.borderDefault} ${classes.done}`,
      spanDetail: classes.stepDone,
    },
    inprogress: {
      anchor: classes.smartWizardListLinkInActive,
      spanNum: classes.borderActive,
      spanDetail: classes.stepActive,
    },
    pending: {
      anchor: classes.smartWizardListLinkInActive,
      spanNum: classes.borderDefault,
      spanDetail: classes.stepNotStarted,
    },
  };

  //state variables

  const [reprintPopUp, setReprintPopUp] = useState<any>()

  const [status, setStatus] = useState<any>();
  const [createEvent, setCreateEvent] = useState(true);
  const [enableDeliveryPopup, setEnableDeliveryPopup] = useState<any>();
  const [enableStorePickupPopup, setEnableStorePickupPopup] = useState<any>();
  const [enableFieldVisitPopup, setEnableFieldVisitPopup] = useState<any>();
  const [popUpValues, setPopUpValues] = useState<any>()
  const [appointmentDetails, setAppointmentDetails] = useState<any>();
  const [modals, setModals] = useState<any>({ storeEventDetailModal: false, cancelEventReaspnModal: false, timeSlotExceedModal: false, apiErrorInfoMsg: false, reopenConfirmModal: false, completeConfirmModal: false });
  const [currentappointmentDetails, setCurrentappointmentDetails] = useState<any>();
  const [filterStateData, setFilterStateData] = useState<any>({ date: moment().format('YYYY-MM-DD'), category: '', typesOfEvent: '', searchBarData: '', filterRecords: [], timeSlotDate: '' });
  const [eventDD, setEventDD] = useState<any>([{ label: 'ALL', value: 'ALL' }]);
  const [loader, setloader] = useState(false);
  const [isStoreClosed, setIsStoreClosed] = useState(false);
  const [appointmentHistoryRecord, setAppointmentHistoryRecord] = useState<any>([]);
  const [storename, Setstorename] = useState('');
  const [storecity, Setstorecity] = useState('');
  const [StoreAddressline1, SetStoreAddressline1] = useState('');
  const [StoreAddressline2, SetStoreAdressline2] = useState('');
  const [Storephonenum, SetStorephonenum] = useState('');
  const [CustomerName, SetCustomerName] = useState('');
  const [CustomerAddressline1, SetCustomerAddressline1] = useState('');
  const [CustomerAddressline2, SetCustomerAddressline2] = useState('');
  const [CustomerPhoneNumber, SetCustomerPhoneNumber] = useState('');
  const [customercity, Setcustomercity] = useState('');
  const [disableSubmitButton, setdisableSubmitButton] = useState(false);
  const [customerstate, SetCustomerstate] = useState('');
  const [customerzip, Setcustomerzip] = useState('');
  const [customerplus4, Setcustomerplus4] = useState('');
  const [storezip, Setstorezip] = useState('');
  const nextpaymentobj = {
    nextamountdue: '',
    nextduedate: '',
  };
  const [nextpaymentinfo, setnextpaymentinfo] = useState(nextpaymentobj);
  const [inventorydescswitchin, setinventorydescswitchin] = useState('');
  const [inventoryserialnumswitchin, setinventoryserialnumswithchin] =
    useState('');
  const [inventorynumberswitchinitem, setinventorynumberswitchinitem] =
    useState('');
  const [itemdata, setitemdata] = useState([]);
  const [DeliveryDate, setDeliveryDate] = useState<any>('');
  const [DeliveryTime, setDeliveryTime] = useState<any>('');
  const [isCustomerorderenabled, setisCustomerorderenabled] = useState(false);
  const [openCOprintpopup, setopenCOprintpopup] = useState(false);
  const [Openprintpopup, SetOpenprintpopup] = useState(false);
  const [disableconfirmdelivery, Setdisableconfirmdelivery] = useState(true);
  const [isReprintCompleted, setIsReprintCompleted] = useState(false);
  const [printswitchoutpopup, setprintswitchoutpopup] = useState(false);
  const [cancelEventReason, setCancelEventReason] = useState<any>('');
  const [updatingNotesLoader, setUpdatingNotesLoader] = useState<any>(false);
  const [EmpolyeeId, setEmpolyeeId] = useState('');
  const [eventLenght, seteventLenght] = useState<any>();
  const [serviceErrorMsg, setServiceErrorMsg] = useState<string>('');
  const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState<boolean>(false);

  const [customerSignEnabled, setcustomerSignEnabled] = useState(false);
  const [timeSlotExceedRecordsDetails, setTimeSlotExceedRecordsDetails] = useState<any>({ timeSlot: '', appointmentDetails: [] });
  const [digitalSignEnable, setdigitalSignEnable] = useState(false);
  const [manualSignatureEnabled, setmanualSignatureEnabled] = useState(false);
  const [agreementValues, setagreementValues] = useState({
    agreementSign: '',
    agreementNumber: '',
    agreementId: '',
  })
  const [Opendeliveryconfirmpopup, SetOpendeliveryconfirmpopup] =
    useState(false);
  const [appiontmentId, setappiontmentId] = useState('');
  const [agrDelStatus, setagrDelStatus] = useState('')
  const [deliveryerrorpopup, setdeliveryerrorpopup] = useState(false);
  const [CheckforSign, SetCheckforSign] = useState(false);
  const [maualSignReasonDrpdwnVal, setmaualSignReasonDrpdwnVal] = useState([
    {
      value: "",
      label: "Select",
    },
    {
      value: "3",
      label: "Technology issue - Digital Signing",
    },
    {
      value: "2",
      label: "Technology issue - Customer",
    },
    {
      value: "1",
      label: "Customer requires Paper Signature",
    },
  ]);
  const [toShowCustomerSurveyForm, setToShowCustomerSurveyForm] = useState(false);

  const [surveyDetails, setSurveyDetails] = useState({
    readOnlyMode: false,
    productDeliveryQn: '',
    rentalAgrQn: '',
    renewalDateAndAmtQn: false,
    autoEnrollQn: false,
    timelyPayments: false,
    anyOtherQns: '',
    additionalNotes: ''
  });

  const [SurveySave, setSurveySave] = React.useState(true);
  useEffect(() => {
    if (surveyDetails.productDeliveryQn.length == 0 || surveyDetails.rentalAgrQn.length == 0) {
      setSurveySave(true);
    }
    else {
      setSurveySave(false);
    }
  }, [surveyDetails]);

  const [OpenSurveySaveSuccessPopup, setOpenSurveySaveSuccessPopup] =
    useState(false);
  const [surveyError, setSurveyError] = useState(false);

  const [customerSurveyParamKey, setCustomerSurveyParamKey] = useState('')
  const [customerVerification, setCustomerVerification] = useState<any>('')

  const [toShowSurvey, setToShowSurvey] = React.useState(false);

  const [readOnlySurvey, setReadOnlySurvey] = React.useState(false);

  const resetSurvey = () => {
    setSurveyDetails({
      ...surveyDetails,
      readOnlyMode: false,
      productDeliveryQn: '',
      rentalAgrQn: '',
      renewalDateAndAmtQn: false,
      autoEnrollQn: false,
      timelyPayments: false,
      anyOtherQns: '',
      additionalNotes: ''
    });
  }

  const initializeSurvey = (response: any) => {
    setReadOnlySurvey(true);

    setSurveyDetails({
      ...surveyDetails,
      readOnlyMode: true,
      productDeliveryQn: response.deliveryOnTime && response.deliveryOnTime == '1' ? 'yes' : 'no',
      rentalAgrQn: response.questionsAnswered && response.questionsAnswered == '1' ? 'yes' : 'no',
      renewalDateAndAmtQn: response.renewalDiscussed && response.renewalDiscussed == '1' ? true : false,
      autoEnrollQn: response.autopayDiscussed && response.autopayDiscussed == '1' ? true : false,
      timelyPayments: response.paymentDiscussed && response.paymentDiscussed == '1' ? true : false,
      anyOtherQns: response.otherQuestions && response.otherQuestions == '1' ? 'yes' : 'no',
      additionalNotes: response.notes ? response.notes : ''
    });

  };

  const [eligibleRole, setEligibleRole] = useState(false);

  const propData: any = {
    DeliveryDate, DeliveryTime,
    itemdata,
    storename,
    StoreAddressline2,
    StoreAddressline1,
    storecity,
    storezip,
    Storephonenum,
    CustomerName,
    CustomerAddressline2,
    CustomerAddressline1,
    customerplus4,
    customercity,
    customerzip,
    CustomerPhoneNumber,
    currentappointmentDetails,
    nextpaymentinfo,
    inventorydescswitchin,
    inventoryserialnumswitchin,
    inventorynumberswitchinitem
  }
  const classes1 = fieldDeviceStyles()

  const memoizedPropData = useMemo(() => propData, [propData.DeliveryDate, propData.DeliveryTime,
  propData.itemdata,
  propData.storename,
  propData.StoreAddressline2,
  propData.StoreAddressline1,
  propData.storecity,
  propData.storezip,
  propData.Storephonenum,
  propData.CustomerName,
  propData.CustomerAddressline2,
  propData.CustomerAddressline1,
  propData.customerplus4,
  propData.customercity,
  propData.customerzip,
  propData.CustomerPhoneNumber,
  propData.currentappointmentDetails,
  propData.nextpaymentinfo,
  propData.inventorydescswitchin,
  propData.inventoryserialnumswitchin,
  propData.inventorynumberswitchinitem]);
  //functions 
  useEffect(() => {
    pageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getWorkerRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    console.log("UserStateContextResStoreMgmt", UserStateContextRes);

  }, [UserStateContextRes])
  useEffect(() => {
    console.log('WindowSize', window.innerWidth)
    windowSizeFn()
  }, [])
  useEffect(() => {
    getConfigDetailsFn()
  }, [])
  useEffect(() => {

    getQrcodeFn()
  }, [agreementValues.agreementId])

  const getQrcodeFn = async () => {
    const payLoad: any = {
      identifier: agreementValues.agreementId,
      documentStatus: 'signed',
      actionType: 'qrcode',
      identifierType: 'AGR',
    };
    const getPrintDocumentRes = await getPrintDocument(payLoad)
    setQrCodeUrl(getPrintDocumentRes?.data[0]?.documentUrl)
  }
  const getConfigDetailsFn = async () => {
    const getCurrentUserRes: any = await getCurrentUser()
    setgetCurrentUserRes(getCurrentUserRes?.permissions?.NAV_MENU)
    const storeNumber = window.sessionStorage.getItem('storeNumber')
    const payload: any = {
      storeNumbers: [window.sessionStorage.getItem('storeNumber')],
      paramKeyNames: ['FieldDevice', "PilotStore", 'CustomerSurvey', 'CustomerVerificationOptimization'],
    };

    let getConfigDetailsRes

    if (storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != 'null' && storeNumber != 'undefined') {
      getConfigDetailsRes = await GetStatsStatus(payload);
    }
    setfieldDeviceParamKey(getConfigDetailsRes?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'FieldDevice')[0].paramValue)
    setPilotStoreParamKey(getConfigDetailsRes?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'PilotStore')[0].paramValue)
    setCustomerSurveyParamKey(getConfigDetailsRes?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'CustomerSurvey')[0].paramValue)
    setCustomerVerification(getConfigDetailsRes?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'CustomerVerificationOptimization')[0].paramValue)
  }

  const windowSizeFn = async () => {
    if (window.innerWidth <= 1280) {
      seteventLenght(2)
    }
    else {
      seteventLenght(4)
    }
  }

  const filteroutEvent = (item:any) =>{
    if(!item || !item.appointmentInfo) return false;
    if(item.appointmentInfo.appointmentStatus == 'DEL') return false;
    if(item.appointmentInfo.bestFit && item.appointmentInfo.bestFit == '1') return false;
    return true;
  }
  const filterBestFit= (item:any) =>{
    if(!item || !item.appointmentInfo) return false;
    if(item.appointmentInfo.appointmentStatus == 'DEL') return false;
    if(item.appointmentInfo.bestFit && item.appointmentInfo.bestFit == '1') return true;
    return false;
  }
  const pageLoad = async (date?: any, status?: any) => {
    try {
      const payload: any = { storeNumber: sessionStorage.getItem('storeNumber'), appointmentTypes: ['CI', 'CO', 'D', 'P', 'S', 'SCI', 'SCO', 'SD', 'SO', 'SP', 'BE', 'SE', 'AD', 'STOPIC', 'FIELD'], startDate: date || filterStateData.date, endDate: date || filterStateData.date }
      const payloadForGetTimeSlot = {
        isLoaner: 'Y',
        customerId: '108348922',
        endDate: date || filterStateData.date,
        eventSource: 'STO',
        startDate: date || filterStateData.date,
        storeId: sessionStorage.getItem('storeNumber'),
      };
      setloader(true)
      const apiCallStack: any = [getDapEvent(payload)]
      if (!date && !status) {
        apiCallStack.push(GetReferenceValues('appointment_type'))
        apiCallStack.push(getTimeSlot(payloadForGetTimeSlot))
      }
      if (date) {
        apiCallStack.push(getTimeSlot(payloadForGetTimeSlot))
      }
      const response: any = await Promise.all(apiCallStack);
      let bestFitEvents : any = [];
      setloader(false)
      const buildData = new Map()
      const curDate = date || filterStateData.date
      let timeSlotResponse: any = []
      if (!date && !status) {
        bindDDHandler(response[1])
        timeSlotResponse = response[2].data.timeSlots[curDate]
        setFilterStateData({ ...filterStateData, timeSlotDate: response[2].data.timeSlots[curDate] })
      }
      if (date) {
        timeSlotResponse = response[1].data.timeSlots[curDate]
      }
      if (timeSlotResponse && timeSlotResponse.length == 0) {
        setIsStoreClosed(true)
        return
      }
      if (response[0].data && response[0].data.appointments && response[0].data.appointments.length > 0 || timeSlotResponse.length > 0) {
        let appointmentRespone: any = []
        if (response[0].data && response[0].data?.appointments) {
          appointmentRespone = response[0].data?.appointments
        }
       
        bestFitEvents =  appointmentRespone && appointmentRespone?.length > 0 ? appointmentRespone?.filter(filterBestFit) : [];
        appointmentRespone = appointmentRespone && appointmentRespone?.length > 0 ? appointmentRespone?.filter(filteroutEvent) : [];
       

        timeSlotResponse?.forEach((el: any) => {
          appointmentRespone?.push({ timeSlotInfo: el })
        })
        appointmentRespone.push({
          timeSlotInfo: {
            "timeSlot": "3P",
            "startTime": "12:00:00",
            "endTime": "13:00:00",
            "status": "BREAK"
          }
        })
        const sortedRecords = appointmentRespone.sort(compareStartTime);
        sortedRecords.forEach((el: any) => {
          const startTime: any = moment(el.timeSlotInfo.startTime, 'HH:mm:ss').format(
            'hh:mm A'
          );
          const endTime: any = moment(el.timeSlotInfo.endTime, 'HH:mm:ss').format(
            'hh:mm A'
          );
          const keyName = startTime + '-' + endTime
          if (buildData.has(keyName)) {
            if (el.appointmentInfo) {
              const data = buildData.get(keyName)
              data.push(el)
              buildData.set(keyName, data)
            }
          } else {
            buildData.set(keyName, [el])
          }
        })


      }
      
      if(bestFitEvents.length == 0){
        bestFitEvents.push({
          timeSlotInfo: {
            "timeSlot": "BestFit",
            "startTime": "08:00:00",
            "endTime": "17:00:00",
          }
        })
      };
      buildData.set('bestFit', bestFitEvents);
      const res = [...buildData.values()]
     
      setAppointmentDetails(res)
      setCancelEventReason('')

      // AppointmentResponse.appointments.map((el:any) => {
      //     const storeInd = initialArray.findIndex((a) => a.timeSlot.startTime == el.timeSlotInfo.startTime)
      //     console.log(" a.timeSsdlotInfo.startTime ", el.timeSlotInfo.startTime)
      //     console.log("storeInd", storeInd)
      //     if (storeInd >= 0) {
      //         const type = el.appointmentInfo.appointmentTypeDesc;
      //         const custumInfo = el.customerInfo;
      //         const agreementInfo = el.agreement;
      //         // const timeSlotInfo = el.timeSlotInfo;
      //         const inventories = el.invntories;
      //         //type
      //         const types = [...initialArray[storeInd].type, type];
      //         initialArray[storeInd].type = types;
      //         // custumInfo
      //         const custumInfoDetails = [...initialArray[storeInd].custumInfo, custumInfo];
      //         initialArray[storeInd].custumInfo = custumInfoDetails;
      //         // agreementInfo
      //         const agreementInfoDetails = [...initialArray[storeInd].agreementInfo, agreementInfo];
      //         initialArray[storeInd].agreementInfo = agreementInfoDetails;
      //         // //timeSlotInfo 
      //         // const timeSlotInfoDetails = [...initialArray[storeInd].timeSlotInfo, timeSlotInfo];
      //         // initialArray[storeInd].timeSlotInfo = timeSlotInfoDetails;
      //         //inventories
      //         const inventoryDetails = [...initialArray[storeInd].inventories, inventories];
      //         initialArray[storeInd].inventories = inventoryDetails;
      //     }
      //     else {
      //         const formedOBJ = {
      //             timeSlot: el.timeSlotInfo,
      //             custumInfo: [el.customerInfo],
      //             agreementInfo: [el.agreement],
      //             inventories: [el.invntories],
      //             type: [el.appointmentInfo.appointmentTypeDesc]
      //         }
      //         initialArray.push(formedOBJ)
      //     }
      //     console.log("initialArray", initialArray)
      //     setAppointmentDetails({
      //         ...appointmentDetails,
      //         OriginalAppointmentDetails: initialArray,
      //         AlteredAppointmentDetails: initialArray
      //     })
      // });
    }
    catch (e) {
      setloader(false)
      setModals({ ...modals, apiErrorInfoMsg: true })
      console.log(e)
    }

  }
  const getWorkerRole = async () => {
    let role = '';
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      role = containerData.GetRole();
    }
    else {
      const roleDetailsObj: any = await getmenuandstore();
      role = roleDetailsObj?.coworkerProfile?.role;
    }
    if (role !== 'CAR') {
      setEligibleRole(true);
    }
  };

  const bindDDHandler = (response: any) => {
    const buildStateDropDown: any = [{ label: 'ALL', value: 'ALL' }]
    response.referenceDetails.forEach((value: any, index: any) => {
      // if (value.referenceCode !== 'SE') {
      buildStateDropDown.push({
        label: value.description,
        value: value.description,
      });
      // }
    });
    setEventDD(buildStateDropDown)
  }
  const phoneNumberFormat = (phonenum: any) => {
    if (phonenum == '') return phonenum;
    if (!phonenum) return phonenum;
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const phoneNumberFormat9prefix = (phonenum: any) => {
    if (phonenum == '') return phonenum;
    if (!phonenum) return phonenum;
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `9 (${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  function compareStartTime(a: any, b: any) {
    const startTimeA = a.timeSlotInfo.startTime;
    const startTimeB = b.timeSlotInfo.startTime;

    if (startTimeA < startTimeB) {
      return -1;
    } else if (startTimeA > startTimeB) {
      return 1;
    }
    return 0;
  }
  const editStoreEventHandler = () => {
    history.push({ pathname: `/storemgmt/createevent`, search: '?editevent=true', state: currentappointmentDetails })
  }

  const editPickupEventHandler = () => {
    history.push({ pathname: `/storemgmt/storepickupevent`, search: '?editevent=true', state: currentappointmentDetails })
  }

  const editFieldVisitEventHandler = () => {
    history.push({ pathname: `/storemgmt/fieldvisitevent`, search: '?editevent=true', state: currentappointmentDetails })
  }

  const cancelOrCompleteStoreEvent = async (eventType: any) => {
    try {
      setEnableDeliveryPopup(false)

      setEnableStorePickupPopup(false);
      setEnableFieldVisitPopup(false);
      // setModals({ ...modals, storeEventDetailModal: false })
      // if (cancelEventReason.length == 0 && eventType == 'Cancel') {
      //     setModals({ ...modals, cancelEventReaspnModal: true, storeEventDetailModal: false })
      //     return
      // }
      setModals({ ...modals, storeEventDetailModal: false, reopenConfirmModal: false, cancelEventReaspnModal: false, completeConfirmModal: false })
      setEnableDeliveryPopup(false)
      const payloadForAppointmentAction: any = {
        storeNumber: currentappointmentDetails.storeNumber,
        appointmentId: currentappointmentDetails.appointmentInfo.appointmentId,
        actionName: eventType
      }
      // const payloadForManageEvent: any = {
      //   storeNumber: sessionStorage.getItem('storeNumber'),
      //   appointmentId: currentappointmentDetails.appointmentInfo.appointmentId,
      //   coworkerId: EmpolyeeId,
      //   note: cancelEventReason,
      // }
      setloader(true)
      // if (eventType == 'Cancel') {
      //     await manageAppointment(payloadForManageEvent)
      // }
      await updateEventStatus(payloadForAppointmentAction)
      await pageLoad(filterStateData?.date)
      setloader(false)
    } catch (e: any) {
      setloader(false)
      setModals({ ...modals, apiErrorInfoMsg: true })
    }
  }
  const acimaPocancelEvent = async (eventType: any) => {
    try {
      setacimaEventDetails(false)
      const payloadForAppointmentAction: any = {
        storeNumber: window.sessionStorage.getItem("storeNumber"),
        appointmentId: acimaEventValues?.appointmentId,
        actionName: eventType
      }
      setloader(true)
      setModals({ ...modals, reopenConfirmModal: false })
      await updateEventStatus(payloadForAppointmentAction)
      await pageLoad(filterStateData?.date)
      setloader(false)
    } catch (e: any) {
      setloader(false)
      setModals({ ...modals, apiErrorInfoMsg: true })
    }
  }
  const normalizeinput = (phonenum: any) => {
    if (phonenum == '') return phonenum;
    if (!phonenum) return phonenum;
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const bindChecklist = (source?: any) => {
    if (itemdata !== undefined && itemdata !== null && itemdata.length > 0) {
      let data: any = itemdata;
      if (
        source == "ResendVideo"
      ) {

        const lineItem = {
          inventoryId: "",
          inventoryNumber: "",
          inventoryDesc: "",
          serialNumber: "",
          modelNumber: "",
        };
        // if (agreementInfoData?.agreementWorkflow?.isWelcomeVideoSent == "1") {
        //   const activityDesc =
        //     agreementInfoData?.welcomeVideoInfo?.activityDesc?.split(" ");
        //   if (
        //     agreementInfoData?.welcomeVideoInfo?.activityRefCode == "SSKYES"
        //   ) {
        //     lineItem.inventoryDesc = `Introduction video sent ${agreementInfoData.welcomeVideoInfo.activityDate} to email ${activityDesc[4]}.`;
        //     //setrepeatContent(true);
        //   } else {
        //     lineItem.inventoryDesc = `Introduction video texted ${agreementInfoData.welcomeVideoInfo.activityDate
        //       } to phone number ${agreementInfoData?.welcomeVideoInfo?.activityDesc?.replace(
        //         /\D/g,
        //         ""
        //       )}.`;
        //     //setrepeatContent(true);
        //   }
        // } else {
        //   lineItem.inventoryDesc = "The Introduction video has not been sent.";
        //   //setrepeatContent(true);
        // }

        lineItem.inventoryDesc = ""
        data.push(lineItem);
        console.log("agreementInfoData if data", data);
      } else {
        data = itemdata;
        console.log("agreementInfoData else data", data);
      }
      const uniquedesc = [
        ...new Map(
          data.map((item: any) => [item.inventoryDesc, item])
        ).values(),
      ];
      return uniquedesc.map((value: any, index: any) => {
        return (
          <tr key={index} style={{ width: "100%" }}>
            <td
              style={{
                width: "15px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryDesc
                ? <span
                  style={{
                    padding: PaddingConst,
                    borderRadius: borderRadiuss,
                    border: Constinter,
                    marginRight: "10px",
                  }}
                />
                : qrCodeUrl && source ? <span
                  style={{
                    padding: PaddingConst,
                    borderRadius: borderRadiuss,
                    border: Constinter,
                    marginRight: "10px",
                  }}
                /> : ''}

            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryDesc
                ? value.inventoryDesc
                : qrCodeUrl && source ? "Did you have a chance to watch the customized video that we sent you about how your new agreement works?" : ''}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value !== undefined &&
                value.serialNumber !== undefined &&
                value.serialNumber !== null &&
                value.serialNumber != ""
                ? value.serialNumber
                : qrCodeUrl && source ? <a onClick={() => { window.open(qrCodeUrl, '_blank'); }} style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }}>RACPAD Welcome Video</a> : ''}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryNumber ?
                value.inventoryNumber
                :
                qrCodeUrl !== undefined && source ?
                  <img style={{ width: "26%" }} src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeUrl}`} alt="QR Code" />
                  : ''
              }

            </td>
          </tr>
        );
      });
    }
  };
  const bindSwitchOutdata = (source?: any) => {
    let data: any = [];
    let itemdata1: any = {};
    if (inventorydescswitchin) {
      itemdata1.inventoryDesc = inventorydescswitchin;
      itemdata1.serialNumber = inventoryserialnumswitchin;
      itemdata1.inventoryNumber = inventorynumberswitchinitem;
      data.push(itemdata1)
      const lineItem = {
        inventoryNumber: "",
        inventoryDesc: "",
        serialNumber: "",
      };
      lineItem.inventoryDesc = ""
      data.push(lineItem);
      const uniquedesc = [
        ...new Map(
          data.map((item: any) => [item.inventoryDesc, item])
        ).values(),
      ];
      return uniquedesc.map((value: any, index: any) => {
        return (
          <tr key={index} style={{ width: "100%" }}>
            <td
              style={{
                width: "15px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryDesc
                ? <span
                  style={{
                    padding: PaddingConst,
                    borderRadius: borderRadiuss,
                    border: Constinter,
                    marginRight: "10px",
                  }}
                />
                : qrCodeUrl && source ? <span
                  style={{
                    padding: PaddingConst,
                    borderRadius: borderRadiuss,
                    border: Constinter,
                    marginRight: "10px",
                  }}
                /> : ''}

            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryDesc
                ? value.inventoryDesc
                : qrCodeUrl && source ? "Did you have a chance to watch the customized video that we sent you about how your new agreement works?" : ''}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value !== undefined &&
                value.serialNumber !== undefined &&
                value.serialNumber !== null &&
                value.serialNumber != ""
                ? value.serialNumber
                : qrCodeUrl && source ? <a onClick={() => { window.open(qrCodeUrl, '_blank'); }} style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }}>RACPAD Welcome Video</a> : ''}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryNumber ?
                value.inventoryNumber
                :
                qrCodeUrl !== undefined && source ?
                  <img style={{ width: "26%" }} src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeUrl}`} alt="QR Code" />
                  : ''
              }

            </td>
          </tr>
        );
      });
    }
  }
  // function HandleDeliveryModalCard() {
  //     return (
  //         <RACModalCard
  //             isOpen={enableDeliveryPopup}
  //             maxWidth="md"
  //             closeIcon={true}
  //             borderRadius="20px"
  //             onClose={() => setEnableDeliveryPopup(false)}
  //             // eslint-disable-next-line react/no-children-prop
  //             children={DeliveryPopup()}
  //             title="Event Details"
  //             TitleVariantClassName={classes.popUpFont}
  //         />
  //     )
  // }
  const renderAppointmentTableHead = () => {
    return (
      <>
        <React.Fragment>
          <RACTableCell>
            <Typography
              variant="h5"
            >
              Inventory #
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography
              variant="h5"
            >
              Description
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography
              variant="h5"
            >
              Condition
            </Typography>
          </RACTableCell>
        </React.Fragment>
      </>)
  }
  const renderAppointmentTableHistoryHeader = () => {
    return (
      <>
        <React.Fragment>
          <RACTableCell>
            <Typography
              variant="h5"
            >
              Activity Date & Time
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography
              variant="h5"
            >
              Coworker
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography
              variant="h5"
            >
              Action
            </Typography>
          </RACTableCell>
        </React.Fragment>
      </>)
  }
  const renderAppointmentTableContent = () => {
    return currentappointmentDetails?.inventories?.map((data: any) => {
      return (
        <>
          <React.Fragment>
            {/* <Grid className={classes.racLightBlue}> */}
            <RACTableRow style={{ backgroundColor: "white", padding: "4px 14px" }}>
              <RACTableCell
                className={`${classes.racLightBlue}`}
              >
                <Typography>
                  {data?.inventoryNumber}
                </Typography>
              </RACTableCell>
              <RACTableCell className={`${classes.racLightBlue}`}>
                <Typography>
                  {data?.description}
                </Typography>
              </RACTableCell>
              <RACTableCell className={`${classes.racLightBlue}`}>
                <Typography>
                  {data.itemCondition && data.itemCondition.toLowerCase() == 'new' ? 'New' : data.itemCondition.toLowerCase() == 'ato' ? 'Auto' : data.itemCondition.toLowerCase() == 'excp' ? 'Exception' : data.itemCondition}
                </Typography>
              </RACTableCell>
            </RACTableRow>
            {/* </Grid> */}
          </React.Fragment>
        </>
      )
    })
  }
  const renderAppointmentTableHistoryContent = () => {
    return appointmentHistoryRecord?.appointmentHistory?.map((data: any) => {
      return (
        <>
          <React.Fragment>
            {/* <Grid className={classes.racLightBlue}> */}
            <RACTableRow style={{ backgroundColor: "white", padding: "4px 14px" }}>
              <RACTableCell
                className={`${classes.racLightBlue}`}
              >
                <Typography>
                  {moment(data?.actionTimeStamp).format('MM/DD/YYYY hh:mm A')}
                </Typography>
              </RACTableCell>
              <RACTableCell className={`${classes.racLightBlue}`}>
                <Typography>
                  {data?.createdBy}
                </Typography>
              </RACTableCell>
              <RACTableCell className={`${classes.racLightBlue}`}>
                <Typography>
                  {data.actionName == 'CAN' ? 'Cancelled' : data.actionName == 'EDITED' ? 'Edited' : data.actionName == 'CMP' ? 'Completed' : data.actionName}
                </Typography>
              </RACTableCell>
            </RACTableRow>
            {/* </Grid> */}
          </React.Fragment>
        </>
      )
    })
  }
  const renderAcimaAppointmentTableHistoryHeader = () => {
    return (
      <>
        <React.Fragment>
          <RACTableCell>
            <Typography
              variant="h5"
            >
              Activity Date & Time
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography
              variant="h5"
            >
              Coworker
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography
              variant="h5"
            >
              Action
            </Typography>
          </RACTableCell>
        </React.Fragment>
      </>)
  }

  const renderAcimaAppointmentTableHistoryContent = () => {
    return appointmentHistoryRecord?.appointmentHistory?.map((data: any) => {
      return (
        <>
          <React.Fragment>
            {/* <Grid className={classes.racLightBlue}> */}
            <RACTableRow style={{ backgroundColor: "white", padding: "4px 14px" }}>
              <RACTableCell
                className={`${classes.racLightBlue}`}
              >
                <Typography>
                  {moment(data?.actionTimeStamp).format('MM/DD/YYYY hh:mm A')}
                </Typography>
              </RACTableCell>
              <RACTableCell className={`${classes.racLightBlue}`}>
                <Typography>
                  {data?.createdBy}
                </Typography>
              </RACTableCell>
              <RACTableCell className={`${classes.racLightBlue}`}>
                <Typography>
                  {data.actionName == 'CAN' ? 'Cancelled' : data.actionName == 'EDITED' ? 'Edited' : data.actionName == 'CMP' ? 'Completed' : data.actionName}
                </Typography>
              </RACTableCell>
            </RACTableRow>
            {/* </Grid> */}
          </React.Fragment>
        </>
      )
    })
  }
  const handleConfirmDelivery = async () => {
    try {
      setEnableDeliveryPopup(false)
      const updatedeliverystatus: any = {
        agreementId: currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId,
        documentId: updateRecieptKey
      };
      if (currentappointmentDetails && currentappointmentDetails.isSwitchoutEnabled == '1') {
        updatedeliverystatus.isSwitchOut = 1
        updatedeliverystatus.switchInItem = Number(currentappointmentDetails.switchInItem)
        updatedeliverystatus.documentId = updateRecieptKey
      }
      const payload: any = {
        storeNumber: currentappointmentDetails.storeNumber,
        appointmentId: currentappointmentDetails.appointmentInfo.appointmentId,
        actionName: 'Complete',
        documentId: updateRecieptKey
      }
      setloader(true)
      if (['CI', 'P', 'S', 'SCI', 'SCO', 'SD', 'SP'].includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
        await updateEventStatus(payload)
        if (['S', 'SCI', 'SCO', 'SD', 'SP'].includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
          const updatesign = {
            agreementId: currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId,
            value: 1,
          };
          Recieptsign(updatesign)
        }

      } else {
        const ConfirmdeliveryRes = await Confirmdelivery(updatedeliverystatus)
        const updatesign = {
          agreementId: currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId,
          value: 1,
        };
        Recieptsign(updatesign)
        if (ConfirmdeliveryRes?.status == 200 && ConfirmdeliveryRes?.data) {
          if (currentappointmentDetails?.isSwitchoutEnabled == '1') {
            const payload: any = {
              storeException: [
                {
                  identifierId: Number(currentappointmentDetails?.agreement?.agreementId),
                  identifierType: 'AGREEMENT',
                  storeNumber: window.sessionStorage.getItem('storeNumber'),
                  exceptionTypeRefCode: "SWO",
                  exceptionText: null
                }
              ]
            }
            storeException(payload)
          }
        }
      }
      setloader(false)
      SetOpendeliveryconfirmpopup(true)
    } catch (e: any) {
      setloader(false)
      setModals({ ...modals, apiErrorInfoMsg: true })
    }

    // window.location.reload();

  }
  const formatNum = (val: any) => {
    const cleaned = ('' + val).replace(/\D/g, '');
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, '($1) $2-$3');
  };
  // const replaceEmtyString = ()=>{}
  const reScheduleBtnHandler = () => {
    debugger
    if (['S', 'SCI', 'SCO', 'SD', 'SP'].includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
      const invNumber = currentappointmentDetails?.inventories && currentappointmentDetails?.inventories.length > 0 && currentappointmentDetails?.inventories[0].inventoryNumber
      history.push({
        pathname: `/inventory/${invNumber}/service`
      })
    } else {
      history.push({
        pathname: `/agreement/info/details/${currentappointmentDetails?.customerInfo?.customerId}/${currentappointmentDetails?.agreement?.agreementId}`
      })
    }
  }
  // digital Sign Function

  const digitalSignClickFn = async () => {
    setloader(true)
    setEnableDeliveryPopup(false)
    if (agreementValues?.agreementSign == '0') {
      const apiResponse: any = await CheckSign(agreementValues?.agreementId);
      setloader(false);
      if (apiResponse?.data?.isSigned == '0') {
        // setEnableDeliveryPopup(false);
        setloader(false)
        SetCheckforSign(true);
      }
      else {
        setloader(false)
        setdigitalSignEnable(true);
      }
    }
    else {
      setloader(false)
      setdigitalSignEnable(true);
    }
  }
  // Customer Survery Form
  const showCustomerSurveyForm = async () => {
    setloader(true)
    resetSurvey()
    setToShowCustomerSurveyForm(true)
  }

  // Customer Survery Form
  const showReadOnlyCustomerSurveyForm = async () => {
    setloader(true)
    setToShowCustomerSurveyForm(true)
  }

  const AcimaDigitalSignClickFn = async () => {
    setacimaDeliveryDigitalsignEnbale(true)
  }
  const addressFormatFnResFn = () => {
    return (currentappointmentDetails?.customerInfo?.address?.addressLine1 + ',' + currentappointmentDetails?.customerInfo?.address?.addressLine2 + ',' + currentappointmentDetails?.customerInfo?.address?.state)

  }
  const handleAddressClick = async () => {
    const addressFormatFnRes = await addressFormatFnResFn();
    const encodedAddress = encodeURIComponent(addressFormatFnRes);
    const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(googleMapsURL, '_blank');
  };

  const handlePhoneNumberClick = () => {
    window.location.href = `tel:${currentappointmentDetails?.customerInfo?.phoneNumber}`;
  }

  const DeliveryPopup = () => {
    currentappointmentDetails
    let flag: any = true
    if (currentappointmentDetails && (currentappointmentDetails.appointmentInfo.appointmentStatus != 'CMP') && (fieldDeviceParamKey == '1')) {
      flag = false
    }
    return (
      <Grid item md={12}>
        <Grid item md={12}
          //  className={classes.textCenter}
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }}>
            <Typography>
              Agreement #
            </Typography>
          </Grid>
          {/* <Grid style={{ width: "33%" }}>
                        <Typography>
                            Customer ID
                        </Typography>
                    </Grid> */}
          <Grid style={{ width: "50%" }}>
            <Typography>
              Customer Name
            </Typography>
          </Grid>
        </Grid>

        <Grid
          //  className={classes.textCenter}
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }}>
            <a
              onClick={() => { pilotStoreParamKey == '1' ? history.push({ pathname: `/agreement/info/details/${currentappointmentDetails?.customerInfo?.customerId}/${currentappointmentDetails?.agreement?.agreementId}` }) : null }}
              className={pilotStoreParamKey == '1' ? classes.customerRaclink : classes.customerRaclinkAcima}
            >
              {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
            </a>
          </Grid>
          {/* <Grid style={{ width: "33%" }}>
                        <a
                            onClick={() => history.push({ pathname: `/customer/update/${currentappointmentDetails?.customerInfo?.customerId}/customer` })}
                            className={classes.customerRaclink}
                        >
                            {currentappointmentDetails && currentappointmentDetails?.customerInfo?.customerId}
                        </a>
                    </Grid> */}
          <Grid style={{ width: "50%" }}>
            <a
              onClick={() => { pilotStoreParamKey == '1' ? history.push({ pathname: `/customer/update/${currentappointmentDetails?.customerInfo?.customerId}/customer` }) : null }}
              className={pilotStoreParamKey == '1' ? classes.customerRaclink : classes.customerRaclinkAcima}
            >
              {currentappointmentDetails?.customerInfo?.firstName + ` ` + currentappointmentDetails?.customerInfo?.lastName}
            </a>
          </Grid>
        </Grid>

        <Grid item md={12}
          //  className={classes.textCenter}
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }}>
            <Typography>
              Customer Address
            </Typography>
          </Grid>
          {/* <Grid style={{ width: "33%" }}>
                        <Typography>
                            Customer ID
                        </Typography>
                    </Grid> */}
          <Grid style={{ width: "50%" }}>
            <Typography>
              Phone #
            </Typography>
          </Grid>
        </Grid>

        <Grid
          //  className={classes.textCenter}
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }} className={classes.customerRaclink}>
            <a
              onClick={() => { { currentappointmentDetails?.customerInfo?.address?.addressLine1 ? handleAddressClick() : null } }}
            >
              {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.address?.addressLine1 || '') + ' ' + currentappointmentDetails?.customerInfo?.address?.addressLine2 || ''}
              {/* //{currentappointmentDetails?.customerInfo?.address?.addressLine2 || ''} */}
              {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.address?.city || '')}
            </a>
          </Grid>
          <Grid style={{ width: "50%" }} className={classes.customerRaclink}>
            <a
              onClick={() => { { currentappointmentDetails?.customerInfo?.phoneNumber ? handlePhoneNumberClick() : null } }}
            >
              {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.phoneNumber ? formatNum(currentappointmentDetails?.customerInfo?.phoneNumber) : '')}
            </a>
          </Grid>
        </Grid>

        <Grid className={classes.racLightBlue} style={{ margin: "10px" }}>
          <RACTable
            renderTableHead={renderAppointmentTableHead}
            renderTableContent={renderAppointmentTableContent}
          />
        </Grid>
        {!flag ?
          (<div>
            <Grid style={{ display: 'inline-block' }}>
              <RACToggle
                checked={manualSignatureEnabled}
                toggleColor="#0d6efd"
                OnClick={() => { manualSignatureEnabled ? setmanualSignatureEnabled(false) : setmanualSignatureEnabled(true) }}
              />
            </Grid>
            <Typography style={{ display: 'inline' }}>Manual Signature</Typography>
          </div>) : null}
        {/* ----------------------------Toggle Ending--------- */}
        {/*-------------------Delivery Digital Start-----------------------  */}
        {/*  */}
        {((!manualSignatureEnabled) && (currentappointmentDetails) && (currentappointmentDetails.appointmentInfo.appointmentStatus != 'CMP') && (fieldDeviceParamKey == '1')) ? (<div>
          {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus == 'CMP' ?
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h5" style={{ alignSelf: 'center' }}>Status :</Typography>
              <span style={{ marginLeft: '10px', marginTop: '2px', color: '#58af52', border: '1px solid #58af52', padding: '5px', borderRadius: '5px', fontWeight: '800' }}>Completed</span>
            </Grid> : currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus !== 'CAN' ?
              <div data-testid="WizardTest">
                <Grid className={classes.swThemeDefault}>
                  <Grid item className={classes.swThemeDefaultContainer}>
                    <List className={classes.smartWizard} style={{ display: 'flex', justifyContent: 'center' }}>
                      <ListItem
                        className={classes.smartWizardList}
                      // onClick={() => WizardFieldClick('Itemsearch')}
                      >
                        <a className={dynamicClasses?.status?.Signature?.anchor}>
                          {status?.Signature === 'complete' ? (
                            <Box
                              component="span"
                              className={`${classes.done} ${classes.borderDone}`}
                              style={{ backgroundColor: '#5ae286', borderColor: '#5ae286' }}
                            >
                              <CompletedIcon className={classes.paddingTop3} />
                            </Box>

                          ) : null}


                          <span
                            className={dynamicClasses?.status?.Signature?.spanDetail}
                          >
                            Agreement Signature
                          </span>
                        </a>
                      </ListItem>

                      <ListItem className={classes.smartWizardList}>
                        <a
                          className={dynamicClasses?.status?.ConfirmDelivery?.anchor}
                          style={{ cursor: 'not-allowed' }}
                        >
                          {status?.ConfirmDelivery === 'complete' ? (
                            <Box
                              component="span"
                              className={`${classes.done} ${classes.borderDone}`}
                              style={{ backgroundColor: '#5ae286', borderColor: '#5ae286' }}
                            >
                              <CompletedIcon className={classes.paddingTop3} />
                            </Box>
                          ) : (
                            <span
                              className={
                                dynamicClasses?.status?.ConfirmDelivery?.spanNum
                              }
                              style={{
                                color: '#ffffff',
                                border: '2px solid #d9d9d9',
                                margin: '0 5px',
                                padding: '4px 11px',
                                borderColor: '#1b4ae1',
                                borderRadius: '50px',
                                backgroundColor: '#1b4ae1'
                              }}
                            >
                              2
                            </span>
                          )}

                          <span
                            className={
                              dynamicClasses?.status?.ConfirmDelivery?.spanDetail
                            }
                          >
                            Sign Delivery Receipt
                          </span>
                        </a>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </div> : null}
          {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus !== 'CMP' ?
            <Grid style={{ textAlign: "center" }}>
              {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus == 'CAN' ? <>
                <Typography variant="h4" style={{ backgroundColor: 'gray', color: 'black', display: 'contents' }}>Status:Cancelled</Typography>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => setModals({ ...modals, reopenConfirmModal: true })}
                  style={{ marginLeft: '40px' }}
                >
                  Reopen Event
                </RACButton></> : <><RACButton
                  variant="outlined"
                  className={classes.unassignBtnClass}
                  onClick={() => cancelOrCompleteStoreEvent('Cancel')}
                >Cancel Event</RACButton>
                {
                  pilotStoreParamKey == '1' ? <RACButton
                    color="primary"
                    variant="contained"
                    className={classes.ms2}
                    onClick={() => reScheduleBtnHandler()}
                  >Reschedule</RACButton> : null
                }

                <RACButton
                  className={classes.ms2}
                  color="primary"
                  type="button"
                  // disabled={handleDisable()}
                  variant="contained"
                  // onClick={() => { setEnableDeliveryPopup(false), setdigitalSignEnable(true) }}
                  onClick={() => { digitalSignClickFn() }}
                >Sign and Confirm Delivery</RACButton>

                {
                  toShowSurvey ? <RACButton
                    className={classes.ms2}
                    color="primary"
                    type="button"
                    variant="contained"
                    onClick={() => { showCustomerSurveyForm() }}
                  >Customer Survey
                  </RACButton> : readOnlySurvey ?
                    <RACButton
                      className={classes.ms2}
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={() => { showReadOnlyCustomerSurveyForm() }}
                    >Show Survey
                    </RACButton> : null
                }</>}


            </Grid> : null}
        </div>) : (<div>

          {/* ----------------------------Delivery Digital End--------------------- */}


          {/* --------------------------Manual Signature Start------------------- */}
          {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus == 'CMP' ?
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h5" style={{ alignSelf: 'center' }}>Status :</Typography>
              <span style={{ marginLeft: '10px', marginTop: '2px', color: '#58af52', border: '1px solid #58af52', padding: '5px', borderRadius: '5px', fontWeight: '800' }}>Completed</span>
              {
                toShowSurvey ? <RACButton
                  className={classes.ms2}
                  color="primary"
                  type="button"
                  variant="contained"
                  onClick={() => { showCustomerSurveyForm() }}
                >Customer Survey</RACButton> : readOnlySurvey ?
                  <RACButton
                    className={classes.ms2}
                    color="primary"
                    type="button"
                    variant="contained"
                    onClick={() => { showReadOnlyCustomerSurveyForm() }}
                  >Show Survey
                  </RACButton> : null
              }
            </Grid> : currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus !== 'CAN' ?
              <div data-testid="WizardTest">
                <Grid className={classes.swThemeDefault}>
                  <Grid item className={classes.swThemeDefaultContainer}>
                    <List className={classes.smartWizard} style={{ display: 'flex', justifyContent: 'center' }}>
                      <ListItem
                        className={classes.smartWizardList}
                      // onClick={() => WizardFieldClick('Itemsearch')}
                      >
                        <a className={dynamicClasses?.status?.Signature?.anchor}>
                          {status?.Signature === 'complete' ? (
                            <Box
                              component="span"
                              className={`${classes.done} ${classes.borderDone}`}
                              style={{ backgroundColor: '#5ae286', borderColor: '#5ae286' }}
                            >
                              <CompletedIcon className={classes.paddingTop3} />
                            </Box>

                          ) : null}


                          <span
                            className={dynamicClasses?.status?.Signature?.spanDetail}
                          >
                            Signature
                          </span>
                        </a>
                      </ListItem>
                      <ListItem
                        className={classes.smartWizardList}
                      // onClick={() => WizardFieldClick('AgrDetails')}
                      >
                        <a className={dynamicClasses?.status?.Reprint?.anchor}>
                          {status?.Reprint === 'complete' ? (
                            <Box
                              component="span"
                              className={`${classes.done} ${classes.borderDone}`}
                              style={{ backgroundColor: '#5ae286', borderColor: '#5ae286' }}
                            >
                              <CompletedIcon className={classes.paddingTop3} />
                            </Box>
                          ) : (
                            <span
                              className={
                                dynamicClasses?.status?.Reprint?.spanNum
                              }
                              style={{
                                color: '#ffffff',
                                border: '2px solid #d9d9d9',
                                margin: '0 5px',
                                padding: '4px 11px',
                                borderColor: '#1b4ae1',
                                borderRadius: '50px',
                                backgroundColor: '#1b4ae1'
                              }}
                            >
                              2
                            </span>
                          )}

                          <span
                            className={
                              dynamicClasses?.status?.Reprint?.spanDetail
                            }
                          >
                            Re-print Delivery Receipt
                          </span>
                        </a>
                      </ListItem>
                      <ListItem className={classes.smartWizardList}>
                        <a
                          className={dynamicClasses?.status?.ConfirmDelivery?.anchor}
                          style={{ cursor: 'not-allowed' }}
                        >
                          {status?.ConfirmDelivery === 'complete' ? (
                            <Box
                              component="span"
                              className={`${classes.done} ${classes.borderDone}`}
                              style={{ backgroundColor: '#5ae286', borderColor: '#5ae286' }}
                            >
                              <CompletedIcon className={classes.paddingTop3} />
                            </Box>
                          ) : (
                            <span
                              className={
                                dynamicClasses?.status?.ConfirmDelivery?.spanNum
                              }
                              style={{
                                color: '#ffffff',
                                border: '2px solid #d9d9d9',
                                margin: '0 5px',
                                padding: '4px 11px',
                                borderColor: '#1b4ae1',
                                borderRadius: '50px',
                                backgroundColor: '#1b4ae1'
                              }}
                            >
                              3
                            </span>
                          )}

                          <span
                            className={
                              dynamicClasses?.status?.ConfirmDelivery?.spanDetail
                            }
                          >
                            Confirm Delivery
                          </span>
                        </a>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </div> : null}
          {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus !== 'CMP' ?
            <Grid style={{ textAlign: "center" }}>
              {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus == 'CAN' ? <>
                <Typography variant="h4" style={{ backgroundColor: 'gray', color: 'black', display: 'contents' }}>Status:Cancelled</Typography>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => setModals({ ...modals, reopenConfirmModal: true })}
                  style={{ marginLeft: '40px' }}
                >
                  Reopen Event
                </RACButton></> : <><RACButton
                  variant="outlined"
                  className={classes.unassignBtnClass}
                  onClick={() => cancelOrCompleteStoreEvent('Cancel')}
                >Cancel Event</RACButton>
                {pilotStoreParamKey == "1" ? <RACButton
                  color="primary"
                  variant="contained"
                  className={classes.ms2}
                  onClick={() => reScheduleBtnHandler()}
                >Reschedule</RACButton> : null}

                {!isReprintCompleted ? <RACButton
                  className={classes.ms2}
                  color="primary"
                  type="button"
                  // disabled={handleDisable()}
                  variant="contained"
                  onClick={() => handleRePrintDelivery()}
                >Re-print Delivery Receipt</RACButton> : <RACButton
                  className={classes.ms2}
                  color="primary"
                  type="button"
                  // disabled={handleDisable()}
                  variant="contained"
                  onClick={() => handleConfirmDelivery()}
                >Confirm Delivery</RACButton>}</>}


            </Grid> : null}
        </div>)}
        {/* -----------Manual Signature End-------------- */}
        <Grid style={{ width: '100%' }}>
          <Grid container style={{ width: '100%' }}>
            <Card
              className={`${customerStyleClasses.card} ${customerStyleClasses.w100}  ${customerStyleClasses.borderRadiusZero}`}
            >
              <CardContent
                className={`${classes.headerTab} ${customerStyleClasses.floatLeft} ${customerStyleClasses.w100} ${classes.py0}`} style={{ padding: '0px' }}
              >
                <Grid
                  item
                  className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.w100}`}
                >
                  <Box className={customerStyleClasses.floatLeft}>
                    <List
                      className={`${classes.racpadTab} ${classes.py0}`}
                      id="CustTab"
                      role="tabList"
                    >
                      <ListItem
                        className={
                          activetab.notes
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem}`
                        }
                        id="employment-tab"
                        data-testid="employmentInfoTab"
                        data-bs-toggle="tab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => setactivetab({ ...activetab, notes: true, history: false })}

                      >
                        Notes
                      </ListItem>
                      <ListItem
                        className={
                          activetab.history
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem}`
                        }
                        id="Customerinfo-tab"
                        data-testid="customerInfoTab"
                        data-bs-toggle="tab"
                        data-bs-target="#customerinfo"
                        role="tab"
                        aria-controls="Customerinfo-tab"
                        aria-selected="true"
                        onClick={() => setactivetab({ ...activetab, notes: false, history: true })}                                            >
                        History
                      </ListItem>

                    </List>
                  </Box>

                </Grid>
                {updatingNotesLoader ? <div className={classes.Norecords}>
                  <CircularProgress />
                </div> : null}
                {activetab.notes && !updatingNotesLoader ? <Grid about="notesContent" style={{ width: '100%', minHeight: '150px', marginTop: '40px' }}>
                  {currentappointmentDetails && currentappointmentDetails.notes && currentappointmentDetails.notes.map((el: any) => {
                    return (<Grid about="notesHeader" key={el.appointmentNoteId} style={{ padding: '1rem', backgroundColor: 'aliceblue', marginBottom: '10px' }}>
                      <Grid about="messageDetails" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ display: 'inline-block' }}>{el.lastModifiedBy}</Typography>
                        <span>{moment(el.lastModifiedDate).format('MM/DD/YYYY')} {moment(el.lastModifiedDate, 'HH:mm:ss').format(
                          'hh:mm A'
                        )}</span>
                      </Grid>
                      <Grid about="message" style={{ marginTop: '5px' }}>
                        <span style={{ fontWeight: '500' }}>{el.noteContent}</span>
                      </Grid>
                    </Grid>)
                  })}
                  <Grid about="notesFooter" style={{ width: '100%', padding: '1rem' }}>
                    <Typography>Write a new Note</Typography>
                    <Grid about="textFeilds" className={classes.storeEventNots}>
                      <RACTextbox
                        value={storeEventNotes}
                        OnChange={(e: any) => {
                          setStoreEventNotes(e.target.value);
                        }}
                        placeHolder={'Enter the text here'}
                        className={`${classes.searchSpacing} ${classes.w100} ${classes.mr1}`}
                      />
                      <RACButton
                        type="button"
                        className={`${classes.ms2} ${classes.todayButton}`}
                        color="primary"
                        disabled={storeEventNotes && storeEventNotes.trim().length !== 0 ? false : true}
                        style={{ height: '36px', margin: '0px' }}
                        variant="contained"
                        onClick={() => updateNotesHandler()}
                      >
                        Add
                      </RACButton>
                    </Grid>

                  </Grid>
                </Grid> : null}
                {activetab.history && !updatingNotesLoader ? <Grid>
                  <RACTable
                    renderTableHead={renderAppointmentTableHistoryHeader}
                    renderTableContent={renderAppointmentTableHistoryContent}
                  />
                </Grid> : null}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid >
    )
  }
  const StorePickupEventPopup = () => {
    const startTime: any = moment(currentappointmentDetails?.timeSlotInfo?.startTime, 'HH:mm:ss').format(
      'hh:mm A'
    );
    const endTime: any = moment(currentappointmentDetails?.timeSlotInfo?.endTime, 'HH:mm:ss').format(
      'hh:mm A'
    );
    currentappointmentDetails?.storePickUpInfo
    {
      return (
        <Grid item md={12}>
          <Grid item md={12}
            className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
          >
            <Grid style={{ width: "45%" }}>
              <Typography>
                Agreement #
              </Typography>
            </Grid>
            <Grid style={{ width: "30%" }}>
              <Typography>
                Customer Name
              </Typography>
            </Grid>
            <Grid style={{ width: "25%" }}>
              <Typography>
                Store Pick-up
              </Typography>
            </Grid>
          </Grid>

          <Grid
            className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
          >
            <Grid style={{ width: "45%" }}>
              <a
                onClick={() => { pilotStoreParamKey == '1' ? history.push({ pathname: `/agreement/info/details/${currentappointmentDetails?.customerInfo?.customerId}/${currentappointmentDetails?.agreement?.agreementId}` }) : null }}
                className={pilotStoreParamKey == '1' ? classes.customerRaclink : classes.customerRaclinkAcima}
              >
                {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
              </a>
            </Grid>
            <Grid style={{ width: "30%" }}>
              <a
                onClick={() => { pilotStoreParamKey == '1' ? history.push({ pathname: `/customer/update/${currentappointmentDetails?.customerInfo?.customerId}/customer` }) : null }}
                className={pilotStoreParamKey == '1' ? classes.customerRaclink : classes.customerRaclinkAcima}
              >
                {currentappointmentDetails?.customerInfo?.firstName + ` ` + currentappointmentDetails?.customerInfo?.lastName}
              </a>
            </Grid>
            <Grid className={`${customerSurveyClasses.popUpContent}`}>
              {currentappointmentDetails && (currentappointmentDetails?.storePickUpInfo?.locationNumber ? formatNum(currentappointmentDetails?.storePickUpInfo?.locationNumber) : '')}
              <span className={`${customerSurveyClasses.tabSpace}`}>{startTime + `-` + endTime}</span>
            </Grid>
          </Grid>

          <Grid item md={12}
            className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
          >
            <Grid style={{ width: "45%" }}>
              <Typography>
                Customer Address
              </Typography>
            </Grid>
            <Grid style={{ width: "30%" }}>
              <Typography>
                Phone #
              </Typography>
            </Grid>
            <Grid style={{ width: "25%" }}>
              <Typography>
                Store Address
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
          >
            <Grid style={{ width: "45%" }} className={classes.customerRaclink}>
              <a
                onClick={() => { { currentappointmentDetails?.customerInfo?.address?.addressLine1 ? handleAddressClick() : null } }}
              >
                {currentappointmentDetails && ((currentappointmentDetails?.customerInfo?.address?.addressLine1 ? currentappointmentDetails?.customerInfo?.address?.addressLine1 : '') + ' ' + (currentappointmentDetails?.customerInfo?.address?.addressLine2 ? currentappointmentDetails?.customerInfo?.address?.addressLine2 : ''))}
                {/* //{currentappointmentDetails?.customerInfo?.address?.addressLine2 || ''} */}
                {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.address?.city || '')}
              </a>
            </Grid>
            <Grid style={{ width: "30%" }} className={classes.customerRaclink}>
              <a
                onClick={() => { { currentappointmentDetails?.customerInfo?.phoneNumber ? handlePhoneNumberClick() : null } }}
              >
                {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.phoneNumber ? formatNum(currentappointmentDetails?.customerInfo?.phoneNumber) : '')}
              </a>
            </Grid>
            <Grid className={`${customerSurveyClasses.popUpContent}`}>
              {currentappointmentDetails && ((currentappointmentDetails?.storePickUpInfo?.address?.addressLine1 ? currentappointmentDetails?.storePickUpInfo?.address?.addressLine1 : '') + ' ' + (currentappointmentDetails?.storePickUpInfo?.address?.addressLine2 ? currentappointmentDetails?.storePickUpInfo?.address?.addressLine2 : ''))}
            </Grid>
          </Grid>
          <Grid
            className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
          >
            <Grid style={{ width: "45%" }}>
            </Grid>
            <Grid style={{ width: "30%" }}>
            </Grid>
            <Grid className={`${customerSurveyClasses.popUpContent}`}>
              {currentappointmentDetails && (currentappointmentDetails?.storePickUpInfo?.address?.city ? currentappointmentDetails?.storePickUpInfo?.address?.city : '')}
            </Grid>
          </Grid>
          <Grid className={classes.racLightBlue} style={{ margin: "10px" }}>
            <RACTable
              renderTableHead={renderAppointmentTableHead}
              renderTableContent={renderAppointmentTableContent}
            />
          </Grid>
          <Grid style={{ width: '50%' }}>
            <Grid style={{ margin: '10px' }}>

              <Typography variant="h5">Coworker required</Typography>
              <span>{currentappointmentDetails?.appointmentInfo?.requiredCoworkers || '-'}</span>
            </Grid>
          </Grid>
          {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus !== 'CMP' ?
            <Grid about="footer" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus == 'CAN' ? <RACButton
                variant="contained"
                color="primary"
                onClick={() => setModals({ ...modals, reopenConfirmModal: true })}
                style={{ marginLeft: '20px' }}
              >
                Reopen Event
              </RACButton> : currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus != 'CMP' ? <><RACButton
                variant="outlined"
                className={classes.unassignBtnClass}
                onClick={() => cancelOrCompleteStoreEvent('Cancel')}
              >Cancel Pick-up</RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => editPickupEventHandler()}
                  style={{ marginLeft: '20px' }}
                >
                  Edit Pick-up
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => setModals({ ...modals, completeConfirmModal: true })}
                  style={{ marginLeft: '20px' }}
                >
                  Mark As Completed
                </RACButton> </> : null}
            </Grid> : null}
          {/* -----------Manual Signature End-------------- */}
          <Grid style={{ width: '100%' }}>
            <Grid container style={{ width: '100%' }}>
              <Card
                className={`${customerStyleClasses.card} ${customerStyleClasses.w100}  ${customerStyleClasses.borderRadiusZero}`}
              >
                <CardContent
                  className={`${classes.headerTab} ${customerStyleClasses.floatLeft} ${customerStyleClasses.w100} ${classes.py0}`} style={{ padding: '0px' }}
                >
                  <Grid
                    item
                    className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.w100}`}
                  >
                    <Box className={customerStyleClasses.floatLeft}>
                      <List
                        className={`${classes.racpadTab} ${classes.py0}`}
                        id="CustTab"
                        role="tabList"
                      >
                        <ListItem
                          className={
                            activetab.notes
                              ? `${classes.navLinkActive} ${classes.navLinkItem}`
                              : `${classes.navLinkItem}`
                          }
                          id="employment-tab"
                          data-testid="employmentInfoTab"
                          data-bs-toggle="tab"
                          data-bs-target="#employmentinfo"
                          role="tab"
                          aria-controls="employment-tab"
                          aria-selected="false"
                          onClick={() => setactivetab({ ...activetab, notes: true, history: false })}

                        >
                          Notes
                        </ListItem>
                        <ListItem
                          className={
                            activetab.history
                              ? `${classes.navLinkActive} ${classes.navLinkItem}`
                              : `${classes.navLinkItem}`
                          }
                          id="Customerinfo-tab"
                          data-testid="customerInfoTab"
                          data-bs-toggle="tab"
                          data-bs-target="#customerinfo"
                          role="tab"
                          aria-controls="Customerinfo-tab"
                          aria-selected="true"
                          onClick={() => setactivetab({ ...activetab, notes: false, history: true })}                                            >
                          History
                        </ListItem>

                      </List>
                    </Box>

                  </Grid>
                  {updatingNotesLoader ? <div className={classes.Norecords}>
                    <CircularProgress />
                  </div> : null}
                  {activetab.notes && !updatingNotesLoader ? <Grid about="notesContent" style={{ width: '100%', minHeight: '150px', marginTop: '40px' }}>
                    {currentappointmentDetails && currentappointmentDetails.notes && currentappointmentDetails.notes.map((el: any) => {
                      return (<Grid about="notesHeader" key={el.appointmentNoteId} style={{ padding: '1rem', backgroundColor: 'aliceblue', marginBottom: '10px' }}>
                        <Grid about="messageDetails" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography style={{ display: 'inline-block' }}>{el.lastModifiedBy}</Typography>
                          <span>{moment(el.lastModifiedDate).format('MM/DD/YYYY')} {moment(el.lastModifiedDate, 'HH:mm:ss').format(
                            'hh:mm A'
                          )}</span>
                        </Grid>
                        <Grid about="message" style={{ marginTop: '5px' }}>
                          <span style={{ fontWeight: '500' }}>{el.noteContent}</span>
                        </Grid>
                      </Grid>)
                    })}
                    <Grid about="notesFooter" style={{ width: '100%', padding: '1rem' }}>
                      <Typography>Write a new Note</Typography>
                      <Grid about="textFeilds" className={classes.storeEventNots}>
                        <RACTextbox
                          value={storeEventNotes}
                          OnChange={(e: any) => {
                            setStoreEventNotes(e.target.value);
                          }}
                          placeHolder={'Enter the text here'}
                          className={`${classes.searchSpacing} ${classes.w100} ${classes.mr1}`}
                        />
                        <RACButton
                          type="button"
                          className={`${classes.ms2} ${classes.todayButton}`}
                          color="primary"
                          disabled={storeEventNotes && storeEventNotes.trim().length !== 0 ? false : true}
                          style={{ height: '36px', margin: '0px' }}
                          variant="contained"
                          onClick={() => updateNotesHandler()}
                        >
                          Add
                        </RACButton>
                      </Grid>

                    </Grid>
                  </Grid> : null}
                  {activetab.history && !updatingNotesLoader ? <Grid>
                    <RACTable
                      renderTableHead={renderAppointmentTableHistoryHeader}
                      renderTableContent={renderAppointmentTableHistoryContent}
                    />
                  </Grid> : null}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid >
      )
    }
  }

  const FieldVisitEventPopup = () => {
      return (
        <Grid item md={12}>
          <Grid item md={12}
            className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
          >
            <Grid style={{ width: "50%" }}>
              <Typography>
                Agreement #
              </Typography>
            </Grid>
            <Grid style={{ width: "50%" }}>
              <Typography>
                Customer Name
              </Typography>
            </Grid>
          </Grid>

          <Grid
            className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
          >
            <Grid style={{ width: "50%" }}>
              <a
                onClick={() => { pilotStoreParamKey == '1' ? history.push({ pathname: `/agreement/info/details/${currentappointmentDetails?.customerInfo?.customerId}/${currentappointmentDetails?.agreement?.agreementId}` }) : null }}
                className={pilotStoreParamKey == '1' ? classes.customerRaclink : classes.customerRaclinkAcima}
              >
                {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
              </a>
            </Grid>
            <Grid style={{ width: "50%" }}>
              <a
                onClick={() => { pilotStoreParamKey == '1' ? history.push({ pathname: `/customer/update/${currentappointmentDetails?.customerInfo?.customerId}/customer` }) : null }}
                className={pilotStoreParamKey == '1' ? classes.customerRaclink : classes.customerRaclinkAcima}
              >
                {currentappointmentDetails?.customerInfo?.firstName + ` ` + currentappointmentDetails?.customerInfo?.lastName}
              </a>
            </Grid>
          </Grid>

          <Grid item md={12}
            className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
          >
            <Grid style={{ width: "50%" }}>
              <Typography>
                Customer Address
              </Typography>
            </Grid>
            <Grid style={{ width: "50%" }}>
              <Typography>
                Phone #
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
          >
            <Grid style={{ width: "50%" }} className={classes.customerRaclink}>
              <a
                onClick={() => { { currentappointmentDetails?.customerInfo?.address?.addressLine1 ? handleAddressClick() : null } }}
              >
                {currentappointmentDetails && ((currentappointmentDetails?.customerInfo?.address?.addressLine1 ? currentappointmentDetails?.customerInfo?.address?.addressLine1 : '') + ' ' + (currentappointmentDetails?.customerInfo?.address?.addressLine2 ? currentappointmentDetails?.customerInfo?.address?.addressLine2 : ''))}
                {/* //{currentappointmentDetails?.customerInfo?.address?.addressLine2 || ''} */}
                {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.address?.city || '')}
              </a>
            </Grid>
            <Grid style={{ width: "50%" }} className={classes.customerRaclink}>
              <a
                onClick={() => { { currentappointmentDetails?.customerInfo?.phoneNumber ? handlePhoneNumberClick() : null } }}
              >
                {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.phoneNumber ? formatNum(currentappointmentDetails?.customerInfo?.phoneNumber) : '')}
              </a>
            </Grid>
          </Grid>
          <Grid className={classes.racLightBlue} style={{ margin: "10px" }}>
            <RACTable
              renderTableHead={renderAppointmentTableHead}
              renderTableContent={renderAppointmentTableContent}
            />
          </Grid>
          {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus !== 'CMP' ?
            <Grid about="footer" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus == 'CAN' ? <RACButton
                variant="contained"
                color="primary"
                onClick={() => setModals({ ...modals, reopenConfirmModal: true })}
                style={{ marginLeft: '20px' }}
              >
                Reopen Event
              </RACButton> : currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus != 'CMP' ? <><RACButton
                variant="outlined"
                className={classes.unassignBtnClass}
                onClick={() => cancelOrCompleteStoreEvent('Cancel')}
              >Cancel Field Visit</RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => editFieldVisitEventHandler()}
                  style={{ marginLeft: '20px' }}
                >
                  Edit Field Visit
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => setModals({ ...modals, completeConfirmModal: true })}
                  style={{ marginLeft: '20px' }}
                >
                  Mark As Completed
                </RACButton> </> : null}
            </Grid> : null}
          {/* -----------Manual Signature End-------------- */}
          <Grid style={{ width: '100%' }}>
            <Grid container style={{ width: '100%' }}>
              <Card
                className={`${customerStyleClasses.card} ${customerStyleClasses.w100}  ${customerStyleClasses.borderRadiusZero}`}
              >
                <CardContent
                  className={`${classes.headerTab} ${customerStyleClasses.floatLeft} ${customerStyleClasses.w100} ${classes.py0}`} style={{ padding: '0px' }}
                >
                  <Grid
                    item
                    className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.w100}`}
                  >
                    <Box className={customerStyleClasses.floatLeft}>
                      <List
                        className={`${classes.racpadTab} ${classes.py0}`}
                        id="CustTab"
                        role="tabList"
                      >
                        <ListItem
                          className={
                            activetab.notes
                              ? `${classes.navLinkActive} ${classes.navLinkItem}`
                              : `${classes.navLinkItem}`
                          }
                          id="employment-tab"
                          data-testid="employmentInfoTab"
                          data-bs-toggle="tab"
                          data-bs-target="#employmentinfo"
                          role="tab"
                          aria-controls="employment-tab"
                          aria-selected="false"
                          onClick={() => setactivetab({ ...activetab, notes: true, history: false })}

                        >
                          Notes
                        </ListItem>
                        <ListItem
                          className={
                            activetab.history
                              ? `${classes.navLinkActive} ${classes.navLinkItem}`
                              : `${classes.navLinkItem}`
                          }
                          id="Customerinfo-tab"
                          data-testid="customerInfoTab"
                          data-bs-toggle="tab"
                          data-bs-target="#customerinfo"
                          role="tab"
                          aria-controls="Customerinfo-tab"
                          aria-selected="true"
                          onClick={() => setactivetab({ ...activetab, notes: false, history: true })}                                            >
                          History
                        </ListItem>

                      </List>
                    </Box>

                  </Grid>
                  {updatingNotesLoader ? <div className={classes.Norecords}>
                    <CircularProgress />
                  </div> : null}
                  {activetab.notes && !updatingNotesLoader ? <Grid about="notesContent" style={{ width: '100%', minHeight: '150px', marginTop: '40px' }}>
                    {currentappointmentDetails && currentappointmentDetails.notes && currentappointmentDetails.notes.map((el: any) => {
                      return (<Grid about="notesHeader" key={el.appointmentNoteId} style={{ padding: '1rem', backgroundColor: 'aliceblue', marginBottom: '10px' }}>
                        <Grid about="messageDetails" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography style={{ display: 'inline-block' }}>{el.lastModifiedBy}</Typography>
                          <span>{moment(el.lastModifiedDate).format('MM/DD/YYYY')} {moment(el.lastModifiedDate, 'HH:mm:ss').format(
                            'hh:mm A'
                          )}</span>
                        </Grid>
                        <Grid about="message" style={{ marginTop: '5px' }}>
                          <span style={{ fontWeight: '500' }}>{el.noteContent}</span>
                        </Grid>
                      </Grid>)
                    })}
                    <Grid about="notesFooter" style={{ width: '100%', padding: '1rem' }}>
                      <Typography>Write a new Note</Typography>
                      <Grid about="textFeilds" className={classes.storeEventNots}>
                        <RACTextbox
                          value={storeEventNotes}
                          OnChange={(e: any) => {
                            setStoreEventNotes(e.target.value);
                          }}
                          placeHolder={'Enter the text here'}
                          className={`${classes.searchSpacing} ${classes.w100} ${classes.mr1}`}
                        />
                        <RACButton
                          type="button"
                          className={`${classes.ms2} ${classes.todayButton}`}
                          color="primary"
                          disabled={storeEventNotes && storeEventNotes.trim().length !== 0 ? false : true}
                          style={{ height: '36px', margin: '0px' }}
                          variant="contained"
                          onClick={() => updateNotesHandler()}
                        >
                          Add
                        </RACButton>
                      </Grid>

                    </Grid>
                  </Grid> : null}
                  {activetab.history && !updatingNotesLoader ? <Grid>
                    <RACTable
                      renderTableHead={renderAppointmentTableHistoryHeader}
                      renderTableContent={renderAppointmentTableHistoryContent}
                    />
                  </Grid> : null}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid >
      )
  }
  const SetSurveyRenewalDateAndAmtQn = () => {
    if (!surveyDetails.renewalDateAndAmtQn) {
      setSurveyDetails({ ...surveyDetails, renewalDateAndAmtQn: true })
    } else { setSurveyDetails({ ...surveyDetails, renewalDateAndAmtQn: false }) }
  }
  const SetSurveyAutoEnrollQn = () => {
    if (!surveyDetails.autoEnrollQn) {
      setSurveyDetails({ ...surveyDetails, autoEnrollQn: true })
    } else { setSurveyDetails({ ...surveyDetails, autoEnrollQn: false }) }
  }
  const SetSurveyTimelyPayments = () => {
    if (!surveyDetails.timelyPayments) {
      setSurveyDetails({ ...surveyDetails, timelyPayments: true })
    } else { setSurveyDetails({ ...surveyDetails, timelyPayments: false }) }
  }

  const CustomerSurveyPopup = () => {
    currentappointmentDetails
    return (
      <Grid item md={12}>
        <Grid item md={12}
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.accordionHeader}`}>
            <Typography>
              Delivery Information
            </Typography>
          </Grid>
          <Grid style={{ width: "50%" }}>
            <Typography>
              Agreement #
            </Typography>
          </Grid>
          <Grid style={{ width: "50%" }}>
            <Typography>
              Customer Name
            </Typography>
          </Grid>
        </Grid>

        <Grid
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }}>
            <a
              onClick={() => { pilotStoreParamKey == '1' ? history.push({ pathname: `/agreement/info/details/${currentappointmentDetails?.customerInfo?.customerId}/${currentappointmentDetails?.agreement?.agreementId}` }) : null }}
              className={pilotStoreParamKey == '1' ? classes.customerRaclink : classes.customerRaclinkAcima}
            >
              {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
            </a>
          </Grid>
          <Grid style={{ width: "50%" }}>
            <a
              onClick={() => { pilotStoreParamKey == '1' ? history.push({ pathname: `/customer/update/${currentappointmentDetails?.customerInfo?.customerId}/customer` }) : null }}
              className={pilotStoreParamKey == '1' ? classes.customerRaclink : classes.customerRaclinkAcima}
            >
              {currentappointmentDetails?.customerInfo?.firstName + ` ` + currentappointmentDetails?.customerInfo?.lastName}
            </a>
          </Grid>
        </Grid>

        <Grid item md={12}
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }}>
            <Typography>
              Customer Address
            </Typography>
          </Grid>
          <Grid style={{ width: "50%" }}>
            <Typography>
              Phone #
            </Typography>
          </Grid>
        </Grid>

        <Grid
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }} className={classes.customerRaclink}>
            <a
              onClick={() => { { currentappointmentDetails?.customerInfo?.address?.addressLine1 ? handleAddressClick() : null } }}
            >
              {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.address?.addressLine1 || '') + ' ' + currentappointmentDetails?.customerInfo?.address?.addressLine2 || ''}
              {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.address?.city || '')}
            </a>
          </Grid>
          <Grid style={{ width: "50%" }} className={classes.customerRaclink}>
            <a
              onClick={() => { { currentappointmentDetails?.customerInfo?.phoneNumber ? handlePhoneNumberClick() : null } }}
            >
              {currentappointmentDetails && (currentappointmentDetails?.customerInfo?.phoneNumber ? formatNum(currentappointmentDetails?.customerInfo?.phoneNumber) : '')}
            </a>
          </Grid>
        </Grid>
        <Grid style={{ margin: "10px", width: "100%" }}>
          <div style={{ width: '100%', float: 'left' }}>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.accordionHeader}`}>
                <Typography>
                  Survey Form
                </Typography>
              </Grid>
              <Grid className={`${customerSurveyClasses.colMD12} ${customerSurveyClasses.floatStart}`}>
                <Typography className={`${customerSurveyClasses.w100} ${customerSurveyClasses.headerLabel}`}>Questions</Typography>
                <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}>1. Are you 100% satisfied with the product and delivery? ( if not, solve immediately)<span style={{ "color": "red" }}> *</span></Typography>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    id="inlineRadio1"
                    defaultValue=''
                    value="yes"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    onClick={(e: any) => {
                      setSurveyDetails({ ...surveyDetails, productDeliveryQn: e.target.value })
                    }}
                    checked={surveyDetails.productDeliveryQn === "yes"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>Yes</Typography>
                </Grid>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    defaultValue=''
                    id="inlineRadio2"
                    value="no"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    onClick={(e: any) => {
                      setSurveyDetails({ ...surveyDetails, productDeliveryQn: e.target.value })
                    }}
                    checked={surveyDetails.productDeliveryQn === "no"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>No</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Grid className={`${customerSurveyClasses.colMD12} ${customerSurveyClasses.floatStart}`}>
                <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}>2. Have we answered all of your questions regarding the rental agreement?<span style={{ "color": "red" }}> *</span></Typography>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    defaultValue=''
                    value="yes"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    onClick={(e: any) => {
                      setSurveyDetails({ ...surveyDetails, rentalAgrQn: e.target.value })
                    }}
                    checked={surveyDetails.rentalAgrQn === "yes"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>Yes</Typography>
                </Grid>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    defaultValue=''
                    value="no"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    onClick={(e: any) => {
                      setSurveyDetails({ ...surveyDetails, rentalAgrQn: e.target.value })
                    }}
                    checked={surveyDetails.rentalAgrQn === "no"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>No</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Grid className={`${customerSurveyClasses.colMD12} ${customerSurveyClasses.floatStart}`}>
                <Typography className={`${customerSurveyClasses.w100} ${customerSurveyClasses.headerLabel}`}>Reinforcements</Typography>
                <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}> 1. Timely Renewal Payments</Typography>
                <Grid className={`${customerSurveyClasses.reinforcementGrid}`}>
                  <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                    <RACCheckBox
                      checked={surveyDetails.renewalDateAndAmtQn}
                      size={'small'}
                      disabled={surveyDetails.readOnlyMode}
                      onClick={SetSurveyRenewalDateAndAmtQn}
                    />
                    <Typography className={customerSurveyClasses.checkboxLabel}>1. Can you tell me when your next renewal is due and the amount you will pay?</Typography>
                  </Grid>
                  <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                    <RACCheckBox
                      checked={surveyDetails.autoEnrollQn}
                      size={'small'}
                      disabled={surveyDetails.readOnlyMode}
                      onClick={SetSurveyAutoEnrollQn}
                    />
                    <Typography className={customerSurveyClasses.checkboxLabel}>2. AutoPay enrollment? If No explain to them the benefits & close the deal.</Typography>
                  </Grid>
                  <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                    <RACCheckBox
                      checked={surveyDetails.timelyPayments}
                      size={'small'}
                      disabled={surveyDetails.readOnlyMode}
                      onClick={SetSurveyTimelyPayments}

                    />
                    <Typography className={customerSurveyClasses.checkboxLabel}>3. Reinforce the importance of timely payments and to contact the store <span style={{ color: '#2179FE', fontFamily: 'OpenSans-bold' }}>BEFORE payment is due </span> should you have any issue </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Grid className={`${customerSurveyClasses.colMD12} ${customerSurveyClasses.floatStart}`}>
                <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}>2. Do you have any other questions or is there anything I can do for you?</Typography>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    defaultValue=''
                    value="yes"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    onClick={(e: any) => {
                      setSurveyDetails({ ...surveyDetails, anyOtherQns: e.target.value })
                    }}
                    checked={surveyDetails.anyOtherQns === "yes"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>Yes</Typography>
                </Grid>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    defaultValue=''
                    value="no"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    onClick={(e: any) => {
                      setSurveyDetails({ ...surveyDetails, anyOtherQns: e.target.value })
                    }}
                    checked={surveyDetails.anyOtherQns === "no"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>No</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Typography className={`${customerSurveyClasses.headerLabel} ${customerSurveyClasses.w100}`}>Notes
              </Typography>
              <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}>Additional Comments
              </Typography>
              <RACTextbox
                maxlength={500}
                placeHolder="Enter the additional comments if you have anything"
                value={surveyDetails.additionalNotes}
                disabled={surveyDetails.readOnlyMode}
                OnChange={(e: any) => {
                  setSurveyDetails({ ...surveyDetails, additionalNotes: e.target.value })
                }}
              />
            </Grid>
            <Grid
              item
              md={12}
              className={`${classes.pb4} ${classes.px3} ${classes.textRight} ${customerSurveyClasses.buttonsContent}`}
              style={{ marginTop: '100px' }}
            >
              <RACButton
                color="primary"
                variant="outlined"
                className={classes.mx1}
                onClick={() => cancelSurvey()}
              >
                Cancel
              </RACButton>
              {surveyDetails.readOnlyMode ? null :
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.mx1}
                  disabled={SurveySave}
                  onClick={() => saveSurveyDetails(surveyDetails)}
                >
                  Save
                </RACButton>}
            </Grid>
          </div>
        </Grid>
      </Grid >
    )
  }
  const cancelSurvey = async () => {
    setToShowCustomerSurveyForm(false);
    setloader(true)
    await pageLoad(filterStateData?.date)
    setloader(false)
  }

  const saveSurveyDetails = async (surveyDetails: any) => {
    try {
      setloader(true);
      currentappointmentDetails
      const currentTime = new Date();
      let userIdValue = '';
      if (!EmpolyeeId) {
        const coWorkerDetailsObj: any = await getmenuandstore();
        userIdValue = coWorkerDetailsObj?.coworkerProfile.employeeId;
        setEmpolyeeId(userIdValue)
      }
      const bodyobj = {
        agreementId: currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId.toString(),
        managerUserId: EmpolyeeId ? EmpolyeeId : userIdValue,
        surveyDate: currentTime,
        notes: surveyDetails.additionalNotes,
        reinforcements:
        {
          autopayDiscussed: surveyDetails.autoEnrollQn ? "1" : "0",
          renewalDiscussed: surveyDetails.renewalDateAndAmtQn ? "1" : "0",
          paymentsDiscussed: surveyDetails.timelyPayments ? "1" : "0"
        }
        ,
        questionsAsked: {
          deliveryOnTime: surveyDetails.productDeliveryQn === "yes" ? "1" : "0",
          questionsAnswered: surveyDetails.rentalAgrQn === "yes" ? "1" : "0",
          otherQuestions: surveyDetails.anyOtherQns === "yes" ? "1" : "0",
        },


      };
      const response = await saveSurveyForm(bodyobj);
      if (response?.status == 200) {
        setToShowSurvey(false);
        setReadOnlySurvey(true);
        setOpenSurveySaveSuccessPopup(true);


        // now we log the activity 
        await logWorkedHistory({
          storeNumber: sessionStorage.getItem('storeNumber') || '',
          customerId: Number(currentappointmentDetails?.customerInfo?.customerId),
          agreementId: currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId.toString(),
          acctActivityRefCode: 'CUSTSURV',
          notes: 'Completed Survey',
          coWorkerId: EmpolyeeId ? EmpolyeeId : userIdValue,
          activityDate: new Date().toISOString(),
        });


      }
      else {
        setSurveyError(true);
      }
      setloader(false);
    } catch (e: any) {
      setloader(false)
      setSurveyError(true);
      setModals({ ...modals, apiErrorInfoMsg: true })
    }

  }
  const SurveySavedSuccess = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Successimage></Successimage>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Feedback Saved Successfully
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={SurveySavedOk}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const SurveryErrorPopup = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Alert></Alert>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Customer Survey is not saved
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => { setSurveyError(false) }}
          >
            OK
          </RACButton>
        </Grid>
      </div >
    );
  };
  function HandleRePrintModalCard() {
    return (
      <RACModalCard
        isOpen={reprintPopUp}
        maxWidth="xs"
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={RePrintPopup()}
        title="Re-Print Pickup Checklist"
        TitleVariantClassName={classes.popUpFont}
      />
    )
  }
  const StoreEventModal = () => {
    return (
      <>
        <Grid style={{ width: '100%' }}>
          <Grid about="body" style={{ width: '100%' }}>
            <Grid about="content" style={{ height: 'auto', marginBottom: '10px' }}>
              <Grid style={{ width: '100%', display: 'flex' }}>
                <Grid style={{ width: '50%' }}>
                  <Grid style={{ margin: '10px' }}>
                    <Typography variant="h5">Title</Typography>
                    <span>{currentappointmentDetails?.appointmentInfo?.title || ''}</span>
                  </Grid>
                </Grid>
                <Grid style={{ width: '50%' }}>
                  <Grid style={{ margin: '10px' }}>
                    <Typography variant="h5">Description</Typography>
                    <span>{currentappointmentDetails?.appointmentInfo?.instructions || ''}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ width: '100%', display: 'flex' }}>
                <Grid style={{ width: '50%' }}>
                  <Grid style={{ margin: '10px' }}>

                    <Typography variant="h5">Coworker required</Typography>
                    <span>{currentappointmentDetails?.appointmentInfo?.requiredCoworkers || '-'}</span>
                  </Grid>
                </Grid>

                {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus == 'CMP' ?
                  <Grid style={{ width: '50%' }}>
                    <Grid style={{ margin: '10px' }}>

                      <Typography variant="h5">Status</Typography>
                      <span>Completed</span>
                    </Grid>
                  </Grid> : null}
              </Grid>
            </Grid>
            {/* {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus !== 'CMP' ?  */}


            <Grid about="footer" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              {currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus == 'CAN' ? <RACButton
                variant="contained"
                color="primary"
                onClick={() => setModals({ ...modals, reopenConfirmModal: true })}
                style={{ marginLeft: '20px' }}
              >
                Reopen Event
              </RACButton> : currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentStatus != 'CMP' ? <><RACButton
                variant="outlined"
                className={classes.unassignBtnClass}
                onClick={() => cancelOrCompleteStoreEvent('Cancel')}
              >Cancel Event</RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => editStoreEventHandler()}
                  style={{ marginLeft: '20px' }}
                >
                  Edit Event
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => setModals({ ...modals, completeConfirmModal: true })}
                  style={{ marginLeft: '20px' }}
                >
                  Mark As Completed
                </RACButton> </> : null}
            </Grid>
            {/* : null} */}

          </Grid>
          <Grid style={{ width: '100%' }}>
            <Grid container style={{ width: '100%' }}>
              <Card
                className={`${customerStyleClasses.card} ${customerStyleClasses.w100}  ${customerStyleClasses.borderRadiusZero}`}
              >
                <CardContent
                  className={`${classes.headerTab} ${customerStyleClasses.floatLeft} ${customerStyleClasses.w100} ${classes.py0}`} style={{ padding: '0px' }}
                >
                  <Grid
                    item
                    className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.w100}`}
                  >
                    <Box className={customerStyleClasses.floatLeft}>
                      <List
                        className={`${classes.racpadTab} ${classes.py0}`}
                        id="CustTab"
                        role="tabList"
                      >
                        <ListItem
                          className={
                            activetab.notes
                              ? `${classes.navLinkActive} ${classes.navLinkItem}`
                              : `${classes.navLinkItem}`
                          }
                          id="employment-tab"
                          data-testid="employmentInfoTab"
                          data-bs-toggle="tab"
                          data-bs-target="#employmentinfo"
                          role="tab"
                          aria-controls="employment-tab"
                          aria-selected="false"
                          onClick={() => setactivetab({ ...activetab, notes: true, history: false })}

                        >
                          Notes
                        </ListItem>
                        <ListItem
                          className={
                            activetab.history
                              ? `${classes.navLinkActive} ${classes.navLinkItem}`
                              : `${classes.navLinkItem}`
                          }
                          id="Customerinfo-tab"
                          data-testid="customerInfoTab"
                          data-bs-toggle="tab"
                          data-bs-target="#customerinfo"
                          role="tab"
                          aria-controls="Customerinfo-tab"
                          aria-selected="true"
                          onClick={() => setactivetab({ ...activetab, notes: false, history: true })}                                            >
                          History
                        </ListItem>
                      </List>
                    </Box>

                  </Grid>
                  {updatingNotesLoader ? <div className={classes.Norecords}>
                    <CircularProgress />
                  </div> : null}
                  {activetab.notes && !updatingNotesLoader ? <Grid about="notesContent" style={{ width: '100%', minHeight: '150px', marginTop: '40px' }}>
                    {currentappointmentDetails && currentappointmentDetails.notes && currentappointmentDetails.notes.map((el: any) => {
                      return (<Grid about="notesHeader" key={el.appointmentNoteId} style={{ padding: '1rem', backgroundColor: 'aliceblue', marginBottom: '10px' }}>
                        <Grid about="messageDetails" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography style={{ display: 'inline-block' }}>{el.lastModifiedBy}</Typography>
                          <span>{moment(el.lastModifiedDate).format('MM/DD/YYYY')} {moment(el.lastModifiedDate, 'HH:mm:ss').format(
                            'hh:mm A'
                          )}</span>
                        </Grid>
                        <Grid about="message" style={{ marginTop: '5px' }}>
                          <span style={{ fontWeight: '500' }}>{el.noteContent}</span>
                        </Grid>
                      </Grid>)
                    })}
                    <Grid about="notesFooter" style={{ width: '100%', padding: '1rem' }}>
                      <Typography>Write a new Note</Typography>
                      <Grid about="textFeilds" className={classes.storeEventNots}>
                        <RACTextbox
                          value={storeEventNotes}
                          OnChange={(e: any) => {
                            setStoreEventNotes(e.target.value);
                          }}
                          placeHolder={'Enter the text here'}
                          className={`${classes.searchSpacing} ${classes.w100} ${classes.mr1}`}
                        />
                        <RACButton
                          type="button"
                          className={`${classes.ms2} ${classes.todayButton}`}
                          color="primary"
                          disabled={storeEventNotes && storeEventNotes.trim().length !== 0 ? false : true}
                          style={{ height: '36px', margin: '0px' }}
                          variant="contained"
                          onClick={() => updateNotesHandler()}
                        >
                          Add
                        </RACButton>
                      </Grid>

                    </Grid>
                  </Grid> : null}
                  {activetab.history && !updatingNotesLoader ? <Grid>
                    <RACTable
                      renderTableHead={renderAppointmentTableHistoryHeader}
                      renderTableContent={renderAppointmentTableHistoryContent}
                    />
                  </Grid> : null}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

      </>
    )
  }
  const RePrintPopup = () => {
    return (
      <div>
        <Grid container spacing={2} className={classes.p3}>
          <Grid item md={12}>
            {/* <Images className={classes.me2}></Images> */}
            <Typography variant="caption" className={classes.formLabel}>
              {/* {isReturnStart || returnReschedule */}
              {/* ?  */}
              Pickup Receipt
              {/* :
                       'Delivery Receipt' */}
              {/* } */}
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() => {
                //   isReturnStart || returnReschedule
                // ?
                printHTML(document.getElementById('pickupreciptPrintHTML'))
                // : printHTML(
                //     document.getElementById('DeliveryReceiptrentalPrintHTML')
                //   );
              }}
            >
              View
            </RACButton>
          </Grid>
          <Grid item md={12}>
            {/* <PDFImage className={classes.me2}></PDFImage> */}
            <Typography variant="caption" className={classes.formLabel}>
              {/* {isReturnStart || returnReschedule
                      ?  */}
              Pickup Checklist
              {/* :
                       'Delivery Checklist'} */}
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                //   isReturnStart || returnReschedule
                //     ? 
                printHTML(
                  document.getElementById('pickupchecklistprintHTML')
                )
                // : 
                // printHTML(
                //     document.getElementById('DeliveryChecklistrentalPdf')
                //   )
              }
            >
              View
            </RACButton>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4pp} ${classes.px3pp} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1pp}
            variant="contained"
            color="primary"
            onClick={() => Ok_Popup()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  }
  const handleRePrintDelivery = async () => {
    debugger
    //for showing the reprint popup.git
    setEnableDeliveryPopup(false)
    if (currentappointmentDetails.appointmentInfo.appointmentType == 'P' || currentappointmentDetails.appointmentInfo.appointmentType == 'CI') {
      await agreementsignedmethod('3');

    }
    else {
      await agreementsignedmethod();

    }
    // setReprintPopUp(true);
  }
  const UpdateInfoMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography
            variant={'body2'}
            className={`${classes.my3} ${classes.RACPOPMsg}`}
          >
            Something went wrong
          </Typography>
        </Grid>
        <Grid
          item
          className={`${classes.textCenter} ${classes.mt4} ${classes.w100}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => setModals({ ...modals, apiErrorInfoMsg: false })}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const getFontBackgroundColour = (appointmentStatus: any, appointmentEndTime: any) => {
    const currentTime = new Date();
    const targetDateTime = new Date(appointmentEndTime);
    const isCurrentTimeGreaterThanTarget = currentTime > targetDateTime;
    if (appointmentStatus == 'CAN') {
      return {
        fontColor: '#5a5a5a',
        backgroundColor: '#f6f6f6'
      }
    }
    if (appointmentStatus == 'CMP') {
      return {
        fontColor: '#539b4d',
        backgroundColor: '#f7fff7'
      }
    }
    if (isCurrentTimeGreaterThanTarget) {
      return {
        fontColor: '#cc1f1f',
        backgroundColor: '#fff1ef'
      }
    }
    if (!isCurrentTimeGreaterThanTarget) {
      return {
        fontColor: '#2e31be',
        backgroundColor: '#f4f7ff'
      }
    }
  }

  const getBatchColorByappointmentType = (appointmentType: any) => {
    if (appointmentType == 'CI' || appointmentType == 'CO') {
      return '#26c0c7'
    }
    if (appointmentType == 'D') {
      return '#ff77ad'
    }
    if (appointmentType == 'P') {
      return '#ff77ad'
    }
    if (appointmentType == 'S' || appointmentType == 'SCI' || appointmentType == 'SCO' || appointmentType == 'SD' || appointmentType == 'SP') {
      return '#ffcb7b'
    }
    if (appointmentType == 'SO') {
      return '#5DBB6E'
    }
    if (appointmentType == 'SE') {
      return '#ff77ad'
    }
    if (appointmentType == 'STOPIC') {
      return '#9933ff'
    }
    if (appointmentType == 'FIELD') {
      return '#96daeb'
    }
    return '#26c0c7'
  }
  const generatereceippdf = async (generatereceiptpayload: any) => {
    console.log('view payload', generatereceiptpayload);

    const receiptresponse = await GenerateReceipt(generatereceiptpayload);

    if (receiptresponse.data !== undefined && receiptresponse.data !== null) {
      // setpdfresponse(receiptresponse.data);
      console.log('delievry checklist', receiptresponse.data);
    }
  };
  const generateReceiptFn = async (generatereceiptpayload: any) => {
    setloader(true)
    debugger
    console.log("view payload", generatereceiptpayload);
    let deliveryReceiptElement: any
    let deliveryChecklistElement: any
    let deliveryaddendumElement: any
    let pickupReceiptElement: any
    let pickChecklistElement: any
    const serviceTypes: any = ['SCI', 'SP', 'S']
    // Rental document Delivery Receipt and delivery checklist
    if (!generatereceiptpayload?.action && !serviceTypes.includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
      deliveryReceiptElement = document.getElementById(
        'DeliveryReceiptrentalPrintHTML'
      );
      deliveryChecklistElement = document.getElementById(
        'DeliveryChecklistrentalPdf'
      );
    }
    // Return Documnet Pick receipt and Pick check list
    else if (generatereceiptpayload?.action == 3 || serviceTypes.includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
      deliveryReceiptElement = document.getElementById(
        'pickupreciptPrintHTML'
      );
      deliveryChecklistElement = document.getElementById(
        'pickupchecklistprintHTML'
      );
    }
    // customer order agreement 
    else if (generatereceiptpayload?.action == 2) {
      deliveryReceiptElement = document.getElementById(
        'DeliveryReceiptrentalPrintHTML'
      );
      deliveryChecklistElement = document.getElementById(
        'DeliveryChecklistrentalPdf'
      );
      deliveryaddendumElement = document.getElementById(
        'deliveryaddendum'
      );

    }
    //  Switch out agreement
    else if (generatereceiptpayload?.action == 1) {
      deliveryReceiptElement = document.getElementById(
        'DeliveryReceiptPrintHTML'
      );
      deliveryChecklistElement = document.getElementById(
        'DeliveryChecklistPdf'
      );
      pickupReceiptElement = document.getElementById(
        'pickupreciptPrintHTML'
      );
      pickChecklistElement = document.getElementById(
        'pickupchecklistprintHTML'
      );
    }

    if (!generatereceiptpayload?.action || generatereceiptpayload?.action == 3) {
      html2pdf()
        .from(deliveryReceiptElement)
        .outputPdf('blob')
        .then((pdfBlob) => {
          const DRreader: any = new FileReader();
          console.log('reader deliveryReceipt', DRreader);
          DRreader.onloadend = async () => {
            console.log('after Reader deliveryReceipt', DRreader);
            const b64DeliveryReceipt = DRreader?.result.split(',')[1];
            //delivery check list flow
            html2pdf()
              .from(deliveryChecklistElement)
              .outputPdf('blob')
              .then((pdfBlob) => {
                const CLreader: any = new FileReader();
                console.log('reader deliveryChecklist', CLreader);
                CLreader.onloadend = async () => {
                  console.log('after Reader deliveryReceipt', CLreader);
                  const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                  makeUploadDocument(b64DeliveryReceipt, b64DeliveryCheckList, generatereceiptpayload?.action);
                }
                CLreader.readAsDataURL(pdfBlob);
              });
            //end of check list flow
          };
          DRreader.readAsDataURL(pdfBlob);
        });
    }
    else if (generatereceiptpayload?.action == 1) {
      html2pdf()
        .from(deliveryReceiptElement)
        .outputPdf('blob')
        .then((pdfBlob) => {
          const DRreader: any = new FileReader();
          console.log('reader deliveryReceipt', DRreader);
          DRreader.onloadend = async () => {
            console.log('after Reader deliveryReceipt', DRreader);
            const b64DeliveryReceipt = DRreader?.result.split(',')[1];
            //delivery check list flow
            html2pdf()
              .from(deliveryChecklistElement)
              .outputPdf('blob')
              .then((pdfBlob) => {
                const CLreader: any = new FileReader();
                console.log('reader deliveryChecklist', CLreader);
                CLreader.onloadend = async () => {
                  console.log('after Reader deliveryReceipt', CLreader);
                  const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                  html2pdf()
                    .from(pickupReceiptElement)
                    .outputPdf('blob')
                    .then((pdfBlob) => {
                      const PRreader: any = new FileReader();
                      console.log('reader deliveryReceipt', PRreader);
                      PRreader.onloadend = async () => {
                        console.log('after Reader deliveryReceipt', PRreader);
                        const b64PickUpReceipt = PRreader?.result.split(',')[1];
                        html2pdf()
                          .from(pickChecklistElement)
                          .outputPdf('blob')
                          .then((pdfBlob) => {
                            const PCreader: any = new FileReader();
                            console.log('reader deliveryReceipt', PCreader);
                            PCreader.onloadend = async () => {
                              console.log('after Reader deliveryReceipt', PCreader);
                              const b64PickupCheckList = PCreader?.result.split(',')[1];
                              makeUploadDocument(b64DeliveryReceipt, b64DeliveryCheckList, 1, '', b64PickUpReceipt, b64PickupCheckList);
                            };
                            PCreader.readAsDataURL(pdfBlob);
                            // 
                          });

                      };
                      PRreader.readAsDataURL(pdfBlob);
                      // 
                    });
                }
                CLreader.readAsDataURL(pdfBlob);
              });
            //end of check list flow
          };
          DRreader.readAsDataURL(pdfBlob);
        });
    }
    else {
      html2pdf()
        .from(deliveryReceiptElement)
        .outputPdf('blob')
        .then((pdfBlob) => {
          const DRreader: any = new FileReader();
          console.log('reader deliveryReceipt', DRreader);
          DRreader.onloadend = async () => {
            console.log('after Reader deliveryReceipt', DRreader);
            const b64DeliveryReceipt = DRreader?.result.split(',')[1];
            //delivery check list flow
            html2pdf()
              .from(deliveryChecklistElement)
              .outputPdf('blob')
              .then((pdfBlob) => {
                const CLreader: any = new FileReader();
                console.log('reader deliveryChecklist', CLreader);
                CLreader.onloadend = async () => {
                  console.log('after Reader deliveryReceipt', CLreader);
                  const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                  html2pdf()
                    .from(deliveryaddendumElement)
                    .outputPdf('blob')
                    .then((pdfBlob) => {
                      const DAreader: any = new FileReader();
                      console.log('reader deliveryReceipt', DAreader);
                      DAreader.onloadend = async () => {
                        console.log('after Reader deliveryReceipt', DAreader);
                        const b64DeliveryAddendumReceipt = DAreader?.result.split(',')[1];
                        makeUploadDocument(b64DeliveryReceipt, b64DeliveryCheckList, generatereceiptpayload?.action, b64DeliveryAddendumReceipt);
                      };
                      DAreader.readAsDataURL(pdfBlob);
                      // 
                    });
                }
                CLreader.readAsDataURL(pdfBlob);
              });
            //end of check list flow
          };
          DRreader.readAsDataURL(pdfBlob);
        });
    }



  };
  const makeUploadDocument = async (
    deliveryReceiptFile?: any,
    deliveryCheckListFile?: any,
    type?: any,
    deliveryAddendumFile?: any,
    pickupReceiptFile?: any,
    pickupChecklistFile?
  ) => {
    debugger;
    setloader(true)
    let uploadDeliveryReceiptPayload: any
    const serviceTypes: any = ['SCI', 'SP', 'S']

    if (!type && !serviceTypes.includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
      uploadDeliveryReceiptPayload = {
        agreementId: Number(currentappointmentDetails?.agreement?.agreementId),
        documents: [
          {
            documentType: 'DLRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'DCL',
            file: deliveryCheckListFile,
          },
        ],
      };
    }
    else if (type == 3 || serviceTypes.includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
      uploadDeliveryReceiptPayload = {
        action: 3,
        agreementId: Number(currentappointmentDetails?.agreement?.agreementId),
        documents: [
          {
            documentType: 'PKRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'PCL',
            file: deliveryCheckListFile,
          },
        ],
      };
    }
    else if (type == 2) {
      uploadDeliveryReceiptPayload = {
        action: 2,
        agreementId: Number(currentappointmentDetails?.agreement?.agreementId),
        documents: [
          {
            documentType: 'DLRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'DCL',
            file: deliveryCheckListFile,
          },
          {
            documentType: 'DELAD',
            file: deliveryAddendumFile,
          },
        ],
      };
    }
    else if (type == 1) {
      uploadDeliveryReceiptPayload = {
        action: 1,
        agreementId: Number(currentappointmentDetails?.agreement?.agreementId),
        documents: [
          {
            documentType: 'DLRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'DCL',
            file: deliveryCheckListFile,
          },
          {
            documentType: 'PKRCT',
            file: pickupReceiptFile,
          },
          {
            documentType: 'PCL',
            file: pickupChecklistFile,
          },
        ],
      };
    }
    const uploadDeliveryReceiptResponse = await GenerateReceipt(
      uploadDeliveryReceiptPayload
    )

    setloader(false)
    debugger
    if (uploadDeliveryReceiptResponse?.status == 200) {
      let i = 0;
      for (i = 0; i < uploadDeliveryReceiptResponse?.data?.documents?.length; i++) {
        if (uploadDeliveryReceiptResponse?.data?.documents[i]?.documentName == "Delivery Receipt" || uploadDeliveryReceiptResponse?.data?.documents[i]?.documentName == "Pickup Receipt") {
          setUpdateRecieptKey(uploadDeliveryReceiptResponse?.data?.documents[i]?.documentId)
          break
        }
      }
      if (isCustomerorderenabled === true) {
        setopenCOprintpopup(true);
      } else if (currentappointmentDetails && (currentappointmentDetails.appointmentInfo.appointmentType === 'SO' || currentappointmentDetails.isSwitchoutEnabled == '1')) {
        setprintswitchoutpopup(true);
      } else {
        // currentappointmentDetails
        SetOpenprintpopup(true);
      }
    } else if (uploadDeliveryReceiptResponse?.status == 400) {
      setServiceErrorMsg(uploadDeliveryReceiptResponse?.data?.errors[0]?.error);
      seterrorMessagePopupOpen(true);
    } else {

      setloader(false);
      setModals({ ...modals, apiErrorInfoMsg: true })
    }
  };
  const agreementsignedmethod = async (actionUpdate?: string) => {
    debugger
    try {
      currentappointmentDetails
      const bodyobj = {
        agreementIdCustomerId: [
          {
            agreementId: parseInt(currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId),
          },
        ],
      };
      let getamountresponse: any = {
        getamountdata: {},
      };

      let deliveryreceiptresponse: any = {
        deliverydata: {},
      };
      setloader(true);
      const apiCallStack = [getnextpaymentamount(bodyobj)]
      if (currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentType == 'SO' && !isCustomerorderenabled) {
        apiCallStack.push(LoadswitchoutDeliveryreceipt(currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId))
      } else {
        apiCallStack.push(LoadDeliveryreceipt(currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId))
      }
      await Promise.all(apiCallStack).then((resp) => {
        [getamountresponse, deliveryreceiptresponse] = resp;
        const deliveryreceiptres = deliveryreceiptresponse
        console.log('asynchronous api calls', getamountresponse);
        console.log(
          'asynchronous api calls for reprint',
          deliveryreceiptresponse
        );
        console.log('responseData', deliveryreceiptresponse.responseData);
        console.log(
          'amountdueresponse',
          getamountresponse.data.amountDueResponse
        );
        deliveryreceiptresponse = deliveryreceiptresponse.data || []
        if (
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          getamountresponse.status == 200

        ) {
          // setloader(false);

          const resUniqueValue: any = deliveryreceiptresponse.responseData.filter(
            (obj: any, index: any) => {
              return (
                index ===
                deliveryreceiptresponse.responseData.findIndex(
                  (o: any) => obj.inventoryId === o.inventoryId
                )
              );
            }
          );
          setitemdata(resUniqueValue);

          console.log('view response', deliveryreceiptresponse);
          // if (isReturnStart || returnReschedule) {
          //     setinventorynumber(
          //         deliveryreceiptresponse.responseData[0].inventoryNumber
          //     );
          //     setinventorydesc(
          //         deliveryreceiptresponse.responseData[0].inventoryDesc
          //     );
          //     setinventoryserialnum(
          //         deliveryreceiptresponse.responseData[0].serialNumber
          //     );
          // }
          Setstorename(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeName
              : ''
          );

          // SetstoreNumber(
          //     deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //         ? deliveryreceiptresponse.responseData[0].storeNumber
          //         : ''
          // );
          // Setstorestate(
          //     deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //         ? deliveryreceiptresponse.responseData[0].storeState
          //         : ''
          // );
          Setstorezip(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeZip
              : ''
          );

          Setstorecity(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeCity
              : ''
          );
          SetStorephonenum(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storePhoneNumber
              : ''
          );
          SetStoreAddressline1(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeAddressLine1
              : ''
          );
          SetStoreAdressline2(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeAddressLine2
              : ''
          );
          SetCustomerName(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerName
              : ''
          );
          SetCustomerAddressline1(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].addressLine1
              : ''
          );
          SetCustomerAddressline2(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].addressLine2
              : ''
          );
          Setcustomercity(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerCity
              : ''
          );
          SetCustomerstate(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerState
              : ''
          );

          Setcustomerzip(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerZip
              : ''
          );

          Setcustomerplus4(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerPlus4
              : ''
          );
          SetCustomerPhoneNumber(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
              : ''
          );
          if (
            getamountresponse !== undefined &&
            getamountresponse !== null &&
            getamountresponse.data.amountDueResponse !== undefined &&
            getamountresponse.data.amountDueResponse !== null
          ) {
            const AmountResponse = getamountresponse.data.amountDueResponse.find((x: { agreementId: string }) => x.agreementId == currentappointmentDetails?.agreement?.agreementId);
            console.log('agreementid related data', AmountResponse);

            if (
              AmountResponse !== undefined &&
              AmountResponse !== null &&
              AmountResponse !== '' &&
              AmountResponse.ldwAmount !== undefined &&
              AmountResponse.ldwAmount !== null
            ) {
              const Totalamountdue =
                parseFloat(AmountResponse.amountDue) +
                parseFloat(AmountResponse.ldwAmount);
              console.log('agreementid Total Amount', Totalamountdue);
              setnextpaymentinfo({
                ...nextpaymentinfo,
                nextamountdue: Totalamountdue.toFixed(2),
                nextduedate: AmountResponse.nextDueDate,
              });
              console.log('next amount due', nextpaymentinfo);
            } else {
              console.log('nextpaymentamount', getamountresponse);

              setnextpaymentinfo({
                ...nextpaymentinfo,
                nextamountdue:
                  getamountresponse.data.amountDueResponse[0].amountDue,
                nextduedate:
                  getamountresponse.data.amountDueResponse[0].nextDueDate,
              });
              console.log('next amount due', nextpaymentinfo);
              console.log(
                'getamountdue response',
                getamountresponse?.data?.amountDueResponse[0].amountDue
              );
            }

            const TempArr: any = [];
            if (
              deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined
            ) {
              for (
                let i = 0;
                i <= deliveryreceiptresponse.responseData.length - 1;
                i++
              ) {
                let obj: any;
                if (isCustomerorderenabled === true) {
                  obj = {
                    inventoryId:
                      deliveryreceiptresponse.responseData[i].inventoryId,
                    inventoryNumber:
                      deliveryreceiptresponse.responseData[i].inventoryNumber,
                    serialNumber:
                      deliveryreceiptresponse.responseData[i].serialNumber,
                    inventoryDesc:
                      deliveryreceiptresponse.responseData[i].inventoryDesc,
                    modelNumber:
                      deliveryreceiptresponse.responseData[i].modelNumber,
                  };
                } else {
                  obj = {
                    inventoryId:
                      deliveryreceiptresponse.responseData[i].inventoryId,
                    inventoryNumber:
                      deliveryreceiptresponse.responseData[i].inventoryNumber,
                    serialNumber:
                      deliveryreceiptresponse.responseData[i].serialNumber,
                    inventoryDesc:
                      deliveryreceiptresponse.responseData[i].inventoryDesc,
                  };
                }
                TempArr.push(obj);
              }
            }
            let generatereceiptpayload: any;
            if (isCustomerorderenabled === true) {
              generatereceiptpayload = {
                action: 2,
                inventorydescription: TempArr,
                customerName:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].customerName
                    : '',
                addressLine1:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].addressLine1
                    : '',
                addressLine2:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0 &&
                    deliveryreceiptresponse.responseData[0].addressLine2 !== null &&
                    deliveryreceiptresponse.responseData[0].addressLine2 !== ''
                    ? deliveryreceiptresponse.responseData[0].addressLine2
                    : '',
                customerCity:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].customerCity
                    : '',
                customerZip:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0 &&
                    deliveryreceiptresponse.responseData[0].customerZip !== null &&
                    deliveryreceiptresponse.responseData[0].customerPlus4 !== null
                    ? deliveryreceiptresponse.responseData[0].customerZip +
                    '-' +
                    deliveryreceiptresponse.responseData[0].customerPlus4
                    : deliveryreceiptresponse.responseData[0].customerZip,
                customerState:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].customerState
                    : '',

                storeName:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeName
                    : '',
                storeNumber:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeNumber
                    : '',
                storeAddressLine1:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeAddressLine1
                    : '',
                storeAddressLine2:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0 &&
                    deliveryreceiptresponse.responseData[0].storeAddressLine2 !==
                    null &&
                    deliveryreceiptresponse.responseData[0].storeAddressLine2 !== ''
                    ? deliveryreceiptresponse.responseData[0].storeAddressLine2
                    : '',
                storeCity:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeCity
                    : '',
                storeState:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeState
                    : '',
                storeZip:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeZip
                    : '',
                deliveryDate: DeliveryDate,
                agreementNumber: currentappointmentDetails & currentappointmentDetails.agreement.agreementNumber,
              };
            }
            else if (
              currentappointmentDetails.isSwitchoutEnabled == '1'
            ) {
              generatereceiptpayload = {
                action: 1
              }
            }
            else {
              generatereceiptpayload = {
                ...(actionUpdate == '3' && {
                  action: 3
                }),
                inventorydescription: TempArr,
                customerName:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].customerName
                    : '',
                addressLine1:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].addressLine1
                    : '',
                addressLine2:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0 &&
                    deliveryreceiptresponse.responseData[0].addressLine2 !== null &&
                    deliveryreceiptresponse.responseData[0].addressLine2 !== ''
                    ? deliveryreceiptresponse.responseData[0].addressLine2
                    : '',
                customerCity:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].customerCity
                    : '',
                customerZip:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0 &&
                    deliveryreceiptresponse.responseData[0].customerZip !== null &&
                    deliveryreceiptresponse.responseData[0].customerPlus4 !== null
                    ? deliveryreceiptresponse.responseData[0].customerZip +
                    '-' +
                    deliveryreceiptresponse.responseData[0].customerPlus4
                    : deliveryreceiptresponse.responseData[0].customerZip,
                customerState:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].customerState
                    : '',
                customerPhoneNumber:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
                    : '',

                storeName:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeName
                    : '',
                storeNumber:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeNumber
                    : '',
                storeAddressLine1:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeAddressLine1
                    : '',
                storeAddressLine2:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0 &&
                    deliveryreceiptresponse.responseData[0].storeAddressLine2 !==
                    null &&
                    deliveryreceiptresponse.responseData[0].storeAddressLine2 !== ''
                    ? deliveryreceiptresponse.responseData[0].storeAddressLine2
                    : '',
                storePhoneNumber:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storePhoneNumber
                    : '',
                storeCity:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeCity
                    : '',
                storeState:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeState
                    : '',
                storeZip:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].storeZip
                    : '',
                nexDueAmount:
                  AmountResponse.amountDue !== undefined &&
                    AmountResponse.amountDue !== null &&
                    AmountResponse.ldwAmount !== undefined &&
                    AmountResponse.ldwAmount !== null
                    ? (
                      parseFloat(AmountResponse.amountDue) +
                      parseFloat(AmountResponse.ldwAmount)
                    )

                      .toFixed(2)
                      .toString()
                    : getamountresponse.data.amountDueResponse[0].amountDue.toString(),

                deliveryDate: DeliveryDate,
                deliveryTime: DeliveryTime,
                agreementNumber: currentappointmentDetails && currentappointmentDetails.agreement.agreementNumber,
                agreementId: parseInt(currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId),
                customerId: currentappointmentDetails && currentappointmentDetails?.customerInfo?.customerId,
              };
            }
            setloader(true)
            generateReceiptFn(generatereceiptpayload);

          }
        }
        else if (deliveryreceiptres.status == 400) {
          setServiceErrorMsg(deliveryreceiptresponse?.errors[0]?.error);
          setloader(false);
          seterrorMessagePopupOpen(true);
        } else if (getamountresponse.status == 400) {
          setServiceErrorMsg(getamountresponse?.errors[0]?.error);
          setloader(false);
          seterrorMessagePopupOpen(true);
        } else {
          setloader(false);
          setModals({ ...modals, apiErrorInfoMsg: true })
        }

      });
    } catch (e: any) {
      setloader(false)
      setModals({ ...modals, apiErrorInfoMsg: true })
    }

  };
  const updateNotesHandler = async () => {

    try {
      setUpdatingNotesLoader(true)
      // setModals({ ...modals, storeEventDetailModal: false })
      const payload: any = {
        storeNumber: sessionStorage.getItem('storeNumber'),
        appointmentId: currentappointmentDetails.appointmentInfo.appointmentId,
        coworkerId: EmpolyeeId,
        note: storeEventNotes,
        // appointmentInfo: {
        //     appointmentType: 'SE',
        //     appointmentSource: 'STO',
        //     appointmentDate: filterStateData.date,
        //     instructions: Cadbury, formerly Cadbury's and Cadbury Schweppes,
        //     requiredCoworkers: 2,
        //     title: Cadburry
        // }
      }
      // setloader(true);
      const response = await manageAppointment(payload);
      if (response.status == 200) {
        setCurrentappointmentDetails({ ...currentappointmentDetails, notes: response.data.notes })
        // setModals({ ...modals, storeEventDetailModal: true })
      }
      setStoreEventNotes('')
      setUpdatingNotesLoader(false);
    } catch (e) {
      setUpdatingNotesLoader(false);
      setModals({ ...modals, apiErrorInfoMsg: true })
      console.log(e)
    }
  }

  //Function for Showing the Delivery popup.
  const handleEventDetails: any = async (popupVal: any) => {
    try {
      setloader(true)
      setToShowSurvey(false)
      if (eligibleRole && customerSurveyParamKey == '1' && (popupVal?.appointmentInfo?.appointmentType == 'D' ||
        popupVal?.appointmentInfo?.appointmentType == 'CI' ||
        popupVal?.appointmentInfo?.appointmentType == 'CO') && popupVal?.agreement?.agreementId) {
        getSurveyStatus(popupVal.agreement.agreementId)
      }
      setModals({ ...modals, timeSlotExceedModal: false })
      if (popupVal.appointmentInfo.appointmentType == 'AD') {
        setloader(true)
        setisManaul(false);
        setacimaManulaSign(false);
        setmanualSignature(false);
        setacimaEventValues({
          ...acimaEventValues, poNumber: popupVal?.appointmentInfo?.additionalDetails?.poNumber, addressLine1: popupVal?.appointmentInfo?.additionalDetails?.customerInfo?.addressLine1, addressLine2: popupVal?.appointmentInfo?.additionalDetails?.customerInfo?.addressLine2, state: popupVal?.appointmentInfo?.additionalDetails?.customerInfo?.state, city: popupVal?.appointmentInfo?.additionalDetails?.customerInfo?.city, zip: popupVal?.appointmentInfo?.additionalDetails?.customerInfo?.zip, phoneNumber: popupVal?.appointmentInfo?.additionalDetails?.customerInfo?.phoneNumber, firstName: popupVal?.appointmentInfo?.additionalDetails?.customerInfo?.firstName, lastName: popupVal?.appointmentInfo?.additionalDetails?.customerInfo?.lastName, itemInformation: popupVal?.appointmentInfo?.additionalDetails?.itemInformation, appointmentStatus: popupVal?.appointmentInfo?.appointmentStatus,
          appointmentId: popupVal?.appointmentInfo?.appointmentId,
          appiontmnetDate: popupVal?.appointmentInfo?.appointmentDate,
          appiontmnetTime: popupVal?.timeSlotInfo?.startTimeStamp,
          emailId: popupVal?.appointmentInfo?.additionalDetails?.customerInfo?.emailId
        })
        const response: any = await EventHistory({ appointmentId: popupVal?.appointmentInfo?.appointmentId })
        setloader(false)
        setacimaEventDetails(true)
        setAppointmentHistoryRecord(response?.data)
        return
      }
      if (popupVal.appointmentInfo.appointmentType == 'SE') {
        // setEnableDeliveryPopup(true)
        setloader(true);
        const apiCallStack: any = [EventHistory({ appointmentId: popupVal.appointmentInfo.appointmentId })]
        if (!EmpolyeeId) {
          apiCallStack.push(getmenuandstore())
        }
        const response: any = await Promise.all(apiCallStack)
        setAppointmentHistoryRecord(response[0].data)
        if (!EmpolyeeId) {
          setEmpolyeeId(response[1]?.coworkerProfile?.employeeId)
        }
        setloader(false);
        setModals({ ...modals, storeEventDetailModal: true })
        setCurrentappointmentDetails({ ...popupVal, isSwitchoutEnabled: '0' })
        return
      }

      // const agrInfoResponse = await getAgreementInfo(popupVal.agreement.agreementId);
      // const del = await getAgreementDelivery(popupVal.agreement.agreementId);


      // if ((appointmentHistoryRecord && appointmentHistoryRecord.appointmentId !== popupVal.appointmentInfo.appointmentId) || appointmentHistoryRecord.length == 0) {
      setloader(true);
      const apiCallStack: any = [getAgreementInfo(popupVal.agreement.agreementId), getAgreementDelivery(popupVal.agreement.agreementId, popupVal.appointmentInfo.appointmentId), EventHistory({ appointmentId: popupVal.appointmentInfo.appointmentId })]
      if (!EmpolyeeId) {
        apiCallStack.push(getmenuandstore())
      }
      const response: any = await Promise.all(apiCallStack)


      if (response && response.length > 0 && response[0].data && response[0].data.agreementWorkflow && response[0].data.agreementWorkflow.isSwitchoutEnabled == '1') {
        const switchInItem: any = response[0].data.agreementInventories.find((el: any) => el.isItemSwitchedIn == '1')
        setCurrentappointmentDetails({ ...popupVal, isSwitchoutEnabled: '1', switchInItem: switchInItem?.inventoryId })
        setagreementValues({ ...agreementValues, agreementSign: response[0]?.data?.agreementWorkflow?.isAgreementSigned, agreementNumber: response[0]?.data?.agreementWorkflow?.agreementNumber, agreementId: response[0]?.data?.agreementWorkflow?.agreementId })
      } else {
        setCurrentappointmentDetails({ ...popupVal, isSwitchoutEnabled: '0' })
        setagreementValues({ ...agreementValues, agreementSign: response[0]?.data?.agreementWorkflow?.isAgreementSigned, agreementNumber: response[0]?.data?.agreementWorkflow?.agreementNumber, agreementId: response[0]?.data?.agreementWorkflow?.agreementId })
      }

      if (response && response.length > 0 && response[0].data.agreementWorkflow.isCOAgreement == '1') {
        setisCustomerorderenabled(true)
      }
      if (!EmpolyeeId) {
        setEmpolyeeId(response[1]?.coworkerProfile?.employeeId)
      }
      const modifiedEndtime =
        popupVal?.timeSlotInfo?.endTime || '';
      const modifiedStarttime =
        popupVal?.timeSlotInfo?.startTime || ''
          .replace(' PM', '')
          .replace(' AM', '');
      const slicedStartTimeslot =
        modifiedStarttime.slice(0, 5) +
        modifiedStarttime.slice(8, modifiedStarttime.length);
      const slotDuration =
        moment(modifiedStarttime, 'HH:mm').format('hh:mm') +
        ' - ' +
        moment(modifiedEndtime, 'HH:mm').format('hh:mm A');
      setDeliveryTime(slotDuration);
      setDeliveryDate(popupVal?.appointmentInfo?.appointmentDate ? moment(popupVal?.appointmentInfo?.appointmentDate).format("MM/DD/YYYY") : '');
      (popupVal?.appointmentInfo?.appointmentDatee || '')
      // constsetDeliveryDate historyAppointmentRes = await EventHistory({ appointmentId: popupVal.appointmentInfo.appointmentId })
      setloader(false);
      setAppointmentHistoryRecord(response[2].data)
      // } else {
      //     setloader(true);
      //     const response: any = await EventHistory({ appointmentId: popupVal.appointmentInfo.appointmentId })
      //     if (response && response.status == 200) {
      //         setAppointmentHistoryRecord(response.data)
      //     }
      //     setloader(false);
      // }
      if (popupVal?.appointmentInfo?.appointmentType == 'STOPIC') {
        setEnableStorePickupPopup(true)
      }
      else if (popupVal?.appointmentInfo?.appointmentType == 'FIELD') {
        setEnableFieldVisitPopup(true)
      }
      else
        setEnableDeliveryPopup(true)
      console.log("popupVal", popupVal)
      setStatus({ ...status, Signature: 'complete', Reprint: 'inprogress', ConfirmDelivery: 'pending' });
      setPopUpValues(popupVal);
    } catch (e: any) {
      setloader(false)
      setModals({ ...modals, apiErrorInfoMsg: true })
    }

  }

  const getSurveyStatus = async (agreementId) => {
    const getSurveyDone: any = await getSavedSurvey(agreementId);

    setReadOnlySurvey(false);

    if ((getSurveyDone?.status != 200) || (getSurveyDone?.status == 200 && ((getSurveyDone?.data?.length == 0) || !(getSurveyDone?.data?.agreementId)))) {
      console.log("setToShowSurvey: " + agreementId + ":" + toShowSurvey)
      setToShowSurvey(true);
    }
    else {
      setToShowSurvey(false);// chhange this to be 204 and 200 

      if (getSurveyDone?.status == 200 && getSurveyDone?.data?.agreementId) {
        setReadOnlySurvey(true);
        initializeSurvey(getSurveyDone.data);
      }
      console.log("setToShowSurvey: " + agreementId + ":" + toShowSurvey)
    }
  }
  // Appoinment Events to bind.
  const eventToBind: any = (binder: any, index: any) => {
    if (eventType !== 'ALL') {
      binder = binder.filter((filterEl: any) => (!filterEl.storeNumber || filterEl.appointmentInfo.appointmentTypeDesc == eventType))
    }
    if (documentName) {
      binder = binder.filter((filterEl: any) => (!filterEl.storeNumber || (filterEl.appointmentInfo && filterEl.appointmentInfo.instructions && filterEl.appointmentInfo.instructions.toLowerCase().includes(documentName)) || (filterEl.customerInfo && filterEl.customerInfo.firstName && filterEl.customerInfo.firstName.toLowerCase().includes(documentName.toLowerCase())) || (filterEl.customerInfo && filterEl.customerInfo.lastName && filterEl.customerInfo.lastName.toLowerCase().includes(documentName))))
    }
    const InactiveCheck = ['Paid in Full', 'Same As Cash', 'Early Purchase Option', 'Charged Off', 'Return To Vendor']
    binder = binder.filter((filterEl: any) => !InactiveCheck.includes(filterEl?.agreement?.agreementStatusDesc))
    console.log(binder, "...bind")
    return binder?.map((el: any, index: any) => {
      // return binder?.agreementInfo.map((agree:any)=>{
      if (index < eventLenght) {
        return (
          <Grid style={{
            width: "24%",
            margin: "0.5%",
            zIndex: 0, textDecoration: 'none', fontSize: 'medium',
            fontWeight: 'bold', color: '#2179FE',
            borderLeft: `4px solid ${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.fontColor}`, display: 'flex',
            position: 'relative',


          }} className={`${classes1.DAPcardwidget} ${classes1.DAPsmallcardwidget}`}
            key={index} >
            <a style={{
              width: "100%",
              position: 'relative',
              display: 'block',
              padding: '0.5rem1rem',
              color: '#212529',
              textDecoration: 'none',
              backgroundColor: `${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.backgroundColor}`,
              // border: '1px solid rgba(0, 0, 0, 0.125)',
              paddingLeft: "5%",
              cursor: 'pointer',
              borderRadius: "3px",
              paddingTop: "2%",
              paddingBottom: "2%",
              boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
            }}
              className={classes1.DAPeventcardparent}
              onClick={() => { el.appointmentInfo?.appointmentSource !== 'OFFSITE' ? handleEventDetails(el) : el?.appointmentInfo?.appointmentStatus == 'TD' && el?.agreement?.agreementStatus == 'PRELIM' ? history.push({ pathname: `/payment1/customerorder/preliminary/${el?.customerInfo?.customerId}/${el?.agreement?.agreementId}` }) : ((el?.appointmentInfo?.appointmentStatus == 'TD' || el?.appointmentInfo?.appointmentStatus == 'CAN') && (el.agreement?.agreementStatus == 'PRELIMINV' || el?.agreement?.agreementStatus == 'PRELIMPAID')) ? history.push({ pathname: `/agreement/customerorder/postatus/${el?.customerInfo?.customerId}/${el?.agreement?.agreementId}` }) : el?.appointmentInfo?.appointmentStatus == 'CMP' ? history.push({ pathname: `/agreement/info/details/${el?.customerInfo?.customerId}/${el?.agreement?.agreementId}` }) : null }}
            // onClick={() => handleEventDetails({ wholeEl: el, agreementInfo: el?.agreementInfo, customerInfo: el?.custumInfo, inventory: el?.inventories })}

            >
              <Grid style={{ width: '100%', display: 'flex' }}>
                <Grid style={{ width: '60%', display: 'flex' }}>

                  {el.appointmentInfo?.appointmentType == 'AD' ? <Typography style={{
                    fontFamily: 'OpenSans-bold',
                    fontSize: ' 13px',
                    display: 'inline-block',
                    color: `${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.fontColor}`
                  }} className={classes1.DAPeventcardfont}>
                    {`${(el?.appointmentInfo?.additionalDetails?.customerInfo?.firstName) + ' ' + (el?.appointmentInfo?.additionalDetails?.customerInfo?.lastName)}`}
                  </Typography> : <Typography style={{
                    fontFamily: 'OpenSans-bold',
                    fontSize: ' 13px',
                    display: 'flex',
                    wordBreak: 'break-word',
                    width: `${el.appointmentInfo?.appointmentSource == 'OFFSITE' ? '60%' : '100%'}`,
                    color: `${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.fontColor}`
                  }} className={classes1.DAPeventcardfont}>{el.appointmentInfo.appointmentType == 'SE' ? el.appointmentInfo.title || '' : `${(el?.customerInfo?.firstName || '') + ' ' + (el?.customerInfo?.lastName || '')}`}
                  </Typography>
                  }
                  {el.appointmentInfo?.appointmentSource == 'OFFSITE' ?
                    <><span style={{ width: "7px", height: '7px', background: `#4c4fc7`, display: 'inline-block', borderRadius: '50%', marginTop: '8px' }}></span><span style={{ fontSize: '11px', paddingLeft: '3px', paddingTop: '4px' }}>{`Tire Buyer`}</span></> : null}

                </Grid>
                <Grid style={{ width: '50%', display: 'flex' }}>
                  {/* PS_07 */}
                  {(el?.appointmentInfo?.appointmentType == 'D' || el?.appointmentInfo?.appointmentType == 'CO') && el?.approvals?.verificationStatus == 'PENDING' && customerVerification == 1 ? <WarningIcon></WarningIcon> : null}
                  {/* PS_09*/}
                  {el.appointmentInfo.appointmentType !== 'SE' ?
                    <RACBadge className={`${classes.w100Px} ${classes1.w100Px} ${classes.floatRight}`} handleOnClick={function (event: any): void {
                      throw new Error("Function not implemented.");
                    }} backgroundColor={getBatchColorByappointmentType(el.appointmentInfo.appointmentType)} color={"white"} TextValue={el.appointmentInfo.appointmentTypeDesc} OnclickNeed={false}>
                    </RACBadge> : null}
                </Grid>
              </Grid>

              <Grid className={classes.mb1} style={{ width: '100%', display: 'flex' }}>
                <Grid style={{ width: '60%' }}>

                  {el.appointmentInfo?.appointmentType == 'AD' ?
                    <Typography className={`${classes.fs12}`} >
                      <Grid> {`${(el?.appointmentInfo?.additionalDetails?.customerInfo?.addressLine1 || '') + ',' + (el?.appointmentInfo?.additionalDetails?.customerInfo?.addressLine2 || '')}`}</Grid>
                      <Grid>{`${(el.appointmentInfo?.additionalDetails?.customerInfo?.city || '') + ' ' + (el.appointmentInfo?.additionalDetails?.customerInfo?.state || '') + ' ' + (el?.appointmentInfo?.additionalDetails?.customerInfo?.zip || '')}`}</Grid>                                        </Typography>
                    :
                    <Typography className={`${classes.fs12}`} >
                      <Grid>{el.appointmentInfo.appointmentType == 'SE' ? (el.appointmentInfo.instructions || '') : (el.customerInfo?.address?.addressLine1 || '') + ' ' + (el.customerInfo?.address?.addressLine2 || '')}</Grid>
                      <Grid>{el.appointmentInfo.appointmentType == 'SE' ? '' : `${((el.customerInfo?.address?.city || '') + (el.customerInfo?.city ? ',' : '') + (el.customerInfo?.address?.state || '')) + (el.customerInfo?.postalCode ? '-' : '') + (el.customerInfo?.address?.postalCode || '')}`}</Grid>
                    </Typography>
                  }
                </Grid>
                <Grid style={{ width: '40%', alignSelf: 'end' }}>
                  <Typography className={`${classes.fs12}`}>
                    <Grid style={{ textAlign: 'center' }}>{el.appointmentInfo.appointmentType == 'SE' ? 'Store Event' : 'Event'}</Grid>
                  </Typography>
                </Grid>
              </Grid>
            </a>
          </Grid>
        )
      }
      // })
    })
  }
  // color to be binded
  const colorBind: any = (index: any) => {
    if (index == 'Delivery') {
      return 'Pink';
    }
    if (index == 'Service') {
      return '#DD4F89';
    }
    if (index == 'CarryOut') {
      return '#26C0C7';
    }
    if (index == 'CarryIn') {
      return '#B36458';
    }
    if (index == 'Pickup') {
      return '#3DCFA3';
    }
    if (index == 'In home service') {
      return '#AF7AB3';
    }
  }
  const onChangeDate = (val: any) => {
    setIsStoreClosed(false)
    pageLoad(moment(val).format('YYYY-MM-DD'))
    setFilterStateData({ ...filterStateData, date: val })
  };
  const handleDisable = () => {
    const Date = moment().format('YYYY-MM-DD');
    console.log('Date', Date);
    console.log('dateDap', dateDap);
    if (filterStateData.date == Date.toString()) {
      console.log('true');
      return true;
    } else {
      console.log('false');
      return false;
    }
  };
  const handleEventDropdown = (val: any) => {
    appointmentDetails
    console.log('events', val.target.value);
    setEventType(val.target.value);
  };

  // function handleCreateStoreEvent(){
  // }

  const handleKeypress = (event: any) => {
    console.log('charCode', event.charCode);
    console.log('key pressed');
    if (event.charCode === 13) {
      event.preventDefault();
      console.log('enter pressed');
    }
  };
  const storeClosedGrid = () => {
    return (
      <Grid style={{ width: '100%', height: '150px' }}>
        <Typography style={{ textAlign: 'center', paddingTop: '50px' }}>Store closed</Typography>
      </Grid>
    )
  }
  const LunchBreakGrid = () => {
    return (
      <Grid style={{ width: '90%', height: '100%', display: 'flex' }} className={classes1.DAPlunchbreak}>
        <span style={{
          width: '46%',
          height: '1px', alignSelf: 'center',
          color: 'gray',
          background: 'repeating-linear-gradient(to right, transparent, transparent 5px, gray 5px, gray 10px)'
        }}></span>
        <Typography style={{ display: 'inline-block', textAlign: 'center', alignSelf: 'center', width: '8%', color: 'gray' }} className={classes1.breaktext}>Lunch Break</Typography>
        <span style={{
          width: '46%', alignSelf: 'center',
          height: '1px',
          color: 'gray',
          background: 'repeating-linear-gradient(to right, transparent, transparent 5px, gray 5px, gray 10px)'
        }}></span>
      </Grid>
    )
  }
  const printHTML = (html: any) => {
    if (html !== undefined && html !== null) {
      const divContents = html.innerHTML;
      const a: any = window.open('/h', '_blank', 'height=1000, width=1200');
      a.document.write('<html>');
      a.document.write(divContents);
      a.document.write('</html>');
      a.document.close();
      a.print();
    }
  };
  const TimeSlotExceedRecord = () => {
    return (<Grid style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      {timeSlotExceedRecordsDetails?.appointmentDetails?.map((el: any, index: any) => {
        // return binder?.agreementInfo.map((agree:any)=>{
        return (
          <Grid key={el?.appointmentInfo?.appointmentId} style={{ width: '32%', display: 'flex', padding: '0.5rem' }} className={classes1.popupcard}>
            <Grid style={{
              width: "100%",
              margin: "0.5%",
              zIndex: 0, textDecoration: 'none', fontSize: 'medium',
              fontWeight: 'bold', color: '#2179FE',
              borderLeft: `4px solid ${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.fontColor}`, display: 'flex',
              position: 'relative',

            }} key={index} >
              <a style={{
                width: "100%",
                position: 'relative',
                display: 'block',
                padding: '0.5rem1rem',
                color: '#212529',
                textDecoration: 'none',
                backgroundColor: `${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.backgroundColor}`,
                // border: '1px solid rgba(0, 0, 0, 0.125)',
                paddingLeft: "5%",
                cursor: 'pointer',
                borderRadius: "3px",
                paddingTop: "2%",
                paddingBottom: "2%",
                boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
              }}
                onClick={() => { el.appointmentInfo?.appointmentSource !== 'OFFSITE' ? handleEventDetails(el) : el?.appointmentInfo?.appointmentStatus == 'TD' && el?.agreement?.agreementStatus == 'PRELIM' ? history.push({ pathname: `/payment1/customerorder/preliminary/${el?.customerInfo?.customerId}/${el?.agreement?.agreementId}` }) : ((el?.appointmentInfo?.appointmentStatus == 'TD' || el?.appointmentInfo?.appointmentStatus == 'CAN') && (el.agreement?.agreementStatus == 'PRELIMINV' || el?.agreement?.agreementStatus == 'PRELIMPAID')) ? history.push({ pathname: `/agreement/customerorder/postatus/${el?.customerInfo?.customerId}/${el?.agreement?.agreementId}` }) : el?.appointmentInfo?.appointmentStatus == 'CMP' ? history.push({ pathname: `/agreement/info/details/${el?.customerInfo?.customerId}/${el?.agreement?.agreementId}` }) : null }}
              // onClick={() => handleEventDetails({ wholeEl: el, agreementInfo: el?.agreementInfo, customerInfo: el?.custumInfo, inventory: el?.inventories })}

              >
                <Grid style={{ width: '100%', display: 'flex' }}>
                  <Grid style={{ width: '60%' }} className={classes1.eventnamewidth}>
                    {el.appointmentInfo?.appointmentType == 'AD' ?
                      <Typography style={{
                        fontFamily: 'OpenSans-bold',
                        fontSize: ' 13px',
                        display: 'inline-block',
                        color: `${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.fontColor}`
                      }} className={classes1.DAPeventcardfont}>
                        {`${(el?.appointmentInfo?.additionalDetails?.customerInfo?.firstName) + ' ' + (el?.appointmentInfo?.additionalDetails?.customerInfo?.lastName)}`}
                      </Typography> :
                      <Typography style={{
                        fontFamily: 'OpenSans-bold',
                        fontSize: ' 14px',
                        display: 'inline-block',
                        wordBreak: 'break-word',
                        color: `${getFontBackgroundColour(el.appointmentInfo.appointmentStatus, el.timeSlotInfo.endTimeStamp)?.fontColor}`
                      }} className={classes1.fontsize13}>{el.appointmentInfo.appointmentType == 'SE' ? el.appointmentInfo.title || '' : `${(el?.customerInfo?.firstName || '') + ' ' + (el?.customerInfo?.lastName || '')}`}</Typography>
                    }

                  </Grid>
                  <Grid style={{ width: '60%' , display: 'flex'}} >
                    {/* PS_07 */}
                    {(el?.appointmentInfo?.appointmentType == 'D' || el?.appointmentInfo?.appointmentType == 'CO') && el?.approvals?.verificationStatus == 'PENDING' && customerVerification == 1 ? <WarningIcon></WarningIcon> : null}
                    {/* PS_09*/}
                    {el.appointmentInfo.appointmentType !== 'SE' ?
                      <RACBadge className={`${classes.w100Px} ${classes1.badgefontsize}`} handleOnClick={function (event: any): void {
                        throw new Error("Function not implemented.");
                      }} backgroundColor={getBatchColorByappointmentType(el.appointmentInfo.appointmentType)} color={"white"} TextValue={el.appointmentInfo.appointmentTypeDesc} OnclickNeed={false} >
                      </RACBadge> : null}
                  </Grid>
                </Grid>


                <Grid className={classes.mb1} style={{ width: '100%', display: 'flex' }}>
                  <Grid style={{ width: '60%' }}>
                    {el.appointmentInfo?.appointmentType == 'AD' ?
                      <Typography className={`${classes.fs12}`} >
                        <Grid> {`${(el?.appointmentInfo?.additionalDetails?.customerInfo?.addressLine1 || '') + ',' + (el?.appointmentInfo?.additionalDetails?.customerInfo?.addressLine2 || '')}`}</Grid>
                        <Grid>{`${(el.appointmentInfo?.additionalDetails?.customerInfo?.city || '') + ' ' + (el.appointmentInfo?.additionalDetails?.customerInfo?.state || '') + ' ' + (el?.appointmentInfo?.additionalDetails?.customerInfo?.zip || '')}`}</Grid>                                        </Typography> :
                      <Typography className={`${classes.fs12}`} >
                        <Grid>{el.appointmentInfo.appointmentType == 'SE' ? (el.appointmentInfo.instructions || '') : (el.customerInfo?.address?.addressLine1 || '')}</Grid>
                        <Grid>{el.appointmentInfo.appointmentType == 'SE' ? '' : `${((el.customerInfo?.address?.city || '') + (el.customerInfo?.city ? ',' : '') + (el.customerInfo?.address?.state || '')) + (el.customerInfo?.postalCode ? '-' : '') + (el.customerInfo?.address?.postalCode || '')}`}</Grid>
                      </Typography>
                    }

                  </Grid>
                  <Grid style={{ width: '40%', alignSelf: 'end' }}>
                    <Typography className={`${classes.fs12}`}>
                      <Grid style={{ textAlign: 'center' }}>{el.appointmentInfo.appointmentType == 'SE' ? 'Store Event' : 'Event'}</Grid>
                    </Typography>
                  </Grid>
                </Grid>
              </a>
            </Grid>
          </Grid>
        )
      })}
    </Grid>)

  }


  const bindAppointmentDetails = (): any => {
    return appointmentDetails?.map((el: any) => {
      const [firstEl] = el;
      let firstColumnName = '';
      const startTime: any = moment(firstEl.timeSlotInfo.startTime, 'HH:mm:ss').format(
        'hh:mm A'
      );
      const endTime: any = moment(firstEl.timeSlotInfo.endTime, 'HH:mm:ss').format(
        'hh:mm A'
      )
      if(firstEl.timeSlotInfo.timeSlot == 'BestFit' ){
        firstColumnName = "Best Fit";
      }
      else if(firstEl.appointmentInfo && firstEl.appointmentInfo.bestFit &&firstEl.appointmentInfo.bestFit == '1' ){
        firstColumnName = "Best Fit";
      }
      else{
        firstColumnName = startTime + `-` + endTime;
      }
     
      return (
        <>
          <CardContent className={classes.cardBody}>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 0,
                marginBottom: 0,
                borderRadius: '0.25rem',
              }}
              className={classes1.responsiveeventcontainer}
            >
              <Grid className={`${classes.row} ${classes1.DAPcardwidgetmain} `} style={{ width: '100%', height: '80px' }}>
                <Grid style={{ width: '10%', justifyContent: 'center', height: '100%' }} className={classes1.eventtimecontainer}>
                  <Typography
                    className={`${classes.racstrapTablecellBgColor} ${classes1.DAPmargintopcustom}`}
                  >
                    {firstColumnName}
                  </Typography>
                </Grid>
                {firstEl?.timeSlotInfo?.status == 'BREAK' ? <LunchBreakGrid /> : <Grid style={{ width: '88%', height: '100%' }} className={classes1.eventitemcontainer}>
                  {/* <RACTableCell style={{width:"100%"}}> */}
                  {firstEl?.storeNumber ? <Grid className={classes.row}>{eventToBind(el)}</Grid> : <Grid className={classes.row}></Grid>}
                  {/* </RACTableCell>  */}
                </Grid>}

                {el && el.length > eventLenght ?
                  <Grid style={{ width: '2%', paddingLeft: '15px', paddingTop: '25px', height: '100%' }} className={classes1.DAPextraevents}>
                    {/* <RACTableCell style={{width:"100%"}}> */}
                    <a
                      onClick={() => { setTimeSlotExceedRecordsDetails({ timeSlot: `${startTime + ' ' + endTime}`, appointmentDetails: el }); setModals({ ...modals, timeSlotExceedModal: true }) }}
                      className={classes.customerRaclink}
                    >
                      +{el.length - eventLenght}
                    </a>
                    {/* </RACTableCell>  */}
                  </Grid> : null}


              </Grid>
            </Grid>
          </CardContent>
        </>
      );
    });
  };
  const Okpopup = () => {
    setIsReprintCompleted(true);
    Setdisableconfirmdelivery(false);
    setopenCOprintpopup(false);
  };
  const Coprintpopupopen = () => {
    return (
      <div>
        <Grid container spacing={2} className={classes.p3}>
          <Grid item md={12} style={{ display: 'flex' }}>
            <Grid><Images className={classes.me2}></Images>
              <Typography variant="caption" className={classes.formLabel}>
                Delivery Receipt
              </Typography></Grid>
            <Grid><RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(
                  document.getElementById('DeliveryReceiptrentalPrintHTML')
                )
              }
            >
              View PDF
            </RACButton></Grid>
          </Grid>
          <Grid item md={12}>
            <PDFImage className={classes.me2}></PDFImage>
            <Typography variant="caption" className={classes.formLabel}>
              Delivery Checklist
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById('DeliveryChecklistrentalPdf'))
              }
            >
              View PDF
            </RACButton>
          </Grid>
          <Grid item md={12}>
            <PDFImage className={classes.me2}></PDFImage>
            <Typography variant="caption" className={classes.formLabel}>
              Delivery Addendum
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById('deliveryaddendum'))
              }
            >
              View PDF
            </RACButton>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => Okpopup()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const Ok_Popup = () => {
    setReprintPopUp(false)
    setIsReprintCompleted(true);
    setStatus({ ...status, Reprint: 'complete' })
    setEnableDeliveryPopup(true);
    Setdisableconfirmdelivery(false);
    SetOpenprintpopup(false);
  };
  const PrintPopupOpen = () => {

    return (
      <div>
        <Grid container spacing={2} className={classes.p3}>
          <Grid item md={12}>
            <Images className={classes.me2}></Images>
            <Typography variant="caption" className={classes.formLabel}>
              {currentappointmentDetails && (currentappointmentDetails.appointmentInfo.appointmentType == 'P' || currentappointmentDetails.appointmentInfo.appointmentType == 'CI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SCI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SP')
                ? `${currentappointmentDetails && currentappointmentDetails?.appointmentInfo?.appointmentType.includes('SCI', 'CI') ? 'Service Pickup Receipt' : 'Pickup Receipt'}`
                : `${currentappointmentDetails && currentappointmentDetails?.appointmentInfo?.appointmentType.includes('SCO', 'CO') ? 'Service Delivery Receipt' : 'Delivery Receipt'}`}
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() => {
                currentappointmentDetails && (currentappointmentDetails.appointmentInfo.appointmentType == 'P' || currentappointmentDetails.appointmentInfo.appointmentType == 'CI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SCI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SP')
                  ? printHTML(document.getElementById('pickupreciptPrintHTML'))
                  : printHTML(
                    document.getElementById('DeliveryReceiptrentalPrintHTML')
                  );
              }}
            >
              View PDF
            </RACButton>
          </Grid>
          <Grid item md={12}>
            <PDFImage className={classes.me2}></PDFImage>
            <Typography variant="caption" className={classes.formLabel}>
              {currentappointmentDetails && (currentappointmentDetails.appointmentInfo.appointmentType == 'P' || currentappointmentDetails.appointmentInfo.appointmentType == 'CI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SCI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SP')
                ? `${currentappointmentDetails && currentappointmentDetails?.appointmentInfo?.appointmentType.includes('SCI', 'CI') ? 'Service Pickup Checklist' : 'Pickup Checklist'}`
                : `${currentappointmentDetails && currentappointmentDetails?.appointmentInfo?.appointmentType.includes('SCO', 'CO') ? 'Service Delivery Checklist' : 'Delivery Checklist'}`}
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                currentappointmentDetails && currentappointmentDetails.appointmentInfo.appointmentType == 'P' || currentappointmentDetails.appointmentInfo.appointmentType == 'CI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SCI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SP'
                  ? printHTML(
                    document.getElementById('pickupchecklistprintHTML')
                  )
                  : printHTML(
                    document.getElementById('DeliveryChecklistrentalPdf')
                  )
              }
            >
              View PDF
            </RACButton>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => Ok_Popup()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const switchoutokclick = () => {
    setprintswitchoutpopup(false);
  };
  const switchoutpopup = () => {
    return (
      <div>
        <Grid container spacing={2} className={classes.p3}>
          <Grid item md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid>
              <Images className={classes.me2}></Images>
              <Typography variant="caption" className={classes.formLabel}>
                Delivery Receipt
              </Typography>
            </Grid>
            <Grid>
              <RACButton
                className={classes.agrPrintDoc}
                onClick={() =>
                  printHTML(document.getElementById('DeliveryReceiptPrintHTML'))
                }
              >
                View PDF
              </RACButton>
            </Grid>
          </Grid>
          <Grid item md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid> <PDFImage className={classes.me2}></PDFImage>
              <Typography variant="caption" className={classes.formLabel}>
                Delivery Checklist
              </Typography></Grid>
            <Grid><RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById('DeliveryChecklistPdf'))
              }
            >
              View PDF
            </RACButton></Grid>
          </Grid>
          <Grid item md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid><Images className={classes.me2}></Images>
              <Typography variant="caption" className={classes.formLabel}>
                Pickup Receipt
              </Typography></Grid>
            <Grid><RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById('pickupreciptPrintHTML'))
              }
            >
              View PDF
            </RACButton></Grid>
          </Grid>
          <Grid item md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid><Images className={classes.me2}></Images>
              <Typography variant="caption" className={classes.formLabel}>
                Pickup Checklist
              </Typography></Grid>
            <Grid><RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById('pickupchecklistprintHTML'))
              }
            >
              View PDF
            </RACButton></Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => switchoutokclick()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const cancelEventReasonPopUp = () => {
    return (
      <Grid style={{ width: '100%' }}>
        <Grid className={classes.row}>
          <Grid style={{ width: '100%', marginRight: '2%' }}>
            <Typography
              className={classes.RACPOPMsg}
              style={{ marginTop: '-1rem' }}
            >
              Reason for Canceling the event
              <span style={{ color: 'red' }}> *</span>
            </Typography>
            <textarea
              style={{
                marginTop: '5%',
                width: '100%',
                padding: '6px',
                paddingBottom: '31px',
                resize: 'none',
                border: '1px solid #C4C4C4',
                paddingTop: '5px',
                paddingRight: '5px',
                // marginRight:"4px",
                // paddingLeft:"20px",
                borderRadius: '0.6rem',
                backgroundColor: '#fff',
                transition:
                  'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                fontSize: '14px',
                fontFamily: 'OpenSans-semibold',
              }}
              // onChange={(e) => handleDropNotesInp(e)}
              rows={6}
              cols={54}
              value={cancelEventReason}
              onChange={(e) => setCancelEventReason(e.target.value)}
              className={classes.cancelReason}
            ></textarea>
          </Grid>
        </Grid>
        <Grid
          style={{ float: 'right' }}
          container
          className={`${classes.explanationContent} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => setModals({ ...modals, cancelEventReaspnModal: false })}
          >
            Cancel
          </RACButton>
          <RACButton
            disabled={cancelEventReason.length == 0 ? true : false}
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => cancelOrCompleteStoreEvent('Cancel')}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const reopenPopup = () => {
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            <Typography className={classes.RACPOPMsg}>
              Are you sure you want to Reopen this event?
            </Typography>
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.me2}
              onClick={() => {
                setModals({ ...modals, reopenConfirmModal: false })
              }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.me2} ${classes.foc}`}
              onClick={() => { acimaEventDetails ? acimaPocancelEvent('Reopen') : cancelOrCompleteStoreEvent('Reopen') }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const completeAckPopup = () => {
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            <Typography className={classes.RACPOPMsg}>
              Are you sure you want to Complete this event?
            </Typography>
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.me2}
              onClick={() => {
                setModals({ ...modals, completeConfirmModal: false })
              }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.me2} ${classes.foc}`}
              onClick={() => cancelOrCompleteStoreEvent('Complete')}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const customerSignatureFn = async () => {
    setEnableDeliveryPopup(false)
    SetCheckforSign(false);
    setloader(true);
    const result = await GetCustomerinfo(agreementValues.agreementId);
    console.log("GetCustomerinfo response : ", result.data);
    setloader(false);
    if (result?.status == 200) {
      if (result.data.coCustomer.length == 0) {
        console.log("customerdatalog", result.data.customer);
        const CustomerObj = [
          {
            customerId: result.data.customer[0].customerId,
            customerName: result.data.customer[0].customerName,
            phoneNumber: result.data.customer[0].phoneNumber,
            emailId: result.data.customer[0].emailAddress,
          },
        ];
        setcustomersArray(CustomerObj);
      } else {
        if (result.data.coCustomer[0].isDocumentGenerated === "1") {
          /* eslint-disable no-console */
          console.log("Co Customer : ", result.data);
          const CustomerObj = [
            {
              customerId: result.data.customer[0].customerId,
              customerName: result.data.customer[0].customerName,
              phoneNumber: result.data.customer[0].phoneNumber,
              emailId: result.data.customer[0].emailAddress,
            },
            {
              emailId: result.data.coCustomer[0].emailAddress,
              customerName: result.data.coCustomer[0].customerName,
              phoneNumber: result.data.coCustomer[0].phoneNumber,
              customerId: result.data.coCustomer[0].customerId,
            },
          ];
          setcustomersArray(CustomerObj);
        } else {
          console.log("No Co Customer : ", result.data);
          const CustomerObj = [
            {
              customerId: result.data.customer[0].customerId,
              customerName: result.data.customer[0].customerName,
              phoneNumber: result.data.customer[0].phoneNumber,
              emailId: result.data.customer[0].emailAddress,
            },
          ];
          setcustomersArray(CustomerObj);
        }
      }
      setcustomerSignEnabled(true);
    }
  }
  // Agreement Not Sign Check popup

  const SignCheckPopup = () => {
    return (
      <Grid className={classes.p2}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Agreement# {agreementValues.agreementNumber} has been unsigned. Do you like to sign the
            agreement to continue?{" "}
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            onClick={() => { SetCheckforSign(false) }}
            variant="outlined"
            color="primary"
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => { customerSignatureFn() }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const DeliveryConfirmPopupOpen = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Successimage></Successimage>
          {currentappointmentDetails?.appointmentInfo?.appointmentType.includes('P', 'CI') ?
            <Typography className={`${classes.popupText} ${classes.mt3}`}>
              Pickup has been confirmed.
            </Typography>
            :
            <Typography className={`${classes.popupText} ${classes.mt3}`}>
              Delivery has been confirmed.
            </Typography>}
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={Delivery_confirmed_ok}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const deliveryerrorconfirm = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Alert></Alert>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Delivery is not confirmed
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => { setdeliveryerrorpopup(false) }}
          >
            OK
          </RACButton>
        </Grid>
      </div >
    );
  };
  const isCustomerSignedPopup = () => {
    return (
      <Grid style={{ "textAlign": "center", padding: "0rem 0rem 0rem 0.75rem" }} className={classes.p4}>
        <Typography
          variant="h6" style={{
            textAlign: "center",
            "fontWeight": "normal",
            color: "#393939",
            fontSize: "17px",
            maxWidth: "100%",
            alignContent: "center",
            fontFamily: 'OpenSans-semibold',
          }}>Did the customer sign the delivery receipt?</Typography>
        <Grid style={{ "textAlign": "center" }}>
          <RACButton
            color="primary"
            variant="outlined"
            size="small"
            className={classes.mt4 + " " + classes.p2 + " " + classes.mx2 + " " + classes.px4 + " " + classes.py2}
            onClick={() => {
              setisSignedByCustomer(false);
            }}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            size="small"
            className={classes.mt4 + " " + classes.p2 + " " + classes.mx2 + " " + classes.px4 + " " + classes.py2}
            onClick={() => {

              acimaManualCompletFn()
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    )
  }
  const SurveySavedOk = async () => {
    setEnableDeliveryPopup(false);
    setOpenSurveySaveSuccessPopup(false);
    setToShowCustomerSurveyForm(false);
    setloader(true)
    window.location.reload();
    await pageLoad(filterStateData?.date)
    setloader(false)
  }
  const Delivery_confirmed_ok = async () => {
    if (['CI', 'P'].includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
      history.push({
        pathname: `/agreement/info/delivery/${currentappointmentDetails?.customerInfo?.customerId}/${currentappointmentDetails?.agreement?.agreementId}`,
        search: `?apid=${currentappointmentDetails.appointmentInfo.appointmentId}`
      })
    }
    SetOpendeliveryconfirmpopup(false);
    setacimaEventDetails(false)
    setloader(true)
    await pageLoad(filterStateData?.date)
    setloader(false)
  };
  const deliveryDigitalSignCompletedFn = async () => {
    setdigitalSignEnable(false);
    setEnableDeliveryPopup(false);
    setloader(true)
    if (
      agrDelStatus == "Cancelled"
    ) {
      const value = {
        agreementId: currentappointmentDetails?.agreement?.agreementId,
        isLoaner: "Y",
        loanerAction: {
          appointmentId: String(appiontmentId),
          storeNumber: window.sessionStorage.getItem("storeNumber"),
          actionName: "Reopen",
          coWorkerId: EmpolyeeId,
        },
      };
      const result = await Confirmdelivery(value);
    }
    let updatedeliverystatus: any = '';
    if (currentappointmentDetails && currentappointmentDetails.isSwitchoutEnabled == '1') {
      updatedeliverystatus = {
        agreementId: currentappointmentDetails?.agreement?.agreementId,
        isSwitchOut: 1,
        switchInItem: Number(currentappointmentDetails.switchInItem),
        documentId: updateRecieptKey,
      }
    }
    else {
      updatedeliverystatus = {
        agreementId: currentappointmentDetails?.agreement?.agreementId,
        documentId: updateRecieptKey,
      }
    }
    const updatesign = {
      agreementId: currentappointmentDetails?.agreement?.agreementId,
      value: 2,
    };
    let updatestatusresponse: any = {
      updatedata: {},
    };

    let updatesignresponse: any = {
      signdata: {},
    };
    const payload: any = {
      storeNumber: currentappointmentDetails.storeNumber,
      appointmentId: currentappointmentDetails.appointmentInfo.appointmentId,
      actionName: 'Complete',
      documentId: updateRecieptKey
    }
    if (['CI', 'P', 'S', 'SCI', 'SCO', 'SD', 'SP'].includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
      updatestatusresponse = await updateEventStatus(payload)

      if (['S', 'SCI', 'SCO', 'SD', 'SP'].includes(currentappointmentDetails?.appointmentInfo?.appointmentType)) {
        const updatesign = {
          agreementId: currentappointmentDetails && currentappointmentDetails?.agreement?.agreementId,
          value: 2,
        };
        Recieptsign(updatesign)
      }

    }
    else {
      await Promise.all([
        Confirmdelivery(updatedeliverystatus),
        Recieptsign(updatesign),
      ]).then((resp) => {
        [updatestatusresponse, updatesignresponse] = resp;
      });
    }
    setloader(false)
    if (
      updatestatusresponse?.status == 200 && updatestatusresponse?.data
    ) {

      if (currentappointmentDetails?.isSwitchoutEnabled == '1') {
        const payload: any = {
          storeException: [
            {
              identifierId: Number(currentappointmentDetails?.agreement?.agreementId),
              identifierType: 'AGREEMENT',
              storeNumber: window.sessionStorage.getItem('storeNumber'),
              exceptionTypeRefCode: "SWO",
              exceptionText: null
            }
          ]
        }
        storeException(payload)
      }
      SetOpendeliveryconfirmpopup(true);
    } else {
      setdeliveryerrorpopup(true);
    }
  }
  const deliveryDigitalSignCanceledFn = () => {
    setdigitalSignEnable(false);
    setmanualSignatureEnabled(false)
  }
  const AcimadeliveryDigitalSignCompletedFn = async (type?: any) => {
    if (isManual && type == 'Manual') {
      setmanualSignature(true)
      return
    }
    const payLoad = {
      agreementId: "afjndl",
      isAcimaPo: "Y",
      acimaCreate: {
        actionName: "Complete",
        appointmentId: acimaEventValues.appointmentId,
        storeNumber: window.sessionStorage.getItem('storeNumber')
      }
    }
    const resp = await Confirmdelivery(payLoad);
    const payLoadForm = {
      "purchaseOrderNumber": String(acimaEventValues?.poNumber),
      "poDetailReceivedId": acimaEventValues.itemInformation.map((value: any, index: number) => {
        return value.poDetailReceivedId
      }),
      "appointmentId": acimaEventValues.appointmentId
    }
    const updateManage = managePoAcimaFn(payLoadForm);
    if (resp?.status !== 200) {
      setacimaDeliveryDigitalsignEnbale(false)
      setdeliveryerrorpopup(true);
    }
  }
  const acimaManualCompletFn = async () => {
    setloader(true)
    setisSignedByCustomer(false)
    const payLoad = {
      agreementId: "afjndl",
      isAcimaPo: "Y",
      acimaCreate: {
        actionName: "Complete",
        appointmentId: acimaEventValues.appointmentId,
        storeNumber: window.sessionStorage.getItem('storeNumber')
      }
    }
    const resp = await Confirmdelivery(payLoad);
    setloader(false)
    const payLoadForm = {
      "purchaseOrderNumber": String(acimaEventValues?.poNumber),
      "poDetailReceivedId": acimaEventValues.itemInformation.map((value: any, index: number) => {
        return value.poDetailReceivedId
      }),
      "appointmentId": acimaEventValues.appointmentId
    }
    const updateManage = managePoAcimaFn(payLoadForm);
    if (resp?.status == 200) {
      SetOpendeliveryconfirmpopup(true);
      setacimaDeliveryDigitalsignEnbale(false)
    }
    else {
      setacimaDeliveryDigitalsignEnbale(false)
      setdeliveryerrorpopup(true);

    }

  }
  const AcimadeliveryDigitalSignCanceledFn = async () => {
    setacimaDeliveryDigitalsignEnbale(false)
  }
  const customerSignatureCompletedFn = async () => {
    setEnableDeliveryPopup(false);
    setcustomerSignEnabled(false);
  }
  const customerSignatureCanceledFn = async () => {
    setEnableDeliveryPopup(false);
    setcustomerSignEnabled(false)
  }
  const documentTypeArrFn = () => {
    if (currentappointmentDetails.isSwitchoutEnabled == '1') {
      return ["SWA"];
    }
    else if (
      currentappointmentDetails?.agreement?.agreementTypeDesc == 'Rent to Rent - Loaner'
    ) {
      return ["LOAN"];
    }
    return [];
  }
  const customerSignUsignModuleFn = () => {
    if (currentappointmentDetails.isSwitchoutEnabled == '1') {
      return "switchOut";
    }
    else if (
      currentappointmentDetails?.agreement?.agreementTypeDesc == 'Rent to Rent - Loaner'
    ) {
      return "loaner";
    }
    return "rental";
  };
  const itemInformationbind = () => {
    return acimaEventValues?.itemInformation?.map((value: any, index: any) => {
      return (
        <tr key={index} style={{ width: "100%" }}>
          <td
            style={{
              padding: '10px 20px',
              fontSize: 14,
            }}
          >
            {value !== undefined &&
              value.poDetailReceivedId !== undefined &&
              value.poDetailReceivedId !== null &&
              value.poDetailReceivedId != ""
              ? value.poDetailReceivedId
              : "-"}
          </td>
          <td
            style={{
              padding: '0px 0px 0px 67px',
              fontSize: 14,
            }}
          >
            {value !== undefined && value.description !== undefined
              ? value.description
              : "-"}
          </td>
        </tr>
      );
    });
  }
  const CancelEvent = () => {
    return (
      <Grid >
        <div style={{ marginLeft: '10px' }}>
          <Typography>Reason for cancelling the PO <span style={{ color: 'red' }}>*</span></Typography>
          <textarea style={{ resize: 'none', padding: '0px, 20px' }} rows={7} cols={60} onChange={(e) => { setcancelPOReason(e.target.value.trimStart()) }}></textarea>
        </div>
        <RACButton
          className={classes.mx1}
          style={{ marginLeft: '20rem', marginTop: '5px' }}
          onClick={() => setcancelEventPopup(false)}
          variant='outlined'
          color='secondary'
        >
          Cancel
        </RACButton>
        <RACButton
          disabled={cancelPOReason ? false : true
          }
          className={classes.mx1}
          style={{ marginTop: '5px' }}
          variant="contained"
          color="primary"
          onClick={() => setcancelEventPopup(false)}
        >
          Save
        </RACButton>
      </Grid>

    )
  }
  const acimaPoaddressFormatFnResFn = () => {
    return (acimaEventValues.addressLine1 + ',' + acimaEventValues?.state)
  }
  const handleAddressClickPO = async () => {
    const addressFormatFnRes = await acimaPoaddressFormatFnResFn()
    const encodedAddress = encodeURIComponent(addressFormatFnRes);
    const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(googleMapsURL, '_blank');
  };
  const acimaEventModal = () => {
    return (
      <>
        <Grid>
          <Grid container style={{ padding: '7px 20px' }}>
            <Grid item md={7} sm={7}>
              <Typography >PO Number #</Typography>
              <Typography className={classes.customerRaclinkAcima} style={{ cursor: 'pointer' }} onClick={() => {
                history.push({
                  pathname: `/acimapo/poreceive/details/${acimaEventValues?.poNumber}`,
                });
              }}>{acimaEventValues?.poNumber}</Typography>
            </Grid>
            <Grid item md={5} sm={5}>
              <Typography >Customer Name</Typography>
              <Typography className={classes.customerRaclinkAcima} style={{ cursor: 'pointer' }} onClick={() => {
                history.push({
                  pathname: `/acimapo/poreceive/details/${acimaEventValues?.poNumber}`,
                });
              }}>{`${acimaEventValues?.firstName} ${acimaEventValues?.lastName}`}</Typography>
            </Grid>

          </Grid>
          <Grid container style={{ padding: '7px 20px' }}>
            <Grid item md={7} sm={7}>
              <Typography >Customer Address</Typography>
              <Typography className={classes.customerRaclinkAcima} style={{ cursor: 'pointer' }}
                onClick={() => { handleAddressClickPO() }}
              >{`${(acimaEventValues?.addressLine1 ? acimaEventValues?.addressLine1 + ', ' : ' ') + (acimaEventValues?.addressLine2 ? acimaEventValues?.addressLine2 + ', ' : ' ') + (acimaEventValues?.city ? acimaEventValues?.city + ', ' : '') + (acimaEventValues?.state ? acimaEventValues?.state + ' ' : ' ') + (acimaEventValues?.zip ? acimaEventValues?.zip : '')}`}</Typography>
            </Grid>
            <Grid item md={5} sm={5}>
              <Typography >Phone #</Typography>
              <Typography className={classes.customerRaclinkAcima} >{acimaEventValues?.phoneNumber ? formatNum(acimaEventValues?.phoneNumber) : '-'}</Typography>
            </Grid>
          </Grid>
          <Grid style={{ padding: '5px 18px 5px 18px' }}>
            <Card>
              <CardContent className={classes.racLightBlue}>
                <table>
                  <thead>
                    <th
                      style={{
                        width: "400px",
                        padding: '10px 20px',
                        fontFamily: 'OpenSans-bold',
                        textAlign: 'left'
                      }}
                    >
                      Item #
                    </th>
                    <th
                      style={{
                        fontFamily: 'OpenSans-bold',
                        textAlign: 'left',
                        paddingLeft: '65px'
                      }}
                    >
                      Description
                    </th>
                  </thead>
                  <tbody>
                    {itemInformationbind()}

                  </tbody>

                </table>
              </CardContent>
            </Card>
          </Grid>
          {acimaEventValues?.appointmentStatus !== 'CMP' ?
            <div>
              {acimaEventValues?.appointmentStatus == 'CAN' ? null :
                < Grid style={{ padding: '10px 20px' }}>
                  <Grid style={{ display: 'inline-block' }}>
                    <RACToggle
                      checked={acimaManulaSign}
                      toggleColor="#0d6efd"
                      OnChange={() => { setisManaul(!isManual); setacimaManulaSign(!acimaManulaSign) }}
                    />
                  </Grid>
                  <Typography style={{ display: 'inline' }}>  Manual Signature</Typography>
                </Grid>
              }
              {acimaEventValues?.appointmentStatus == 'CAN' ?
                <Grid style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', marginLeft: '30px', marginTop: '10px' }}>
                  <Typography variant="h5" style={{ alignSelf: 'center', color: 'black' }}>Status :</Typography>
                  <span style={{ marginLeft: '10px', marginTop: '2px', color: 'black', border: '1px solid #58af52', padding: '5px', borderRadius: '5px', fontWeight: '800' }}>Cancelled</span>
                </Grid>
                : null}
              <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                {acimaEventValues?.appointmentStatus == 'CAN' ?
                  <RACButton
                    variant="contained"
                    color="primary"
                    onClick={() => setModals({ ...modals, reopenConfirmModal: true })}
                    style={{ marginLeft: '40px' }}
                  >
                    Reopen Event
                  </RACButton>
                  : <RACButton
                    variant="outlined"
                    className={classes.unassignBtnClass}
                    onClick={() => (acimaPocancelEvent('Cancel'))}
                  >Cancel Event
                  </RACButton>
                }
                {acimaEventValues?.appointmentStatus == 'CAN' ? null : GetCurrentUserRes?.includes('RPDACIMAPO') ?
                  <RACButton
                    color="primary"
                    variant="contained"
                    className={classes.ms2}
                    onClick={() => {
                      history.push({
                        pathname: `/acimapo/poreceive/details/${acimaEventValues?.poNumber}`,
                      });
                    }}
                  >Reschedule</RACButton> : null
                }
                {acimaEventValues?.appointmentStatus == 'CAN' ? null : !acimaManulaSign ?
                  <RACButton
                    color="primary"
                    variant="contained"
                    className={classes.ms2}
                    onClick={() => {
                      AcimaDigitalSignClickFn()
                    }}
                    disabled={acimaEventValues?.appointmentStatus == 'CAN' ? true : false}
                  >Sign and Confirm Delivery</RACButton> :
                  manualSignature ? <RACButton
                    color="primary"
                    variant="contained"
                    className={classes.ms2
                    }
                    onClick={() => {
                      setisSignedByCustomer(true)
                    }}
                  >Confirm Delivery</RACButton>
                    : <RACButton
                      color="primary"
                      variant="contained"
                      className={classes.ms2}
                      onClick={() => {
                        setacimaDeliveryDigitalsignEnbale(true)
                        setisManaul(true)
                      }}
                      disabled={acimaEventValues?.appointmentStatus == 'CAN' ? true : false}
                    >Re-print Delivery Receipt</RACButton>
                }
              </Grid>
            </div> :

            <div>
              <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Typography variant="h5" style={{ alignSelf: 'center' }}>Status :</Typography>
                <span style={{ marginLeft: '10px', color: '#58af52', border: '1px solid #58af52', padding: '5px', borderRadius: '5px', fontWeight: '800' }}>Completed</span>
              </Grid>
            </div>}

          <Grid style={{ padding: '25px 25px' }}>
            <span style={{ fontSize: '14px' }}>Note: Only individuals who are 18 years or older are eligilble to sign the delivery receipt.</span>
          </Grid>
          <RACModalCard
            isOpen={cancelEventPopup}
            borderRadius="25px"
            maxWidth='sm'
            title="Reason for Cancelling PO"
            onClose={() => setcancelEventPopup(false)}
            closeIcon={true}
            // eslint-disable-next-line react/no-children-prop
            children={CancelEvent()}
          />
        </Grid >
        <Grid
          item
          className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.w100}`}
        >
          <Box className={customerStyleClasses.floatLeft}>
            <List
              className={`${classes.racpadTab} ${classes.py0}`}
              id="CustTab"
              role="tabList"
            >
              <ListItem
                className={
                  activetab.history
                    ? `${classes.navLinkActive} ${classes.navLinkItem}`
                    : `${classes.navLinkItem}`
                }
                id="Customerinfo-tab"
                data-testid="customerInfoTab"
                data-bs-toggle="tab"
                data-bs-target="#customerinfo"
                role="tab"
                aria-controls="Customerinfo-tab"
                aria-selected="true"
                onClick={() => setactivetab({ ...activetab, notes: false, history: true })}                             >
                History
              </ListItem>

            </List>
          </Box>

        </Grid>
        <RACTable
          renderTableHead={renderAcimaAppointmentTableHistoryHeader}
          renderTableContent={renderAcimaAppointmentTableHistoryContent}
        />
      </>
    )
  }
  const errorMessagePopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alert></Alert>
          <Typography className={classes.formLabel}>
            {serviceErrorMsg}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setEnableDeliveryPopup(false)
              setModals({ ...modals, timeSlotExceedModal: false })
              seterrorMessagePopupOpen(false)
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      <CardContent className={classes.cardBody}>
        {/* fields */}
        {digitalSignEnable ? (
          <DeliveryDigitalSign
            agreementId={String(currentappointmentDetails?.agreement?.agreementId)}
            deliveryDigitalSignCompletedFn={() => { deliveryDigitalSignCompletedFn() }}
            deliveryDigitalSignCanceledFn={() => { deliveryDigitalSignCanceledFn() }}
          />
        )
          : null}
        {acimaDeliveryDigitalsignEnbale ? (
          < AcimaDeliveryDigitalSign
            deliveryDigitalSignCompletedFn={AcimadeliveryDigitalSignCompletedFn}
            deliveryDigitalSignCanceledFn={AcimadeliveryDigitalSignCanceledFn}
            customerDetails={
              {
                itemDetails: acimaEventValues.itemInformation,
                customerName: acimaEventValues?.firstName + ' ' + acimaEventValues?.lastName,
                addressLine1: acimaEventValues?.addressLine1,
                addressLine2: acimaEventValues?.addressLine2,
                city: acimaEventValues?.city,
                zip: acimaEventValues?.zip,
                phoneNumber: acimaEventValues?.phoneNumber,
                appointmentId: acimaEventValues?.appointmentId,
                date: acimaEventValues?.appiontmnetDate,
                time: acimaEventValues?.appiontmnetTime,
                emailId: acimaEventValues?.emailId,
                state: acimaEventValues?.state
              }
            }
            isManual={isManual}
            openPop={SetOpendeliveryconfirmpopup}
            poNumber={acimaEventValues?.poNumber}
          />
        )
          : null}
        {customerSignEnabled ? (

          <CustomerSignature
            customerSignUsingField='dashBoard'
            customerSignUsingModule={customerSignUsignModuleFn()}
            documentGenerateCompleted={true}
            identifier={Number(agreementValues?.agreementId)}
            customersArr={customersArray}
            manualSignOptions={maualSignReasonDrpdwnVal}
            documentTypeArr={documentTypeArrFn()}
            customerSignatureCompletedFn={() => customerSignatureCompletedFn()}
            customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
          />

        ) : null}

        <RACModalCard
          isOpen={acimaEventDetails}
          maxWidth="md"
          borderRadius="20px"
          title="Event Details"
          closeIcon={true}
          onClose={() => setacimaEventDetails(false)}
          // eslint-disable-next-line react/no-children-prop
          children={acimaEventModal()}
        />
        <RACModalCard
          isOpen={CheckforSign}
          borderRadius="15px"
          maxWidth="xs"
          closeIcon={true}
          onClose={() => SetCheckforSign(false)}
          // eslint-disable-next-line react/no-children-prop
          children={SignCheckPopup()}
        />
        <RACModalCard
          isOpen={Opendeliveryconfirmpopup}
          onClose={() => { Delivery_confirmed_ok() }}
          maxWidth="xs"
          borderRadius="20px"
          closeIcon={true}
          // eslint-disable-next-line react/no-children-prop
          children={DeliveryConfirmPopupOpen()}
        />
        <RACModalCard
          isOpen={OpenSurveySaveSuccessPopup}
          onClose={() => { SurveySavedOk() }}
          maxWidth="xs"
          borderRadius="20px"
          closeIcon={true}
          // eslint-disable-next-line react/no-children-prop
          children={SurveySavedSuccess()}
        />
        <RACModalCard
          isOpen={surveyError}
          closeIcon={true}
          maxWidth="sm"
          borderRadius="20px"
          onClose={() => setSurveyError(false)}
          // eslint-disable-next-line react/no-children-prop
          children={SurveryErrorPopup()}
        />
        <RACModalCard
          isOpen={deliveryerrorpopup}
          closeIcon={true}
          maxWidth="sm"
          borderRadius="20px"
          onClose={() => setdeliveryerrorpopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={deliveryerrorconfirm()}
        />
        <RACModalCard
          isOpen={isSignedByCustomer}
          closeIcon={false}
          maxWidth="xs"
          borderRadius={"10px"}
          onClose={() => {
            setisSignedByCustomer(false);
          }
          }
        >
          {isCustomerSignedPopup()}
        </RACModalCard>
        <RACModalCard
          isOpen={openCOprintpopup}
          onClose={() => setopenCOprintpopup(false)}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          title="Re-Print Delivery Receipt"
          // eslint-disable-next-line react/no-children-prop
          children={Coprintpopupopen()}
        />
        <RACModalCard
          borderRadius="20px"
          isOpen={modals.apiErrorInfoMsg}
          maxWidth="xs"
          closeIcon={true}
          onClose={() => setModals({ ...modals, apiErrorInfoMsg: false })}
          // eslint-disable-next-line react/no-children-prop
          children={UpdateInfoMsgPopup()}
          title=""
        />
        <RACModalCard
          isOpen={modals.timeSlotExceedModal}
          onClose={() => setModals({ ...modals, timeSlotExceedModal: false })}
          maxWidth="md"
          closeIcon={true}
          borderRadius="20px"
          title={timeSlotExceedRecordsDetails.timeSlot}
          // eslint-disable-next-line react/no-children-prop
          children={TimeSlotExceedRecord()}
        />
        {/*Print switchout receipt popup starts here*/}
        <RACModalCard
          isOpen={printswitchoutpopup}
          closeIcon={true}
          maxWidth="xs"
          borderRadius="20px"
          onClose={() => setprintswitchoutpopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={switchoutpopup()}
        />
        {/* Re-Print Delivery Reciept */}
        <RACModalCard
          isOpen={Openprintpopup}
          onClose={() => SetOpenprintpopup(false)}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          title={currentappointmentDetails && (currentappointmentDetails.appointmentInfo.appointmentType == 'P' || currentappointmentDetails.appointmentInfo.appointmentType == 'CI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SCI' || currentappointmentDetails.appointmentInfo.appointmentType == 'SP')
            ? `${currentappointmentDetails && currentappointmentDetails?.appointmentInfo?.appointmentType.includes('SCI', 'CI') ? 'Service Pickup Receipt' : 'Pickup Receipt'}`
            : `${currentappointmentDetails && currentappointmentDetails?.appointmentInfo?.appointmentType.includes('SCO', 'CO') ? 'Service Delivery Receipt' : 'Delivery Receipt'}`}
          // eslint-disable-next-line react/no-children-prop
          children={PrintPopupOpen()}
        />
        <RACModalCard
          isOpen={modals.cancelEventReaspnModal}
          onClose={() => setModals({ ...modals, cancelEventReaspnModal: false })}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          title={'Canceling the event'}
          // eslint-disable-next-line react/no-children-prop
          children={cancelEventReasonPopUp()}
        />

        <RACModalCard
          isOpen={modals.reopenConfirmModal}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => setModals({ ...modals, reopenConfirmModal: false })}
          // eslint-disable-next-line react/no-children-prop
          children={reopenPopup()}
        />
        <RACModalCard
          isOpen={modals.completeConfirmModal}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => setModals({ ...modals, completeConfirmModal: false })}
          // eslint-disable-next-line react/no-children-prop
          children={completeAckPopup()}
        />

        {/* Error message popup */}
        <RACModalCard
          isOpen={errorMessagePopupOpen}
          closeIcon={false}
          maxWidth="xs"
          borderRadius="20px"
          // eslint-disable-next-line react/no-children-prop
          children={errorMessagePopup()}
        />
        <Modal
          isOpen={loader}
          className={classes.loaderStyle}
          id="openpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <Grid>
            <Grid>
              <Grid className={classes.RACLoaderPage}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <RACModalCard
          isOpen={enableDeliveryPopup}
          maxWidth="md"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => setEnableDeliveryPopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={DeliveryPopup()}
          title="Event Details"
          TitleVariantClassName={classes.popUpFont}
        />
        <RACModalCard
          isOpen={enableStorePickupPopup}
          maxWidth="md"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => setEnableStorePickupPopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={StorePickupEventPopup()}
          title="Store Pick-Up Details"
          TitleVariantClassName={classes.popUpFont}
        />
        <RACModalCard
          isOpen={enableFieldVisitPopup}
          maxWidth="md"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => setEnableFieldVisitPopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={FieldVisitEventPopup()}
          title="Field Visit Details"
          TitleVariantClassName={classes.popUpFont}
        />
        <RACModalCard
          isOpen={toShowCustomerSurveyForm}
          maxWidth="md"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => cancelSurvey()}
          // eslint-disable-next-line react/no-children-prop
          children={CustomerSurveyPopup()}
          title="Customer Survey"
          TitleVariantClassName={classes.popUpFont}
        />
        {/* <HandleDeliveryModalCard /> */}
        <HandleRePrintModalCard />
        <RACModalCard
          isOpen={modals.storeEventDetailModal}
          maxWidth="md"
          borderRadius="20px"
          title="Store Event Details"
          closeIcon={true}
          onClose={() => setModals({ ...modals, storeEventDetailModal: false })}
          // eslint-disable-next-line react/no-children-prop
          children={StoreEventModal()}
        />
        <Grid className={`${classes.filterContainer} ${classes1.DAPresponsivefilter}`}>
          <Grid className={`${classes.leftContainer} ${classes1.leftContainer}`}>
            <Grid className={classes.filterContainerItems}>
              <RACDatePicker
                classes={{ input: `${classes.p3} ${classes.ml24} ${classes.h65} ${classes1.spacingDAC}` }}
                label={""}
                onChange={(val: any) => onChangeDate(val)}
                value={filterStateData && filterStateData?.date ? filterStateData?.date : ''} />
              <RACButton
                type="button"
                className={`${classes.ms2} ${classes.todayButton}`}
                color="primary"
                disabled={handleDisable()}
                style={{ height: '30px', marginTop: '15px' }}
                variant="contained"
                onClick={() => {
                  setFilterStateData({ ...filterStateData, date: moment().format('YYYY-MM-DD') });
                  setIsStoreClosed(false)
                  onChangeDate(moment().format('YYYY-MM-DD'))
                }
                }
              >
                Today
              </RACButton>
            </Grid>
            {createEvent ? (
              <Grid className={classes.filterContainerItems}>
                <Typography style={{ textAlign: 'center', width: '200px', marginTop: '20px', height: '25px' }}>
                  Type of Events
                </Typography>
                <RACSelect
                  name="typeofEvent"
                  classes={{ formControl: classes.p3 }}
                  options={eventDD}
                  onChange={(e: any) => handleEventDropdown(e)}
                  defaultValue={
                    eventType !== undefined &&
                      eventType !== null &&
                      eventType !== ''
                      ? String(eventType)
                      : 'ALL'
                  }
                />
              </Grid>) : null}
          </Grid>
          <Grid className={`${classes.rightContainer} ${classes1.rightContainer}`}>
            <Grid className={`${classes.inputGroup} ${classes.searchBar}`}>
              <RACButton
                className={`${classes.px0} ${classes.searchBarIcon}`}
                onClick={() => {
                  console.log('need to include the filter');
                }}
              >
                <SearchbarIcon></SearchbarIcon>
              </RACButton>
              <RACTextbox
                value={documentName}
                placeHolder="Search"
                OnChange={(e: any) => {
                  setDocumentName(e.target.value);
                }}
                className={`${classes.searchSpacing}`}
                onKeyPress={handleKeypress}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ width: '30%', float: 'right', display: 'flex' }} className={classes1.legendstyles}>
          <Grid
            style={{
              borderLeft: '3px solid #2E31BE',
              backgroundColor: 'aliceblue',
              width: '23%',
              marginRight: '2%'
            }}
          >
            <Typography style={{ color: '#2E31BE', marginLeft: '10px', paddingLeft: '10px' }}>
              Upcoming
            </Typography>
          </Grid>
          <Grid
            style={{
              borderLeft: '3px solid red',
              backgroundColor: '#fcf2f2',
              width: '23%',
              marginRight: '2%'
            }}
          >
            <Typography style={{ color: 'red', marginLeft: '10px', paddingLeft: '10px' }}>
              Due
            </Typography>
          </Grid>
          <Grid
            style={{
              borderLeft: '3px solid green',
              backgroundColor: '#ebfdeb',
              width: '23%',
              marginRight: '2%'
            }}
          >
            <Typography style={{ color: 'green', marginLeft: '10px', paddingLeft: '10px' }}>
              Completed
            </Typography>
          </Grid>
          <Grid
            style={{
              borderLeft: '3px solid #5a5a5a',
              backgroundColor: '#f6f6f6',
              width: '23%',
              marginRight: '2%'
            }}
          >
            <Typography style={{ color: '#5a5a5a', marginLeft: '10px', paddingLeft: '10px' }}>
              Cancelled
            </Typography>
          </Grid>

        </Grid>
      </CardContent>
      {appointmentDetails && appointmentDetails.length > 0 && !isStoreClosed
        ? // <Grid style={{width:"100%"}}>
         bindAppointmentDetails()
        : // </Grid>
        null}
      {isStoreClosed ? storeClosedGrid() : null}

      <Grid className={classes.hide}>
        {/* delivery receipt inner html starts here */}
        {/* delivery receipt inner html ends here */}

        {/* delivery checklist inner html starts here */}
        <div
          style={{
            width: '90%',
            fontFamily: 'Arial',
            margin: '10px auto 0px auto',
          }}
          id="DeliveryChecklistPdf"
        >
          {/*first part starts here*/}
          <div style={{ width: '100%', float: 'left' }}>
            <div style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}>
              <div style={{ width: '30%', float: 'left' }}>
                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                  Delivery Checklist
                </h5>
              </div>
              <div
                style={{
                  width: '31%',
                  marginLeft: '2%',
                  marginRight: '2%',
                  float: 'left',
                  height: '100px',
                  marginBottom: '10px',
                }}
              >
                <Logo style={{ width: '100%', height: '100%' }}></Logo>
              </div>
              <div></div>
              <table style={{ width: '35%', float: 'left', marginTop: '30px' }}>
                <thead />
                <tbody style={{ width: '100%', float: 'right' }}>
                  {/*child-parent of first widget*/}
                  <tr
                    style={{ width: '42%', float: 'left', textAlign: 'left' }}
                  >
                    <td
                      style={{
                        marginBottom: '0.2rem',
                        color: '#111111',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      Delivery Date:
                    </td>
                  </tr>
                  <tr
                    style={{ width: '58%', float: 'left', textAlign: 'left' }}
                  >
                    <td>
                      <span
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          fontSize: '13px',
                        }}
                      >
                        {DeliveryDate}
                      </span>
                    </td>
                  </tr>
                  <tr
                    style={{ width: '43%', float: 'left', textAlign: 'left' }}
                  >
                    <td
                      style={{
                        marginBottom: '0.2rem',
                        color: '#111111',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      Delivery Time:
                    </td>
                  </tr>
                  <tr
                    style={{ width: '57%', float: 'left', textAlign: 'left' }}
                  >
                    <td>
                      <span
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          fontSize: '13px',
                        }}
                      >
                        {DeliveryTime}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*first part ends here*/}
          {/*second part starts here */}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <div style={{ width: '33.33%', float: 'left' }}>
                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                  RAC Store
                </h5>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {storename}
                </p>
                <>
                  {StoreAddressline2 != undefined &&
                    StoreAddressline2 != null &&
                    StoreAddressline2 != '' ? (
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {StoreAddressline1 + ' , ' + StoreAddressline2}
                    </p>
                  ) : (
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {StoreAddressline1}
                    </p>
                  )}
                </>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {' '}
                  {storecity + ',' + storezip}{' '}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {normalizeinput(Storephonenum)}
                </p>
              </div>
              <div style={{ width: '30.33%', float: 'left' }}>
                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                  Customer
                </h5>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {CustomerName}
                </p>
                {CustomerAddressline2 !== undefined &&
                  CustomerAddressline2 !== null &&
                  CustomerAddressline2 !== '' ? (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '13px',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {CustomerAddressline1 + ',' + CustomerAddressline2}
                  </p>
                ) : (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '13px',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {CustomerAddressline1}
                  </p>
                )}
                {customerplus4 != undefined &&
                  customerplus4 != null &&
                  customerplus4 != '' ? (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '13px',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {customercity + ',' + customerzip + '-' + customerplus4}
                  </p>
                ) : (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '13px',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {customercity + ',' + customerzip}
                  </p>
                )}
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {normalizeinput(CustomerPhoneNumber)}
                </p>
              </div>
              <div
                style={{ width: '36.33%', float: 'left', marginTop: '12px' }}
              >
                <table>
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr
                      style={{ width: '50%', textAlign: 'left', float: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Delivery Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '40%', textAlign: 'left', float: 'left' }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {DeliveryDate}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Agreement # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
                        currentappointmentDetails && currentappointmentDetails?.customerInfo?.customerId
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Customer # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '40%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {currentappointmentDetails && currentappointmentDetails?.customerInfo?.customerId}{' '}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Next Payment Due:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '40%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {nextpaymentinfo.nextamountdue !== undefined &&
                          nextpaymentinfo.nextamountdue !== null
                          ? '$' + ' ' + nextpaymentinfo.nextamountdue
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/*second part ends here */}
          {/*3rd part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
              <h5
                style={{
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  fontSize: '14px',
                  margin: '0px',
                  marginBottom: '10px',
                }}
              >
                Delivery Checklist
              </h5>
            </div>
            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                <thead>
                  <tr style={{ width: '100%' }}>
                    <th
                      style={{
                        width: '15px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    ></th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Description of Property
                    </th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Serial #
                    </th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Item #
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bindSwitchOutdata('ResendVideo')}
                </tbody>
              </table>
            </div>
          </div>
          {/*3rd part ends*/}
          {/*fourth part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              marginBottom: '400px',
              marginTop: '10px',
            }}
          >
            <h5
              style={{
                fontSize: '14px',
                textDecoration: 'underline',
                float: 'left',
              }}
            >
              Delivery Information
            </h5>
            <table style={{ width: '100%', float: 'left' }}>
              <thead />
              <tbody
                style={{ width: '100%', marginTop: '20px', float: 'left' }}
              >
                <tr
                  style={{ width: '18%', float: 'left', whiteSpace: 'nowrap' }}
                >
                  <td
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    CoWorker Signature:
                  </td>
                </tr>
                <tr
                  style={{
                    width: '28%',
                    float: 'left',
                    paddingRight: '25px',
                    whiteSpace: 'nowrap',
                    marginLeft: '8px',
                  }}
                >
                  <td
                    style={{
                      marginTop: '18px',
                      color: '#656565',
                      borderBottom: borderbotttom,
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </tr>
                <tr
                  style={{ width: '8%', float: 'left', whiteSpace: 'nowrap' }}
                >
                  <td
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Date:
                  </td>
                </tr>
                <tr
                  style={{ width: '32%', float: 'left', whiteSpace: 'nowrap' }}
                >
                  <td
                    style={{
                      marginTop: '15px',
                      color: '#656565',
                      borderBottom: borderbotttom,
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </div>
          {/*fourth part ends*/}
          {/*fifth part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: '100%', float: 'left' }}>
              <h5
                style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  marginTop: '0px',
                  marginBottom: '10px',
                }}
              >
                For Office Use Only
              </h5>
            </div>
            <div style={{ width: '100%', float: 'left' }}>
              <span
                style={{
                  fontSize: '14px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  fontStyle: 'italic',
                }}
              >
                I acknowledge that Rent-A-Center has delivered, installed and
                demonstrated, to my satisfaction, the rental property listed
                above.
              </span>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ width: '100%', float: 'left', marginTop: '20px' }}>
                <span
                  style={{
                    fontSize: '14px',
                    marginBottom: '10px',
                    textDecoration: 'underline',
                  }}
                >
                  Customer Call back survey
                </span>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginTop: '20px',
                marginBottom: '30px',
              }}
            >
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '35%', float: 'left' }}>
                  <span
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Date of Call Back
                  </span>
                </div>
                <div style={{ width: '65%', float: 'right' }}>
                  <span
                    style={{
                      marginTop: '12px',
                      color: '#656565',
                      borderBottom: borderbotttom,
                      width: '80%',
                      float: 'left',
                    }}
                  />
                </div>
              </div>
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '30%', float: 'left' }}>
                  <span
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Mgr/Asst Initials
                  </span>
                </div>
                <div style={{ width: '70%', float: 'right' }}>
                  <span
                    style={{
                      marginTop: '12px',
                      color: '#656565',
                      borderBottom: borderrbottommm,
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*fifth part ends*/}
          {/*letter format starts*/}
          <div style={{ width: '100%', fontSize: '14px', float: 'left' }}>
            <p
              style={{
                fontSize: '14px',
                marginBottom: '5px',
                lineHeight: '26px',
              }}
            >
              Hello Mr./Mrs./Ms., this is{' '}
              <span
                style={{
                  marginTop: '12px',
                  color: '#656565',
                  borderBottom: borderrbottommm,
                  paddingRight: '135px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
              , the manager of the Rent-A-Center in{' '}
              <span
                style={{
                  marginTop: '12px',
                  color: '#656565',
                  borderBottom: borderrbottommm,
                  paddingRight: '135px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
              I want to welcome you as a customer and ask you a few questions
              about the delivery we made to you the other day. Is this a
              convenient time for you to answer a few questions?
            </p>
            <p style={{ fontStyle: 'italic', marginTop: '5px' }}>
              {' '}
              (If yes, continue with survey. If no, ask for a convenient time to
              call back)
            </p>
          </div>
          {/*letter format ends*/}
          {/*grid starts here*/}
          <div style={{ width: '100%', float: 'left' }}>
            <table style={{ marginBottom: '10px', fontSize: '14px' }}>
              <tbody>
                <tr style={{ width: '100%' }}>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    1. Was the delivery on time ?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    2. Was the unit&apos; operation explained to your
                    satisfaction ?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    3. Have we answered all of your questions regarding the
                    rental agreement?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    3. Have we answered all of your questions regarding the
                    rental agreement?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    4. Can you tell me when your next renewal is due and the
                    amount you will pay?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    5. Did we explain our customer referral program?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    6. Did you take advantage of our Benefits Plus program when
                    you rented your item?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingLeft: '30px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                      marginRight: '20px',
                    }}
                  >
                    If No explain to them the benefits &amp; close the deal.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    7. Do you have any other questions or is there anything I
                    can do for you?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ width: '100%', float: 'left' }}>
            <p style={{ fontSize: '14px', marginBottom: '5px' }}>Notes :</p>
            <span
              style={{
                marginTop: '20px',
                color: '#656565',
                borderBottom: borderbotttom,
                width: '100%',
                float: 'left',
              }}
            />
            <span
              style={{
                marginTop: '30px',
                color: '#656565',
                borderBottom: borderbotttom,
                width: '100%',
                float: 'left',
              }}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              <p style={{ fontSize: '14px', marginBottom: '5px' }}>
                Thank you for your time and please do not hesitate to call us at
                Rent-A-Center with any questions.
              </p>
            </div>
          </div>
          {/*grid ends here*/}
        </div>
        {/* delivery checklist inner html ends here */}
        {/* pickup receipt innerhtml starts here */}
        <div
          style={{
            borderTop: '10px solid #2179FE',
            margin: '0px',
            width: '100%',
            float: 'left',
          }}
          id="pickupreciptPrintHTML"
        >
          <div
            style={{
              width: '100%',
              fontFamily: 'Arial',
              margin: '10px auto 0px auto',
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: '100%', float: 'left' }}>
              <div
                style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
              >
                <div style={{ width: '20.33%', float: 'left' }}>
                  <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                    Pickup Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: '13.33%',
                    marginLeft: '2%',
                    marginRight: '2%',
                    float: 'left',
                    height: '100px',
                    marginBottom: '10px',
                  }}
                >
                  <Logo style={{ width: '100%', height: '100%' }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                >
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: '24%', float: 'left', textAlign: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Pickup Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '24%', float: 'left', textAlign: 'left' }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: '5px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            fontSize: '13px',
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: '22%', float: 'left', textAlign: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Pickup Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '30%', float: 'left', textAlign: 'left' }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: '5px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            fontSize: '13px',
                          }}
                        >
                          {DeliveryTime}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                >
                  <div style={{ width: '33.33%', float: 'left' }}>
                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != '' ? (
                        <p
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '13px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {StoreAddressline1 + ' , ' + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '13px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>

                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {storecity + ',' + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {normalizeinput(Storephonenum)}{' '}
                    </p>
                  </div>
                  <div style={{ width: '30.33%', float: 'left' }}>
                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {CustomerName}{' '}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== '' ? (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {CustomerAddressline1 + ',' + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}
                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != '' ? (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {customercity + ',' + customerzip + '-' + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {customercity + ',' + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: '36.33%',
                      float: 'left',
                      marginTop: '12px',
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: '100%', float: 'left' }}>
                        <tr
                          style={{
                            width: '50%',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {currentappointmentDetails && currentappointmentDetails?.customerInfo?.customerId}{' '}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Back Rent:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {/* {'$' + ' ' + NextPaymentDate} */}$ 0.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: '100%', float: 'left', marginTop: '15px' }}
                >
                  <h5
                    style={{
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      fontSize: '13px',
                      margin: '0px',
                      marginBottom: '10px',
                    }}
                  >
                    ITEMS
                  </h5>
                </div>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginBottom: '100px',
                  }}
                >
                  <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                    <thead>
                      <tr style={{ width: '100%' }}>
                        <th
                          style={{
                            width: '15px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        ></th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bindChecklist()}
                      {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: PaddingConst,
                              borderRadius: borderRadiuss,
                              border: Constinter,
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}

              {/*fourth part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <table style={{ width: '100%', float: 'left' }}>
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr
                      style={{
                        width: '18%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '15px',
                          fontWeight: 'bold',
                        }}
                      >
                        Employee Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '28%',
                        float: 'left',
                        paddingRight: '25px',
                        whiteSpace: 'nowrap',
                        marginLeft: '8px',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '18px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: '8%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '15px',
                          fontWeight: 'bold',
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '32%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '15px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  width: '13.33%',
                  marginLeft: '2%',
                  marginRight: '2%',
                  float: 'left',
                  height: '100px',
                  marginBottom: '10px',
                }}
              >
                {/* /> */}
                <Racfont style={{ width: '700%', height: '75%' }}></Racfont>
              </div>
              <div>{/* 5th part ends */}</div>
            </div>
          </div>
          {/* pickup receipt Inner html ends here */}
        </div>
        {/* pickup receipt inner htnl ends here */}
        {/* pickup checklist inner html starts here */}
        <div
          style={{
            borderTop: '10px solid #2179FE',
            margin: '0px',
            width: '100%',
            float: 'left',
          }}
          id="pickupchecklistprintHTML"
        >
          <div
            style={{
              width: '100%',
              fontFamily: 'Arial',
              margin: '10px auto 0px auto',
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: '100%', float: 'left' }}>
              <div
                style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
              >
                <div style={{ width: '20.33%', float: 'left' }}>
                  <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                    Pickup Checklist
                  </h5>
                </div>
                <div
                  style={{
                    width: '13.33%',
                    marginLeft: '2%',
                    marginRight: '2%',
                    float: 'left',
                    height: '100px',
                    marginBottom: '10px',
                  }}
                >
                  <Logo style={{ width: '100%', height: '100%' }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                >
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: '24%', float: 'left', textAlign: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Pickup Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '24%', float: 'left', textAlign: 'left' }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: '5px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            fontSize: '13px',
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: '22%', float: 'left', textAlign: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Pickup Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '30%', float: 'left', textAlign: 'left' }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: '5px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            fontSize: '13px',
                          }}
                        >
                          {DeliveryTime}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                >
                  <div style={{ width: '33.33%', float: 'left' }}>
                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != '' ? (
                        <p
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '13px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {StoreAddressline1 + ' , ' + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '13px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {storecity + ',' + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {normalizeinput(Storephonenum)}{' '}
                    </p>
                  </div>
                  <div style={{ width: '30.33%', float: 'left' }}>
                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {CustomerName}{' '}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== '' ? (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {CustomerAddressline1 + ',' + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}
                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != '' ? (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {customercity + ',' + customerzip + '-' + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {customercity + ',' + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: '36.33%',
                      float: 'left',
                      marginTop: '12px',
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: '100%', float: 'left' }}>
                        <tr
                          style={{
                            width: '50%',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {currentappointmentDetails && currentappointmentDetails?.customerInfo?.customerId}{' '}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Due Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {nextpaymentinfo.nextduedate !== undefined &&
                              nextpaymentinfo.nextduedate !== null
                              ? nextpaymentinfo.nextduedate
                              : ''}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Back Rent:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            $0.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: '100%', float: 'left', marginTop: '15px' }}
                >
                  <h5
                    style={{
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      fontSize: '13px',
                      margin: '0px',
                      marginBottom: '10px',
                    }}
                  >
                    PICKUP CHECKLIST
                  </h5>
                </div>
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                >
                  <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                    <thead>
                      <tr style={{ width: '100%' }}>
                        <th
                          style={{
                            width: '15px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        ></th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: '0px 8px 0px 8px',
                              borderRadius: '3px',
                              border: '1px solid  #545454',
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}
                      {bindChecklist()}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}
              {/* fourth part starts here */}
              <table>
                <thead />
                <tbody>
                  <tr>
                    <td>
                      <div
                        style={{
                          width: '100%',
                          float: 'left',
                          marginBottom: '250px',
                          marginTop: '10px',
                        }}
                      >
                        <h5
                          style={{
                            fontSize: '14px',
                            textDecoration: 'underline',
                            float: 'left',
                          }}
                        >
                          Pickup Information
                        </h5>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* fourth part ends here */}

              {/*fifth part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                  marginBottom: '10px',
                }}
              >
                <div style={{ width: '100%', float: 'left' }}>
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginBottom: '10px',
                      textAlign: 'center',
                      textDecorationLine: 'underline',
                    }}
                  >
                    Before merchandise is taken from the customer&apos; home,
                    please check the condition of the following areas and note
                    any damage:
                  </p>
                </div>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginBottom: '10px',
                    marginTop: '30px',
                  }}
                >
                  <div style={{ width: '15%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Wall(s)
                    </span>
                  </div>
                  <div style={{ width: '15%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      {' '}
                      Floors
                    </span>
                  </div>
                  <div style={{ width: '22%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Door(s)/Doorway(s)
                    </span>
                  </div>
                  <div style={{ width: '17%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Stairs
                    </span>
                  </div>
                  <div style={{ width: '8%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Other
                    </span>
                  </div>
                  <div
                    style={{
                      width: '15%',
                      float: 'left',
                      marginTop: '12px',
                      marginLeft: '5px',
                    }}
                  >
                    <span
                      style={{
                        borderBottom: '1px solid#545454',
                        width: '100%',
                        float: 'left',
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ width: '40%', float: 'left' }}>
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      If any boxes were checked, explain damage:
                    </p>
                  </div>
                  <div
                    style={{ width: '60%', float: 'right', marginTop: '20px' }}
                  >
                    <span
                      style={{
                        borderBottom: '1px solid#545454',
                        width: '100%',
                        float: 'left',
                      }}
                    />
                  </div>
                </div>
              </div>
              {/*-fifth part ends*/}
              {/*sixth part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <table style={{ width: '100%', float: 'left' }}>
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr
                      style={{
                        width: '18%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Customer Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '28%',
                        float: 'left',
                        paddingRight: '25px',
                        whiteSpace: 'nowrap',
                        marginLeft: '8px',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '18px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: '8%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '32%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '15px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{ width: '100%', float: 'left', marginTop: '25px' }}
                >
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr
                      style={{
                        width: '18%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Co-Worker Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '28%',
                        float: 'left',
                        paddingRight: '25px',
                        whiteSpace: 'nowrap',
                        marginLeft: '8px',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '18px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: '8%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '32%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '15px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                {/*sixth part ends*/}
              </div>
            </div>
          </div>
        </div>
        {/* pickup checklist inner html ends here */}
        <div
          style={{
            borderTop: '10px solid #2179FE',
            margin: '0px',
            width: '100%',
            float: 'left',
          }}
          id="DeliveryReceiptPrintHTML"
        >
          <div
            style={{
              width: '100%',
              fontFamily: 'Arial',
              margin: '10px auto 0px auto',
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: '100%', float: 'left' }}>
              <div
                style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
              >
                <div style={{ width: '20.33%', float: 'left' }}>
                  <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                    Delivery Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: '13.33%',
                    marginLeft: '2%',
                    marginRight: '2%',
                    float: 'left',
                    height: '100px',
                    marginBottom: '10px',
                  }}
                >
                  <Logo style={{ width: '100%', height: '100%' }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                >
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: '24%', float: 'left', textAlign: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Delivery Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '24%', float: 'left', textAlign: 'left' }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: '5px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            fontSize: '13px',
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: '22%', float: 'left', textAlign: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Delivery Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '30%', float: 'left', textAlign: 'left' }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: '5px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            fontSize: '13px',
                          }}
                        >
                          {DeliveryTime}
                          {/* 3.00-5.00 PM */}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                >
                  <div style={{ width: '33.33%', float: 'left' }}>
                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != '' ? (
                        <p
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '13px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {StoreAddressline1 + ' , ' + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '13px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {storecity + ',' + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {normalizeinput(Storephonenum)}{' '}
                    </p>
                  </div>
                  <div style={{ width: '30.33%', float: 'left' }}>
                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {CustomerName}{' '}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== '' ? (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {CustomerAddressline1 + ',' + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}

                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != '' ? (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {customercity + ',' + customerzip + '-' + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {customercity + ',' + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: '36.33%',
                      float: 'left',
                      marginTop: '12px',
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: '100%', float: 'left' }}>
                        <tr
                          style={{
                            width: '50%',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Delivery Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {currentappointmentDetails && currentappointmentDetails?.customerInfo?.customerId}{' '}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Next Payment Due:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {nextpaymentinfo.nextamountdue !== undefined &&
                              nextpaymentinfo.nextamountdue !== null
                              ? '$' + ' ' + nextpaymentinfo.nextamountdue
                              : ''}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: '100%', float: 'left', marginTop: '15px' }}
                >
                  <h5
                    style={{
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      fontSize: '13px',
                      margin: '0px',
                      marginBottom: '10px',
                    }}
                  >
                    Items
                  </h5>
                </div>
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                >
                  <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                    <thead>
                      <tr style={{ width: '100%' }}>
                        <th
                          style={{
                            width: '15px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        ></th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bindSwitchOutdata('ResendVideo')}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}
              {/*fourth part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                  marginBottom: '10px',
                }}
              >
                <div style={{ width: '100%', float: 'left' }}>
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginBottom: '10px',
                      textAlign: 'center',
                      textDecorationLine: 'underline',
                    }}
                  >
                    Before merchandise is taken from the customer&apos; home,
                    please check the condition of the following areas and note
                    any damage:
                  </p>
                </div>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginBottom: '10px',
                    marginTop: '30px',
                  }}
                >
                  <div style={{ width: '15%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Wall(s)
                    </span>
                  </div>
                  <div style={{ width: '15%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      {' '}
                      Floors
                    </span>
                  </div>
                  <div style={{ width: '22%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Door(s)/Doorway(s)
                    </span>
                  </div>
                  <div style={{ width: '17%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Stairs
                    </span>
                  </div>
                  <div style={{ width: '8%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Other
                    </span>
                  </div>
                  <div
                    style={{
                      width: '15%',
                      float: 'left',
                      marginTop: '12px',
                      marginLeft: '5px',
                    }}
                  >
                    <span
                      style={{
                        borderBottom: '1px solid#545454',
                        width: '100%',
                        float: 'left',
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ width: '40%', float: 'left' }}>
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      If any boxes were checked, explain damage:
                    </p>
                  </div>
                  <div
                    style={{ width: '60%', float: 'right', marginTop: '20px' }}
                  >
                    <span
                      style={{
                        borderBottom: '1px solid#545454',
                        width: '100%',
                        float: 'left',
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                    marginBottom: '20px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px',
                      marginTop: 10,
                      marginBottom: 10,
                      backgroundColor: "#E0EAFF"
                    }}
                  >
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckDefault-7"
                      checked={false}

                    />
                    <label
                      htmlFor="flexCheckDefault-6"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 13,
                        color: '#4A5174',
                      }}
                    >
                    </label>
                  </div>
                  <div style={{ width: '100%', float: 'left' }}>
                    <h5
                      style={{
                        textAlign: 'left',
                        fontSize: '14px',
                        marginTop: '0px',
                        textDecoration: 'underline',
                      }}
                    >
                      Accept / Decline (Check one)
                    </h5>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: '10px',
                      }}
                    />
                    <span
                      style={{
                        fontSize: '12px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      I acknowledge that Rent-A-Center has delivered, installed
                      and demonstrated, to my satisfaction, the rental property
                      listed above.
                    </span>
                  </div>
                  <div
                    style={{ width: '100%', float: 'left', marginTop: '10px' }}
                  >
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', marginBottom: '10px' }}>
                      I decline delivery by Rent-A-Center of the rental property
                      listed above.
                    </span>
                  </div>
                </div>
              </div>
              {/*-fourth part ends*/}
              {/*fifth part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <table style={{ width: '100%', float: 'left' }}>
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr
                      style={{
                        width: '18%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Customer Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '28%',
                        float: 'left',
                        paddingRight: '25px',
                        whiteSpace: 'nowrap',
                        marginLeft: '8px',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '18px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: '8%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '32%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '15px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{ width: '100%', float: 'left', marginTop: '25px' }}
                >
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr
                      style={{
                        width: '18%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Co-Worker Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '28%',
                        float: 'left',
                        paddingRight: '25px',
                        whiteSpace: 'nowrap',
                        marginLeft: '8px',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '18px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: '8%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '32%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '15px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                {/*fifth part ends*/}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '90%',
            fontFamily: 'Arial',
            margin: '10px auto 0px auto',
          }}
          id="DeliveryChecklistrentalPdf"
        >
          {/*first part starts here*/}
          <div style={{ width: '100%', float: 'left' }}>
            <div style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}>
              <div style={{ width: '30%', float: 'left' }}>
                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                  Delivery Checklist
                </h5>
              </div>
              <div
                style={{
                  width: '31%',
                  marginLeft: '2%',
                  marginRight: '2%',
                  float: 'left',
                  height: '100px',
                }}
              >
                <Logo style={{ width: '100%', height: '100%' }}></Logo>
              </div>
              <div></div>
              <table style={{ width: '35%', float: 'left', marginTop: '30px' }}>
                <thead />
                <tbody style={{ width: '100%', float: 'right' }}>
                  {/*child-parent of first widget*/}
                  <tr style={{ width: '42%', float: 'left', textAlign: 'left' }}>
                    <td
                      style={{
                        marginBottom: '0.2rem',
                        color: '#111111',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      Delivery Date:
                    </td>
                  </tr>
                  <tr style={{ width: '58%', float: 'left', textAlign: 'left' }}>
                    <td>
                      <span
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          borderBottom: '1px solid #545454',
                          fontSize: '13px',
                        }}
                      >
                        {DeliveryDate}
                      </span>
                    </td>
                  </tr>
                  <tr style={{ width: '43%', float: 'left', textAlign: 'left' }}>
                    <td
                      style={{
                        marginBottom: '0.2rem',
                        color: '#111111',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      Delivery Time:
                    </td>
                  </tr>
                  <tr style={{ width: '57%', float: 'left', textAlign: 'left' }}>
                    <td>
                      <span
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          borderBottom: '1px solid #545454',
                          fontSize: '13px',
                        }}
                      >
                        {DeliveryTime}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*first part ends here*/}
          {/*second part starts here */}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: '100%', float: 'left' }}>
              <div style={{ width: '33.33%', float: 'left' }}>
                <h5 style={{ fontSize: '12px' }}>RAC Store</h5>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    marginTop: '8px',
                    marginBottom: '10px',
                  }}
                >
                  {storename}
                </p>
                <>
                  {StoreAddressline2 != undefined &&
                    StoreAddressline2 != null &&
                    StoreAddressline2 != '' ? (
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        marginTop: '8px',
                        marginBottom: 'px',
                      }}
                    >
                      {StoreAddressline1 + ' , ' + StoreAddressline2}
                    </p>
                  ) : (
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        marginTop: '8px',
                        marginBottom: '8px',
                      }}
                    >
                      {StoreAddressline1}
                    </p>
                  )}
                </>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {' '}
                  {storecity + ',' + storezip}{' '}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {normalizeinput(Storephonenum)}
                </p>
              </div>
              <div style={{ width: '30.33%', float: 'left' }}>
                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>Customer</h5>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {CustomerName}
                </p>
                {CustomerAddressline2 !== undefined &&
                  CustomerAddressline2 !== null &&
                  CustomerAddressline2 !== '' ? (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '12px',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    {CustomerAddressline1 + ',' + CustomerAddressline2}
                  </p>
                ) : (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '12px',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    {CustomerAddressline1}
                  </p>
                )}
                {customerplus4 != undefined &&
                  customerplus4 != null &&
                  customerplus4 != '' ? (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '12px',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    {customercity + ',' + customerzip + '-' + customerplus4}
                  </p>
                ) : (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '12px',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    {customercity + ',' + customerzip}
                  </p>
                )}
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {normalizeinput(CustomerPhoneNumber)}
                </p>
              </div>
              <div style={{ width: '36.33%', float: 'left', marginTop: '12px' }}>
                <table>
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr style={{ width: '50%', textAlign: 'left', float: 'left' }}>
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                      >
                        Delivery Date:
                      </td>
                    </tr>
                    <tr style={{ width: '40%', textAlign: 'left', float: 'left' }}>
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '12px',
                        }}
                      >
                        {DeliveryDate}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                      >
                        Agreement # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Customer # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '40%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '12px',
                        }}
                      >
                        {currentappointmentDetails && currentappointmentDetails?.customerInfo?.customerId}{' '}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '51%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                      >
                        Next Payment Due:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '40%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {nextpaymentinfo.nextamountdue !== undefined &&
                          nextpaymentinfo.nextamountdue !== null
                          ? '$' + ' ' + nextpaymentinfo.nextamountdue
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/*second part ends here */}
          {/*3rd part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
              <h5
                style={{
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  fontSize: '13px',
                  margin: '0px',
                  marginBottom: '10px',
                }}
              >
                Delivery Checklist
              </h5>
            </div>
            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <table style={{ marginBottom: '20px', fontSize: '11px' }}>
                <thead>
                  <tr style={{ width: '100%' }}>
                    <th
                      style={{
                        width: '15px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    ></th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Description of Property
                    </th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Serial #
                    </th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Item #
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bindChecklist('ResendVideo')}
                </tbody>
              </table>
            </div>
          </div>
          {/*3rd part ends*/}
          {/*fourth part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              marginBottom: '4px',
              marginTop: '4px',
            }}
          >
            <div
              style={{
                fontSize: '12px',
                textDecoration: 'underline',
                float: 'left',
              }}
            >
              Delivery Information
            </div>
            <table style={{ width: '100%', float: 'left' }}>
              <thead />
              <tbody style={{ width: '100%', marginTop: '4px', float: 'left' }}>
                <tr style={{ width: '18%', float: 'left', whiteSpace: 'nowrap' }}>
                  <td
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '11px',
                      fontWeight: 'bold',
                    }}
                  >
                    CoWorker Signature:
                  </td>
                </tr>
                <tr
                  style={{
                    width: '28%',
                    float: 'left',
                    paddingRight: '25px',
                    whiteSpace: 'nowrap',
                    marginLeft: '8px',
                  }}
                >
                  <td
                    style={{
                      marginTop: '18px',
                      color: '#656565',
                      borderBottom: borderbotttom,
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </tr>
                <tr style={{ width: '8%', float: 'left', whiteSpace: 'nowrap' }}>
                  <td
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '11px',
                      fontWeight: 'bold',
                    }}
                  >
                    Date:
                  </td>
                </tr>
                <tr style={{ width: '32%', float: 'left', whiteSpace: 'nowrap' }}>
                  <td
                    style={{
                      marginTop: '15px',
                      color: '#656565',
                      borderBottom: borderbotttom,
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </div>
          {/*fourth part ends*/}
          {/*fifth part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: '100%', float: 'left' }}>
              <h5
                style={{
                  textAlign: 'center',
                  fontSize: '18px',
                  marginTop: '0px',
                  marginBottom: '5px',
                }}
              >
                For Office Use Only
              </h5>
            </div>
            <div style={{ width: '100%', float: 'left' }}>
              <span
                style={{
                  fontSize: '12px',
                  marginTop: '5px',
                  marginBottom: '5px',
                  fontStyle: 'italic',
                }}
              >
                I acknowledge that Rent-A-Center has delivered, installed and
                demonstrated, to my satisfaction, the rental property listed above.
              </span>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ width: '100%', float: 'left', marginTop: '10px' }}>
                <span
                  style={{
                    fontSize: '12px',
                    marginBottom: '10px',
                    textDecoration: 'underline',
                  }}
                >
                  Customer Call back survey
                </span>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginTop: '15px',
                marginBottom: '18px',
              }}
            >
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '35%', float: 'left' }}>
                  <span
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    Date of Call Back
                  </span>
                </div>
                <div style={{ width: '65%', float: 'right' }}>
                  <span
                    style={{
                      marginTop: '12px',
                      color: '#656565',
                      borderBottom: borderbotttom,
                      width: '80%',
                      float: 'left',
                    }}
                  />
                </div>
              </div>
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '30%', float: 'left' }}>
                  <span
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Mgr/Asst Initials
                  </span>
                </div>
                <div style={{ width: '70%', float: 'right' }}>
                  <span
                    style={{
                      marginTop: '12px',
                      color: '#656565',
                      borderBottom: borderrbottommm,
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*fifth part ends*/}
          {/*letter format starts*/}
          <div style={{ width: '100%', fontSize: '12px', float: 'left' }}>
            <p
              style={{
                fontSize: '12px',
                marginBottom: '4px',
                lineHeight: '26px',
              }}
            >
              Hello Mr./Mrs./Ms., this is{' '}
              <span
                style={{
                  marginTop: '12px',
                  color: '#656565',
                  borderBottom: borderrbottommm,
                  paddingRight: '135px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
              , the manager of the Rent-A-Center in{' '}
              <span
                style={{
                  marginTop: '12px',
                  color: '#656565',
                  borderBottom: borderrbottommm,
                  paddingRight: '135px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
              I want to welcome you as a customer and ask you a few questions about
              the delivery we made to you the other day. Is this a convenient time
              for you to answer a few questions?
            </p>
            <p style={{ fontStyle: 'italic', marginTop: '4px' }}>
              {' '}
              (If yes, continue with survey. If no, ask for a convenient time to
              call back)
            </p>
          </div>
          {/*letter format ends*/}
          {/*grid starts here*/}
          <div style={{ width: '100%', float: 'left' }}>
            <table style={{ marginBottom: '2px', fontSize: '12px' }}>
              <tbody>
                <tr style={{ width: '100%' }}>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    1. Was the delivery on time ?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    2. Was the unit&apos; operation explained to your satisfaction ?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    3. Have we answered all of your questions regarding the rental
                    agreement?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    3. Have we answered all of your questions regarding the rental
                    agreement?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    4. Can you tell me when your next renewal is due and the amount
                    you will pay?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    5. Did we explain our customer referral program?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    6. Did you take advantage of our Benefits Plus program when you
                    rented your item?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingLeft: '30px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                      marginRight: '20px',
                    }}
                  >
                    If No explain to them the benefits &amp; close the deal.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    7. Do you have any other questions or is there anything I can do
                    for you?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ width: '100%', float: 'left' }}>
            <p style={{ fontSize: '12px', marginBottom: '3px' }}>Notes :</p>
            <span
              style={{
                marginTop: '12px',
                color: '#656565',
                borderBottom: borderbotttom,
                width: '100%',
                float: 'left',
              }}
            />
            <span
              style={{
                marginTop: '18px',
                color: '#656565',
                borderBottom: borderbotttom,
                width: '100%',
                float: 'left',
              }}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                // marginTop: '20px',
                // marginBottom: '20px',
              }}
            >
              <p style={{ fontSize: '11px' }}>
                Thank you for your time and please do not hesitate to call us at
                Rent-A-Center with any questions.
              </p>
            </div>
          </div>
          {/*grid ends here*/}
        </div>
        <div
          style={{
            borderTop: '10px solid #2179FE',
            margin: '0px',
            width: '100%',
            float: 'left',
          }}
          id="DeliveryReceiptrentalPrintHTML"
        >
          <div
            style={{
              width: '100%',
              fontFamily: 'Arial',
              margin: '10px auto 0px auto',
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: '100%', float: 'left' }}>
              <div
                style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
              >
                <div style={{ width: '20.33%', float: 'left' }}>
                  <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                    Delivery Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: '13.33%',
                    marginLeft: '2%',
                    marginRight: '2%',
                    float: 'left',
                    height: '100px',
                    marginBottom: '10px',
                  }}
                >
                  <Logo style={{ width: '100%', height: '100%' }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                >
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: '24%', float: 'left', textAlign: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Delivery Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '24%', float: 'left', textAlign: 'left' }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: '5px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            fontSize: '13px',
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: '22%', float: 'left', textAlign: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Delivery Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '30%', float: 'left', textAlign: 'left' }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: '5px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            fontSize: '13px',
                          }}
                        >
                          {DeliveryTime}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                >
                  <div style={{ width: '33.33%', float: 'left' }}>
                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != '' ? (
                        <p
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '13px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {StoreAddressline1 + ' , ' + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '13px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {storecity + ',' + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {normalizeinput(Storephonenum)}{' '}
                    </p>
                  </div>
                  <div style={{ width: '30.33%', float: 'left' }}>
                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {CustomerName}{' '}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== '' ? (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {CustomerAddressline1 + ',' + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}

                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != '' ? (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {customercity + ',' + customerzip + '-' + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '13px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        {customercity + ',' + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: '36.33%',
                      float: 'left',
                      marginTop: '12px',
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: '100%', float: 'left' }}>
                        <tr
                          style={{
                            width: '50%',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Delivery Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {currentappointmentDetails && currentappointmentDetails?.customerInfo?.customerId}{' '}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '50%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginBottom: '0.2rem',
                              color: '#111111',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Next Payment Due:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: '40%',
                            marginTop: '10px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <td
                            style={{
                              marginTop: '5px',
                              color: '#656565',
                              fontSize: '13px',
                            }}
                          >
                            {nextpaymentinfo.nextamountdue !== undefined &&
                              nextpaymentinfo.nextamountdue !== null
                              ? '$' + ' ' + nextpaymentinfo.nextamountdue
                              : ''}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: '100%', float: 'left', marginTop: '15px' }}
                >
                  <h5
                    style={{
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      fontSize: '13px',
                      margin: '0px',
                      marginBottom: '10px',
                    }}
                  >
                    Items
                  </h5>
                </div>
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                >
                  <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                    <thead>
                      <tr style={{ width: '100%' }}>
                        <th
                          style={{
                            width: '15px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        ></th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: '300px',
                            textAlign: 'left',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>{bindChecklist('ResendVideo')}</tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}
              {/*fourth part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: borderrr,
                  marginBottom: '10px',
                }}
              >
                <div style={{ width: '100%', float: 'left' }}>
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginBottom: '10px',
                      textAlign: 'center',
                      textDecorationLine: 'underline',
                    }}
                  >
                    Before merchandise is taken from the customer&apos; home,
                    please check the condition of the following areas and note
                    any damage:
                  </p>
                </div>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginBottom: '10px',
                    marginTop: '30px',
                  }}
                >
                  <div style={{ width: '15%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Wall(s)
                    </span>
                  </div>
                  <div style={{ width: '15%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      {' '}
                      Floors
                    </span>
                  </div>
                  <div style={{ width: '22%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Door(s)/Doorway(s)
                    </span>
                  </div>
                  <div style={{ width: '17%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Stairs
                    </span>
                  </div>
                  <div style={{ width: '8%', float: 'left' }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Other
                    </span>
                  </div>
                  <div
                    style={{
                      width: '15%',
                      float: 'left',
                      marginTop: '12px',
                      marginLeft: '5px',
                    }}
                  >
                    <span
                      style={{
                        borderBottom: '1px solid#545454',
                        width: '100%',
                        float: 'left',
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ width: '40%', float: 'left' }}>
                    <p
                      style={{
                        fontSize: '13px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      If any boxes were checked, explain damage:
                    </p>
                  </div>
                  <div
                    style={{ width: '60%', float: 'right', marginTop: '20px' }}
                  >
                    <span
                      style={{
                        borderBottom: '1px solid#545454',
                        width: '100%',
                        float: 'left',
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                    marginBottom: '20px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px',
                      marginTop: 10,
                      marginBottom: 10,
                      backgroundColor: "#E0EAFF"
                    }}
                  >
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckDefault-7"
                      checked={false}

                    />
                    <label
                      htmlFor="flexCheckDefault-6"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 13,
                        color: '#4A5174',
                      }}
                    >
                    </label>
                  </div>
                  <div style={{ width: '100%', float: 'left' }}>
                    <h5
                      style={{
                        textAlign: 'left',
                        fontSize: '14px',
                        marginTop: '0px',
                        textDecoration: 'underline',
                      }}
                    >
                      Accept / Decline (Check one)
                    </h5>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      float: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: '10px',
                      }}
                    />
                    <span
                      style={{
                        fontSize: '12px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      I acknowledge that Rent-A-Center has delivered, installed
                      and demonstrated, to my satisfaction, the rental property
                      listed above.
                    </span>
                  </div>
                  <div
                    style={{ width: '100%', float: 'left', marginTop: '10px' }}
                  >
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: '10px',
                      }}
                    />
                    <span style={{ fontSize: '12px', marginBottom: '10px' }}>
                      I decline delivery by Rent-A-Center of the rental property
                      listed above.
                    </span>
                  </div>
                </div>
              </div>
              {/*-fourth part ends*/}
              {/*fifth part starts*/}
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <table style={{ width: '100%', float: 'left' }}>
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr
                      style={{
                        width: '18%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Customer Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '28%',
                        float: 'left',
                        paddingRight: '25px',
                        whiteSpace: 'nowrap',
                        marginLeft: '8px',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '18px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: '8%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '32%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '15px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{ width: '100%', float: 'left', marginTop: '25px' }}
                >
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr
                      style={{
                        width: '18%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Co-Worker Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '28%',
                        float: 'left',
                        paddingRight: '25px',
                        whiteSpace: 'nowrap',
                        marginLeft: '8px',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '18px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: '8%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '32%',
                        float: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '15px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          width: '100%',
                          float: 'left',
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                {/*fifth part ends*/}
              </div>
            </div>
          </div>
        </div>
        {/* delivery addendum inner html starts here */}
        <div id="deliveryaddendum">
          <meta charSet="UTF-8" />
          <title>Delivery Addendum And Confirmation</title>
          <div style={{ width: '100%', float: 'left' }}>
            {/*Heading section starts here*/}
            <div
              style={{
                width: '100%',
                float: 'left',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              <h3 style={{ fontWeight: 'bold' }}>
                Delivery Addendum and Confirmation
              </h3>
            </div>
            {/*Heading section ends here*/}
            {/*detail section starts here*/}
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <div style={{ width: '50%', float: 'left' }}>
                <label
                  style={{
                    float: 'left',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  Date:
                </label>
                <span
                  style={{
                    borderBottom: '1px solid #000',
                    paddingBottom: '10px',
                    width: '30%',
                    float: 'left',
                    marginLeft: '15px',
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  {DeliveryDate}
                </span>
              </div>
              <div style={{ width: '50%', float: 'left' }}>
                <label
                  style={{
                    float: 'left',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  Agreement Number:
                </label>
                <span
                  style={{
                    borderBottom: '1px solid #000',
                    paddingBottom: '10px',
                    width: '42%',
                    float: 'left',
                    marginLeft: '15px',
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  {currentappointmentDetails && currentappointmentDetails?.agreement?.agreementNumber}
                </span>
              </div>
            </div>
            <div style={{ width: '100%', float: 'left', marginBottom: '30px' }}>
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '20%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
                    Lessor:
                  </label>
                </div>
                <div style={{ width: '80%', float: 'left' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #000',
                      paddingBottom: '10px',
                      width: '80%',
                      float: 'left',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {storename}
                  </span>
                  {StoreAddressline2 !== undefined &&
                    StoreAddressline2 !== null &&
                    StoreAddressline2 !== '' ? (
                    <span
                      style={{
                        borderBottom: '1px solid #000',
                        paddingBottom: '10px',
                        width: '80%',
                        float: 'left',
                        marginTop: '5px',
                        fontSize: '14px',
                        fontWeight: 600,
                      }}
                    >
                      {StoreAddressline1 + ' , ' + StoreAddressline2}
                    </span>
                  ) : <span
                    style={{
                      borderBottom: '1px solid #000',
                      paddingBottom: '10px',
                      width: '80%',
                      float: 'left',
                      marginTop: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {StoreAddressline1 + ' , ' + StoreAddressline2}
                  </span>
                  }

                  <span
                    style={{
                      borderBottom: '1px solid #000',
                      paddingBottom: '10px',
                      width: '80%',
                      float: 'left',
                      marginTop: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {storecity + ',' + storezip}
                  </span>
                </div>
              </div>
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '25%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
                    Consumer:
                  </label>
                </div>
                <div style={{ width: '65%', float: 'left' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #000',
                      paddingBottom: '10px',
                      width: '91%',
                      float: 'left',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {CustomerName}
                  </span>
                  <span
                    style={{
                      borderBottom: '1px solid #000',
                      paddingBottom: '10px',
                      width: '91%',
                      float: 'left',
                      marginTop: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {CustomerAddressline1 + ',' + CustomerAddressline2}
                  </span>
                  <span
                    style={{
                      borderBottom: '1px solid #000',
                      paddingBottom: '10px',
                      width: '91%',
                      float: 'left',
                      marginTop: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {customercity + ',' + customerzip + '-' + customerplus4}
                  </span>
                </div>
              </div>
            </div>
            {/*detail section ends here*/}
            {/*Agreement parah section starts here*/}
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <p
                style={{
                  paddingRight: '70px',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                Upon execution by you, this document will be incorporated into
                and become part of the Rental-Purchase / Lease Purchase
                Agreement referenced above ( the "Agreement"). By accepting
                delivery and signing below, you are confirming that the
                merchandise described below has been delivered and that the
                serial number(s) listed correctly identifies the items listed on
                your Agreement.
              </p>
            </div>
            {/*Agreement parah section ends here*/}
            {/*DESCRIPTION table section starts here*/}
            <div style={{ width: '100%', float: 'left', marginBottom: '60px' }}>
              <h4
                style={{
                  padding: '5px',
                  fontSize: '16px',
                  marginBottom: '0px',
                  borderTop: '1px solid #000',
                  borderBottom: '1px solid #000',
                  borderLeft: '1px solid #000',
                  borderRight: '1px solid #000',
                }}
              >
                {' '}
                DESCRIPTION OF DELIVERED MERCHANDISE
              </h4>
              <table
                style={{
                  width: '100%',
                  float: 'left',
                  borderBottom: '1px solid #000',
                  borderLeft: '1px solid #000',
                  borderRight: '1px solid #000',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        paddingTop: '12px',
                        fontSize: '14px',
                        fontWeight: 600,
                      }}
                    >
                      Item #
                    </td>
                    <td
                      style={{
                        paddingTop: '12px',
                        fontSize: '14px',
                        fontWeight: 600,
                      }}
                    >
                      Item Description
                    </td>
                    <td
                      style={{
                        paddingTop: '12px',
                        fontSize: '14px',
                        fontWeight: 600,
                      }}
                    >
                      Serial #
                    </td>
                    <td
                      style={{
                        paddingTop: '12px',
                        fontSize: '14px',
                        fontWeight: 600,
                      }}
                    >
                      Model #
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingTop: '10px',
                        fontWeight: 600,
                        fontSize: '14px',
                        paddingBottom: '25px',
                      }}
                    >
                      <span
                        style={{
                          borderBottom: '1px solid #000',
                          paddingBottom: '10px',
                        }}
                      >
                        9999207978448
                      </span>{' '}
                    </td>
                    <td
                      style={{
                        paddingTop: '10px',
                        fontWeight: 600,
                        fontSize: '14px',
                        paddingBottom: '25px',
                      }}
                    >
                      <span
                        style={{
                          borderBottom: '1px solid #000',
                          paddingBottom: '10px',
                        }}
                      >
                        MAYTAG 7.0 CF. ELECTRIC DRYER 15
                      </span>
                    </td>
                    <td
                      style={{
                        paddingTop: '10px',
                        fontWeight: 600,
                        fontSize: '14px',
                        paddingBottom: '25px',
                      }}
                    >
                      <span
                        style={{
                          borderBottom: '1px solid #000',
                          paddingBottom: '10px',
                        }}
                      >
                        999922029478746059
                      </span>
                    </td>
                    <td
                      style={{
                        paddingTop: '10px',
                        fontWeight: 600,
                        fontSize: '14px',
                        paddingBottom: '25px',
                      }}
                    >
                      <span
                        style={{
                          borderBottom: '1px solid #000',
                          paddingBottom: '10px',
                        }}
                      >
                        MEDC465HW
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*DESCRIPTION table section ends here*/}
            {/*PLEASE INITIAL section starts here*/}
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <label
                style={{
                  marginBottom: '25px',
                  width: '100%',
                  float: 'left',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                PLEASE INITIAL ONE OF THE FOLLOWING:
              </label>
              <div
                style={{
                  width: '100%',
                  float: 'left',
                  marginBottom: '15px',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                <span
                  style={{
                    borderBottom: '1px solid #000',
                    width: '45px',
                    float: 'left',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                />
                <label
                  style={{
                    marginBottom: '10px',
                    marginLeft: '15px',
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  I acknowledge that Rent-A-Center has delivered the rental
                  property listed above.
                </label>
              </div>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '15px' }}
              >
                <span
                  style={{
                    borderBottom: '1px solid #000',
                    width: '45px',
                    float: 'left',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                />
                <label
                  style={{
                    marginBottom: '10px',
                    marginLeft: '15px',
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  I decline delivery by Rent-A-Center of the rental property
                  listed above.
                </label>
              </div>
              <p
                style={{
                  fontWeight: 'bold',
                  marginBottom: '40px',
                  fontSize: '16px',
                }}
              >
                YOU AGREE BY SIGNING THIS DELIVERY CONFIRMATION SUPPLEMENT THAT
                (1) YOU HAVE READ IT, (2) THE INFORMATION ABOVE IS CORRECTLY
                STATED, AND (3) YOU RECEIVED A COPY OF IT.
              </p>
            </div>
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <div style={{ width: '50%', float: 'left' }}>
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                >
                  <label
                    style={{
                      borderTop: '1px solid #000',
                      paddingTop: '10px',
                      width: '75%',
                      float: 'left',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER NAME (PRINT)
                  </label>
                </div>
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                >
                  <label
                    style={{
                      borderTop: '1px solid #000',
                      paddingTop: '10px',
                      width: '75%',
                      float: 'left',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER SIGNATURE
                  </label>
                </div>
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                >
                  <label
                    style={{
                      borderTop: '1px solid #000',
                      paddingTop: '10px',
                      width: '35%',
                      float: 'left',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    DATE
                  </label>
                </div>
              </div>
              <div style={{ width: '50%', float: 'left' }}>
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                >
                  <label
                    style={{
                      borderTop: '1px solid #000',
                      paddingTop: '10px',
                      width: '75%',
                      float: 'left',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER NAME (PRINT)
                  </label>
                </div>
                <div
                  style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                >
                  <label
                    style={{
                      borderTop: '1px solid #000',
                      paddingTop: '10px',
                      width: '75%',
                      float: 'left',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER SIGNATURE
                  </label>
                </div>
              </div>
            </div>
            {/*PLEASE INITIAL section ends here*/}
          </div>
        </div>
        {/* delivery addendum inner html ends here */}
      </Grid>
    </div>
  );
}

export default Calender;
